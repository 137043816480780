import { features } from 'reactApp/constants/products/features';
import { Product } from 'reactApp/types/Billing';
import { TariffFeatureListItemType } from 'reactApp/ui/TariffCardNew/components/TariffFeatureList.types';
import { isMonth6Period } from 'reactApp/utils/Period';

const getProductFeatures = (product: Product, compactList: boolean) => {
    if (product.isBase) {
        return compactList ? features.freeQuotaCompact : features.singleQuotaBase;
    }

    if (product.isSingleQuota) {
        if (product.isProfessional && product.isDisko) {
            if (isMonth6Period(product.period)) {
                return features.quotaProfessional_6m;
            }
            return features.quotaProfessional;
        }
        return compactList ? features.quotaCompact : features.quotaRegular;
    } else if (product.isProfessional) {
        if (isMonth6Period(product.period)) {
            return features.professional_new6M;
        }
        return features.professional_new;
    }
    return features.paidWithIcon;
};

/**
 * Хук для определения какой список фичей будут отображаться в карточке продукта
 * @param product
 */
export const useFeatures = (product: Product, enableJsxText = true, compactList = false) => {
    const productFeatures = getProductFeatures(product, compactList);

    const { featureList, footerFeatureList } = productFeatures.reduce(
        (acc, feature) => {
            const listItem = {
                key: feature.key,
                text: enableJsxText && feature.textJsx ? feature.textJsx : feature.text,
                icon: feature.icon,
                onClick: feature.onClick,
            };

            if (feature.delimiters) {
                acc.footerFeatureList.push(listItem);
            } else {
                acc.featureList.push(listItem);
            }

            return acc;
        },
        {
            featureList: <TariffFeatureListItemType[]>[],
            footerFeatureList: <TariffFeatureListItemType[]>[],
        }
    );

    return [featureList, footerFeatureList.length ? footerFeatureList : undefined] as const;
};
