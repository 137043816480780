import { useSelector } from 'react-redux';
import { crossedFullPriceInTariffCard } from 'reactApp/appHelpers/featuresHelpers';
import { getPretextPeriodForProduct } from 'reactApp/modules/products/products.helpers';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { IProduct } from 'reactApp/modules/products/products.types';
import { RootState } from 'reactApp/store';
import { Product } from 'reactApp/types/Billing';
import { getPeriodName, getPeriodNameAsWord, isMonthPeriod, isYear2Period } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

export const useProduct = ({ productId, product: incomingProduct }: { productId: string; product?: Product }) => {
    const product: IProduct = useSelector((state: RootState) => incomingProduct || ProductsSelectors.getProductById(state, productId));

    const price = product?.discountPrice || product?.price || 0;
    const space = product?.space?.value || '';
    let trialPeriod = (!product?.isForceDiscountTrial && product?.trialPeriod) || product?.discountPeriod;

    const formattedPrice = `${formatPrice(price)}\u00A0₽`;
    const fullPrice = `${formatPrice(product?.price)}\u00A0₽`;

    const isMonth = isMonthPeriod(product.period);

    if (isMonth && product?.discountPeriodCount && crossedFullPriceInTariffCard.month) {
        trialPeriod = `${product.discountPeriodCount}m`;
    }

    if (trialPeriod && price) {
        return `${
            product?.isForceDiscountTrial && product?.isTransit ? 'Тариф начнет действовать после окончания предыдущей подписки. ' : ''
        }${getPretextPeriodForProduct(trialPeriod)} ${getPeriodNameAsWord(trialPeriod, true)} оплата составит ${fullPrice} ${
            isYear2Period(product.period) ? 'за' : 'в'
        } ${getPeriodName(product.period)}.`;
    }

    return `Подписка на ${space} ${price && `за ${formattedPrice}`} продлевается автоматически через ${getPeriodName(
        product.period
    )} на аналогичный период.`;
};
