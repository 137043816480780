import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { noopVoid } from 'reactApp/utils/helpers';

export const openVkBuyModalWithSidebar = ({ onClose = noopVoid, ...data }) =>
    openPopupHelper({
        popupName: popupNames.VKBUY_MODAL,
        data,
        onClose,
    });
