import { AppRoot } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BUILD_VERSION, IS_DOCUMENTS_DOMAIN, IS_MY_TEAM, IS_PROMO_LANDOS, IS_REACT_LANDINGS } from 'reactApp/appHelpers/configHelpers';
import { useOrientation } from 'reactApp/hooks/useOrientation';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { initMedia } from 'reactApp/modules/media/media.module';
import { AppRouter } from 'reactApp/ui/App/AppRouter';
import { DocumentsRouter } from 'reactApp/ui/App/DocumentsRouter';
import { useDataContainer } from 'reactApp/ui/App/effect/useDataContainer';
import { RotateScreen } from 'reactApp/ui/RotateScreen/RotateScreen';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

initMedia();

export const App = () => {
    const { title } = useDataContainer();
    const isPhone = EnvironmentSelectors.isPhone();
    const { isPortrait } = useOrientation();

    useEffect(() => {
        sendDwh({
            eventCategory: ECategoryGa.entered,
            action: 'app_load',
            dwhData: { build: BUILD_VERSION, place: isPhone ? 'touch' : 'web' },
        });
    }, [isPhone]);
    if (isPhone && IS_MY_TEAM && !isPortrait) {
        return <RotateScreen />;
    }
    return (
        <>
            {/* На реакт-лендосах ставим мета в Meta, на обычных - в самих лендосах */}
            {!IS_REACT_LANDINGS && !IS_PROMO_LANDOS && (
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            )}
            {/**
             * Подключаем vkui в режиме partial.
             * Почитать подробнее [тут]{@link https://vkcom.github.io/VKUI/#/Modes}
             */}
            <AppRoot mode="partial">{IS_DOCUMENTS_DOMAIN ? <DocumentsRouter /> : <AppRouter />}</AppRoot>
        </>
    );
};
