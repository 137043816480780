export enum ETariffLabelKind {
    blue = 'blue',
    green = 'green',
    pink = 'pink',
    turquoise = 'turquoise',
    violet = 'violet',
    yellow = 'yellow',
    grey = 'grey',
    white = 'white',
    actionBlack = 'action-black',
}

export enum ETariffLabelRadius {
    r24 = 'radius24',
    r22 = 'radius22',
}

export interface ITariffLabelProps {
    kind?: ETariffLabelKind;
    radius?: ETariffLabelRadius;
    dataQa?: string;
}
