import { Icon12Cancel } from '@vkontakte/icons';
import theme from '@vkontakte/vkui-tokens/themes/cloud/cssVars/theme';
import { ReactComponent as DiskoIcon } from 'img/icons/disko.svg';
import { ReactComponent as VkMusicIcon } from 'img/icons/vk_music.svg';
import React from 'react';
import { isFamilySubsInTariff, isOnlyWebAdInTariffs } from 'reactApp/appHelpers/featuresHelpers';
import { IFeatureData } from 'reactApp/constants/products/features';
import { openDisko } from 'reactApp/constants/products/features.constants';

const baseQuotaFeatures: IFeatureData[] = [
    {
        text: isOnlyWebAdInTariffs ? 'Без рекламы в веб-версии' : 'Без рекламы в Почте и Облаке',
        textJsx: !isOnlyWebAdInTariffs && (
            <>
                <a
                    target="_blank"
                    href="https://cloud.mail.ru/promo/quota?utm_source=subscriptions&utm_medium=organic&utm_campaign=single_quota"
                    rel="noreferrer"
                >
                    Без рекламы
                </a>
                {'\u00A0'}в Почте и Облаке
            </>
        ),
        key: 'ad',
    },
    {
        text: 'Загрузка файлов до\u00A0100\u00A0ГБ в Облако',
        key: 'upload',
    },
    {
        text: 'Отправка файлов до\u00A0100\u00A0ГБ в письмах',
        key: 'sending',
    },
    {
        text: 'Автоудаление папок',
        key: 'autodelete',
    },
    isFamilySubsInTariff
        ? {
              text: 'Делитесь местом с близкими',
              key: 'family',
          }
        : {
              text: 'Приоритетная поддержка',
              key: 'support',
          },
];
const baseQuotaProFeatures: IFeatureData[] = [
    {
        text: 'Лицензия Диск-О: на год в подарок',
        key: 'disko',
        icon: <DiskoIcon width={16} height={16} />,
        onClick: openDisko,
        delimiters: true,
    },
    {
        text: 'VK Музыка бесплатно на 3\u00A0месяца',
        key: 'combo',
        icon: <VkMusicIcon width={16} height={16} viewBox="0 0 18 18" />,
        delimiters: true,
    },
];

const baseQuotaProFeatures_6m = [
    {
        ...baseQuotaProFeatures[0],
        text: 'Лицензия ДискО: на 6\u00A0месяцев в подарок',
    },
    {
        ...baseQuotaProFeatures[1],
        text: 'VK Музыка бесплатно на 1\u00A0месяц',
    },
];

const baseQuotaCompact: IFeatureData[] = [
    {
        text: isOnlyWebAdInTariffs ? 'Без рекламы в веб-версии' : 'Без рекламы в Почте и Облаке',
        key: 'ad',
    },
    {
        text: 'Загрузка файлов до\u00A0100\u00A0ГБ',
        key: 'upload',
    },
    isFamilySubsInTariff
        ? {
              text: 'Делитесь местом с близкими',
              key: 'family',
          }
        : {
              text: 'Быстрый ответ поддержки',
              key: 'support',
          },
    {
        text: 'И другие возможности',
        textJsx: (
            <>
                И другие{'\u00A0'}
                <a href="#benefits">возможности</a>
            </>
        ),
        key: 'benefits',
    },
];

const baseFreeQuotaCompact: IFeatureData[] = [
    {
        text: isOnlyWebAdInTariffs ? 'Реклама в веб-версии' : 'Реклама в Почте и Облаке',
        key: 'ad',
        icon: <Icon12Cancel fill={theme.colorIconSecondary.normal.value} width={14} height={14} />,
    },
    {
        text: 'Нет загрузки больших файлов',
        key: 'upload',
        icon: <Icon12Cancel fill={theme.colorIconSecondary.normal.value} width={14} height={14} />,
    },
    isFamilySubsInTariff
        ? {
              text: 'Делиться местом нельзя',
              key: 'family',
              icon: <Icon12Cancel fill={theme.colorIconSecondary.normal.value} width={14} height={14} />,
          }
        : {
              text: 'Нет быстрой поддержки',
              key: 'support',
              icon: <Icon12Cancel fill={theme.colorIconSecondary.normal.value} width={14} height={14} />,
          },
    {
        text: 'Нет дополнительных возможностей',
        textJsx: (
            <>
                Нет{'\u00A0'}дополнительных
                <br />
                <a href="#benefits">возможностей</a>
            </>
        ),
        key: 'benefits',
        icon: <Icon12Cancel fill={theme.colorIconSecondary.normal.value} width={14} height={14} />,
    },
];

export const quotaFeatures: Record<string, IFeatureData[]> = {
    quotaTariffsLanding: baseQuotaFeatures,
    freeQuotaCompact: baseFreeQuotaCompact,
    quotaRegular: baseQuotaFeatures,
    quotaCompact: baseQuotaCompact,
    quotaProfessional: [...baseQuotaFeatures, ...baseQuotaProFeatures],
    quotaProfessional_6m: [...baseQuotaFeatures, ...baseQuotaProFeatures_6m],
};
