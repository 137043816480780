import { clone } from 'ramda';
import { CloudItem } from 'reactApp/modules/storage/storage.types';
import { Url } from 'reactApp/types/Tree';

export enum DomainType {
    CLOUD = 'cloud',
    ALL_DOCUMENTS = 'doc',
}

export const changeDomainTo = (origin: string, changeTo: DomainType) => {
    if (!origin || !Object.values(DomainType).includes(changeTo)) {
        return;
    }

    const url = origin.split('.');
    const indexToChange = url.length > 3 ? 1 : 0;
    const domainToReplace = changeTo === DomainType.CLOUD ? DomainType.ALL_DOCUMENTS : DomainType.CLOUD;

    // Проверяем вхождение DomainType в том месте, где мы хотим заменить домен.
    if (!url[indexToChange].includes(domainToReplace)) {
        return;
    }

    url[indexToChange] = url[indexToChange].replace(domainToReplace, changeTo);

    return url.join('.');
};

export const changeDocumentDomainToCloud = (origin: string) => changeDomainTo(origin, DomainType.CLOUD);

export const changeCloudToDocumentDomain = (origin: string) => changeDomainTo(origin, DomainType.ALL_DOCUMENTS);

const convertItemUrlToDocumentsDomain = (urlMap: Url) => {
    const result = {} as Url;

    for (const key in urlMap) {
        result[key] = urlMap[key].replace('cloud', 'doc');
    }

    return result;
};

export const convertItemToDocumentsDomain = (item: CloudItem) => {
    if (!item || !item?.url) {
        return item;
    }

    const clonedItem = clone(item);

    clonedItem.url = convertItemUrlToDocumentsDomain(clonedItem.url as Url);

    return clonedItem;
};
