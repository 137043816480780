import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ACTION_PROMO, IS_REACT_PROMO_QUOTA_TARIFFS_PAGE, IS_SOCIAL_USER } from 'reactApp/appHelpers/configHelpers';
import { abEternalPromoYearTariffs } from 'reactApp/appHelpers/featuresHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { CardSelectors } from 'reactApp/modules/creditCard/creditCard.selectors';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getAbOpenBrowserPayment } from 'reactApp/modules/features/features.selectors';
import { sendPaymentLetter } from 'reactApp/modules/paidInfo/paidInfo.module';
import { openPromocodeGiftModalAfterBuy } from 'reactApp/modules/payment/payment.module';
import { authPopup } from 'reactApp/modules/ph/ph.thunkActions';
import { initProducts, updateProducts } from 'reactApp/modules/products/products.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { getSocialLifeCycleState, isEmailAbsent } from 'reactApp/modules/socialUser/socialUser.selectors';
import { updateSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { useCardHandlers } from 'reactApp/sections/MobileSubscriptionsPage/hooks/useCardHandlers';
import { normalizeMobileTariffList } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.helpers';
import { showNoTariffDialog } from 'reactApp/sections/SubscriptionsPage/SubscriptionsPage.helpers';
import { RootState } from 'reactApp/store';
import { Product } from 'reactApp/types/Billing';
import { renderCardModal } from 'reactApp/ui/AddCardModal/AddCardModal.helpers';
import { renderConfirmEmailMobile } from 'reactApp/ui/ConfirmEmail/ConfirmEmailMobile/ConfirmEmailMobile.helpers';
import { renderSubscriptionsModal } from 'reactApp/ui/Mobile/SubscriptionsModal/SubscriptionsModal.helpers';
import { openRemoveCardModal } from 'reactApp/ui/RemoveCardModal/RemoveCardModal.helpers';
import { openTariffBuyDialog } from 'reactApp/ui/TariffBuy/TariffBuy.helpers';
import { getTariffsByTab } from 'reactApp/ui/TariffSectionNew/TariffsSection.selectors';
import { ACTIONS_DEFS, ETabsName } from 'reactApp/ui/TariffsSection/TariffSection.constants';
import { getDiscount } from 'reactApp/utils/getDiscount';
import { ECategoryGa, EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { getMonthes, getPeriodName, isMonthPeriod, isYearPeriod } from 'reactApp/utils/Period';
import { sendPostMessage } from 'reactApp/utils/windowHelper';
import { EQueryParams } from 'server/helpers/getRequestParams';

import { TABS_MAP } from './MobileSubscriptionsPage.constants';
import { EPostMessageBuy, IClient, TOpenTariffBuy } from './MobileSubscriptionsPage.types';
import { openPayInBrowserDialog } from './PayInBrowser/PayInBrowser.helpers';
// tempexp_13600-next-line
import { ITariffCardProduct } from './TariffCard/TariffCard.types';

export const useMarketingPromo = () => {
    const { hash } = useLocation();

    const specialTariffs = useSelector((state: RootState) => getTariffsByTab(state, ETabsName.special, ACTION_PROMO, true));
    /**
     * FIXME CLOUDWEB-13426 Сейчас я частично перенес селекторы с декстопа
     * но полноценно это будет сделано в задаче. По этому тут такие мапинги на старый интерфейс
     */
    const marketingTariffs = useMemo(
        () =>
            specialTariffs.reduce((acc, item) => {
                acc[item.space.original] = [item.products.yearProduct, item.products.monthProduct];
                return acc;
            }, <Record<number, [Product, Product | undefined]>>{}),
        [specialTariffs]
    );

    // tempexp_13695-start
    const isUsualTariffs = hash === TABS_MAP[ETabsName.usual];
    const isAction = Boolean(ACTION_PROMO && Object.keys(marketingTariffs).length && !isUsualTariffs);
    const marketingPromo = isAction && ACTION_PROMO ? ACTIONS_DEFS[ACTION_PROMO] : undefined;

    return {
        marketingPromo,
        marketingTariffs,
    };
};

export const useSendGa = () => {
    const { client } = getQueryParams() || {};

    return useCallback(
        (
            label: string,
            extraParams?: {
                name_button?: string;
            }
        ) => {
            sendPaymentGa({
                action: IS_REACT_PROMO_QUOTA_TARIFFS_PAGE ? ECategoryGa.quotaTariffs : EPaymentGa.touchSubs,
                app: client,
                vk_pay: true,
                label,
                group: 'none',
                ...extraParams,
            });
        },
        []
    );
};

export const useOpenPaymentInBrowser = () => {
    const sendGa = useSendGa();
    const dispatch = useDispatch();

    const onOpen = useCallback(
        (id: string) => {
            sendGa('link-open');
            dispatch(sendPaymentLetter({ id }));
        },
        [dispatch, sendGa]
    );

    const onClose = useCallback(() => sendGa('link-close'), [sendGa]);
    const onCopy = useCallback(() => sendGa('link-copy'), [sendGa]);

    return useCallback(
        (id: string) => {
            openPayInBrowserDialog({ onOpen, onCopy, onClose, id });
        },
        [onClose, onCopy, onOpen]
    );
};

export const useTariffBuyDialog = () => {
    const dispatch = useDispatch();

    const sendGa = useSendGa();
    const isSocialUserWithoutEmail = useSelector(isEmailAbsent);
    const { client } = getQueryParams() || {};
    const isWebview = useSelector(EnvironmentSelectors.isWebview);
    const browserPaymentFeature = useSelector(getAbOpenBrowserPayment);
    const openBrowserPayment = useOpenPaymentInBrowser();
    const isSuccess = useRef(false);

    const closeTariffBuy = useCallback(
        (productId) => {
            sendGa('tariff-close');

            if (isSuccess?.current) {
                dispatch(openPromocodeGiftModalAfterBuy({ productId }));
            }
        },
        [sendGa]
    );

    const onSuccess = useCallback(
        (productId, price) => {
            sendGa('tariff-success');
            sendPostMessage({
                paymentResult: EPostMessageBuy.SUCCESS,
                productId,
                price,
            });

            isSuccess.current = true;
            dispatch(updateSubscriptionsRequest({ showSnackbar: true }));
            dispatch(updateUser());
            dispatch(updateProducts());
        },
        [sendGa]
    );

    const onError = useCallback(() => {
        sendGa('tariff-error');

        sendPostMessage({
            paymentResult: EPostMessageBuy.FAILED,
        });
    }, [sendGa]);

    const openBuyDialog = useCallback(
        (id: string, price?: number) => {
            openTariffBuyDialog({
                id,
                onClose: () => closeTariffBuy(id),
                onSuccess: () => onSuccess(id, price),
                onError,
                isMobile: true,
            });
            isSuccess.current = false;
        },
        [closeTariffBuy, onError, onSuccess]
    );

    const openTariffBuy: TOpenTariffBuy = useCallback(
        ({ id, period, price, shouldSendGa = true }) => {
            if (shouldSendGa) {
                sendGa('tariff-click', {
                    name_button: period,
                });
            }

            // Социальным пользователям показываем окно привязки почты
            if (IS_SOCIAL_USER && isSocialUserWithoutEmail) {
                renderConfirmEmailMobile({ onSuccess: () => openBuyDialog(id, price) });
                return;
            }

            // В эксперименте андроиду показываем окно "Скопируйте ссылку"
            if (client === IClient.ANDROID && isWebview && browserPaymentFeature === 'b') {
                openBrowserPayment(id);
                return;
            }

            openBuyDialog(id, price);
        },
        [browserPaymentFeature, client, isSocialUserWithoutEmail, isWebview, openBrowserPayment, openBuyDialog, sendGa]
    );

    return { closeTariffBuy, openTariffBuy };
};

export const useProductFromQuery = () => {
    const sendGa = useSendGa();
    const queryParams = getQueryParams();
    const { openTariffBuy } = useTariffBuyDialog();
    const productFromQuery = useSelector((state: RootState) =>
        ProductsSelectors.getProductById(state, queryParams?.productId || queryParams?.tariffId)
    );

    const productsState = useSelector(ProductsSelectors.getLifeCycleState);

    const isFromAction = queryParams?.action === 'request-payment';
    const id = isFromAction ? productFromQuery?.id : '';
    const isFromActionAndLoaded = isFromAction && productsState.isLoaded;

    useEffect(() => {
        if (!isFromActionAndLoaded) {
            return;
        }

        if (!productFromQuery?.available || !id) {
            showNoTariffDialog();
            return;
        }

        sendGa('tariff-request');
        openTariffBuy({ id, price: productFromQuery?.discountPrice || productFromQuery?.price, shouldSendGa: false });
    }, [id, isFromActionAndLoaded]);
};

export const useRemoveCardModal = () => {
    const queryParams = getQueryParams();
    const showModal = queryParams?.action === 'delete-card';

    useEffect(() => {
        if (showModal) {
            openRemoveCardModal();
        }
    }, []);
};

export const useAddCardModal = () => {
    const { showAddCardModal, onSuccessAddCard, onCloseAddCard, onUnlinkCard } = useCardHandlers();

    useEffect(() => {
        if (showAddCardModal) {
            renderCardModal({
                size: 'tiny',
                isMobile: true,
                onSuccess: onSuccessAddCard,
                onClose: onCloseAddCard,
                onUnlinkCard,
            });
        }
    }, []);
};

export const useSubscriptionsModal = () => {
    const queryParams = getQueryParams();

    useEffect(() => {
        if (queryParams[EQueryParams.my_subscriptions]) {
            renderSubscriptionsModal();
        }
    }, []);
};

export const useLoadProductsOrLogin = () => {
    const dispatch = useDispatch();

    const login = useSelector(UserSelectors.getLogin);
    const sendGa = useSendGa();

    useEffect(() => {
        if (!login) {
            dispatch(
                authPopup({
                    closable: false,
                    loginRequest: true,
                    successPage: window.location.href,
                })
            );

            return;
        }

        dispatch(initProducts());

        sendGa('list-show');
    }, [dispatch, login, sendGa]);
};

export const useTariffs = () => {
    const usualTariffs = useSelector((state: RootState) => getTariffsByTab(state, ETabsName.usual));
    /**
     * FIXME CLOUDWEB-13426 Сейчас я частично перенес селекторы с декстопа
     * но полноценно это будет сделано в задаче. По этому тут такие мапинги на старый интерфейс
     */
    const productsBySpace = useMemo(
        () =>
            usualTariffs.reduce((acc, item) => {
                acc[item.space.original] = [item.products.yearProduct, item.products.monthProduct];
                return acc;
            }, <Record<number, [Product, Product | undefined]>>{}),
        [usualTariffs]
    );

    const { marketingPromo, marketingTariffs } = useMarketingPromo();

    if (marketingPromo) {
        return Object.entries(marketingTariffs).map(([bytes, products]) => {
            const countSnapshotsBySpace = Math.round(Number(bytes) / (3.5 * 1024 * 1024));
            const space = bytesToNDigits(Number(bytes), 3);
            const quota = space.space;
            // const tariffTypeLabel = getTariffLabelByQuota(quota);
            const yearProduct = products.find((p) => p?.period && isYearPeriod(p.period));
            const monthProduct = products.find((p) => p?.period && isMonthPeriod(p.period));
            const oldPrice = yearProduct?.price || 1;
            const discountPrice = yearProduct?.discountPrice || oldPrice;
            // const saving = 50;
            const yearPriceByMonth = getMonthes(yearProduct?.period || '') * (monthProduct?.price || 1);
            const { discount } = getDiscount({
                discountPrice: yearProduct?.discountPrice || oldPrice,
                price: yearPriceByMonth || oldPrice,
            });

            const tariffProducts: ITariffCardProduct[] = [];

            if (monthProduct) {
                tariffProducts.push(monthProduct);
            }

            if (yearProduct) {
                tariffProducts.push({
                    ...yearProduct,
                    price: yearProduct.price,
                    primary: true,
                    saving: discount,
                });
            }

            return {
                space,
                products: tariffProducts,
                tariffTypeLabel: quota === 256 ? 'Выгодно' : '',
                oldPrice,
                hasOldPriceYear: Boolean(marketingPromo),
                fullPrice: oldPrice,
                // fullPricePeriod: '1y',
                // savingLable: `${roundedDiscount}%`,
                // economy: oldPrice - discountPrice,
                theme: 'white',
                isOldCard: false,
                price: discountPrice,
                giftSpace: yearProduct?.gift?.space,
                infoText: yearProduct?.gift && `* в подарок на ${getPeriodName(yearProduct.gift.period)}`,
                // tempexp_16215-next-line
                showPriceByMonth: !marketingPromo || abEternalPromoYearTariffs.length,
                countSnapshotsBySpace,
            };
        });
    }

    return normalizeMobileTariffList(productsBySpace);
};

export const useLoading = () => {
    const tariffsLoading = useSelector(ProductsSelectors.getLifeCycleState);
    const cardsLoading = useSelector(CardSelectors.getLoadingState);
    const userLoading = useSelector(getSocialLifeCycleState);

    const isTariffsLoading = tariffsLoading.isLoading || !tariffsLoading.isLoaded;
    const isUserLoading = IS_SOCIAL_USER && (userLoading.isLoading || !userLoading.isLoaded);
    const isCardsLoading = !IS_SOCIAL_USER && (cardsLoading.isLoading || !cardsLoading.isLoaded);

    return isTariffsLoading || isUserLoading || isCardsLoading;
};
