import format from 'date-fns/format';
import React, { ReactNode } from 'react';
import { IProduct } from 'reactApp/modules/products/products.types';
import { defaultDateFormat } from 'reactApp/utils/formatDate';
import { getPeriodName, getTrialOrDiscountDateEnd } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

interface TrialSidebarContent {
    header: ReactNode;
    description: string;
    features: string[];
    footer: string;
}

const features = {
    ad: 'Без рекламы в Почте и Облаке',
    upload: 'Загрузку файлов до 100 ГБ',
    uploadCloud: 'Загрузка файлов до 100 ГБ в Облако',
    autodelete: 'Автоудаление папок',
    supportGenetive: 'Приоритетную поддержку',
    support: 'Приоритетную поддержка',
    attaches: 'Отправка файлов до 100 ГБ в письмах',
};

export const useContent = (product: IProduct): TrialSidebarContent => {
    const { space, trialPeriod, discountPeriod, period, discountPrice, price, hasTrial } = product ?? {};
    const freePeriod = hasTrial ? trialPeriod : discountPeriod;
    const priceText = hasTrial ? 'бесплатно' : `за ${formatPrice(discountPrice)} ₽`;

    // если настоящий триал с бесплатным периодом
    if (hasTrial) {
        return {
            header: `${space?.value} в Облаке в подарок`,
            description: `Получите ${space?.value} в Облаке на ${getPeriodName(freePeriod || '', true)} бесплатно`,
            features: [features.ad, features.uploadCloud, features.attaches, features.autodelete, features.support],
            footer: `
                Для проверки карты мы спишем и сразу же вернем 1 руб. Стоимость тарифа ${formatPrice(price)} руб. начиная с 
                ${Boolean(freePeriod) && format(getTrialOrDiscountDateEnd(freePeriod), defaultDateFormat)}. Подписка продлевается
                автоматически, её можно отменить в любой момент.
            `,
        };
    }

    // фейк триал с первым периодом за 1 рубль
    return {
        header: (
            <>
                Получите <span>{space?.value}</span> <br />
                {priceText} на {getPeriodName(freePeriod || period)}
            </>
        ),
        description: 'За один рубль вы получите:',
        features: [`${space?.value} дополнительного места`, features.ad, features.upload, features.autodelete, features.supportGenetive],
        footer: `Стоимость тарифа ${formatPrice(price)} ₽, начиная с
                ${
                    Boolean(freePeriod) && format(getTrialOrDiscountDateEnd(freePeriod), defaultDateFormat)
                }. Подписка продлевается автоматически, её
                можно отменить в любой момент.`,
    };
};
