import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getFeatureQuotaCleanerMob } from 'reactApp/modules/features/features.selectors';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { hasMailSubscription } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { helpUrl } from 'reactApp/sections/QuotaLanding/Footer/Footer.data';
import { ETabs } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { isRaiseTariffsExperiment, openVkWidget } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { getMobileTabs, getTabsChecker } from 'reactApp/sections/QuotaLanding/TabMenu/TabMenu.data';
import { LeftDialog } from 'reactApp/ui/Mobile/LeftDialog/LeftDialog';
import { UserBlock } from 'reactApp/ui/Mobile/UserBlock/UserBlock';
import { scrollLock, scrollUnlock } from 'reactApp/utils/scrollLock';

import styles from './MobileNavigationPanel.css';

interface IProps {
    scrollToBlock: (tab) => void;
    currentTab?: ETabs;
    onClose: () => void;
}

export const MobileNavigationPopup = memo(({ scrollToBlock, onClose, currentTab = ETabs.quota }: IProps): ReactElement | null => {
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const isPaidUser = useSelector(hasMailSubscription);
    const isQuotaCleanerMob = useSelector(getFeatureQuotaCleanerMob);
    const raiseTariffsExperiment = isRaiseTariffsExperiment(true);

    const tabsConfigOff = {
        [ETabs.tariffs]: isAnonymous || isPaidUser,
        [ETabs.features]: isPaidUser,
    };

    const filterTabs = getTabsChecker(tabsConfigOff);
    const isNewLanding = useSelector(QuotaLandingSelectors.getAbLandingFeature);
    const tabs = useMemo(
        () => getMobileTabs(currentTab, filterTabs, raiseTariffsExperiment, isNewLanding, isQuotaCleanerMob),
        [currentTab]
    );

    const scrollRef = useRef(null);

    useEffect(() => {
        const el = scrollRef?.current;
        scrollLock(el, { reserveScrollBarGap: false });

        return () => {
            scrollUnlock(el);
        };
    }, []);

    const onTabClick = useCallback(
        (id) => {
            onClose();

            if (id === ETabs.help) {
                window.open(helpUrl);
                return;
            }

            if (id === ETabs.feedback) {
                openVkWidget();
                return;
            }

            // фикс, чтобы работало в сафари
            setTimeout(() => {
                scrollToBlock(id);
            }, 0);
        },
        [scrollToBlock, onClose]
    );

    return (
        <LeftDialog onClose={onClose} mode={'white'}>
            <div className={styles.menu}>
                <div className={styles.dialog} ref={scrollRef}>
                    {tabs.map((tab) => (
                        <div
                            key={tab.id}
                            data-qa-id={tab.id}
                            className={classNames(styles.tab, {
                                [styles.tabActive]: tab.isCurrent,
                            })}
                            onClick={() => onTabClick(tab.id)}
                        >
                            {tab.text}
                        </div>
                    ))}
                </div>
                <div className={styles.userBlock}>
                    <UserBlock isLeftMenu />
                </div>
            </div>
        </LeftDialog>
    );
});

MobileNavigationPopup.displayName = 'MobileNavigationPopup';
