import { useSelector } from 'react-redux';
import { platform } from 'reactApp/appHelpers/configHelpers';
import { getDocumentsState } from 'reactApp/modules/allDocuments/allDocuments.selectors';
import { AllDocumentsSections, EAllDocumentsType } from 'reactApp/modules/allDocuments/allDocuments.types';

type DwhData = {
    platform: string;
    size_file: number;
    cnt_file: number;
    place: EAllDocumentsType;
} | null;

/**
 * Хук для получения dwh метрик для страницы документов.
 *
 * @param documentType {string} Тип документа по которому мы понимаем в какой секции находимся.
 */
export const useGetAllDocumentsPageDwhData = (documentType: AllDocumentsSections): DwhData => {
    const { isLoaded, type, count, docsSize } = useSelector(getDocumentsState);

    if (documentType === 'trashbin' || !isLoaded) {
        return null;
    }

    return { platform, size_file: docsSize, cnt_file: count, place: type as EAllDocumentsType };
};
