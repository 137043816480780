import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { isAllowedConflictResolution } from 'reactApp/modules/uploading/serviceClasses/helpers';
import { EConflictResolution, EUploadingMakeAction } from 'reactApp/modules/uploading/uploading.types';

export class UploadingPacketConfig {
    workingDirectory: string;
    packetId: number;
    currentPacketIdForUI: number;
    storage: EStorageType;
    albumId = '';
    documentType?;
    isSpecificDocument?: boolean;
    isDomainFolder?: boolean;
    isMountedFolder?: boolean;
    wasAnotherDirAsked = false;
    conflictResolution = EConflictResolution.strict;
    conflictResolutionReadOnlyFolder: null | { action: string; destination: string } = null;
    userFileSizeLimit = 0;
    publicUploadLimit = 0;
    ownPublicFolderWeblink?: string | false | null;
    email = '';
    startTime: number;
    finishTime?: number;

    isOwnPublic = false;
    isPublicUpload = false;
    size = 0;
    currentProgressBytes = 0;
    fileCount = 0;
    folderCount = 0;
    packetUploadEndProcessed = false;
    isProcessed = false;
    hasError = false;
    successCount = 0;
    putTime = 0;
    pollTime = 0;
    hashCalcTime = 0;
    diskReadTime = 0;
    addTime = 0;
    prevUploadedBytes = 0;
    cancelCount = 0;
    makeAction?: EUploadingMakeAction;
    continueUpload?: boolean;

    constructor(storage: EStorageType, workingDirectory: string, packetId: number, currentPacketIdForUI: number) {
        this.storage = storage;
        this.workingDirectory = workingDirectory;
        this.packetId = packetId;
        this.currentPacketIdForUI = currentPacketIdForUI;

        this.startTime = Date.now();
    }

    setConflictResolution(resolution: EConflictResolution) {
        if (!isAllowedConflictResolution(resolution)) {
            resolution = EConflictResolution.strict;
        }

        this.conflictResolution = resolution;
    }
}
