import classNames from 'clsx';
import React, { VFC } from 'react';

import styles from './TariffSpace.css';
import { ITariffSpaceProps } from './TariffSpace.types';

export const TariffSpace: VFC<ITariffSpaceProps> = ({ dataQa, space, units, giftSpace, giftKind, showSpaceWithPlus }) => (
    <div
        className={classNames(styles.space, {
            [styles.space_withGift]: Boolean(giftSpace),
        })}
        data-qa={dataQa}
    >
        <div className={styles.spaceName} data-qa={dataQa && 'volume'}>
            {!giftSpace && showSpaceWithPlus && '+'} {space}
        </div>
        <div className={styles.spaceUnits} data-qa={dataQa && 'units'}>
            {units}
        </div>
        {giftSpace && (
            <div
                className={classNames(styles.gift, {
                    [styles[`gift_${giftKind}`]]: Boolean(giftKind),
                })}
            >
                <div className={styles.plus}>+</div>
                <div className={styles.spaceName} data-qa={dataQa && 'gift-volume'}>
                    {giftSpace.space}
                </div>
                <div className={styles.spaceUnits} data-qa={dataQa && 'gift-units'}>
                    * {giftSpace.units}
                </div>
            </div>
        )}
    </div>
);
