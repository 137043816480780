import { createErrorList } from 'Cloud/Application/ErrorList';
import config from 'Cloud/config';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';

import { ESnackbarType } from './modifying.types';

export interface ISnackbarWordDeclination {
    file: string;
    folder: string;
    many: string;
}

export const snackbarMessage = {
    [ESnackbarType.COPY]: {
        file: 'скопирован',
        folder: 'скопирована',
        many: 'Скопировано',
    },
    [ESnackbarType.MOVE]: {
        file: 'перемещён',
        folder: 'перемещена',
        many: 'Перемещено',
    },
    [ESnackbarType.REMOVE]: {
        file: 'удалён',
        folder: 'удалена',
        many: 'Удалено',
    },
    [ESnackbarType.SAVE_TO_CLOUD]: {
        file: 'сохранен в Облако',
        folder: 'сохранена в Облако',
        many: 'Сохранено в Облако',
    },
};

const invalidCharacters = (config.get('ITEM_NAME_INVALID_CHARACTERS') || '').split('').join(' ');

export const removeErrors = createErrorList({
    read_only: 'Невозможно удалить. Доступ только для просмотра',
    unknown: 'Не удалось удалить',
    external_cloud: 'Удалять папки можно только внутри своего Облака',
});

export const errorsMove = createErrorList({
    exists: 'Элемент с таким названием уже существует. Попробуйте другое название',
    invalid: `Недопустимое имя файла или папки. В названии нельзя использовать символы «${invalidCharacters}». Также название не может состоять только из точки «.» или из двух точек «..»`,
    name_too_long: 'Превышена длина имени',
    read_only: 'Невозможно переместить. Доступ только для просмотра',
    required: 'Название нужно таки указать',
    unknown: 'Не удалось переместить',
    trees_conflict: 'Совместные с другими пользователями папки нельзя переносить в другие совместные папки',
    overquota: 'Невозможно переместить. Превышен объём Облака',
});

export const errorsCopy = createErrorList({
    exists: 'Элемент с таким названием уже существует. Попробуйте другое название',
    invalid: `Недопустимое имя файла или папки. В названии нельзя использовать символы «${invalidCharacters}». Также название не может состоять только из точки «.» или из двух точек «..»`,
    name_too_long: 'Превышена длина имени',
    overquota: 'Невозможно скопировать. Превышен объём Облака',
    read_only: 'Невозможно скопировать. Доступ только для просмотра',
    required: 'Название нужно таки указать',
    unknown: 'Не удалось скопировать',
});

export const renameErrors = createErrorList({
    exists: 'Элемент с таким названием уже существует. Попробуйте другое название',
    invalid:
        'Недопустимое имя файла или папки. В названии нельзя использовать символы «{INVALID_CHARACTERS}». Также название не может состоять только из точки «.» или из двух точек «..»',
    name_too_long: `Ошибка: Превышена длина имени.${IS_BIZ_USER ? '' : ' {HELP_LINK_NAME_CONFINES}'}`,
    read_only: 'Невозможно переименовать. Доступ только для просмотра',
    required: 'Название не может быть пустым',
    unknown: 'Ошибка на сервере',
    violated_file_name: 'Файл в таком формате сохранить нельзя',
});

export const publishErrors = createErrorList({
    limit_exceeded: 'Не удалось создать ссылку. Вы можете иметь не более одной тысячи публичных ссылок',
    virus_scan_fail: 'Файл заражен вирусом',
    blocked:
        'Невозможно создать ссылку. Публикация контента заблокирована по требованию правообладателя или уполномоченного государственного ведомства.',
    unknown: 'Не удалось создать ссылку. Ошибка сервера',
});

export const mountErrors = createErrorList({
    import_limit_exceeded: 'Невозможно подключить папку. Вы можете иметь не более 50 примонтированных папок',
    user_limit_exceeded: 'Невозможно подключить папку. Превышено максимальное число подключенных пользователей к одной папке',
    overquota: 'Невозможно подключить папку. Размер папки превышает доступный объём Облака',
    unknown: 'Не удалось подключить',
});

export const createFolderErrors = createErrorList({
    exists: 'Папка с таким названием уже существует. Попробуйте другое название',
    invalid:
        '«{FOLDER_NAME}» это неправильное название папки. В названии папок нельзя использовать символы «{INVALID_CHARACTERS}». Также название не может состоять только из точки «.» или из двух точек «..»',
    name_too_long: 'Ошибка: Превышена длина имени папки. {HELP_LINK_NAME_CONFINES}',
    read_only: 'Невозможно создать. Доступ только для просмотра',
    required: 'Название папки не может быть пустым',
    unknown: 'Ошибка на сервере',
});

export const createDocumentErrors = createErrorList({
    name_too_long: 'Ошибка: Превышена длина имени файла. {HELP_LINK_NAME_CONFINES}',
    required: 'Название не может быть пустым',
    invalid: `Недопустимое имя файла или папки. В названии нельзя использовать символы «${invalidCharacters}». Также название не может состоять только из точки «.» или из двух точек «..»`,
});

export const cloneErrors = createErrorList({
    overquota: 'Невозможно сохранить. Превышен объём Облака',
    read_only: 'Невозможно сохранить. Папка только для просмотра',
    unknown: 'Не удалось сохранить в Облако',
    virus_scan_fail: 'Невозможно сохранить. Заражено вирусом',
    clone_own: 'Нельзя копировать собственные файлы',
});
