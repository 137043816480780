import classNames from 'clsx';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { loadDocumentRequest } from 'reactApp/modules/personalDocuments/personalDocuments.module';
import { getDocumentsRecognitionStatus } from 'reactApp/modules/personalDocuments/personalDocuments.selectors';
import { ERecognitionStatus } from 'reactApp/modules/personalDocuments/personalDocuments.types';
import { routeChangeSuccess } from 'reactApp/modules/router/router.module';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { EInformerColor } from 'reactApp/ui/Informer/Informer.types';
import { InlineInformer } from 'reactApp/ui/Informer/InlineInformer/InlineInformer';
import { noop } from 'reactApp/utils/helpers';

import { DocumentsInformer } from './DocumentsInformer/DocumentsInformer';
import styles from './PersonalDocuments.css';
import { sendDocumentPageGa } from './PersonalDocuments.helpers';

export const PersonalDocuments = memo(function PersonalDocumentPage() {
    const documentsRecognitionStatus = useSelector(getDocumentsRecognitionStatus);
    const dispatch = useDispatch();
    const { documentType } = useParams() as any;

    useEffect(() => {
        dispatch(
            routeChangeSuccess({
                id: `/${documentType ?? ''}`,
                storage: EStorageType.documents,
                params: {},
                __isFolder: true,
                url: '',
                __parent: '',
            })
        );

        dispatch(loadDocumentRequest({ documentType }));

        return () => {
            dispatch(
                routeChangeSuccess({
                    id: `/`,
                    storage: EStorageType.documents,
                    params: {},
                    __isFolder: true,
                    url: '',
                    __parent: '',
                })
            );
        };
    }, [documentType, dispatch]);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_new_portal_header]: isNewPortalHeader.client,
            })}
        >
            <div className={styles.title}>
                <BreadcrumbsContainer />
                {documentsRecognitionStatus === ERecognitionStatus.PROCESSED && (
                    <div className={styles.info}>
                        <div className={styles.informer}>
                            <InlineInformer color={EInformerColor.blue}>
                                На основе ваших фотографий в Облаке — это безопасно и надёжно!
                            </InlineInformer>
                        </div>
                    </div>
                )}
            </div>

            <DocumentsInformer
                sendGa={sendDocumentPageGa}
                className={classNames({ [styles.documentsInformer_new_portal_header]: isNewPortalHeader.client })}
            />

            <div
                className={classNames(styles.content, {
                    [styles.content_new_portal_header]: isNewPortalHeader.client,
                })}
            >
                <DataList goTo={noop} storage={EStorageType.documents} />
            </div>
        </div>
    );
});
