import { filter, find, path, pathEq, sortBy, values } from 'ramda';
import { createSelector } from 'reselect';

const notDiscount = (gift) => !gift.discount;
const isDiscount = (gift) => gift.discount;

const getGifts = (state) => state.receivedGifts.list;
export const getRawGiftsList = (state) => state.receivedGifts.rawList;

export const filterNonDiscountGifts = createSelector(getGifts, (list) => list && filter(notDiscount, list));

export const filterDiscountGifts = createSelector(getGifts, (list) => list && filter(isDiscount, list));

export const sortReceivedGiftsByStart = createSelector(filterNonDiscountGifts, (list) => sortBy(path(['start']), values(list)));

export const getDiscountGiftList = createSelector(filterDiscountGifts, values);

export const getDiscountGiftByProductId = createSelector(
    getDiscountGiftList,
    (state, productId) => productId,
    (discountGifts, productId) => find(pathEq(['discount', 'web'], productId), values(discountGifts))
);
