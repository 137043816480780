import { captureException } from '@sentry/browser';
import classNames from 'clsx';
import hotkeys from 'hotkeys-js';
import React, { memo, PureComponent, ReactElement, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { setHandleOnEnterHotKey } from 'reactApp/components/BaseConfirmDialog/handlers';
import { useIsWindowIntegrationSmall } from 'reactApp/hooks/useIsWindowIntegrationSmall';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import Content from 'reactApp/ui/Content/Content';
import { Dialog, IProps as IDialogProps } from 'reactApp/ui/Dialog/Dialog';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { sendXray } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { Form } from 'semantic-ui-react';

import styles from './BaseConfirmDialog.css';
import { EButtonType } from './BaseConformationDialog.types';

export interface IBaseConfirmDialogProps {
    onClose?: (event?: MouseEvent) => void;
    onAction?: (data?: any) => null | void | Promise<void>;
    onSuccess?: (data?: any) => null | void | Promise<void>;
    renderContent: (() => ReactNode) | null;
    buttons?: EButtonType[];
    renderHeader?: (() => ReactNode) | null;
    successButtonText?: string;
    cancelButtonText?: string;
    size?: IDialogProps['size'];
    waitText?: string;
    dataQAId?: string;
    className?: string;
    actionButtonText?: string;
    loading?: boolean;
    closeOnDimmerClick?: boolean;
    disableAcceptButton?: boolean;
    icon?: null | ReactElement;
    closable?: boolean;
    onShow?(): void;
    isPhone?: boolean;
    isInlineIntegration?: boolean;
    children?: ReactNode | ReactNode[] | null;
    isNegative?: boolean;
    // проп для плавного перехода на новые кнопки
    buttonTheme?: 'vk' | 'octavius';
    isDarkTheme?: boolean;
    isWindowSmall?: boolean;
}

const hotKeysScope = 'BaseConfirmDialog';

class BaseConfirmDialogComponent extends PureComponent<IBaseConfirmDialogProps> {
    public static defaultProps = {
        onClose: noop,
        onSuccess: noop,
        renderContent: noop,
        onShow: noop,
        buttons: [EButtonType.accept, EButtonType.cancel],
        renderHeader: null,
        successButtonText: 'Подтвердить',
        cancelButtonText: 'Отменить',
        dataQAId: 'confirmation-dialog',
        loading: false,
        disableAcceptButton: false,
        icon: null,
        closable: true,
        size: 'small' as IDialogProps['size'],
        closeOnDimmerClick: false,
        isPhone: false,
        isInlineIntegration: false,
        isNegative: false,
        buttonTheme: 'octavius',
        isWindowSmall: false,
    };

    previousKeyScope = '';

    componentDidMount() {
        const { dataQAId, onShow } = this.props;

        this.previousKeyScope = hotkeys.getScope();
        hotkeys.setScope(hotKeysScope);

        hotkeys('enter', hotKeysScope, setHandleOnEnterHotKey(this.onSuccess));

        if (dataQAId) {
            sendXray(['basedlg', 'show', dataQAId]);
        }

        onShow?.();
    }

    componentDidCatch(error, errorInfo) {
        captureException(error, { extra: errorInfo });
    }

    componentWillUnmount() {
        const currentScope = hotkeys.getScope();

        hotkeys.deleteScope(hotKeysScope);
        // Костыль: если currentScope !== hotKeysScope, значит, кто-то уже поменял скоп и менять его уже лучше не надо.
        if (this.previousKeyScope && currentScope === hotKeysScope) {
            hotkeys.setScope(this.previousKeyScope);
        }
    }

    onSuccess = (event) => this.props.onSuccess?.(event);

    onClose = (event) => {
        if (event) {
            event.stopPropagation();
            event.nativeEvent?.stopImmediatePropagation?.();
        }
        this.props.onClose?.(event);
    };

    renderButton = (type: EButtonType, key: string): ReactElement => {
        const {
            successButtonText,
            cancelButtonText,
            actionButtonText,
            loading,
            onAction,
            disableAcceptButton,
            isPhone,
            isNegative,
            dataQAId,
            buttonTheme: butttomThemeProp,
            isInlineIntegration,
        } = this.props;

        const buttonTheme = isPhone ? 'vk' : butttomThemeProp;

        switch (type) {
            case EButtonType.accept:
                return (
                    <div key={key} className={styles.buttonWrapper}>
                        <Button
                            qa={`${dataQAId}_confirm`}
                            data-name="confirm"
                            theme={buttonTheme}
                            disabled={loading || disableAcceptButton}
                            onClick={this.onSuccess}
                            fluid
                            primary
                            className={classNames(styles.button, {
                                [styles[`button_${buttonTheme}`]]: !!buttonTheme && !isPhone,
                            })}
                            sizeMode={(buttonTheme === 'vk' && isPhone)! ? ButtonSizeMode.small : ButtonSizeMode.middle}
                        >
                            {successButtonText}
                        </Button>
                    </div>
                );
            case EButtonType.action:
                return (
                    <div
                        key={key}
                        className={classNames(styles.buttonWrapper, {
                            [styles.buttonWrapper_action_inlineIntegration]: isInlineIntegration,
                        })}
                    >
                        <Button
                            qa={`${dataQAId}_action`}
                            data-name="action"
                            theme={buttonTheme}
                            disabled={loading}
                            onClick={onAction}
                            fluid
                            error={isNegative}
                            className={classNames(styles.button, {
                                [styles[`button_${buttonTheme}`]]: !!buttonTheme && !isPhone,
                            })}
                            sizeMode={(buttonTheme === 'vk' && isPhone)! ? ButtonSizeMode.small : ButtonSizeMode.middle}
                        >
                            {actionButtonText}
                        </Button>
                    </div>
                );
            case EButtonType.cancel:
                return (
                    <div key={key}>
                        <Button
                            qa={`${dataQAId}_cancel`}
                            data-name="cancel"
                            theme={buttonTheme}
                            onClick={this.onClose}
                            disabled={loading}
                            fluid
                            className={classNames(styles.button, {
                                [styles[`button_${buttonTheme}`]]: !!buttonTheme && !isPhone,
                            })}
                            sizeMode={(buttonTheme === 'vk' && isPhone)! ? ButtonSizeMode.small : ButtonSizeMode.middle}
                        >
                            {cancelButtonText}
                        </Button>
                    </div>
                );
            case EButtonType.ok:
                return (
                    <div key={key}>
                        <Button
                            qa={`${dataQAId}_ok`}
                            data-name="ok"
                            theme={buttonTheme}
                            onClick={this.onClose}
                            primary
                            fluid
                            className={classNames(styles.button, styles.buttonOk, {
                                [styles[`button_${buttonTheme}`]]: !!buttonTheme && !isPhone,
                            })}
                            sizeMode={(buttonTheme === 'vk' && isPhone)! ? ButtonSizeMode.small : ButtonSizeMode.middle}
                        >
                            Ок
                        </Button>
                    </div>
                );
        }
    };

    renderFooter = () => {
        const { buttons, isPhone } = this.props;

        if (!buttons?.length) {
            return null;
        }

        return (
            <div
                className={classNames({
                    [styles.footer]: true,
                    [styles.footer_responsive]: ENABLE_FULL_RESPONSIVE,
                    [styles.footer_phone]: isPhone,
                })}
            >
                {buttons.map((buttonType, index) => this.renderButton(buttonType, `${buttonType}-${index}`))}
            </div>
        );
    };

    render() {
        const {
            size,
            renderHeader,
            renderContent,
            children,
            dataQAId,
            className,
            closeOnDimmerClick,
            buttons,
            icon,
            closable,
            isPhone,
            isDarkTheme,
            isWindowSmall,
        } = this.props;

        let content = children || (renderContent ? renderContent() : <></>);
        if (icon) {
            content = (
                <div className={styles.messageWrapper}>
                    <div className={styles.icon}>{icon}</div>
                    <div className={styles.message}>{content}</div>
                </div>
            );
        }

        if (isPhone) {
            return (
                <MobileDialog
                    id={dataQAId}
                    open
                    dimmer
                    onClose={this.onClose}
                    disableScrolling
                    closable={closable}
                    closeOnDimmerClick={closable}
                    mod="confirm"
                    title={renderHeader ? renderHeader() : <></>}
                    footer={this.renderFooter()}
                    topmost
                >
                    <div
                        className={classNames({
                            [styles.content]: true,
                            [styles.content_phone]: true,
                            [styles.content_withoutFooter]: !buttons?.length,
                        })}
                    >
                        <Form onSubmit={this.onSuccess}>{content}</Form>
                    </div>
                </MobileDialog>
            );
        }

        return (
            <Dialog
                id={dataQAId}
                open
                dimmer
                size={size}
                className={classNames(styles.root, className, {
                    [styles.root_darkTheme]: isDarkTheme,
                    [styles.root_tutoria]: isWindowSmall,
                })}
                header={renderHeader ? renderHeader() : <></>}
                closeOnDimmerClick={closeOnDimmerClick}
                onCancel={this.onClose}
                footer={this.renderFooter()}
                closable={closable}
                isWindowSmall={isWindowSmall}
            >
                <Content isModal>
                    <div
                        className={classNames({
                            [styles.content]: true,
                            [styles.content_withoutFooter]: !buttons?.length,
                        })}
                    >
                        <Form onSubmit={this.onSuccess}>{content}</Form>
                    </div>
                </Content>
            </Dialog>
        );
    }
}

export const BaseConfirmDialog: React.FC<IBaseConfirmDialogProps> = memo((props): ReactElement => {
    const { children = null, ...rest } = props;
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const isWindowSmall = useIsWindowIntegrationSmall();
    const storage = useSelector(getCurrentStorage);

    const { isInlineIntegration } = getStorage(storage as EStorageType);
    return (
        <BaseConfirmDialogComponent {...rest} isInlineIntegration={isInlineIntegration} isPhone={isPhone} isWindowSmall={isWindowSmall}>
            {children}
        </BaseConfirmDialogComponent>
    );
});

BaseConfirmDialog.displayName = 'BaseConfirmDialog';
