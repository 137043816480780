import { Promo } from 'Cloud/Application/Promo';
import { promoController } from 'Cloud/Application/PromoController';
import config from 'Cloud/config';
import sha1 from 'js-sha1';
import { logger } from 'lib/logger';
import store from 'lib/store';
import { PushResubscribeAPICall } from 'reactApp/api/PushResubscribeAPICall';
import { IS_BAD_BROWSER, IS_MOBILE_BROWSER, IS_MY_TEAM, IS_REACT_PROMO_QUOTA_PAGE } from 'reactApp/appHelpers/configHelpers';
import { firebaseConfig, vapidKey } from 'reactApp/constants/firebase';
import { renderWebPushScreen, renderWebPushTooltip } from 'reactApp/ui/WebPush/WebPush.toolkit';
import { createGaSender } from 'reactApp/utils/ga';

let firebase;

try {
    // https://jira.vk.team/browse/CLOUDWEB-16116
    // В режиме lockdown на маках\ios внутри firebase не обрабатывается ошибка отсутствия апи IDBIndex, потому ловим сами
    require('firebase/analytics');
    require('firebase/messaging');
    firebase = require('firebase/app');
} catch (_) {}

const FEATURE_NAME = 'web-push';
const IS_BIZ_USER = config.get('BIZ_USER');
const IS_ON_PREMISE = config.get('ON_PREMISE');
const IS_REACT_UPLOADER = config.get('REACT_UPLOADER');
const IS_REACT_BUY = config.get('REACT_BUY');
const IS_REACT_PROMO_NEW_YEAR = config.get('REACT_PROMO_NEW_YEAR');
const IS_AUTO_TEST_HIDE = config.get('IS_AUTO_TEST_HIDE');
const EMAIL = config.get('user.email');
const IS_PAID_USER = config.get('PAID_USER');
const IS_ATTACHES = config.get('ATTACHES');
const ANONYM_USER = config.get('ANONYM_USER');

const api = new PushResubscribeAPICall();
const sendGa = createGaSender('web-push');

const log = (...params) => {
    logger.verbose('[firebase]', ...params);
};

function isTokenSentToServer(emailsHash) {
    return store.get(`${FEATURE_NAME}|sentToServer`) === emailsHash;
}

function setTokenSentToServer(sent) {
    store.set(`${FEATURE_NAME}|sentToServer`, sent);
}

function renderWebPushBlockedTooltip(isCloudBlocked = false) {
    if (IS_ATTACHES || IS_REACT_PROMO_QUOTA_PAGE) {
        return;
    }

    sendGa('blocked-tooltip-show');
    renderWebPushTooltip({
        isBlocked: true,
        onAction: () => {
            sendGa('blocked-tooltip-click');
            sendGa('blocked-screen-show');
            renderWebPushScreen({
                isCloudBlocked,
                onClose: () => {
                    sendGa('blocked-screen-close');
                },
            });
        },
        onClose: () => {
            sendGa('blocked-tooltip-close');
            promoController.markAsShown(FEATURE_NAME, { daysCount: 30 });
        },
    });
}

function getAccounts(): Promise<any[]> {
    return new Promise((resolve) => {
        // @ts-ignore
        if (window.__PH) {
            // @ts-ignore
            window.__PH.loadAccountsList((data) => {
                const accounts = (data?.accounts || []).map(({ email }) => ({ email }));
                resolve(accounts);
            });
        } else {
            resolve([]);
        }
    });
}

function getEmailsHash(accounts): string {
    return sha1(accounts.map((acc) => acc.email).join());
}

async function sendTokenToServer(token) {
    const accounts = await getAccounts();
    const emailsHash = getEmailsHash(accounts);

    if (isTokenSentToServer(emailsHash)) {
        log("Token already sent to server so won't send it again unless it changes");
        return;
    }

    log('Sending token to server...');

    try {
        const res = await api.makeRequest({
            /**
             * settings is required
             */
            settings: {
                /**
                 * client is optional
                 */
                // client: { os_version: '4.1.2' },
                // client_time_zone: 'GMT+0400',
            },
            platform: 'webpush',
            push_token: token,
            push_tags: ['Web'],
            accounts,
        });

        log(res?.statusText);
        setTokenSentToServer(emailsHash);
    } catch (error) {
        log(error);
        setTokenSentToServer(false);
    }
}

async function updateToken(messaging) {
    log('Request token...');
    try {
        const currentToken = await messaging.getToken({ vapidKey });

        if (currentToken) {
            log('Got token', currentToken);
            await sendTokenToServer(currentToken);
            return;
        }

        log('No Instance ID token available. Request permission to generate one.');
        setTokenSentToServer(false);
        renderWebPushBlockedTooltip(true);
    } catch (error) {
        log('An error occurred while retrieving token. ', error);
        setTokenSentToServer(false);
    }
}

async function requestPermission(messaging) {
    log('Requesting permission...');

    if (!('Notification' in window)) {
        log("Browser don't support notifications");
        return;
    }

    if (Notification.permission === 'granted') {
        const accounts = await getAccounts();

        if (isTokenSentToServer(getEmailsHash(accounts))) {
            log('Notifications already enabled');
        } else {
            await updateToken(messaging);
        }
        return;
    }

    const webPushPromo = new Promo(FEATURE_NAME);

    webPushPromo.isEnabled = () => {
        const endDate = store.get(`${EMAIL}|${FEATURE_NAME}`);
        const now = new Date();

        return !endDate || (endDate && now > endDate);
    };

    promoController.register(100, webPushPromo);

    if (!promoController.canShow(FEATURE_NAME)) {
        log(`Promo tooltip can't show`);
        return;
    }

    if (Notification.permission === 'denied') {
        log('Notifications denied');
        renderWebPushBlockedTooltip();
        return;
    }

    if (IS_ATTACHES || IS_REACT_PROMO_QUOTA_PAGE) {
        return;
    }

    sendGa('promo-tooltip-show');

    const close = renderWebPushTooltip({
        onAction: async () => {
            sendGa('promo-tooltip-click');
            try {
                const permission = await window!.Notification.requestPermission();

                sendGa(`permission-${permission}`);

                if (permission === 'granted') {
                    log('Notification permission granted.');
                    await updateToken(messaging);
                } else {
                    log('Unable to get permission to notify.');
                    renderWebPushBlockedTooltip(true);
                }
            } catch (error) {
                sendGa('permission-error');
                log('Failed to get permission to notify.');
                renderWebPushBlockedTooltip(true);
                log(error);
            }
        },
        onClose: () => {
            sendGa('promo-tooltip-close');
            promoController.markAsShown(FEATURE_NAME, { daysCount: 30 });
        },
        onPostpone: () => {
            sendGa('promo-tooltip-postpone');
            promoController.markAsShown(FEATURE_NAME, { daysCount: IS_PAID_USER ? 7 : 3 });
            close();
        },
    });
}

async function deleteToken(messaging) {
    log('Deleting token...');

    try {
        await messaging.deleteToken();
        log('Token deleted.');
        setTokenSentToServer(false);
        await updateToken(messaging);
    } catch (error) {
        log('Unable to delete token. ', error);
    }
}

// eslint-disable-next-line max-lines-per-function
export function initializeFirebase() {
    if (
        !firebase ||
        IS_BAD_BROWSER ||
        IS_AUTO_TEST_HIDE ||
        IS_BIZ_USER ||
        IS_ON_PREMISE ||
        IS_REACT_UPLOADER ||
        IS_REACT_BUY ||
        IS_MOBILE_BROWSER ||
        IS_REACT_PROMO_NEW_YEAR ||
        IS_MY_TEAM ||
        ANONYM_USER
    ) {
        return;
    }

    try {
        if (!firebase.messaging.isSupported()) {
            sendGa('not-supported');
            return;
        }

        firebase.initializeApp(firebaseConfig);
        firebase.analytics();

        const messaging = firebase.messaging();

        // [START receive_message]
        // Handle incoming messages. Called when:
        // - a message is received while the app has focus
        // - the user clicks on an app notification created by a service worker
        //   `messaging.setBackgroundMessageHandler` handler.
        messaging.onMessage((payload) => {
            log('Message received.', payload);
        });
        // [END receive_message]

        (window as any).__FB = {
            requestPermission,
            deleteToken,
            messaging,
        };

        requestPermission(messaging).catch((_) => {
            log('error-init');
            sendGa('error-init');
        });
    } catch (error) {
        log(error);
    }
}
