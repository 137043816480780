/* eslint-disable max-lines-per-function */
import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { useDispatch, useSelector } from 'react-redux';
import { getFeatureAbAutoDelete } from 'reactApp/modules/features/features.selectors';
import { createNewAutoDeleteFolder } from 'reactApp/modules/modifying/modifying.actions';
import { checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { isAutoDeleteEnabled } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { promoInfo } from 'reactApp/sections/SharedAutoDeletePage/AutoDeletePromo/AutoDeletePromo.data';
import { Button } from 'reactApp/ui/Button/Button';
import { DataListItemCreateNew } from 'reactApp/ui/DataListItemCreateNew/DataListItemCreateNew';
import { CreateNewMod } from 'reactApp/ui/DataListItemCreateNew/DataListItemCreateNew.data';
import { PromoProTooltip } from 'reactApp/ui/PromoProTooltip/PromoProTooltip';
import { createGaSender } from 'reactApp/utils/ga';

import styles from './AutoDeletePromo.css';

const sendGa = createGaSender('autodelete-empty');

interface AutoDeletePromoProps {
    className?: string;
}

export const AutoDeletePromo = memo<AutoDeletePromoProps>(({ className }): ReactElement => {
    const dispatch = useDispatch();
    const createBlockRef = useRef(null);
    const isProUser = useSelector(UserSelectors.isProUser);
    const isPaidUser = useSelector(UserSelectors.isPaidUser);
    const autodeleteAb = useSelector(getFeatureAbAutoDelete)?.data;
    const autoDeleteAbData = autodeleteAb === 'paid' ? 'paid' : 'pro';

    const isAutodeleteFeatureEnabled = !!useSelector(isAutoDeleteEnabled);

    const [showProTooltip, setShowProTooltip] = useState<boolean>(false);

    const closeProTooltip = useCallback(() => {
        setShowProTooltip(false);
    }, []);

    const handleOnCreateClick = useCallback(() => {
        sendGa('create-click');

        if (isPaidUser) {
            sendGa('create-click', isProUser ? 'pro' : 'paid');
        }

        if (isAutodeleteFeatureEnabled) {
            dispatch(createNewAutoDeleteFolder());
            return;
        }

        setShowProTooltip(true);
    }, [dispatch, isAutodeleteFeatureEnabled]);

    useEffect(() => {
        sendGa('show');
        dispatch(checkUpdateLicenseRequest());
        if (isPaidUser) {
            sendGa('show', isProUser ? 'pro' : 'paid');
        }
    }, []);

    const onMoreButtonAction = useCallback(() => {
        sendGa('more-button', autoDeleteAbData);
        window.location.href = promoInfo[autoDeleteAbData].link;
    }, []);

    const video = useMemo(
        () => (
            <video className={styles.video} autoPlay muted loop>
                <source type="video/ogg" src="https://cloud-common-static.hb.bizmrg.com/autodelete/v2.ogg" />
                <source type="video/mp4" src="https://cloud-common-static.hb.bizmrg.com/autodelete/v2.mp4" />
                <source type="video/webm" src="https://cloud-common-static.hb.bizmrg.com/autodelete/v2.mebm" />
            </video>
        ),
        []
    );

    return (
        <div className={classNames(styles.root, styles.rootNewLeftMenu, className)}>
            {showProTooltip && (
                <Portal>
                    <PromoProTooltip
                        target={createBlockRef}
                        onClose={closeProTooltip}
                        gaLabel="autodelete-empty-tooltip"
                        tariffsType={autodeleteAb}
                        bottom={true}
                    />
                </Portal>
            )}
            <div className={styles.content}>
                <div className={styles.createBlock} ref={createBlockRef}>
                    <DataListItemCreateNew isThumb mod={CreateNewMod.autodeletePromo} onClick={handleOnCreateClick} />
                </div>

                <div
                    className={classNames({
                        [styles.promoBlock]: true,
                        [styles.paid]: autoDeleteAbData === 'paid',
                        [styles.enabled]: isAutodeleteFeatureEnabled,
                    })}
                >
                    {isAutodeleteFeatureEnabled ? (
                        video
                    ) : (
                        <>
                            <div className={styles.header}>{promoInfo[autoDeleteAbData].header}</div>
                            <div className={styles.info}>
                                {video}

                                <div className={styles.promoDescWrap}>
                                    <div className={styles.promoDesc}>
                                        <div className={styles.subHeader}>{promoInfo[autoDeleteAbData].subHeader}</div>
                                        <ul className={styles.list}>
                                            {promoInfo[autoDeleteAbData].benefits.map((benefit, index) => (
                                                <li key={`benefit-${index}`}>{benefit}</li>
                                            ))}
                                        </ul>
                                        <div>
                                            <Button onClick={onMoreButtonAction} theme="octavius" primary>
                                                Подробнее
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
});

AutoDeletePromo.displayName = 'AutoDeletePromo';
