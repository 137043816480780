import { ReactComponent as SuccessIcon } from 'img/icons/successCheckmark.svg';
import React, { memo, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { sendLinkBySmsRequest } from 'reactApp/modules/smsSender/smsSender.module';
import { SmsSenderSelectors } from 'reactApp/modules/smsSender/smsSender.selectors';
import { GetLinkBySms } from 'reactApp/ui/GetLinkBySms/GetLinkBySms';
import { sendGa } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';

import styles from './GetLinkBySmsPromo.css';

interface Props {
    sendLinkBySms(phone?, gaCategory?): void;
    hasError: boolean;
    isLoading: boolean;
    message: string;
    gaCategory?: string;
}

export const GetLinkBySmsPromoComponent = memo(
    ({ isLoading, hasError, message, sendLinkBySms = noop, gaCategory = 'get-link' }: Props): ReactElement => {
        useEffect(() => {
            sendGa(gaCategory, 'show');
        }, []);

        useEffect(() => {
            if (hasError) {
                sendGa(gaCategory, 'error');
            }
        }, [hasError]);

        let stateMessage: JSX.Element | string = (
            <>
                Оставьте свой номер телефона и мы пришлём вам
                <br />
                ссылку на приложение в SMS
            </>
        );

        if (hasError) {
            stateMessage = message;
        } else if (message) {
            stateMessage = (
                <div className={styles.messageWrapper}>
                    <div className={styles.icon}>
                        <SuccessIcon width={16} height={16} />
                    </div>
                    <div>
                        <b>Готово!</b>
                        <br />
                        Ссылка на приложение придет в SMS
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.root}>
                <div className={styles.title}>Установите Облако на телефон</div>
                <div className={styles.description}>
                    Освободите память телефона от фото и видео,
                    <br />
                    загружая их в Облако и наслаждайтесь просмотром
                    <br />
                    файлов на большом экране компьютера
                </div>

                <GetLinkBySms
                    isLoading={isLoading}
                    isLoaded={!hasError && !!message}
                    hasError={hasError}
                    sendLinkBySms={sendLinkBySms}
                    message={stateMessage}
                    hideIcon={true}
                    title=""
                    size="small"
                    placeholder=""
                    mod="round"
                    buttonText="Отправить"
                    gaCategory={gaCategory}
                />
            </div>
        );
    }
);

GetLinkBySmsPromoComponent.displayName = ' GetLinkBySmsPromoComponent';

GetLinkBySmsPromoComponent.whyDidYouRender = true;

const mapStateToProps = (state) => {
    const { isLoading, hasError } = SmsSenderSelectors.getLifeCycleState(state);

    return {
        isLoading,
        hasError,
        message: SmsSenderSelectors.getMessage(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    sendLinkBySms: (phone, gaCategory) => dispatch(sendLinkBySmsRequest({ phone, gaCategory })),
});

export const GetLinkBySmsPromo = connect(mapStateToProps, mapDispatchToProps)(GetLinkBySmsPromoComponent);
