import classNames from 'clsx';
import React, { PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { IS_BIZ_USER, IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
/* tempexp_14812-next-line */
import { chooseVariant } from 'reactApp/modules/features/features.helpers';
/* tempexp_14812-next-line */
import { getFeatureNewEmptyStates } from 'reactApp/modules/features/features.selectors';
import { changeGalleryCategory as changeGalleryCategoryAction, galleryLoadRequest } from 'reactApp/modules/gallery/gallery.module';
import {
    getGalleryCategories,
    getGalleryCategory,
    getPreviewableMediaForGallery,
    isGalleryCategoryLoaded,
    isGalleryCategoryLoading,
} from 'reactApp/modules/gallery/gallery.selectors';
import { GalleryCategory, GalleryCategoryType } from 'reactApp/modules/gallery/gallery.types';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { StartSelectors } from 'reactApp/modules/start/start.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { LoadingState } from 'reactApp/types/commonStates';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { GetLinkBySmsPromo } from 'reactApp/ui/GetLinkBySmsPromo/GetLinkBySmsPromo';
import { PageTabMenu } from 'reactApp/ui/PageTabMenu/PageTabMenu';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { createGaSender } from 'reactApp/utils/ga';
import opener from 'reactApp/utils/opener';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './GalleryNewPage.css';

const sendGa = createGaSender('gallery');

const mapDispatchToProps = (dispatch) => ({
    loadAll: (category) => {
        dispatch(galleryLoadRequest({ category }));
    },
    changeGalleryCategory: (data) => dispatch(changeGalleryCategoryAction(data)),
    resetSelect: () => dispatch(resetSelect()),
});

const mapStateToProps = (state) => {
    const startLoadingState = StartSelectors.getLoadingState(state);

    const usedSpace = UserSelectors.getCloudSpace(state)?.used?.value || '';

    const currentCategory = getGalleryCategory(state);
    const itemsLoadingState = {
        isLoading: isGalleryCategoryLoading(state, currentCategory),
        isLoaded: isGalleryCategoryLoaded(state, currentCategory),
    };
    const items = itemsLoadingState.isLoading ? [] : getPreviewableMediaForGallery(state, null);

    const categories = getGalleryCategories(state);

    return {
        startLoadingState,
        items,
        itemsLoadingState,
        usedSpace,
        categories,
        currentCategory,
    };
};

const DEFAULT_CATEGORY = IS_ONPREMISE || IS_BIZ_USER ? GalleryCategoryType.images : GalleryCategoryType.all;

interface IMapToStateProps {
    startLoadingState: LoadingState;
    items: string[];
    itemsLoadingState: LoadingState;
    usedSpace: string;
    categories: GalleryCategory[];
    currentCategory: GalleryCategoryType;
}

interface IDispatchToProps {
    loadAll: (category: GalleryCategoryType) => void;
    changeGalleryCategory: (data) => void;
    resetSelect: () => void;
}

interface IProps extends IMapToStateProps, IDispatchToProps {
    initialItemId?: string;
}

export class GalleryNewPage extends PureComponent<IProps> {
    componentDidMount() {
        const { loadAll } = this.props;

        let hash = window?.location?.hash;
        if (hash) {
            hash = hash.replace('#', '');
        }

        const category = GalleryCategoryType[hash as keyof typeof GalleryCategoryType] || DEFAULT_CATEGORY;
        sendGa('show');
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'gallery' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'gallery' });

        loadAll(category);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentCategory !== this.props.currentCategory) {
            sendGa('show', this.props.currentCategory);
        }
    }

    private handleClick = ({ id }) => {
        sendGa('clickitem');

        // TODO: routing
        opener(`/home${encodeURIComponent(id)}`, !id);
    };

    handleMenuTabClick = (category) => {
        const { resetSelect, changeGalleryCategory } = this.props;

        sendGa('clicktab', 'category');

        changeGalleryCategory({ category: GalleryCategoryType[category] });
        resetSelect();
    };

    private renderItems(currentCategory): ReactElement | null {
        const { itemsLoadingState, items, initialItemId } = this.props;

        if (
            /* tempexp_14812-next-line */
            chooseVariant(getFeatureNewEmptyStates, {
                control:
                    !IS_ONPREMISE && currentCategory === GalleryCategoryType.mobile && itemsLoadingState.isLoaded && items.length === 0,
                variant1: false,
            })()
        ) {
            return (
                <div className={styles.smsBlockWrapper}>
                    <GetLinkBySmsPromo gaCategory="glry-sms" />
                </div>
            );
        }

        return (
            <DataList
                goTo={this.handleClick}
                storage={EStorageType.gallery}
                initialItemId={initialItemId}
                selectableClassName={styles.datalistSelectable}
            />
        );
    }

    render(): ReactElement {
        const { startLoadingState, categories, currentCategory } = this.props;

        if (startLoadingState.isLoading) {
            return <Spinner />;
        }

        return (
            <div
                className={classNames(styles.root, {
                    [styles.root_new_portal_header]: isNewPortalHeader.client,
                })}
            >
                <div className={classNames({ [styles.headerContainer_new_portal_header]: isNewPortalHeader.client })}>
                    <BreadcrumbsContainer />
                    <div className={styles.menu}>
                        <PageTabMenu current={currentCategory} tabs={categories} onClick={this.handleMenuTabClick} changeHash />
                    </div>
                </div>
                {this.renderItems(currentCategory)}
            </div>
        );
    }
}

export const GalleryNewPageConnected = connect(mapStateToProps, mapDispatchToProps)(GalleryNewPage);
