import { Icon20Cancel } from '@vkontakte/icons';
import { Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { FC, memo, PropsWithChildren, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { QRCloud } from 'reactApp/ui/QRCloud/QRCloud';

import styles from './AsidePromoModal.css';
import { EAsidePromoModalTheme, IAsidePromoModalProps } from './AsidePromoModal.types';

interface ContainerProps {
    isMobile: boolean;
    onClose: () => void;
}

const Container: FC<PropsWithChildren<ContainerProps>> = ({ isMobile, children, onClose }) => {
    if (isMobile) {
        return (
            <MobileDialog id="aside-promo-modal" onClose={onClose} mod="base" topmost closeOnDimmerClick contextPadding="zero">
                {children}
            </MobileDialog>
        );
    }

    return <>{children}</>;
};

export const AsidePromoModal = memo<IAsidePromoModalProps>(
    ({ theme, title, text, imageUrl, buttonText, href, onShow, onClick, onClose, fixed = true, bottom, qrUrl }) => {
        const dispatch = useDispatch();

        useEffect(() => {
            onShow?.();
        }, []);

        const handleClick = useCallback(() => {
            onClick?.();
            // Просто вызывать onClose?.() нельзя, так как в начале вызова в onClose прокидываются отсыл радаров клика по крестику
            dispatch(closePopupHelper(popupNames.ASIDE_PROMO_MODAL));
        }, [onClick]);

        const handleClose = useCallback(() => {
            onClose?.();
        }, [onClose]);

        const isMobile = EnvironmentSelectors.isPhone();

        return (
            <Container isMobile={isMobile} onClose={handleClose}>
                <div
                    className={classNames({
                        [styles.root]: !isMobile,
                        [styles.root_fixed]: !isMobile && fixed,
                        [styles.root_mobile]: isMobile,
                        [styles[`root_${theme}`]]: Boolean(theme),
                    })}
                    style={bottom ? { bottom: `${bottom}px` } : undefined}
                >
                    {Boolean(imageUrl) && (
                        <div className={styles.imageWrapper}>
                            <img className={styles.image} src={imageUrl} alt="Cover image" />
                        </div>
                    )}
                    {Boolean(qrUrl) && (
                        <div className={styles.qrWrapper}>
                            <QRCloud url={qrUrl ?? ''} size={160} isDark={theme === EAsidePromoModalTheme.dark} />
                        </div>
                    )}
                    <div
                        className={classNames(styles.content, {
                            [styles.content_mobile]: isMobile,
                        })}
                    >
                        <Text
                            className={classNames(styles.title, {
                                [styles.title_mobile]: isMobile,
                            })}
                        >
                            {title}
                        </Text>
                        <Spacing size={isMobile ? 12 : 8} />
                        <Text
                            className={classNames(styles.text, {
                                [styles.text_mobile]: isMobile,
                            })}
                        >
                            {text}
                        </Text>
                        {buttonText && (
                            <>
                                <Spacing size={isMobile ? 24 : 20} />
                                <Button
                                    className={classNames(styles.button, {
                                        [styles.button_mobile]: isMobile,
                                    })}
                                    href={href}
                                    theme="vk"
                                    primary
                                    target="_blank"
                                    onClick={handleClick}
                                    sizeMode={isMobile ? ButtonSizeMode.big : ButtonSizeMode.small}
                                >
                                    {buttonText}
                                </Button>
                            </>
                        )}
                    </div>
                    {!isMobile && (
                        <div className={styles.close} onClick={handleClose}>
                            <Icon20Cancel width={18} height={18} />
                        </div>
                    )}
                </div>
            </Container>
        );
    }
);

AsidePromoModal.displayName = 'AsidePromoModal';
