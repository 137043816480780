import classNames from 'clsx';
import { sanitize } from 'dompurify';
import React, { memo, useCallback } from 'react';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { IThumb } from 'reactApp/ui/DataListItem/DataListItem.helpers';
import { FilePic } from 'reactApp/ui/FilePic/FilePic';
import { CancelIconOutline } from 'reactApp/ui/VKUIIcons';

import styles from './Snackbar.css';

export enum ESnackbarTheme {
    REGULAR = 'regular',
    COMPACT = 'compact',
}

export type SnackbarProps = {
    onClose?: () => void;
    icon?: any;
    stretched?: boolean;
    title?: string;
    text?: string;
    closable?: boolean;
    buttonText?: string;
    onButtonClick?: () => void;
    visible?: boolean;
    theme?: ESnackbarTheme;
    bigSnackbar?: boolean;
    oneline?: boolean;
    wrapText?: boolean;
    alignTop?: boolean;
    thumb?: { data?: IThumb; ext?: string };
};

export const Snackbar = memo(function Snackbar(props: SnackbarProps) {
    const {
        title,
        text,
        icon,
        thumb,
        closable,
        buttonText,
        onClose,
        onButtonClick,
        visible = true,
        oneline = false,
        theme = ESnackbarTheme.COMPACT,
        bigSnackbar,
        wrapText,
        alignTop,
    } = props;

    const enableOneline = !title && oneline;

    const onClick = useCallback(() => {
        onButtonClick?.();
        onClose?.();
    }, [onButtonClick, onClose]);

    return (
        <div
            className={classNames(styles.root, styles[`root_theme_${theme}`], {
                [styles.root_visible]: Boolean(visible),
                [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                [styles.alignTop]: alignTop,
            })}
        >
            {icon && !thumb && <div className={styles.icon}>{icon}</div>}
            {thumb && (
                <div className={styles.thumb}>
                    <FilePic thumbUrl={thumb?.data} isVirus={false} ext={thumb?.ext || ''} isDark />
                </div>
            )}

            <div
                className={classNames(styles.container, {
                    [styles.containerOneline]: enableOneline,
                })}
            >
                {title && <div className={styles.title}>{title}</div>}
                {text && (
                    <div
                        className={classNames(styles.text, bigSnackbar ? styles.textBig : styles.textSmall, {
                            [styles.wrapText]: wrapText,
                        })}
                        dangerouslySetInnerHTML={{ __html: sanitize(text, { ADD_ATTR: ['target'] }) }}
                    />
                )}

                {buttonText && (
                    <div
                        className={classNames(styles.buttonLink, {
                            [styles.buttonLinkOneLine]: enableOneline,
                        })}
                        onClick={onClick}
                    >
                        {buttonText}
                    </div>
                )}
            </div>

            {closable && (
                <div className={styles.close} onClick={onClose}>
                    <CancelIconOutline className={styles.closeIcon} />
                </div>
            )}
        </div>
    );
});
