import { equals } from 'ramda';
import { useSelector } from 'react-redux';
import { getNoClosableSnackbars, getSnackbarList } from 'reactApp/modules/snackbar/snackbar.selectors';
import { SnackbarItem } from 'reactApp/modules/snackbar/snackbar.types';

const MAX_SNACKBARS_COUNT = 2;

export const useSnackbarsList = (): SnackbarItem[] | undefined => {
    const snackbars = useSelector(getSnackbarList);
    const noClosableSnackbars = useSelector(getNoClosableSnackbars);
    const snackbarsCount = snackbars.length;
    const noClosableSnackbarsCount = noClosableSnackbars.length;

    if (!snackbarsCount) {
        return undefined;
    }

    if (snackbarsCount === 1) {
        return snackbars;
    }

    if (noClosableSnackbarsCount) {
        const lastItem = snackbars.reverse().find((item) => !equals(item, noClosableSnackbars[0]));
        const snackbarsList = noClosableSnackbarsCount === 1 ? [lastItem, noClosableSnackbars[0]] : noClosableSnackbars.slice(0, MAX_SNACKBARS_COUNT);

        return snackbarsList.filter(Boolean) as SnackbarItem[];
    }

    return snackbarsCount >= MAX_SNACKBARS_COUNT ? snackbars.slice(snackbarsCount - MAX_SNACKBARS_COUNT, snackbarsCount) : snackbars;
} 