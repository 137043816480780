import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { DatalistBanner } from 'reactApp/ui/AdvBanners/DatalistBanner/DatalistBanner';

import { UploadProvocation } from '../UploadProvocation/UploadProvocation';
import { IProvocation } from '../UploadProvocation/UploadProvocation.types';

interface IProps {
    itemsCount: number;
}

export const ExtraBlocks = ({ itemsCount }: IProps) => {
    const provocationRef = useRef<IProvocation | null>(null);
    const isProvocationShown = Boolean(provocationRef?.current?.canShow());

    const storage = useSelector(getCurrentStorage);
    const { isHome } = getStorage(storage);

    return (
        <>
            {isHome && <UploadProvocation ref={provocationRef} />}
            {!isProvocationShown && <DatalistBanner itemsCount={itemsCount} />}
        </>
    );
};
