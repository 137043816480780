/* eslint-disable max-lines */
import React, { Suspense, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import {
    HIDE_ADS,
    IS_CHOSEN_PUBLIC_FILE,
    IS_MY_TEAM,
    IS_ONPREMISE,
    IS_PUBLIC_FOLDER,
    IS_WEBVIEW,
    PUBLIC_SHOVE,
} from 'reactApp/appHelpers/configHelpers';
import {
    isDarkTheme,
    isFamilySubsDesktopAvailable,
    isFeatureAbPromocodesLanding,
    isTariffsQuotaLandingWebview,
} from 'reactApp/appHelpers/featuresHelpers';
import { useGlobalDarkTheme } from 'reactApp/hooks/useDarkTheme';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { AlbumPage } from 'reactApp/sections/AlbumsPage/AlbumPage';
import { AlbumsPage } from 'reactApp/sections/AlbumsPage/AlbumsPage';
import { AllDocumentsPage } from 'reactApp/sections/AllDocuments/AllDocumentsPage';
import AttachesPage from 'reactApp/sections/AttachesPage/AttachesPage';
import { EmbeddedPage } from 'reactApp/sections/EmbeddedPage/EmbeddedPage';
import { FamilyPage } from 'reactApp/sections/FamilyPage/FamilyPage';
import { FavoritesPage } from 'reactApp/sections/FavoritesPage/FavoritesPage';
import { FeedPage } from 'reactApp/sections/FeedPage/FeedPage';
import { GalleryNewPageConnected } from 'reactApp/sections/GalleryNewPage/GalleryNewPage';
import { HomePage } from 'reactApp/sections/HomePage/HomePage';
import { InlineIntegrationHomePage } from 'reactApp/sections/InlineIntegrationHomePage/InlineIntegrationHomePage';
import { IntegrationHomePage } from 'reactApp/sections/IntegrationHomePage/IntegrationHomePage';
import { MailRecommendPage } from 'reactApp/sections/MailRecommendPage/MailRecommendPage';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';
import { MainPage } from 'reactApp/sections/MainPage/MainPage';
import { MobileAttachesPage } from 'reactApp/sections/MobileAttachesPage/MobileAttachesPage';
import { MobileFamilyPage } from 'reactApp/sections/MobileFamilyPage/MobileFamilyPage';
import { MobileGalleryPage } from 'reactApp/sections/MobileGalleryPage/MobileGalleryPage';
import { MobileHomePage } from 'reactApp/sections/MobileHomePage/MobileHomePage';
import { MobilePdfEditPage } from 'reactApp/sections/MobilePdfEditPage/MobilePdfEditPage';
import { MobilePublicPage } from 'reactApp/sections/MobilePublicPage/MobilePublicPage';
import { MobileQuotaTariffsLanding } from 'reactApp/sections/MobileQuotaTariffsLanding/MobileQuotaTariffsLanding';
import { MobileSearchPage } from 'reactApp/sections/MobileSearchPage/MobileSearchPage';
import { MobileSubscriptionsPage } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage';
import { MobileTrashbinPage } from 'reactApp/sections/MobileTrashbinPage/MobileTrashbinPage';
import { PersonalDocuments } from 'reactApp/sections/PersonalDocuments/PersonalDocuments';
import { PersonalDocumentsMainPage } from 'reactApp/sections/PersonalDocuments/PersonalDocumentsMainPage';
import { SearchPage } from 'reactApp/sections/SearchPage/SearchPage';
import { SharedAutoDeletePage } from 'reactApp/sections/SharedAutoDeletePage/SharedAutoDeletePage';
import { SharedIncomingPage } from 'reactApp/sections/SharedIncomingPage/SharedIncomingPage';
import { SharedPage } from 'reactApp/sections/SharedPage/SharedPage';
import { TrashbinPage } from 'reactApp/sections/TrashbinPage/TrashbinPage';
import { EPageId } from 'reactApp/types/Page';
import { ChangeHistory } from 'reactApp/ui/App/components/ChangeHistory';
import { InitComponent } from 'reactApp/ui/App/components/InitComponent';
import { IntegrationRedirect } from 'reactApp/ui/App/components/IntegrationRedirect';
import { NotFoundPage } from 'reactApp/ui/App/components/NotFoundPage';
import { PageWrapper } from 'reactApp/ui/App/components/PageWrapper';
import { ViewerRender } from 'reactApp/ui/App/components/ViewerRender';
import { useDataContainer } from 'reactApp/ui/App/effect/useDataContainer';
import { BuyIframe } from 'reactApp/ui/IframePages/Buy/Buy';
import { MailPopup } from 'reactApp/ui/MailPopup/MailPopup';
import { PublicFileConnected } from 'reactApp/ui/Mobile/PublicFile/PublicFile';
import { PopupsNew } from 'reactApp/ui/Popup/PopupsNew';
import { PresentLanding, PresentLandingReloader } from 'reactApp/ui/PresentLanding/PresentLanding';
import { Public } from 'reactApp/ui/Public/Public';
import { Snackbars } from 'reactApp/ui/Snackbars/Snackbars';
import { TooltipProvider } from 'reactApp/ui/Tooltip/Tooltip.context';
import { getInitialId } from 'reactApp/utils/urlHelper';

const NewYearLanding = React.lazy(() => import(/* webpackChunkName: "newYearLanding" */ '../../ui/NewYearLanding/NewYearLanding'));
const NineMayLanding = React.lazy(() => import(/* webpackChunkName: "NineMayLanding" */ '../../ui/NineMayLanding/NineMayLanding'));
const PdfEditPage = React.lazy(() => import(/* webpackChunkName: "pdfEdit" */ '../../sections/PdfEditPage/PdfEditPage'));
const QuotaLanding = React.lazy(() => import(/* webpackChunkName: "quotaLanding" */ '../../sections/QuotaLanding/QuotaLanding'));
const PromocodeLanding = React.lazy(
    () => import(/* webpackChunkName: "promocodeLanding" */ '../../sections/PromocodeLanding/PromocodeLanding')
);
const SubscriptionsPage = React.lazy(
    () => import(/* webpackChunkName: "subscriptionsPage" */ '../../sections/SubscriptionsPage/SubscriptionsPage')
);
const FastPageConnected = React.lazy(() => import(/* webpackChunkName: "fastPage" */ '../../sections/FastPage/FastPage'));
const HappyBirthdayCloudPage = React.lazy(
    () => import(/* webpackChunkName: "happybirthday" */ '../../sections/HappyBirthdayCloudPage/HappyBirthdayCloudPage')
);

const PdfEditPageWrapper = () => {
    const location = useLocation();
    useRouteChangeProcessing({
        storage: EStorageType.pdfEdit,
        path: location.pathname,
        search: location.search,
    });

    return <PdfEditPage />;
};

const renderPublic = (storage, isPhone, isFolder, noAds, isShove, isAlbum = false) => {
    if (isPhone) {
        return IS_PUBLIC_FOLDER ? <MobilePublicPage storage={storage} isAlbum={isAlbum} /> : <PublicFileConnected storage={storage} />;
    }

    return <Public storage={storage} isFolder={isFolder} noAds={noAds} isShove={isShove} isAlbum={isAlbum} />;
};

// eslint-disable-next-line max-lines-per-function
export const AppRouter = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isPhone, hasError } = useDataContainer();

    const selectedViewItemId = useSelector(ViewerSelectors.getViewerItemId);

    useGlobalDarkTheme(isDarkTheme);

    return (
        <TooltipProvider>
            <Router>
                <Suspense fallback={null}>
                    {/* Child effects are run in order. Therefore initing here is before any other effects are called. */}
                    <InitComponent setIsLoading={setIsLoading} />
                    <ChangeHistory />
                    <Snackbars />
                    <PopupsNew />
                    {!isPhone && !!selectedViewItemId && !IS_CHOSEN_PUBLIC_FILE && <ViewerRender selectedViewItemId={selectedViewItemId} />}
                    <Routes>
                        <Route
                            path="/pdf/edit"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.pdfEdit} hideHeader={!isPhone}>
                                    {isPhone ? <MobilePdfEditPage /> : <PdfEditPageWrapper />}
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/subscriptions"
                            element={
                                <PageWrapper
                                    isLoading={isLoading}
                                    hasError={hasError}
                                    hasActionPanel={false}
                                    pageId={EPageId.subscriptions}
                                >
                                    {isPhone ? (
                                        isTariffsQuotaLandingWebview && IS_WEBVIEW ? (
                                            <Navigate replace to={`/promo/quota/tariffs${window.location.search}`} />
                                        ) : (
                                            <MobileSubscriptionsPage />
                                        )
                                    ) : (
                                        <SubscriptionsPage />
                                    )}
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/start"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.start}>
                                    <MainPage showUploader storage={EStorageType.start}>
                                        <FastPageConnected />
                                    </MainPage>
                                </PageWrapper>
                            }
                        />
                        {!IS_MY_TEAM && (
                            <Route
                                path="/gallery/*"
                                element={
                                    <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.gallery}>
                                        {(location) =>
                                            isPhone ? (
                                                <MobileGalleryPage />
                                            ) : (
                                                <MainPage storage={EStorageType.gallery} renderWorm>
                                                    <GalleryNewPageConnected initialItemId={getInitialId(location?.pathname)} />
                                                </MainPage>
                                            )
                                        }
                                    </PageWrapper>
                                }
                            />
                        )}
                        <Route
                            path="/feed/*"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.feed}>
                                    {(location) => (
                                        <MainPage storage={EStorageType.feed} renderWorm>
                                            <FeedPage initialItemId={getInitialId(location?.pathname)} />
                                        </MainPage>
                                    )}
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/favorites/*"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.favorites}>
                                    {(location) => (
                                        <MainPage storage={EStorageType.favorites} renderWorm>
                                            <FavoritesPage initialItemId={getInitialId(location?.pathname)} />
                                        </MainPage>
                                    )}
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/attaches/*"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.attaches}>
                                    {isPhone ? (
                                        <MobileAttachesPage />
                                    ) : (
                                        <MainPage storage={EStorageType.attaches} renderWorm>
                                            <AttachesPage />
                                        </MainPage>
                                    )}
                                </PageWrapper>
                            }
                        />
                        {!(IS_MY_TEAM || IS_ONPREMISE) && (
                            <Route
                                path="/recommend"
                                element={
                                    <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.recommend}>
                                        <MainPage storage={EStorageType.recommend} renderWorm>
                                            <MailRecommendPage />
                                        </MainPage>
                                    </PageWrapper>
                                }
                            />
                        )}
                        <Route
                            path="/family"
                            element={
                                isFamilySubsDesktopAvailable ? (
                                    <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.family}>
                                        {isPhone ? (
                                            <MobileFamilyPage />
                                        ) : (
                                            <MainPage
                                                showUploader={false}
                                                allowDnd={false}
                                                renderWorm={false}
                                                showToolbar={false}
                                                showSpace={false}
                                                showTree={false}
                                                showBackHomeButton
                                                storage={EStorageType.family}
                                            >
                                                <FamilyPage />
                                            </MainPage>
                                        )}
                                    </PageWrapper>
                                ) : (
                                    <Navigate replace to="/home/" />
                                )
                            }
                        />
                        <Route
                            path="/family/:inviteLink"
                            element={
                                isFamilySubsDesktopAvailable ? (
                                    <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.family}>
                                        {isPhone ? (
                                            <MobileFamilyPage />
                                        ) : (
                                            <MainPage
                                                showUploader={false}
                                                allowDnd={false}
                                                renderWorm={false}
                                                showToolbar={false}
                                                showSpace={false}
                                                showTree={false}
                                                showBackHomeButton
                                                storage={EStorageType.family}
                                            >
                                                <FamilyPage />
                                            </MainPage>
                                        )}
                                    </PageWrapper>
                                ) : (
                                    <Navigate replace to="/home/" />
                                )
                            }
                        />
                        <Route
                            path="/trashbin"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.trashbin}>
                                    {isPhone ? (
                                        <MobileTrashbinPage />
                                    ) : (
                                        <MainPage storage={EStorageType.trashbin} renderWorm showUploader={false}>
                                            <TrashbinPage />
                                        </MainPage>
                                    )}
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/embedded"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.embedded} hideHeader>
                                    <EmbeddedPage />
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/shared/autodelete"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.sharedAutodelete}>
                                    <MainPage showUploader={false} storage={EStorageType.sharedAutodelete} renderWorm={false}>
                                        <SharedAutoDeletePage />
                                    </MainPage>
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/shared/links/*"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.sharedLinks}>
                                    {(location) => (
                                        <MainPage showUploader={false} storage={EStorageType.sharedLinks} renderWorm>
                                            <SharedPage initialItemId={getInitialId(location?.pathname, 2)} />
                                        </MainPage>
                                    )}
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/shared/incoming"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.sharedIncoming}>
                                    <MainPage showUploader={false} storage={EStorageType.sharedIncoming} renderWorm>
                                        <SharedIncomingPage />
                                    </MainPage>
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/documents"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.personalDocumentsMain}>
                                    <MainPage renderWorm showUploader={false} allowDnd={false} storage={EStorageType.documents}>
                                        <PersonalDocumentsMainPage />
                                    </MainPage>
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/documents/:documentType"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.personalDocuments}>
                                    <MainPage showUploader storage={EStorageType.documents} renderWorm>
                                        <PersonalDocuments />
                                    </MainPage>
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/albums"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.albumsMain}>
                                    <MainPage renderWorm allowDnd={false} showUploader={false} storage={EStorageType.albums}>
                                        <AlbumsPage />
                                    </MainPage>
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/albums/*"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.albums}>
                                    <MainPage showUploader storage={EStorageType.albums} renderWorm>
                                        <AlbumPage />
                                    </MainPage>
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/promocodes"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.promocodes} hideHeader>
                                    {(location) =>
                                        isFeatureAbPromocodesLanding ? (
                                            <PresentLandingReloader location={location} />
                                        ) : (
                                            <PromocodeLanding location={location} />
                                        )
                                    }
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/discount"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.discount} hideHeader>
                                    {(location) => <PromocodeLanding location={location} isDiscountPage />}
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/promo/quota/*"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.singleQuota} hideHeader>
                                    <QuotaLanding />
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/promo/quota/partner"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.singleQuotaPartner} hideHeader>
                                    <QuotaLanding />
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/promo/quota/tariffs"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.singleQuotaTariffs} hideHeader>
                                    {isPhone ? <MobileQuotaTariffsLanding /> : <Navigate replace to="/promo/quota" />}
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/promo/quota/android"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.singleQuotaAndroid} hideHeader>
                                    <QuotaLanding isAndroidPromo={true} />
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/promo/quota/ios"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.singleQuotaIOS} hideHeader>
                                    <QuotaLanding isIOSPromo={true} />
                                </PageWrapper>
                            }
                        />
                        <Route path="/promo/happybirthdaycloud" element={<HappyBirthdayCloudPage />} />
                        <Route
                            path="/uploader"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.uploader} hideHeader>
                                    <MailPopup />
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/search/*"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.search}>
                                    {isPhone ? (
                                        <MobileSearchPage />
                                    ) : (
                                        <MainPage storage={EStorageType.search} renderWorm>
                                            <SearchPage initialItemId={getInitialId(location?.pathname)} />
                                        </MainPage>
                                    )}
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/home/*"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.home}>
                                    {(location) =>
                                        isPhone ? (
                                            <MobileHomePage />
                                        ) : (
                                            <MainPage showUploader renderWorm storage={EStorageType.home}>
                                                <HomePage initialItemId={getInitialId(location?.pathname)} />
                                            </MainPage>
                                        )
                                    }
                                </PageWrapper>
                            }
                        />
                        <Route path="/iframes/inline-integration-iframe/*" element={<IntegrationRedirect />} />
                        <Route
                            path="/integration/*"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.integration}>
                                    <MainPage
                                        showUploader={false}
                                        allowDnd={false}
                                        renderWorm={false}
                                        showSpace={false}
                                        storage={EStorageType.integration}
                                    >
                                        <IntegrationHomePage />
                                    </MainPage>
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/inline-integration/*"
                            element={
                                <PageWrapper
                                    isLoading={isLoading}
                                    hasError={hasError}
                                    pageId={EPageId.inlineIntegration}
                                    hideHeader={true}
                                    hasActionPanel={false}
                                >
                                    <MainPage
                                        showUploader={false}
                                        allowDnd={false}
                                        renderWorm={false}
                                        showSpace={false}
                                        storage={EStorageType.inlineIntegration}
                                    >
                                        <InlineIntegrationHomePage />
                                    </MainPage>
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/buy"
                            element={
                                <PageWrapper
                                    isLoading={isLoading}
                                    hasError={hasError}
                                    pageId={EPageId.buy}
                                    hideHeader
                                    hasActionPanel={false}
                                >
                                    <BuyIframe />
                                </PageWrapper>
                            }
                        />
                        <Route path="/" element={<Navigate replace to="/home/" />} />
                        <Route path="/links" element={<Navigate replace to="/shared/links" />} />
                        <Route path="/incoming" element={<Navigate replace to="/shared/links" />} />
                        <Route path="/promo/newyear" element={<NewYearLanding />} />
                        <Route path="/promo/present" element={<PresentLanding type="cloud" />} />
                        <Route path="/promo/mail" element={<PresentLanding type="mail" />} />
                        <Route path="/promo/9may" element={<NineMayLanding />} />
                        <Route path="/alldocuments" element={<Navigate replace to="/alldocuments/document" />} />
                        <Route
                            path="/alldocuments/:documentType"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.alldocuments}>
                                    <AllDocumentsPage />
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/album/*"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.public}>
                                    {renderPublic(EStorageType.public, isPhone, IS_PUBLIC_FOLDER, HIDE_ADS, Boolean(PUBLIC_SHOVE), true)}
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/public/*"
                            element={
                                <PageWrapper
                                    isLoading={isLoading}
                                    hasError={hasError}
                                    pageId={EPageId.public}
                                    hideHeader={IS_CHOSEN_PUBLIC_FILE && !PUBLIC_SHOVE && !isPhone}
                                >
                                    {renderPublic(EStorageType.public, isPhone, IS_PUBLIC_FOLDER, HIDE_ADS, Boolean(PUBLIC_SHOVE))}
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/stock/*"
                            element={
                                <PageWrapper
                                    isLoading={isLoading}
                                    hasError={hasError}
                                    pageId={EPageId.public}
                                    hideHeader={IS_CHOSEN_PUBLIC_FILE && !PUBLIC_SHOVE && !isPhone}
                                >
                                    {renderPublic(EStorageType.stock, isPhone, IS_PUBLIC_FOLDER, HIDE_ADS, Boolean(PUBLIC_SHOVE))}
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="*"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.notFound}>
                                    <MainPage showUploader renderWorm storage={EStorageType.home}>
                                        <NotFoundPage />
                                    </MainPage>
                                </PageWrapper>
                            }
                        />
                    </Routes>
                </Suspense>
            </Router>
        </TooltipProvider>
    );
};
