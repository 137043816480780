/* tempexp_14812-full-file */
import { Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { FC, useEffect } from 'react';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendEmptyStatesAnalitics } from 'reactApp/utils/emptyStatesGa';

import { useConfigByStorage } from './Empty.config';
import styles from './Empty.css';
import { CategoryType, Config } from './Empty.types';

interface EmptyProps {
    className?: string;
    storage: EStorageType;
    categoryType?: CategoryType;
    isMobile?: boolean;
}

const configIsMap = (config: Config | Map<CategoryType | undefined, Config> | undefined): config is Map<CategoryType | undefined, Config> =>
    !!config && config instanceof Map;

export const Empty: FC<EmptyProps> = ({ className, storage, categoryType, isMobile = false }) => {
    const configByStorage = useConfigByStorage({ isMobile });

    useEffect(() => {
        sendEmptyStatesAnalitics({ action: 'show' });
    }, []);

    let config = configByStorage.get(storage);

    if (configIsMap(config) && categoryType) {
        config = config.get(categoryType);
    }

    if (!configIsMap(config) && !!config) {
        const { icon, title, description: Description, BottomContent } = config;

        return (
            <div
                className={classNames(styles.root, className, {
                    [styles.touch]: isMobile,
                })}
            >
                <div className={styles.container}>
                    <img src={icon} className={styles.icon} />
                    <Spacing size={isMobile ? 60 : 24} />
                    <Text className={styles.title}>{title}</Text>
                    <Spacing size={12} />
                    <div className={styles.description}>
                        {typeof Description === 'function' && !React.isValidElement(Description) ? (
                            <Description isMobile={isMobile} />
                        ) : (
                            Description
                        )}
                    </div>
                    <Spacing size={24} />
                    {BottomContent && <BottomContent isMobile={isMobile} />}
                </div>
            </div>
        );
    }

    return null;
};
