import { createReducer } from '@reduxjs/toolkit';
import settings from 'Cloud/settings';
import { type IntegrationState } from 'reactApp/modules/integration/integration.types';

export const initialState: IntegrationState = {
    parentOrigin: settings.request?.query_params?.['parent_origin'],
    p: settings.request?.query_params?.['integration_p'],
    client: settings.request?.query_params?.['integration_client'],
    userId: settings.request?.query_params?.['integration_uid'],
    channel: settings.request?.query_params?.['integration_channel'],
    authOrigin: settings.request?.query_params?.['auth_origin'],
    authType: settings.request?.query_params?.['auth'],
};

export const integrationReducer = createReducer(initialState, {});
