import { useDispatch, useSelector } from 'react-redux';
import { cancelRenewTrialPeriod } from 'reactApp/appHelpers/featuresHelpers';
import { BuySubscriptionActions } from 'reactApp/modules/buySubscription/buySubscription.module';
import { getFeatureAbCancelSubsWithCaptcha } from 'reactApp/modules/features/features.selectors';
import { SUBSCRIPTION_ANALYTICS } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscription.analytics';
import { ESubscriptionModalAction } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { HardlyUsedThunkDispatch } from 'reactApp/types/app';
import { renderCancelSubscriptionWithCaptchaModal } from 'reactApp/ui/CancelSubscriptionWithCaptchaModal/CancelSubscriptionWithCaptchaModal.helpers';
import { renderSubscriptionsModal } from 'reactApp/ui/Mobile/SubscriptionsModal/SubscriptionsModal.helpers';
import { sendDwh } from 'reactApp/utils/ga';

import { DwhData } from '../CancelAutoRenewalModal.types';

export const useHandlers = (dwhData: DwhData, onClose: () => void) => {
    const dispatch = useDispatch<HardlyUsedThunkDispatch>();

    // tempexp_13984-next-line
    const abWithCaptcha = useSelector(getFeatureAbCancelSubsWithCaptcha);

    const sendAnalytics = (action: ESubscriptionModalAction) => {
        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.CANCEL_AUTOPAY_RESTORE,
            action: `my-tariff-${action}`,
            dwhData,
        });
    };

    const handleCloseSubscription = (id: string) => {
        onClose();

        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.MY_TARIFF_CANCEL_STEP2_CANCEL,
            dwhData,
        });

        // tempexp_13984-start
        if (abWithCaptcha === 'b') {
            renderCancelSubscriptionWithCaptchaModal({ subscriptionId: id, isPhone: true });
            return;
        }
        // tempexp_13984-end

        // tempexp_16480-next-line
        if (cancelRenewTrialPeriod) {
            dispatch(
                BuySubscriptionActions.cancelAutorenewCheckCaptchaAndTrial({
                    subscriptionId: id,
                    sendAnalytics,
                    period: cancelRenewTrialPeriod,
                })
            );
        } else {
            dispatch(BuySubscriptionActions.cancelAutorenewSubscription({ subscriptionId: id, sendAnalytics }));
        }
    };

    const handleGoToAllSubscriptions = () => {
        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.MY_TARIFF_CANCEL_STEP2_SAFE_SUBS,
            dwhData,
        });

        onClose();
        renderSubscriptionsModal();
    };

    return {
        handleCloseSubscription,
        handleGoToAllSubscriptions,
    };
};
