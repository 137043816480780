/* eslint-disable max-lines */
/* eslint-disable unicorn/consistent-function-scoping */
import { userKeeper } from '@mail-core/dashboard';
import { Icon20RotateRight, Icon24Crop, Icon24MagicWandOutline } from '@vkontakte/icons';
import { EditorID } from 'Cloud/Application/Editor/types';
import browser from 'Cloud/browser';
import config from 'Cloud/config';
import classNames from 'clsx';
import hotkeys from 'hotkeys-js';
import { ReactComponent as LeftArrowIcon } from 'img/icons/leftArrow.svg';
import { ReactComponent as RightArrowIcon } from 'img/icons/rightArrow.svg';
import sha1 from 'js-sha1';
import { xray } from 'lib/xray';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import { parse } from 'qs';
import React, { createRef, PureComponent, ReactElement, RefObject } from 'react';
import { downloadItem } from 'reactApp/appHelpers/appDownload';
import { getDownloadItemSize } from 'reactApp/appHelpers/appHelpers';
import {
    ANONYM_USER,
    BACK_URL,
    ENABLE_FULL_RESPONSIVE,
    IS_B2B_BIZ_USER,
    IS_BLOCKED,
    IS_MY_TEAM,
    IS_ONPREMISE,
    IS_PUBLIC_ALBUM,
    platform,
    PROMO_TARIFFS,
} from 'reactApp/appHelpers/configHelpers';
import { openEditor } from 'reactApp/appHelpers/editorHelpers';
import {
    ebookDesktopConfig,
    isAttachesSidebar,
    isAttachSaveToAllDocuments,
    isFeatureAlbumFavorites,
} from 'reactApp/appHelpers/featuresHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { openCreateCopyOfNoneditableFileModal } from 'reactApp/components/CreateCopyOfNoneditableFileModal/helper';
import { sendTechAnalytics, TechAnalyticsType } from 'reactApp/components/CreateCopyOfNoneditableFileModal/sendTechAnalytics';
import { emptyImageUrl } from 'reactApp/constants';
import { MAIL_ATTACHES_FOLDER_ID, REACT_VIEWER_ID } from 'reactApp/constants/magicIdentificators';
import { ESources } from 'reactApp/hooks/useDownloadEbook';
import { openAttachLetter } from 'reactApp/modules/attaches/attaches.helpers';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { StackLimitedCache } from 'reactApp/modules/cache/StackLimitedCache';
import { openEbookReader } from 'reactApp/modules/ebook/ebook.helpers';
import { isImageEditor, isOnPremR7Product } from 'reactApp/modules/features/features.helpers';
import {
    getWeblinkFromPublicId,
    isAudio,
    isDocument,
    isEBook,
    isFileForPlayer,
    isImage,
    isPdf,
    isPlainSource,
    isVideo,
    isVirusItem,
} from 'reactApp/modules/file/utils';
import { editAttachCopyNotification, getGoToAndScrollUrl } from 'reactApp/modules/modifying/modifying.helpers';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { isIntegrationStorage } from 'reactApp/modules/storage/storage.helpers';
import { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { renderWelcomeHelper } from 'reactApp/modules/welcome/utils';
import { EDocSubKind } from 'reactApp/sections/AllDocuments/allDocuments.types';
import { renderAuthDialog } from 'reactApp/ui/AuthDialog/authDialog.toolkit';
import { EditorHeaderContainer as EditorHeader } from 'reactApp/ui/EditorHeader/EditorHeaderContainer';
import { Gestures, GesturesEmpty } from 'reactApp/ui/Gestures/Gestures';
import { GestureTypes } from 'reactApp/ui/Gestures/Gestures.types';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { ViewerSnackbarPromoContainer as MyOfficePromoViewerSnackbar } from 'reactApp/ui/MyOfficePromo/ViewerSnackbar/ViewerSnackbarPromoContainer';
import { BottomBanner } from 'reactApp/ui/ReactViewer/BottomBanner';
import { BottomToolbar } from 'reactApp/ui/ReactViewer/BottomToolbar/BottomToolbar';
import { FaceFilter } from 'reactApp/ui/ReactViewer/FaceFilter/FaceFilter';
import { o2AuthedSourceLoader } from 'reactApp/ui/ReactViewer/O2AuthedSourceLoader';
import { PaginatorLine } from 'reactApp/ui/ReactViewer/PaginatorLine/PaginatorLine';
import {
    CONVERT_NONE_EDITABLE_FORMATS_SNACK_ID,
    hotKeysScope,
    MAX_ZOOM_STATE,
    TIMEOUT_TO_INACTIVE,
} from 'reactApp/ui/ReactViewer/ReactViewer.constants';
import {
    calculateZoomValues,
    getDefaultZoomIdx,
    getDWHParamsOnEntityEvent,
    getItemImageUrl,
    getPosterUrl,
    IViewerDwhData,
    openImageEditor,
    processItemPublish,
    sendViewerDwh,
    tryGetNearestIndexOfValue,
} from 'reactApp/ui/ReactViewer/ReactViewer.helpers';
import { IProps, IState, ViewerConfigType } from 'reactApp/ui/ReactViewer/ReactViewer.types';
import { sessionWatcher } from 'reactApp/ui/ReactViewer/SessionWatcher';
import { ViewerArchiveConnected } from 'reactApp/ui/ReactViewer/ViewerArchive/ViewerArchive';
import { BizInlineViewerToolbar } from 'reactApp/ui/ReactViewer/ViewerHeader/BizInlineViewerToolbar/BizInlineViewerToolbar';
import { ViewerHeaderButton } from 'reactApp/ui/ReactViewer/ViewerHeader/ViewerHeaderButton';
import { IMAGE_EDITOR_ID } from 'reactApp/ui/ReactViewer/ViewerImage/components/ImageEditSurvey/ImageEditorSurvey';
import { ViewerImage } from 'reactApp/ui/ReactViewer/ViewerImage/ViewerImage';
import { renderCopyDialog, renderMoveDialog } from 'reactApp/ui/SelectFolderDialog/SelectFolderDialog.toolkit';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { SwipingComponent } from 'reactApp/ui/SwipingComponent/SwipingComponent';
import { TrialPromoBanner } from 'reactApp/ui/TrialPromoBanner/TrialPromoBanner';
import { AstraMetaProcessorType, createAstraMetaDiv, removeAstraMetaDiv, updateAstraMetaData } from 'reactApp/utils/astraPluginHelpers';
import { FullScreenEvent, fullScreenProcessor } from 'reactApp/utils/fullScreenProcessor';
import { sendDwh, sendXray as sendXrayFunc } from 'reactApp/utils/ga';
import { noop, roundCustom } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';
import { ECategoryGa, EPaymentGa } from 'reactApp/utils/paymentGa';
import { scrollLock, scrollUnlock } from 'reactApp/utils/scrollLock';
import { getFileExtLimit } from 'reactApp/utils/textHelpers';
import { sendTmrGoal } from 'reactApp/utils/tmr';

import { AttachesBanner } from './AttachesBanner';
import { AttachesSidebarBanner } from './AttachesSidebarBanner';
import styles from './ReactViewer.css';
import { Stub } from './Stub/Stub';
import { VideoPlayer } from './VideoPlayer/VideoPlayer';
import { ViewerDoc } from './ViewerDoc/ViewerDoc';
import { ViewerHeader } from './ViewerHeader/ViewerHeader';
import { ViewerPdf } from './ViewerPdf/ViewerPdf';
import { ViewerTxt } from './ViewerTxt/ViewerTxt';

const isPaidUser = config.get('PAID_USER');
const isProUser = config.get('IS_PRO_USER');

const componentsCache = new StackLimitedCache<ReactElement>();

const MIN_ZOOM_SIZE = 0.25;
const MAX_ZOOM_SIZE = 4;
const ZOOM_STEP = 0.25;

const shouldOpenEbookReader = () =>
    parse(window.location.search, { ignoreQueryPrefix: true })?.dialog?.toLowerCase() === popupNames.EBOOK_READER.toLowerCase();

const showBottomBanner = (showBottomAds, isAttaches) => showBottomAds && !isAttaches;
const showAttachesBottomBanner = (hideAdsOnAttaches, isAttaches, file) =>
    !isAttachesSidebar && isAttaches && !hideAdsOnAttaches && Boolean(file);
const showAttachesSidebarBanner = (hideAdsOnAttaches, isAttaches, file) =>
    isAttachesSidebar && isAttaches && !hideAdsOnAttaches && Boolean(file);

/* CLOUDWEB-15260 */
const skipPdfRender = (browser.isIpadOs() || browser.isIpad()) && (browser.isSafari() || browser.isIosChrome());

export class ReactViewerComponent extends PureComponent<IProps, IState> {
    static defaultProps = {
        gaCategory: 'pdf-view',
        gaSuffix: '',
        selectItemIndexInArray: noop,
        isViewer: false,
        toggleFavorite: noop,
        onRetryItemLoad: noop,
        publishItemAction: noop,
        selectFace: noop,
        isPreviewableItem: true,
        showSnackbar: noop,
        getFitContentState: () => false,
        embedded: false,
        showViewerHeader: true,
        showLogo: false,
        showBottomAds: false,
        disableZoom: false,
        isStoryViewed: false,
        hideAdsOnAttaches: false,
        showEditButton: false,
        isNewAdFormat: false,
        sharedFolderFile: false,
        sharedFolderFileBtnFeature: false,
        isEditable: false,
        disableClose: false,
        showTrialPromo: false,
        setOvidiusZoom: noop,
        isAuthorized: false,
    };

    state: IState = {
        isLoading: false,
        thumbsCache: {},
        isInactive: false,
        forceActive: false,
        isSwiping: false,
        mountMyOfficeSnackbar: false,
        filePrev: null,
        zoomState: null,
        zoomStates: [],
        startShowTime: Date.now(),
        customZoomState: null,
        isFitContent: false,
        hasFitContentRatio: false,
        isMousePressed: false,
        isUnpublished: false,
        showFacesFilter: false,
        isPdfConvertedError: false,
        isOvidiusV2Error: false,
        contentForPrintCache: {},
        docViewerCache: {},
        searchText: null,
        isTrialPromoAvailable: true,
        ovidiusZoom: 1,
        normalizedOvidiusScale: 1,
        showEbook: false,
    };

    private navigateClickCount = 0;

    public contentWrapperRef: RefObject<HTMLDivElement> = createRef();
    public contentRef: HTMLDivElement | null = null;

    private timerId: number | undefined;
    private timerDocReadyId: number | undefined;

    private imgCache = {};

    private previousKeyScope = '';

    // eslint-disable-next-line max-lines-per-function, sonarjs/cognitive-complexity
    componentDidMount() {
        const {
            file,
            isViewer,
            getFitContentState,
            isAttaches,
            isSearchRadar,
            attachesSearchedItemPos,
            embedded,
            sharedFolderFile,
            sharedFolderFileBtnFeature,
            isEditable,
            disableClose,
            showEditButton,
            itemStorage,
            editingNoneEditableFormats,
            onAppReady,
            showBottomAds,
            hideAdsOnAttaches,
            setBottomMargin = noop,
            onItemOpen,
        } = this.props;

        fullScreenProcessor.subscribe(this.onFullScreenChange);

        const isPublic = itemStorage === EStorageType.public || itemStorage === EStorageType.stock;

        if (showEditButton && editingNoneEditableFormats && file && itemStorage) {
            const { ext, size, id } = file;
            sendTechAnalytics(ext, itemStorage)(TechAnalyticsType.showEditButton);
            sendDwh({
                eventCategory: ECategoryGa.viewer,
                action: 'show-edit',
                dwhData: {
                    extension: ext,
                    size_file: size,
                    id_media: id,
                },
            });
        }

        this.openConvertNoneEditableFormatsSnack();

        if (sharedFolderFile && isEditable) {
            xray.send(`shrd-folder-edit-btn-${sharedFolderFileBtnFeature ? 'visible' : 'hidden'}`);
        }

        if (IS_B2B_BIZ_USER) {
            this.sendXray('b2b-show', IS_BLOCKED ? 'blocked' : 'nonbl');
        }

        if (!embedded) {
            scrollLock(this.contentWrapperRef.current);
        }

        if (!isPublic) {
            sendTmrGoal({ goal: 'show-item' });
        }
        this.sendXray('open', file ? file?.kind || 'un' : 'null');
        if (file) {
            this.sendXray('show', file.kind ?? 'un');
        }
        if (isAttaches) {
            this.sendXray('showattaches');
            userKeeper.timeEnd('cloud-viewer-ready', { score: 'app:ready' });

            if (window.performance?.timing?.connectStart) {
                const ms = Math.min(Math.round(Date.now().valueOf() - window.performance.timing.connectStart), 18000);
                if (ms >= 0) {
                    sendXrayFunc('attaches_viewer_mnt', { ms });
                    const groupVal = roundCustom(Math.round(ms), [0, 500, 1000, 2000, 3000, 4000, 5000, 7500, 10000, 20000, 30000]);
                    sendXrayFunc(`attaches_viewer_mnt_hist_${groupVal}`);
                }
            }
        }

        if (!onItemOpen) {
            this.sendDwh({
                action: 'open',
                forceSend: true,
            });
        }

        if (file && isSearchRadar) {
            const dwhData = {
                eventCategory: ECategoryGa.entered,
                action: 'open',
                forceSend: true,
                free_user: !isPaidUser,
                paid_user: isPaidUser,
                pro_user: isProUser,
                type: file?.ext,
                mtime: file?.mtime,
                pos: attachesSearchedItemPos,
                debug_stage: 'on_mount',
                placement: file?.srchSrc,
            };

            this.sendDwh(dwhData, true);
        }

        const showBottomAd = showBottomBanner(showBottomAds, isAttaches) || showAttachesBottomBanner(hideAdsOnAttaches, isAttaches, {});
        if (showBottomAd) {
            setBottomMargin(40);
        }

        if (isViewer) {
            this.previousKeyScope = hotkeys.getScope();
            hotkeys.setScope(hotKeysScope);

            hotkeys('space,right', hotKeysScope, (e) => {
                e.preventDefault();

                this.onNavigateRight();
            });

            hotkeys('f', hotKeysScope, (e) => {
                e.preventDefault();
                this.sendXray('hotkey', 'f');

                fullScreenProcessor.switchFullscreen();
            });

            hotkeys('del, delete', hotKeysScope, (e) => {
                e.preventDefault();
                this.sendXray('hotkey', 'del');

                this.handleOnDelete(this.props.file);
            });

            hotkeys('left, backspace', hotKeysScope, (e) => {
                e.preventDefault();

                this.onNavigateLeft();
            });

            hotkeys('*', hotKeysScope, (e) => {
                //  если просто ловить +, то с нампада не приходит. С нампада прилетает только в *

                // после выхода из fullscreen hotkeys прямой обработчик Escape не всегда срабатывает, потому обрабатываем тут
                if (e.key === 'Escape' && !embedded && !disableClose) {
                    const { zoomState, zoomStates } = this.state;
                    e.preventDefault();

                    this.sendXray('hotkey', 'esc');

                    if (zoomState && zoomState !== zoomStates[getDefaultZoomIdx()]) {
                        this.runUITimer(false);
                        this.setState({ zoomState: null });
                        return false;
                    }

                    this.handleOnClose();

                    return false;
                }

                if (['=', '+'].includes(e.key)) {
                    e.preventDefault();

                    this.sendXray('hotkey', 'zoomp');

                    this.increaseZoom(null);
                    return false;
                }

                if (e.key === '-') {
                    e.preventDefault();

                    this.sendXray('hotkey', 'zoomp');

                    this.decreaseZoom(null);
                    return false;
                }
            });
        }

        if (getFitContentState?.()) {
            this.setState({ isFitContent: true });
        }

        this.runUITimer();

        this.preloadImages();

        window.addEventListener?.('resize', this.handleResize);

        document.body.classList.add('viewer_open');

        if (file?.id) {
            this.sendDwh({
                action: EPaymentGa.openContent,
                forceSend: true,
            });
        }

        window.addEventListener?.('storage', this.reloadWindowIfFileChange);

        if (shouldOpenEbookReader()) {
            if (!file) {
                this.setState({ showEbook: true });
            } else {
                this.handleOpenEbook();
            }
        }

        sessionWatcher.init();

        onAppReady?.();

        if (this.props?.astraMetaFeature && file?.id) {
            createAstraMetaDiv();
        }
    }

    handleResize = debounce(() => {
        const { file, embedded } = this.props;
        let { zoomState, customZoomState } = this.state;
        const { thumbsCache, isFitContent } = this.state;

        if (thumbsCache[file?.id]?.isLoaded) {
            const { width, height } = thumbsCache[file?.id];
            const { range, hasFitContentRatio } = calculateZoomValues({
                width,
                height,
                file,
                embedded,
                contentRect: this.contentRef?.getBoundingClientRect(),
            });
            if (range !== null && !range.includes(zoomState)) {
                zoomState = range[getDefaultZoomIdx(isFitContent, hasFitContentRatio)];
                customZoomState = null;
            }

            this.setState({ zoomStates: range, zoomState, customZoomState, hasFitContentRatio });
        }
    }, 250);

    componentDidUpdate(prevProps: IProps) {
        const {
            indexInArray,
            file,
            embedded,
            isPreviewableItem,
            storyId,
            preloadUrls,
            isAttaches,
            showEditButton,
            showTrialPromo,
            isViewer,
        } = this.props;
        const { thumbsCache, showEbook, docViewerCache } = this.state;

        const fileIdChanged = file?.id && file?.id !== prevProps.file?.id;

        if (showEbook && file?.id) {
            // Для аттачей и пабликов надо дождаться, когда загрузится из апи информация о файле
            this.handleOpenEbook();
            this.setState({ showEbook: false });
        }

        if (embedded) {
            if (fileIdChanged) {
                this.sendFileViewRadars(file, isPreviewableItem, isAttaches);
            }

            return;
        }

        if (!storyId && ((indexInArray === null && prevProps.indexInArray !== null) || (!file && prevProps.file))) {
            this.handleOnClose();
            return;
        }

        if (preloadUrls?.length && !prevProps.preloadUrls?.length) {
            this.preloadImages();
        }

        if (storyId && storyId !== prevProps.storyId) {
            this.sendDwh({
                eventCategory: ECategoryGa.story,
                action: 'story_view',
                type_reason: prevProps.storyId ? 'switch' : 'click',
            });
        }

        if (file?.id !== prevProps.file?.id) {
            this.sendFileViewRadars(file, isPreviewableItem, isAttaches);
        }

        if (file?.id !== prevProps.file?.id) {
            this.preloadImages();

            let zoomStates = this.state.zoomStates;
            let hasFitContentRatio = this.state.hasFitContentRatio;

            if (thumbsCache[file?.id]?.isLoaded) {
                this.sendXray('showcached');

                const { width, height } = thumbsCache[file?.id];
                const { range, hasFitContentRatio: newHasRatio } = calculateZoomValues({
                    width,
                    height,
                    contentRect: this.contentRef?.getBoundingClientRect(),
                    file,
                    embedded,
                });

                zoomStates = range;
                hasFitContentRatio = newHasRatio;
                if (range?.length) {
                    this.sendXray('zoom', 'show');
                }
            }

            this.setState({
                filePrev: prevProps.file,
                zoomStates,
                startShowTime: Date.now(),
                hasFitContentRatio,
            });

            if (file?.kind === 'document') {
                this.setFocusForDoc();
            } else if (this.timerDocReadyId) {
                window.clearTimeout(this.timerDocReadyId);
                this.timerDocReadyId = undefined;
            }

            this.runUITimer(!isPreviewableItem);

            sessionWatcher.startSession({ key: file?.id });
        } else if (storyId && !prevProps.storyId) {
            this.sendDwh({ action: 'photo_in_story_view', eventCategory: ECategoryGa.story });
        }

        if (file?.id !== prevProps.file?.id && prevProps.file && (isVideo(prevProps.file) || isAudio(prevProps.file))) {
            componentsCache.updateItem(prevProps.file.id + prevProps.file.mtime, this.renderPlayer(prevProps.file, false));
        }

        if (file?.id !== prevProps.file?.id && file && (isVideo(file) || isAudio(file)) && componentsCache.getItem(file.id + file.mtime)) {
            componentsCache.updateItem(file.id + file.mtime, this.renderPlayer(file, true));
        }

        if (showEditButton && !prevProps.showEditButton) {
            this.sendXray('showeditbtn');
        }

        if ((prevProps.isSearchRadar === false || prevProps.file === undefined) && this.props.file && this.props.isSearchRadar) {
            const dwhData = {
                eventCategory: ECategoryGa.entered,
                action: 'open',
                forceSend: true,
                free_user: !isPaidUser,
                paid_user: isPaidUser,
                pro_user: isProUser,
                type: file?.ext,
                mtime: file?.mtime,
                pos: this.props.attachesSearchedItemPos,
                debug_stage: 'on_update',
            };

            this.sendDwh(dwhData, true);
        }

        // если изменился пропс showTrialPromo, но сам файл не переключали, то промку не показываем
        // надо для случая закрытия промки про Мой Офис
        if (file?.id && file?.id === prevProps.file?.id && prevProps.showTrialPromo !== showTrialPromo) {
            this.setState({
                isTrialPromoAvailable: false,
            });
        }

        if (this.props.astraMetaFeature && file?.id) {
            const { id, wopi } = docViewerCache[file.id] || {};
            const processorType = isViewer ? AstraMetaProcessorType.viewer : AstraMetaProcessorType.default;

            updateAstraMetaData({
                processorType,
                editorID: id,
                fileExt: file?.ext,
                isWOPI: Boolean(wopi),
                processorIDsMap: this.props.astraMetaFeature,
            });
        }
    }

    componentWillUnmount(): void {
        const { embedded, isViewer, setBottomMargin = noop } = this.props;

        fullScreenProcessor.unsubscribe(this.onFullScreenChange);

        componentsCache.clear();

        if (!embedded) {
            scrollUnlock(this.contentWrapperRef.current);
        }

        if (this.timerId) {
            window.clearTimeout(this.timerId);
            this.timerId = undefined;
        }

        if (isViewer) {
            hotkeys.deleteScope(hotKeysScope);
            if (this.previousKeyScope) {
                hotkeys.setScope(this.previousKeyScope);
            }
        }

        window.removeEventListener('resize', this.handleResize);
        this.contentRef?.removeEventListener('wheel', this.handleWheel);

        Object.keys(this.imgCache).forEach((key) => {
            this.imgCache[key].onload = null;
            this.imgCache[key].src = emptyImageUrl;
            delete this.imgCache[key];
        });

        document.body.classList.remove('viewer_open');

        window.removeEventListener('storage', this.reloadWindowIfFileChange);

        sessionWatcher.destroy();

        setBottomMargin(0);

        this.closeConvertNoneEditableFormatsSnack();

        removeAstraMetaDiv();
    }

    sendFileViewRadars = (file, isPreviewableItem?: boolean, isAttaches?: boolean) => {
        const ext = getFileExtLimit(file?.ext);

        this.sendXray('show', file?.kind ?? 'un', false, { [ext]: 1 });

        if (isAttaches) {
            this.sendXray(`show-attach-${file?.attachType}`, file?.kind ?? 'un', false, { [ext]: 1 });
        }

        if (isPreviewableItem && file?.ext === 'zip') {
            this.sendXray('show', 'archive');
        }

        this.sendDwh({ action: 'photo_in_story_view', eventCategory: ECategoryGa.story });

        if (
            isPreviewableItem &&
            !(isImage(file) && getItemImageUrl(file)) &&
            !((isVideo(file) || isAudio(file)) && file?.href?.media) &&
            !(file?.href?.view || file?.url?.view)
        ) {
            this.sendXray('nourl', file.kind);
        }

        if (file?.id) {
            this.sendDwh({
                action: EPaymentGa.openContent,
                forceSend: true,
            });
        }
    };

    handleOpenEbook = () => {
        openPopupHelper({
            popupName: popupNames.EBOOK_READER,
            data: {
                file: this.props.file,
                source: ESources.desktop,
                itemStorage: this.props.itemStorage,
            },
        });
    };

    private openConvertNoneEditableFormatsSnack = () => {
        const { editingNoneEditableFormats, file, isEditable, itemStorage } = this.props;
        const isPublic = itemStorage === EStorageType.public;

        if (!isEditable || !file || !editingNoneEditableFormats || file?.isMounted || isPublic) {
            return;
        }

        const { ext = '', subKind = '', size = 0 } = file;
        const extension = ext.toUpperCase();
        const convertExtension = editingNoneEditableFormats.ext.toUpperCase();
        const text = `Этот файл в формате ${extension}. Чтобы редактировать, конвертируйте его в ${convertExtension}`;

        snackbarController.showSnackbar({
            id: CONVERT_NONE_EDITABLE_FORMATS_SNACK_ID,
            text,
            type: SnackbarTypes.warning,
            closable: true,
            disableCloseTimeout: true,
            buttonText: 'Конвертировать',
            onShow: () => {
                sendDwh({
                    eventCategory: ECategoryGa.viewer,
                    action: 'show-snack_no-edit',
                    dwhData: {
                        platform,
                        extension: ext,
                        size_file: size,
                        type_content: EDocSubKind[subKind] ?? '',
                        source: EDocSubKind[subKind] ?? '',
                    },
                });
            },
            onButtonClick: () => {
                sendDwh({
                    eventCategory: ECategoryGa.viewer,
                    action: 'show-snack_convert',
                    dwhData: {
                        platform,
                        extension,
                        size_file: size,
                        type_content: EDocSubKind[subKind] ?? '',
                        source: EDocSubKind[subKind] ?? '',
                    },
                });
                openCreateCopyOfNoneditableFileModal({
                    file,
                    editingNoneEditableFormats,
                });
            },
        });
    };

    private closeConvertNoneEditableFormatsSnack = () => {
        snackbarController.hideSnackbar(CONVERT_NONE_EDITABLE_FORMATS_SNACK_ID);
    };

    /**
     * При переименовании файла из редактора МойОфис необходимо обновить вкладку просмотрщика.
     * Отслеживание изменений происходит в localstorage.
     */
    private reloadWindowIfFileChange = () => {
        const { getRenamedFileIdInsideViewer, clearRenamedFileIdInsideViewer } = this.props;
        const { newId, outdateId } = getRenamedFileIdInsideViewer?.() || {};

        // TODO: В дальнейшем хотим переписать без перезагрузки страницы
        if (newId && outdateId && outdateId === this.props.file?.id) {
            clearRenamedFileIdInsideViewer?.();
            window.location.href = encodeURI(decodeURI(window.location.href).replace(outdateId, newId));
        }
    };

    private setSearchText = (text: string): void => {
        this.setState({ searchText: text });
    };

    private onContentRefChange = (node) => {
        this.contentRef?.removeEventListener('wheel', this.handleWheel);

        this.contentRef = node;

        this.contentRef?.addEventListener('wheel', this.handleWheel, { passive: false });
    };

    private onNavigateLeft = () => {
        const { backItemIndexInArray } = this.props;

        if (backItemIndexInArray !== null) {
            this.sendXray('hotkey', 'navigate');
            this.handleNavigation({ itemIndex: backItemIndexInArray });
        }
    };

    private onNavigateRight = () => {
        const { forwardItemIndexInArray } = this.props;

        if (forwardItemIndexInArray !== null) {
            this.sendXray('hotkey', 'navigate');
            this.handleNavigation({ itemIndex: forwardItemIndexInArray });
        }
    };

    private setFocusForDoc = () => {
        if (this.timerDocReadyId) {
            window.clearTimeout(this.timerDocReadyId);
        }
        this.contentRef?.focus();
        this.timerDocReadyId = window.setTimeout(() => {
            this.contentRef?.focus();
            this.timerDocReadyId = undefined;
        }, 1000);
    };

    private sendDwh = (
        {
            action,
            type_click,
            eventCategory,
            type_reason,
            storyId = this.props.storyId,
            forceSend = false,
            percent_close,
            ...rest
        }: IViewerDwhData,
        isSearchRadar = false
    ) => {
        const { itemStorage, itemCount, storyType, file, faces, isPreviewableItem, sendSearchRadar } = this.props;

        const source = IS_PUBLIC_ALBUM ? 'album' : itemStorage || '';

        if (forceSend || (storyType && storyId && file)) {
            const dwhData = {
                eventCategory,
                count_media: itemCount ?? 0,
                id_media: file?.id ? sha1(file?.id) : '',
                id_story: storyId || '',
                id_public: itemStorage === EStorageType.public ? getWeblinkFromPublicId(file?.id) : 'None',
                source,
                type_content: file?.kind,
                extension: file?.ext?.toLowerCase() || '',
                type_story: storyType,
                action,
                is_stories: itemStorage === EStorageType.story,
                type_click,
                type_reason,
                have_face: faces && faces.length > 0,
                is_full_render: isPreviewableItem,
                is_edit: false,
                size_files: file?.size,
                percent_close,
                iz_biz: IS_B2B_BIZ_USER,
                is_archive: file?.kind === 'archive' || file?.subKind === 'archive',
                ...rest,
            };

            if (eventCategory === ECategoryGa.viewer_search || isSearchRadar) {
                const dwhData = {
                    eventCategory,
                    action,
                };

                let item: any = {
                    file_name: file.nameWithoutExt,
                    type: file.kind || file.subkind,
                    pos: file.pos,
                    file_id: file.id,
                    extension: file.isFolder ? 'folder' : file.ext,
                };

                if (action !== 'delete') {
                    item = {
                        ...item,
                        mtime: file.mtime,
                        size: file.size || 0,
                    };
                }

                sendSearchRadar({
                    dwhData,
                    items: [item],
                });
            } else {
                sendViewerDwh(dwhData);
            }
        }
    };

    private sendXray = (action, label = '', sendImmediately = false, data: null | Record<string, number> = null) => {
        const { gaCategory, gaSuffix, itemStorage } = this.props;

        let dataSend: Record<string, number> | null = null;
        if (gaSuffix) {
            dataSend = { [gaSuffix]: 1 };
        }
        if (data) {
            dataSend = dataSend ? { ...dataSend, ...data } : data;
        }

        sendXrayFunc([gaCategory, action, label], dataSend, { sendImmediately });
        if (itemStorage) {
            sendXrayFunc([`${gaCategory}-${itemStorage}`, action, label], dataSend, { sendImmediately });
        }
    };

    private increaseZoom = (event) => {
        const { zoomState, zoomStates, customZoomState, isFitContent, hasFitContentRatio } = this.state;
        const { setFitContentState = noop, disableZoom } = this.props;

        if (disableZoom) {
            return;
        }

        event?.stopPropagation();
        this.runUITimer();

        this.sendZoomDwh();

        const currentZoom = (customZoomState || zoomState) as number;

        if (currentZoom === null && zoomStates.length > 1) {
            this.sendXray('zoom', 'inc');

            this.setState({
                zoomState: zoomStates[getDefaultZoomIdx(false, hasFitContentRatio) + 1],
                customZoomState: null,
                isFitContent: false,
            });
            if (isFitContent) {
                setFitContentState(false);
            }
            return;
        }

        let idx = zoomStates.indexOf(currentZoom);
        if (idx === -1) {
            idx = tryGetNearestIndexOfValue(zoomStates, currentZoom);
        }
        if (idx !== -1 && idx < zoomStates.length - 1) {
            this.sendXray('zoom', 'inc');

            this.setState({ zoomState: zoomStates[idx + 1], customZoomState: null, isFitContent: false });
            if (isFitContent) {
                setFitContentState(false);
            }
        }
    };

    private sendToolbarSearchAnalytics(action) {
        if (this.props.isSearchRadar) {
            const dwhData = {
                eventCategory: ECategoryGa.viewer_search,
                action,
                forceSend: true,
            } as any;
            this.sendDwh(dwhData);
        }
    }

    private sendZoomDwh = debounce(
        () =>
            this.sendDwh({
                eventCategory: ECategoryGa.viewer,
                action: 'zoom',
                forceSend: true,
            }),
        500
    );

    private decreaseZoom = (event) => {
        const { zoomState, zoomStates, customZoomState, hasFitContentRatio } = this.state;
        const { setFitContentState = noop, disableZoom } = this.props;

        if (disableZoom) {
            return;
        }

        event?.stopPropagation();
        this.runUITimer();

        const currentZoom = (customZoomState || zoomState) as number;

        let idx = zoomStates.indexOf(currentZoom);
        if (idx === -1) {
            idx = tryGetNearestIndexOfValue(zoomStates, currentZoom) + 1;
        }
        let newIdx;
        if (idx > 0) {
            this.sendXray('zoom', 'dec');

            newIdx = idx - 1;
        } else if (idx === 0) {
            newIdx = 0;
        }

        if (newIdx < 0) {
            return;
        }

        this.sendZoomDwh();

        this.setState({ zoomState: zoomStates[newIdx], customZoomState: null });

        if (newIdx !== 0) {
            return;
        }

        if (hasFitContentRatio) {
            setFitContentState(true);
            this.setState({ isFitContent: true });
        }
        if (idx === 0) {
            this.sendXray('zoom', 'declastpress');
        }
    };

    private resetZoom = () => {
        this.setState({ zoomState: null, zoomStates: [], customZoomState: null });
    };

    private handleWheel = (event) => {
        const { customZoomState, zoomStates, zoomState, isFitContent, hasFitContentRatio } = this.state;
        const { file, isPreviewableItem, disableZoom } = this.props;

        if (!zoomStates?.length || event?.type !== 'wheel' || (isPreviewableItem && file?.ext === 'zip') || disableZoom) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        const defaultZoomValue = zoomStates[getDefaultZoomIdx(isFitContent, hasFitContentRatio)] as number;
        const zoomValue = (customZoomState || zoomState || defaultZoomValue) as number;

        const newCustomZoomState = Math.min(Math.max(zoomValue - Math.sign(event.deltaY) * 1, zoomStates[0] as number), MAX_ZOOM_STATE);

        this.setState({ customZoomState: newCustomZoomState });

        const newFitState = newCustomZoomState === zoomStates[0] && hasFitContentRatio;

        this.sendZoomDwh();

        if (isFitContent !== newFitState) {
            this.props.setFitContentState?.(newFitState);
            this.setState({ isFitContent: newFitState });
        }

        this.runUITimer();
    };

    // TODO: make preload in saga? make special viewer action for it? How to use common cache?
    private preloadImages = () => {
        const { enablePreload, preloadUrls } = this.props;

        if (enablePreload && preloadUrls?.length) {
            const { thumbsCache } = this.state;

            const prevKeys = Object.keys(this.imgCache);
            prevKeys.forEach((key) => {
                if (!preloadUrls.find((item) => item?.url === key)) {
                    this.imgCache[key].onload = null;
                    this.imgCache[key].src = emptyImageUrl;
                    delete this.imgCache[key];
                }
            });

            preloadUrls.forEach(({ url, id }) => {
                if (!thumbsCache[id]?.isLoaded && url) {
                    if (!this.imgCache[url]) {
                        this.imgCache[url] = new Image();
                    }
                    this.imgCache[url].onload = (event) => this.handleImageLoad({ id, event, preload: true });
                    this.imgCache[url].src = url;
                }
            });
        }
    };

    private handleUITimer = () => {
        const { thumbsCache } = this.state;
        const item = this.props.file;

        this.timerId = undefined;

        if (!isImage(item) || thumbsCache[item?.id]?.isLoaded) {
            this.setState({ isInactive: true });
        } else {
            this.timerId = window.setTimeout(this.handleUITimer, TIMEOUT_TO_INACTIVE);
        }
    };

    private runUITimer = (makeActive = true) => {
        const { isPreviewableItem, file, itemStorage } = this.props;

        if (itemStorage === EStorageType.story) {
            return;
        }

        if (this.state.isInactive && makeActive) {
            this.setState({ isInactive: false });
        }

        if (this.timerId) {
            window.clearTimeout(this.timerId);
        }

        if ((!isVideo(file) && !isImage(file)) || !isPreviewableItem || this.state.forceActive) {
            return;
        }

        this.timerId = window.setTimeout(this.handleUITimer, TIMEOUT_TO_INACTIVE);
    };

    private showUIAndSetupTimer = throttle(() => {
        this.runUITimer();
    }, 250);

    private handleEdit = () => {
        const { file, isAuthorized, editingNoneEditableFormats, itemStorage, isNewbie, isAttaches } = this.props;

        this.sendXray('openeditor');

        if (!isAuthorized && !IS_ONPREMISE) {
            renderAuthDialog();
            return;
        }

        if (isNewbie && isAttaches) {
            renderWelcomeHelper({ storage: itemStorage as EStorageType, newDialog: true, source: 'edit-attace-copy' });
            return;
        }

        if (file && itemStorage && editingNoneEditableFormats?.storage) {
            const { ext, size, id } = file;

            sendTechAnalytics(ext, itemStorage)(TechAnalyticsType.clickOnEditButton);
            sendDwh({
                eventCategory: ECategoryGa.viewer,
                action: 'click-edit',
                dwhData: {
                    extension: ext,
                    size_file: size,
                    id_media: id,
                },
            });
            return openCreateCopyOfNoneditableFileModal({
                file,
                editingNoneEditableFormats,
            });
        }

        if (isAttaches) {
            editAttachCopyNotification.setText(
                `Копия сохранена ${isAttachSaveToAllDocuments ? 'у вас в Документах' : 'в папку "Почтовые\u00A0вложения"'}`
            );
        }

        openEditor(file);
    };

    private handleEditPdf = (item) => {
        sendXrayFunc(['pdfedit', 'click']);

        this.props.openPdfEditor?.(item);
    };

    private handleOpenReader = () => {
        const { itemStorage, file } = this.props;

        openEbookReader(itemStorage, file.id, 'viewer');
    };

    private handleOnClose = () => {
        const {
            onClose = noop,
            ids,
            storyId,
            indexInArray,
            itemCount = 0,
            markStoryAsViewed,
            file,
            isStoryViewed = false,
            isAttaches,
            disableClose,
        } = this.props;

        if (disableClose) {
            return;
        }

        if (!isStoryViewed) {
            const isLastItem = indexInArray === itemCount - 1;
            markStoryAsViewed?.({ storyId: storyId as string, lastViewedId: isLastItem ? '' : file?.id });
        }

        this.sendXray('close');

        if (ids?.length) {
            const navProc = Math.round((this.navigateClickCount * 100) / ids?.length);
            const group = roundCustom(ids?.length, [0, 5, 10, 25, 50]);

            this.sendXray('stat', `nav${group}`, true, { proc: navProc });
        }

        if (fullScreenProcessor.isFullScreen()) {
            fullScreenProcessor.switchFullscreen();
        }

        if (isAttaches && BACK_URL) {
            open(decodeURIComponent(BACK_URL), '_self');
        }

        this.sendDwh({
            eventCategory: ECategoryGa.story,
            action: 'story_close',
            type_reason: 'click',
        });

        this.sendDwh({ action: 'close', forceSend: true });

        sessionWatcher.endSession({ clear: true });

        onClose();
    };

    private onFullScreenChange = (type: FullScreenEvent) => {
        let radar = '';
        let dwh = '';
        switch (type) {
            case FullScreenEvent.successStart:
                radar = 'start';
                dwh = 'open';
                break;
            case FullScreenEvent.successStop:
                radar = 'stop';
                dwh = 'close';
                break;
            case FullScreenEvent.errorStart:
                radar = 'start-err';
                break;
            case FullScreenEvent.errorStop:
                radar = 'stop-err';
                break;
            default:
                break;
        }
        if (dwh) {
            this.sendDwh({ action: `fullscreen_${dwh}`, forceSend: true });
        }
        if (radar) {
            this.sendXray(`fs-${radar}`);
        }
    };

    private handleDownload = async (item = undefined) => {
        const { isAttaches, activeArchiveItem, itemStorage, downloadArchiveItemAction, file } = this.props;

        this.sendXray('click', 'download');
        this.sendDwh({ action: 'download_content', forceSend: true });
        if (isAttaches) {
            this.sendXray('attach', 'dwnldclk');
        }
        if (isAttaches && file?.attachType === EAttachTypes.cloudStock) {
            this.sendXray('attach-cld-stck', 'dwnldclk');
        }

        if (file.ext === 'zip' && !isAttaches) {
            this.sendXray('archive', 'dwnldzip');
        }

        if (isIntegrationStorage(itemStorage)) {
            o2AuthedSourceLoader.saveFile(file).catch(this.handleEntityError);
            this.runUITimer();
            return;
        }

        if (activeArchiveItem && itemStorage && downloadArchiveItemAction) {
            downloadArchiveItemAction({
                storage: itemStorage,
                archiveId: itemStorage === EStorageType.public ? file?.weblink : file?.id,
                itemId: activeArchiveItem.path,
            });
        } else {
            downloadItem({ storage: itemStorage, itemOrId: item ?? file, fromViewer: true });
        }

        this.runUITimer();
    };

    private handleClickOnStub = () => {
        this.handleDownload();
    };

    private handleToggleFavorite = (item) => {
        const { toggleFavorite = noop, itemStorage, showSnackbar } = this.props;

        this.sendXray('click', 'favorite');
        this.sendDwh({ action: 'favorites', forceSend: true });

        if (itemStorage === EStorageType.story && !item.isInFavorites) {
            showSnackbar({
                id: 'add-to-favorites',
                closable: true,
                text: 'Файл добавлен в избранные',
                type: SnackbarTypes.success,
            });
        }

        toggleFavorite(item);

        this.runUITimer();
    };

    private handleOnPublish = ({ item, event }: { item: any; event?: Event }): void => {
        const { itemStorage, showSnackbar, publishItemAction } = this.props;
        event?.stopPropagation();

        this.sendXray('click', 'publish');
        this.sendDwh({ action: 'new_public', forceSend: true });

        // eslint-disable-next-line unicorn/consistent-function-scoping
        const handleUnpublishSuccess = () => {
            this.setState({ isUnpublished: true });
        };

        processItemPublish({
            itemStorage,
            showSnackbar,
            publishItemAction,
            item,
            handleUnpublishSuccess,
        });
    };

    private handleOnDelete = (item) => {
        const { deleteFile = noop, itemStorage } = this.props;

        this.sendXray('click', 'delete');
        const onSuccess = () => {
            this.sendDwh({ action: 'delete', forceSend: true });
            this.sendToolbarSearchAnalytics('delete');
        };

        deleteFile(item, itemStorage, onSuccess);
    };

    private handleNavigation = throttle((data, isSwiping = false) => {
        const { indexInArray, selectItemIndexInArray } = this.props;
        const convertNoneEditableFormatsSnack = debounce(this.openConvertNoneEditableFormatsSnack, 100);

        this.setState({
            mountMyOfficeSnackbar: false,
            isPdfConvertedError: false,
            isOvidiusV2Error: false,
            isTrialPromoAvailable: true,
        });
        // eslint-disable-next-line max-lines
        this.resetZoom();

        this.closeConvertNoneEditableFormatsSnack();

        this.setState({ isSwiping });

        selectItemIndexInArray(data);

        convertNoneEditableFormatsSnack();

        this.sendXray('click', 'navigate');
        if (indexInArray !== null) {
            const type_click = data?.itemIndex > (indexInArray ?? 0) ? 'next' : 'previous';
            this.sendDwh({ action: 'skip_photo', type_click, forceSend: true });
        }

        this.navigateClickCount++;
    }, 100);

    private handleImageError = (srcUrl, id) => {
        this.sendXray('imgload', 'error');

        this.handleEntityError();

        this.setState(({ thumbsCache }) => ({
            thumbsCache: { ...thumbsCache, [id]: { isError: true } },
        }));
    };

    private handleImageLoad = ({ id, event, preload = false }) => {
        const { file, embedded } = this.props;
        this.sendXray('imgload', 'ok', false, preload ? { preload: 1 } : null);

        const diffTime = Date.now() - this.state.startShowTime;
        this.sendXray('imgloag', 'time', false, { ms: diffTime });
        if (!preload) {
            this.sendXray('imgloag', 'timemain', false, { ms: diffTime });
        }
        this.sendXray('imgloag', `histogram_${roundCustom(diffTime, [0, 10, 100, 250, 500, 750, 1000, 2000, 3000, 4000, 5000, 10000])}`);

        const height = event.target?.naturalHeight;
        const width = event.target?.naturalWidth;

        if (!this.state.thumbsCache[id]?.isLoaded) {
            let { zoomStates: zoomStatesNew, hasFitContentRatio: hasFitContentRatioNew } = this.state;
            if (id === file?.id) {
                const { range, hasFitContentRatio } = calculateZoomValues({
                    width,
                    height,
                    file,
                    embedded,
                    contentRect: this.contentRef?.getBoundingClientRect(),
                });

                zoomStatesNew = range;
                hasFitContentRatioNew = hasFitContentRatio;

                if (zoomStatesNew?.length) {
                    this.sendXray('zoom', 'show');
                }
            }

            this.setState(({ thumbsCache, zoomStates }) => ({
                zoomStates: zoomStatesNew ?? zoomStates,
                hasFitContentRatio: hasFitContentRatioNew,
                thumbsCache: {
                    ...thumbsCache,
                    [id]: {
                        isLoaded: true,
                        height,
                        width,
                    },
                },
            }));
        }

        this.handleEntityOpen();
    };

    private handleReloadImage = (srcUrl, id) => {
        const { onRetryItemLoad = noop, indexInArray } = this.props;

        this.sendXray('imgload', 'retry');

        this.setState(({ thumbsCache }) => ({
            thumbsCache: { ...thumbsCache, [id]: { isRetry: true } },
        }));

        onRetryItemLoad({ src: srcUrl, idx: indexInArray });
    };

    private handleActiveHeader = ({ isActive }) => {
        this.setState({ forceActive: isActive }, this.runUITimer);
    };

    private handleCopy = () => {
        this.sendXray('click', 'copy');

        renderCopyDialog({
            items: [this.props.file],
            onSuccess: () => {
                this.sendToolbarSearchAnalytics('copy');
                this.sendDwh({ action: 'copy', forceSend: true });
            },
        });
    };

    private handleMove = () => {
        this.sendXray('click', 'move');

        renderMoveDialog({
            items: [this.props.file],
            onSuccess: () => {
                this.sendToolbarSearchAnalytics('move_to_folder');
                this.sendDwh({ action: 'move_to_folder', forceSend: true });
            },
        });
    };

    private handleMail = () => {
        this.sendXray('click', 'move');
        this.sendToolbarSearchAnalytics('send-mail');
        this.sendDwh({ action: 'send_mail', forceSend: true });

        toolbarActions.forward({ items: [this.props.file] });
    };

    private handleClone = () => {
        const { isAttaches, itemStorage, file } = this.props;

        if (isAttaches) {
            this.sendXray('attach', 'clonedclk');
        }
        this.sendXray('click', 'clone');

        toolbarActions.clone({
            id: file.id,
            destination: isAttaches ? MAIL_ATTACHES_FOLDER_ID : '',
            source: isAttaches ? 'viewer-attaches' : 'viewer',
            storage: itemStorage ?? undefined,
        });
    };

    private handleOpenParent = () => {
        const parent = this.props.file.parentFolder || this.props.file.parent || '';
        const folder = encodeURIComponent(parent === '/' ? '' : parent);
        const file = encodeURIComponent(this.props.file.id);

        this.sendXray('click', 'open-parent');
        this.sendDwh({
            eventCategory: this.props.isSearchRadar ? ECategoryGa.viewer_search : undefined,
            action: 'view_in_folder',
            forceSend: true,
        });

        opener(`${window.location.origin}/home${folder}/?item=${file}&action=scroll-to`, IS_MY_TEAM);
    };

    private handleOpenInMyCloud = () => {
        const { file } = this.props;

        this.sendXray('click', 'open-in-cloud');

        if (!file || !('home' in file)) {
            return;
        }

        this.sendDwh({ action: 'open-tree', forceSend: true });
        const url = getGoToAndScrollUrl(file.home?.replace(file.name, '') ?? '', file.home);
        opener(url, IS_MY_TEAM);
    };

    private handleEntityOpen = ({ skipSendAnalytics = false } = {}) => {
        const { file, onItemOpen, requestId, userId } = this.props;

        if (typeof onItemOpen === 'function' && !skipSendAnalytics) {
            onItemOpen({
                xrayParams: {
                    dwh: getDWHParamsOnEntityEvent({ file, requestId, userId }),
                    i: 'open',
                },
            });
        }
    };

    private handleEntityError = () => {
        const { file, onItemError, requestId, userId } = this.props;

        if (typeof onItemError === 'function') {
            onItemError({
                xrayParams: {
                    dwh: getDWHParamsOnEntityEvent({ file, requestId, userId }),
                    i: 'error',
                },
            });
        }

        this.sendDwh({
            action: ECategoryGa.errorShow,
            forceSend: true,
        });
    };

    private handleDocReady = ({ skipSendAnalytics = false } = {}) => {
        this.setFocusForDoc();

        this.handleEntityOpen({ skipSendAnalytics });
    };

    private handleOpenAttachMessage = () => {
        const { folderId = '', message_id = '', attachType } = this.props.file || {};

        if (attachType === EAttachTypes.temporary) {
            return;
        }

        this.sendXray('attachinfo', 'click', true);

        openAttachLetter({ message_id, folderId });
    };

    private handleOnDetailsClick = () => {};

    private handleShowFaceFilter = () => this.setState({ showFacesFilter: !this.state.showFacesFilter });

    private handleOnFaceSelect = (id) => {
        this.sendDwh({
            action: 'select_face',
            forceSend: true,
        });

        this.props.selectFace?.(id);
        this.handleOnClose();
    };

    private handleVideoError = (msg?: string) => {
        const { showSnackbar } = this.props;
        showSnackbar({
            id: 'video-player-error',
            type: SnackbarTypes.failure,
            text: msg || 'Ошибка воспроизведения. Неизвестный кодек видео.',
            closable: true,
        });

        this.sendDwh({
            action: ECategoryGa.errorShow,
            forceSend: true,
        });
    };

    private handleChangeImageZoom = (value: number) => {
        this.setState({ zoomState: value });
    };

    private renderImage = ({ zoomValue, defaultZoomValue, file }) => {
        const { faces, itemStorage } = this.props;
        const { thumbsCache, filePrev, zoomState, zoomStates, customZoomState, isSwiping, hasFitContentRatio, showFacesFilter } =
            this.state;

        zoomValue = (customZoomState || zoomValue || defaultZoomValue) as number;

        let scale = 1;

        if (zoomValue) {
            scale = zoomValue / (zoomStates[getDefaultZoomIdx(false, hasFitContentRatio)] as number);
        }

        const id = file?.id;

        const src = getItemImageUrl(file);
        let srcPrev = '';
        if (filePrev && !thumbsCache[filePrev.id]?.isError && thumbsCache[filePrev.id]?.isLoaded) {
            srcPrev = getItemImageUrl(filePrev);
        }

        const { isError, isLoaded, isRetry } = thumbsCache[id] || {};

        const contentRect = this.props.embedded ? this.contentRef?.getBoundingClientRect() : null;

        const isEmbeddedPage = itemStorage === EStorageType.embedded;
        const ViewerGestures = isEmbeddedPage ? Gestures : GesturesEmpty;

        return (
            <ViewerGestures
                zoom={zoomState || 0}
                zoomMax={zoomStates[zoomStates.length - 1] || 100}
                zoomMin={zoomStates[0] || 0}
                type={GestureTypes.zoom}
                onChangeZoom={this.handleChangeImageZoom}
            >
                <ViewerImage
                    src={src}
                    srcPrev={srcPrev}
                    isError={isError}
                    isLoaded={isLoaded}
                    isRetry={isRetry}
                    file={file}
                    filePrev={filePrev}
                    scale={scale}
                    onImageLoad={this.handleImageLoad}
                    onImageError={this.handleImageError}
                    onReloadImage={this.handleReloadImage}
                    enableAnimationPrev={!isSwiping}
                    embedded={this.props.embedded}
                    contentRect={contentRect}
                    faces={faces}
                    clickOnFace={this.handleOnFaceSelect}
                    showFacesFilter={showFacesFilter}
                    renderSpinner={() => <Spinner />}
                />
            </ViewerGestures>
        );
    };

    private renderPlayer = (file, isVisible = true) => {
        const { isActionOpen, isAttaches } = this.props;
        return (
            <VideoPlayer
                streamUrl={file?.url?.media}
                nativeUrl={file?.url?.view}
                posterUrl={getPosterUrl(file)}
                kind={file.kind}
                ext={file.ext}
                name={file.nameWithoutExt || file.name}
                autoPlay={isActionOpen}
                isVisible={isVisible}
                size={file?.size}
                isAttaches={isAttaches}
                onError={this.handleVideoError}
                sendRadar={this.sendDwh}
            />
        );
    };

    private renderPdf = (file: CloudFile) => {
        if (!file?.url?.view) {
            return null;
        }

        const { itemStorage, itemCount } = this.props;

        return (
            <ViewerPdf
                file={file}
                url={file.url.view}
                renderError={() => this.renderStub(file)}
                fillParent={itemCount === 1 && (itemStorage === EStorageType.public || itemStorage === EStorageType.stock)}
                onOpen={this.handleEntityOpen}
                onError={this.handleEntityError}
            />
        );
    };

    private renderTxt = (file) => {
        if (!file?.url?.view) {
            return null;
        }

        return (
            <ViewerTxt
                url={file?.url?.view}
                renderError={() => this.renderStub(file)}
                embedded={this.props.embedded}
                onOpen={this.props.onItemOpen}
                onError={this.handleEntityError}
            />
        );
    };

    private setContentForPrint = (id: string, contentForPrint: ArrayBuffer[] | null): void => {
        this.setState({
            contentForPrintCache: {
                ...this.state.contentForPrintCache,
                [id]: contentForPrint,
            },
        });
    };

    private handleDocViewer = (id: string, viewerConfig: ViewerConfigType): void => {
        this.setState({ docViewerCache: { ...this.state.docViewerCache, [id]: viewerConfig } });
    };

    private getStub = () => {
        return this.renderStub(this.props.file);
    };

    private renderDoc = (file: CloudFile): ReactElement => (
        <ViewerDoc
            file={file}
            viewersConfig={this.props.viewers}
            isEditable={this.props.isEditable}
            renderError={this.getStub}
            onSelectViewer={this.handleDocViewer}
            onOpen={this.handleDocReady}
            onError={this.handleEntityError}
            onReady={this.handleDocReady}
            embedded={this.props.embedded}
            setContentForPrint={this.setContentForPrint}
        />
    );

    private renderStub = (file) => {
        const itemSize = getDownloadItemSize([file]);
        const showReadButton = isEBook(file) && ebookDesktopConfig?.exts?.includes(file?.ext?.toLowerCase?.());

        return (
            <Stub
                ext={file?.ext}
                kind={file?.kind}
                fileHash={file?.hash ?? file?.id}
                isVirus={isVirusItem(file)}
                name={file?.nameWithoutExt ?? file?.name}
                onClick={this.handleClickOnStub}
                sendRadar={this.sendDwh}
                isDownloadable={file?.weblinkDownloadable}
                itemSize={itemSize}
                isFolder={file?.isFolder}
                isEbook={showReadButton}
                onActionButtonClick={this.handleOpenReader}
            />
        );
    };

    // TODO: добавить типы на file и storage
    private renderArchive = (file, storage) => {
        return (
            <ViewerArchiveConnected
                file={file}
                storage={storage}
                sendGa={this.sendXray}
                embedded={this.props.embedded}
                onClose={this.handleOnClose}
                onDelete={this.handleOnDelete}
                onNavigate={(isLeft) => (isLeft ? this.onNavigateLeft() : this.onNavigateRight())}
                sendRadar={this.sendDwh}
                renderContent={(children) => {
                    return <div className={styles.archiveWrapper}>{children}</div>;
                }}
                renderStub={() => {
                    return this.renderStub(file);
                }}
                onOpen={this.handleEntityOpen}
                onError={this.handleEntityError}
            />
        );
    };

    // eslint-disable-next-line max-lines, complexity
    private renderContentItem = ({ zoomValue, defaultZoomValue }) => {
        const { file: oldFile, isPreviewableItem, itemStorage } = this.props;
        const file = IS_B2B_BIZ_USER && oldFile.ext ? { ...oldFile, ext: oldFile.ext.toLowerCase() } : oldFile;
        const id = file?.id;
        const cacheKey = id + file?.mtime;
        let cashedItem: ReactElement | undefined | null = componentsCache.getItem(cacheKey);
        let content: ReactElement | ReactElement[] | null = null;

        if (isPreviewableItem && !cashedItem) {
            if (file.weblinkDownloadable && (IS_B2B_BIZ_USER || ANONYM_USER) && itemStorage === EStorageType.public) {
                return this.renderStub(file);
            }
            if (isImage(file)) {
                content = this.renderImage({ zoomValue, defaultZoomValue, file });
            } else if (isFileForPlayer(file)) {
                cashedItem = this.renderPlayer(file, true);
            } else if (isPdf(file) && !skipPdfRender) {
                cashedItem = this.renderPdf(file);
            } else if (isPlainSource(file, itemStorage)) {
                cashedItem = this.renderTxt(file);
            } else if (isDocument(file)) {
                this.setState({
                    mountMyOfficeSnackbar: true,
                });
                cashedItem = this.renderDoc(file);
            } else if (file?.kind === 'archive' || file?.subKind === 'archive') {
                content = this.renderArchive(file, itemStorage);
            }

            if (cashedItem) {
                componentsCache.addItem(cacheKey, cashedItem);
            }
        }

        if (!content && !cashedItem) {
            content = this.renderStub(file);
        }

        // need to choose one: content vs cashedItem
        if (content && cashedItem) {
            componentsCache.deleteItem(cacheKey);
        }

        return (
            <>
                {content}
                {/* Рендерим все закешированные компоненты скрытыми. Если не отрендерить - они отмонтируются и соотв. DOM компоненты удалятся. */}
                {componentsCache.getAll().map(
                    ({ key, item }): ReactElement => (
                        <div
                            key={key}
                            className={classNames({
                                [styles.cachedItem]: true,
                                [styles.cachedItem_hidden]: key !== cacheKey,
                            })}
                        >
                            {item}
                        </div>
                    )
                )}
            </>
        );
    };

    private handleOnDown = () => {
        this.setState({ isMousePressed: true });
    };

    private handleOnUp = () => {
        this.setState({ isMousePressed: false });
    };

    private renderContentSlider = (): ReactElement | null => {
        const { file, forwardItemIndexInArray, backItemIndexInArray, itemCount, isAttaches, disableZoom, showBottomAds } = this.props;
        const { isLoading, isInactive, zoomState, zoomStates, customZoomState, isFitContent, hasFitContentRatio } = this.state;

        if (isLoading || !file) {
            return null;
        }

        const zoomValue = customZoomState || zoomState;
        const defaultZoomValue = zoomStates[getDefaultZoomIdx(isFitContent, hasFitContentRatio)];

        const disableSwipe = Boolean((zoomValue && zoomValue !== defaultZoomValue) || itemCount === 1);

        return (
            <div className={styles.contentBlock}>
                <SwipingComponent
                    id={file?.id}
                    onSwipe={(toRight = false) => {
                        const itemIndex = toRight ? backItemIndexInArray : forwardItemIndexInArray;
                        this.runUITimer();
                        if (itemIndex !== null) {
                            this.handleNavigation({ itemIndex }, true);
                        }
                    }}
                    disable={disableSwipe}
                    enableForward={forwardItemIndexInArray !== null}
                    enableBack={backItemIndexInArray !== null}
                >
                    <div
                        ref={this.onContentRefChange}
                        className={styles.content}
                        tabIndex={0}
                        onMouseDown={this.handleOnDown}
                        onMouseUp={this.handleOnUp}
                    >
                        {this.renderContentItem({ zoomValue, defaultZoomValue })}
                    </div>
                </SwipingComponent>
                {!disableZoom &&
                    this.renderZoomer(
                        zoomValue ?? defaultZoomValue,
                        !isInactive,
                        isAttaches || showBottomBanner(showBottomAds, isAttaches)
                    )}
                {this.renderNavigator(isInactive)}
            </div>
        );
    };

    private normalizedOvidiusIncreaseScaleClick = () => {
        xray.send('norm_ovidius_zoom_plus');

        const { ovidiusZoom = this.state.ovidiusZoom, file } = this.props;

        if (ovidiusZoom >= MAX_ZOOM_SIZE || !file) {
            return;
        }

        this.sendZoomDwh();

        const newOvidiusZoom = ovidiusZoom + ZOOM_STEP;

        // для PublicFile
        if (!this.props.ovidiusZoom) {
            this.setState({ ovidiusZoom: newOvidiusZoom });
        }

        this.props.setOvidiusZoom?.({ id: file.id, value: newOvidiusZoom });
    };

    private normalizedOvidiusDecreaseScaleClick = () => {
        xray.send('norm_ovidius_zoom_minus');

        const { ovidiusZoom = this.state.ovidiusZoom, file } = this.props;

        if (ovidiusZoom <= MIN_ZOOM_SIZE || !file) {
            return;
        }

        this.sendZoomDwh();

        const newOvidiusZoom = ovidiusZoom - ZOOM_STEP;

        // для PublicFile
        if (!this.props.ovidiusZoom) {
            this.setState({ ovidiusZoom: newOvidiusZoom });
        }

        this.props.setOvidiusZoom?.({ id: file.id, value: newOvidiusZoom });
    };

    private handleEditImage = () => {
        const { file, itemStorage } = this.props;

        openImageEditor({ file, itemStorage, place: 'zoomer' });
    };

    private renderZoomer = (zoomValue, show, upOnBannerHeight = false) => {
        const isImageEditorEnabled = isImageEditor && !storeHelper.getValue(IMAGE_EDITOR_ID);

        if (!zoomValue) {
            return null;
        }

        return (
            <div>
                <div
                    className={classNames({
                        [styles.zoomer]: true,
                        [styles.zoomer_show]: show,
                        [styles.zoomer_upOnBannerHeight]: upOnBannerHeight,
                        [styles.zoomer_showEditButtons]: isImageEditorEnabled,
                    })}
                >
                    <Hint text="Уменьшить" theme="dark" showDelay>
                        <div className={styles.zoomButton} onMouseDown={this.decreaseZoom}>
                            -
                        </div>
                    </Hint>
                    <div className={styles.zoomValue}>{zoomValue}%</div>
                    <Hint text="Увеличить" theme="dark" showDelay>
                        <div className={styles.zoomButton} onMouseDown={this.increaseZoom}>
                            +
                        </div>
                    </Hint>
                    {isImageEditorEnabled && (
                        <div className={styles.editButtons}>
                            <ViewerHeaderButton
                                title="Обрезка"
                                icon={<Icon24Crop width={20} height={20} />}
                                onClick={this.handleEditImage}
                                hint="Обрезка"
                                isFilled={false}
                                id="crop"
                                iconOnly
                            />
                            <ViewerHeaderButton
                                title="Автоулучшение"
                                icon={<Icon24MagicWandOutline width={20} height={20} />}
                                onClick={this.handleEditImage}
                                hint="Автоулучшение"
                                isFilled={false}
                                id="magic_wand"
                                iconOnly
                            />
                            <ViewerHeaderButton
                                title="Поворот"
                                icon={<Icon20RotateRight width={20} height={20} />}
                                onClick={this.handleEditImage}
                                hint="Поворот"
                                isFilled={false}
                                id="rotate"
                                iconOnly
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    private renderNavigator = (isInactive) => {
        const { backItemIndexInArray, forwardItemIndexInArray, itemCount, itemStorage } = this.props;

        if ((!itemCount || itemCount === 1) && itemStorage !== EStorageType.story) {
            return null;
        }

        const navBackHandler =
            backItemIndexInArray !== null
                ? () => {
                      this.handleNavigation({ itemIndex: backItemIndexInArray });
                      this.runUITimer();
                  }
                : noop;
        const navForwardHandler =
            forwardItemIndexInArray !== null
                ? () => {
                      this.handleNavigation({ itemIndex: forwardItemIndexInArray });
                      this.runUITimer();
                  }
                : noop;

        return (
            <div className={classNames({ [styles.navigator]: true, [styles.dimout]: isInactive })}>
                <Hint text="Предыдущий файл" theme="dark" showDelay>
                    <div
                        className={classNames({
                            [styles.arrow]: true,
                            [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                            [styles.arrow_enabled]: backItemIndexInArray != null,
                            [styles.arrow_hidden]: backItemIndexInArray === null && itemStorage === EStorageType.story,
                        })}
                        onMouseDown={navBackHandler}
                    >
                        <LeftArrowIcon width={17} height={16} />
                    </div>
                </Hint>
                <Hint text="Следующий файл" theme="dark" showDelay>
                    <div
                        className={classNames({
                            [styles.arrow]: true,
                            [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                            [styles.arrow_right]: true,
                            [styles.arrow_enabled]: forwardItemIndexInArray != null,
                            [styles.arrow_hidden]: forwardItemIndexInArray === null && itemStorage === EStorageType.story,
                        })}
                        onMouseDown={navForwardHandler}
                    >
                        <RightArrowIcon width={17} height={16} />
                    </div>
                </Hint>
            </div>
        );
    };

    // eslint-disable-next-line max-lines-per-function, complexity
    public render(): ReactElement | null {
        const {
            isMountedFolder,
            showEditorHeader,
            gaCategory,
            isViewer,
            itemStorage,
            file,
            isAuthorized,
            isNewbie,
            activeArchiveItem,
            isAttaches = false,
            embedded,
            showViewerHeader,
            showBottomAds,
            showLogo,
            isAttachesEnabled,
            topTitle,
            topSubTitle,
            storyType,
            bottomTitle,
            bottomSubTitle,
            itemCount,
            indexInArray,
            forwardItemIndexInArray,
            isDialogShown,
            hideAdsOnAttaches,
            faces,
            isNewAdFormat,
            disableClose,
            showTrialPromo,
            hasParentMountedOrShared,
            showPdfEditButton,
            isViewerFileVerifiedByKaspersky,
            showEditButton,
            isPublicCopyEditable,
        } = this.props;
        const { isLoading, isInactive, isMousePressed, isUnpublished, showFacesFilter, docViewerCache } = this.state;

        const showBottomAd = showBottomBanner(showBottomAds, isAttaches) || showAttachesBottomBanner(hideAdsOnAttaches, isAttaches, file);

        const canShowTrialPromo = showTrialPromo && this.state.isTrialPromoAvailable;

        const isEmbeddedPage = itemStorage === EStorageType.embedded;
        const isPublic = itemStorage === EStorageType.public;
        const onpremR7ProductEnabled =
            isPublic && docViewerCache[file?.id]?.id === EditorID.MYOFFICE && isOnPremR7Product && itemCount === 1;
        const canShowLogo = showLogo && !onpremR7ProductEnabled;

        return (
            <div
                translate="no"
                className={classNames({
                    [styles.wrapper]: true,
                    [styles.wrapper_embedded]: embedded,
                    [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                })}
            >
                <div
                    className={classNames({
                        [styles.facesWrapper]: true,
                        [styles.facesWrapper_hidden]: !showFacesFilter,
                    })}
                >
                    <FaceFilter faces={faces} onHideClick={this.handleShowFaceFilter} onFaceSelect={this.handleOnFaceSelect} />
                </div>
                <div
                    className={classNames({
                        [styles.root]: true,
                        [styles.root_embedded]: embedded,
                        [styles.root_withHeader]: showViewerHeader,
                        [styles.root_withBottom]: showBottomAd,
                        [styles.root_withBottom_new]: showBottomAd && isNewAdFormat,
                        [styles.root_attaches]: itemStorage === EStorageType.attaches || itemStorage === EStorageType.viewerAttaches,
                        [styles.root_noPadding]: this.props.itemCount === 1 || embedded,
                        [styles.root_with_r7_header]: onpremR7ProductEnabled,
                    })}
                    onMouseMove={this.showUIAndSetupTimer}
                    onClick={this.showUIAndSetupTimer}
                    id={REACT_VIEWER_ID}
                >
                    {!!showEditorHeader && !isViewer && (
                        /* EditorHeader приконнекчен и пока сам берет себе айтем из стора - надо убрать это */
                        <EditorHeader
                            isPublic={isPublic}
                            gaCategory={gaCategory}
                            enableFavorites={!isPublic && !isMountedFolder}
                            onBackToCloud={this.handleOnClose}
                            returnButtonTitle={isViewer ? 'Закрыть' : 'Вернуться в Облако'}
                            isViewer={isViewer}
                            itemStorage={itemStorage}
                        />
                    )}
                    {!!isViewer && showViewerHeader && (
                        <div
                            className={classNames({
                                [styles.headerWrapper]: true,
                                [styles.dimout]: isInactive && !embedded,
                            })}
                        >
                            <ViewerHeader
                                isStock={itemStorage === EStorageType.stock}
                                isPublic={isPublic}
                                isAttaches={isAttaches}
                                normalizedOvidiusIncreaseScaleClick={this.normalizedOvidiusIncreaseScaleClick}
                                normalizedOvidiusDecreaseScaleClick={this.normalizedOvidiusDecreaseScaleClick}
                                normalizedOvidiusEnabled={docViewerCache[file?.id]?.id === EditorID.OVIDIUS_V2}
                                onpremR7ProductEnabled={onpremR7ProductEnabled}
                                editingNoneEditableFormats={this.props.editingNoneEditableFormats}
                                showEditButton={showEditButton}
                                isAuthorized={isAuthorized}
                                isNewbie={isNewbie}
                                embedded={embedded}
                                enableFavorites={
                                    !isPublic &&
                                    !isMountedFolder &&
                                    !file?.isMounted &&
                                    !hasParentMountedOrShared &&
                                    (itemStorage !== EStorageType.albums || isFeatureAlbumFavorites)
                                }
                                onClose={this.handleOnClose}
                                onDownload={this.handleDownload}
                                onToggleFavorites={this.handleToggleFavorite}
                                onPublish={this.handleOnPublish}
                                onDelete={this.handleOnDelete}
                                onPdfEdit={this.handleEditPdf}
                                contentForPrint={this.state.contentForPrintCache[file?.id]}
                                allowPrinting={!!this.state.contentForPrintCache[file?.id]?.length}
                                onCopy={this.handleCopy}
                                onMove={this.handleMove}
                                onMail={this.handleMail}
                                onClone={this.handleClone}
                                onOpenParent={this.handleOpenParent}
                                onActive={this.handleActiveHeader}
                                onFullscreen={fullScreenProcessor.switchFullscreen}
                                onOpenInMyCloud={this.handleOpenInMyCloud}
                                isFullScreen={fullScreenProcessor.isFullScreen()}
                                item={activeArchiveItem ?? file}
                                onFileClick={this.handleOpenAttachMessage}
                                isArchiveItem={Boolean(activeArchiveItem)}
                                showLogo={canShowLogo}
                                isB2BBizUser={IS_B2B_BIZ_USER}
                                isAttachesEnabled={isAttachesEnabled}
                                renderTopChildren={(isActive) =>
                                    itemStorage === EStorageType.story && (
                                        <div className={styles.storyNavigator}>
                                            <PaginatorLine
                                                onClick={this.handleNavigation}
                                                count={itemCount ?? 0}
                                                currentIdx={indexInArray ?? 0}
                                                autoTransition={!isActive && !isDialogShown && !isMousePressed}
                                                nextBlockId={forwardItemIndexInArray}
                                                id={file?.id}
                                            />
                                        </div>
                                    )
                                }
                                topTitle={topTitle}
                                topSubTitle={topSubTitle}
                                isUnpublished={isUnpublished}
                                facesCount={faces?.length}
                                onShowFaceFilter={this.handleShowFaceFilter}
                                showFacesFilter={showFacesFilter}
                                itemStorage={itemStorage}
                                disableClose={disableClose}
                                showPdfEditButton={showPdfEditButton}
                                setSearchText={this.setSearchText}
                                // cloudweb-14765
                                enableSearch={false}
                                isViewerFileVerifiedByKaspersky={isViewerFileVerifiedByKaspersky}
                                isPublicCopyEditable={isPublicCopyEditable}
                                sendViewerDwh={this.sendDwh}
                            />
                        </div>
                    )}
                    {onpremR7ProductEnabled && <BizInlineViewerToolbar isPublic={isPublic} item={activeArchiveItem ?? file} />}
                    {(isLoading || !file) && <Spinner />}
                    <div className={classNames({ [styles.contentWrapper]: true, [styles.viewer]: isViewer })} ref={this.contentWrapperRef}>
                        <div className={styles.contentRow}>
                            <div className={styles.contentMain}>
                                {this.renderContentSlider()}
                                {this.state.mountMyOfficeSnackbar && file && file?.attachType !== EAttachTypes.temporary && (
                                    <MyOfficePromoViewerSnackbar file={file} isEmbedded={isEmbeddedPage} />
                                )}
                                {showBottomBanner(showBottomAds, isAttaches) && <BottomBanner isPublic={embedded} />}
                                {showAttachesBottomBanner(hideAdsOnAttaches, isAttaches, file) && <AttachesBanner />}
                                {canShowTrialPromo && (
                                    <div className={styles.trialButton}>
                                        <TrialPromoBanner productId={PROMO_TARIFFS?.attachesTrial} />
                                    </div>
                                )}
                                <BottomToolbar
                                    type={storyType}
                                    title={bottomTitle}
                                    subTitle={bottomSubTitle}
                                    onDetailsClick={this.handleOnDetailsClick}
                                />
                            </div>
                            {showAttachesSidebarBanner(hideAdsOnAttaches, isAttaches, file) && <AttachesSidebarBanner />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
