import { Spacing, Text, Title } from '@vkontakte/vkui';
import { ReactComponent as CuteCat } from 'img/errors/cute-cat.svg';
import qrCode from 'img/qrAppLink.png';
import React, { memo } from 'react';
import { Icon20SmartphoneOutline } from 'reactApp/ui/VKUIIcons';

import styles from './WelcomeNewPromo.css';

export const PhoneAppBlock = memo(() => {
    return (
        <div className={styles.block} data-qa-id="phone">
            <div className={styles.icon}>
                <Icon20SmartphoneOutline />
            </div>
            <Spacing size={24} style={{ pointerEvents: 'none' }} />
            <Title className={styles.block_title} level="3">
                Мобильное приложение
            </Title>
            <Spacing size={8} style={{ pointerEvents: 'none' }} />
            <Text className={styles.block_text}>С приложением доступ к вашим файлам будет постоянно под рукой</Text>
            <Spacing size={24} style={{ pointerEvents: 'none' }} />
            <div className={styles.cat}>
                <CuteCat width={99} height={58} viewBox="0 0 143 85" />
                <div className={styles.qrImage}>
                    <img src={qrCode} alt="qr code" />
                </div>
            </div>
        </div>
    );
});

PhoneAppBlock.displayName = 'PhoneAppBlock';
