import React, { Suspense, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { usePortal } from 'reactApp/hooks/usePortal';
import { ALL_DOCUMENTS_DOMAIN_ROUTES } from 'reactApp/modules/allDocuments/allDocuments.constants';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { AllDocumentsPage } from 'reactApp/sections/AllDocuments/AllDocumentsPage';
import { MainPage } from 'reactApp/sections/MainPage/MainPage';
import { MobileSearchPage } from 'reactApp/sections/MobileSearchPage/MobileSearchPage';
import { SearchPage } from 'reactApp/sections/SearchPage/SearchPage';
import { EPageId } from 'reactApp/types/Page';
import { ChangeHistory } from 'reactApp/ui/App/components/ChangeHistory';
import { InitComponent } from 'reactApp/ui/App/components/InitComponent';
import { PageWrapper } from 'reactApp/ui/App/components/PageWrapper';
import { useDataContainer } from 'reactApp/ui/App/effect/useDataContainer';
import { PopupsNew } from 'reactApp/ui/Popup/PopupsNew';
import { Snackbars } from 'reactApp/ui/Snackbars/Snackbars';
import { TooltipProvider } from 'reactApp/ui/Tooltip/Tooltip.context';
import { noop } from 'reactApp/utils/helpers';
import { getInitialId } from 'reactApp/utils/urlHelper';

const ReactViewerConnected = React.lazy(() => import(/* webpackChunkName: "reactViewer" */ '../../ui/ReactViewer/ReactViewer'));

const renderViewer = ({ selectedViewItemId, reactViewerTarget }) => {
    // Для печати просмотрщика скрываем страницу, а его рендерим в корень
    return createPortal(
        <ReactViewerConnected
            file={{ id: selectedViewItemId }}
            isPublic={false}
            isMountedFolder={false}
            showEditorHeader
            showNotify={noop}
            gaCategory="fv"
            isViewer
            enablePreload
        />,
        reactViewerTarget
    );
};

// eslint-disable-next-line max-lines-per-function
export const DocumentsRouter = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isPhone, hasError } = useDataContainer();

    const reactViewerTarget = usePortal('ReactViewer');
    const selectedViewItemId = useSelector(ViewerSelectors.getViewerItemId);

    return (
        <TooltipProvider>
            <Router>
                <Suspense fallback={null}>
                    {/* Child effects are run in order. Therefore initing here is before any other effects are called. */}
                    <InitComponent setIsLoading={setIsLoading} />
                    <ChangeHistory />
                    <Snackbars />
                    <PopupsNew />
                    {!isPhone && !!selectedViewItemId && renderViewer({ selectedViewItemId, reactViewerTarget })}
                    <Routes>
                        <Route
                            path={ALL_DOCUMENTS_DOMAIN_ROUTES.root}
                            element={<Navigate replace to={`${ALL_DOCUMENTS_DOMAIN_ROUTES.document}${window.location.search}`} />}
                        />
                        <Route
                            path="/:documentType"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.alldocuments}>
                                    <AllDocumentsPage />
                                </PageWrapper>
                            }
                        />
                        <Route
                            path="/search/*"
                            element={
                                <PageWrapper isLoading={isLoading} hasError={hasError} pageId={EPageId.search}>
                                    {isPhone ? (
                                        <MobileSearchPage />
                                    ) : (
                                        <MainPage storage={EStorageType.search} renderWorm>
                                            <SearchPage initialItemId={getInitialId(location?.pathname)} />
                                        </MainPage>
                                    )}
                                </PageWrapper>
                            }
                        />
                        <Route path="*" element={<Navigate replace to={ALL_DOCUMENTS_DOMAIN_ROUTES.document} />} />
                    </Routes>
                </Suspense>
            </Router>
        </TooltipProvider>
    );
};
