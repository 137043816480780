import facesPromoImg from 'img/facesPromoMobile.png';
import { useCallback } from 'react';
import { renderFilterFacesModal } from 'reactApp/ui/Mobile/FilterFacesModal/FilterFacesModal.helpers';
import { ESplashScreenMod, UseContentHook } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.types';
import { noopVoid } from 'reactApp/utils/helpers';

export const useFacesFilterScreen: UseContentHook = ({ onClose = noopVoid }) => {
    const onClick = useCallback(() => {
        onClose();
        renderFilterFacesModal();
    }, [onClose]);

    return {
        id: 'mobile-faces',
        title: 'Встречайте новый фильтр,\nлюди на фото',
        description: 'Теперь вы можете фильтровать фотографии\u00A0по\u00A0распознанным лицам',
        buttonText: 'Показать распознанных людей',
        onClick,
        img: facesPromoImg,
        mod: ESplashScreenMod.faces,
    };
};
