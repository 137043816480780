import { PromocodeInfo } from 'reactApp/api/promo/PromocodeGetAPICall';
import { State } from 'reactApp/modules/promocode/promocode.types';
import { RootState } from 'reactApp/store';
import { LoadingState } from 'reactApp/types/commonStates';
import { createSelector } from 'reselect';

const getPromocodesState = (state: RootState) => state.promocodes as State;

const getLoadingState = createSelector(
    getPromocodesState,
    (state: State): LoadingState => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
    })
);

const getPromocodesList = createSelector(getPromocodesState, (state: State): PromocodeInfo[] => state.promocodes);

export const PromocodesSelectors = {
    getLoadingState,
    getPromocodesList,
};
