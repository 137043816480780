import { Icon20Cancel, Icon20ChevronUpOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import { ReactComponent as UploadIcon } from 'img/icons/uploader/uploader.svg';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setUploadFromAction } from 'reactApp/modules/upload/upload.module';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from './Controls.css';

const uploadIconSize = 20;

interface IProps {
    toggleExpand: boolean;
    progressComplete: boolean;
    showUploadButton: boolean;
    onExpand: () => void;
    onClose: () => void;
    onUpload: () => void;
}

export const Controls = memo(({ onExpand, toggleExpand, onClose, onUpload, progressComplete, showUploadButton }: IProps) => {
    const dispatch = useDispatch();

    const onArrowClick = useCallback(() => {
        onExpand();
    }, [onExpand]);

    const onCloseClick = useCallback(() => {
        onClose();
    }, [onClose]);

    const onUploadClick = useCallback(() => {
        dispatch(setUploadFromAction('uploader'));
        onUpload();
    }, [onUpload]);

    const closeText = progressComplete ? 'Закрыть' : 'Отменить все загрузки';

    return (
        <div className={styles.root}>
            {showUploadButton && (
                <Hint text="Загрузить ещё">
                    <div className={styles.upload} onClick={onUploadClick}>
                        <UploadIcon width={uploadIconSize} height={uploadIconSize} />
                    </div>
                </Hint>
            )}
            <Hint text={toggleExpand ? 'Развернуть' : 'Свернуть'}>
                <div className={classNames(styles.expand, { [styles.expandClosed]: toggleExpand })} onClick={onArrowClick}>
                    <Icon20ChevronUpOutline />
                </div>
            </Hint>
            <Hint text={closeText}>
                <div className={styles.close} onClick={onCloseClick}>
                    <Icon20Cancel />
                </div>
            </Hint>
        </div>
    );
});

Controls.displayName = 'UploadListControls';
