import { BReactComponent } from 'blocks-cloud/b-react-component';
import { IS_FRAME_PAGES } from 'reactApp/appHelpers/configHelpers';
import { isPublic as isPublicSelector } from 'reactApp/modules/router/router.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { FrozenWarning } from 'reactApp/ui/FrozenWarning/FrozenWarning';
import { call, select } from 'redux-saga/effects';

function* initFreezeWarning() {
    if (IS_FRAME_PAGES) {
        return;
    }

    const el = document.querySelector('#react-warning');

    const isFrozen = yield select(UserSelectors.isFrozen);
    const isPublic = yield select(isPublicSelector);

    if (el && !isPublic && isFrozen) {
        const reactFrozenWarning = new BReactComponent({
            ReactComponent: FrozenWarning,
            renderMode: 'append',
        });

        reactFrozenWarning.renderTo(el);
    }
}

export function* watchFreezeUser() {
    yield call(initFreezeWarning);
}
