import classNames from 'clsx';
import { ReactComponent as CheckIcon } from 'img/icons/checkSquare.svg';
import { ReactComponent as DropdownIcon } from 'mrg-icons/src/mailru/navigation/drop_down.svg';
import React, { memo, ReactElement, useCallback, useRef, useState } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { DropdownItemAction } from 'reactApp/ui/DropdownItemAction/DropdownItemAction';
import { DropdownList, DropdownTheme, ListItem } from 'reactApp/ui/DropdownList/DropdownList';
import { Hint } from 'reactApp/ui/Hint/Hint';
import {
    DownloadIcon,
    FavoritesIcon,
    FilledFavoritesIcon,
    Icon16DownloadCircleIcon,
    LinkIcon,
    RestoreIcon,
    ShareIcon,
    TrashbinIcon,
} from 'reactApp/ui/VKUIIcons';
import { createGaSender } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';

import styles from './DataListControl.css';
import { DataListControl as DataListControlNew } from './new/DataListControl';

const sendGa = createGaSender('datalist-control');

export type DataListControlType =
    | 'download'
    | 'publish'
    | 'check'
    | 'restore'
    | 'remove'
    | 'downloadAsJpeg'
    | 'share'
    | 'favorite'
    | 'downloadVK';

export enum EDatalistControlTheme {
    dark = 'dark',
}

export enum EDataListControlVariant {
    noBorder = 'no-border',
}

interface Props {
    type: DataListControlType;
    active?: boolean;
    hover?: boolean;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    size?: 32 | 28 | 24 | 16;
    list?: ListItem[];
    listWithArrow?: boolean;
    onDropdown?: (isOpen: boolean) => void;
    hint?: string;
    theme?: EDatalistControlTheme;
    viewType?: EViewMode;
    variant?: EDataListControlVariant;
    icon?: ReactElement;
}

const iconSize = {
    downloadAsJpeg: {
        default: 14,
    },
    download: {
        default: 18,
        28: 20,
    },

    downloadVK: {
        default: 16,
        28: 16,
    },

    publish: {
        default: 18,
        28: 20,
        32: 20,
    },
    check: {
        default: 16,
    },
    restore: {
        default: 16,
        28: 20,
    },
    remove: {
        default: 16,
    },
    share: {
        28: 20,
        32: 20,
        default: 18,
    },
    favorite: {
        28: 18,
        default: 16,
    },
};

const getIconSize = (type: DataListControlType, size: number): number => {
    const icon = iconSize[type];

    return icon?.[size] || icon?.default || 12;
};

const getIcon = (type: DataListControlType, size: number, active: boolean): ReactElement | undefined => {
    switch (type) {
        case 'check': {
            const sizeIcon = getIconSize(type, size);
            return <CheckIcon width={sizeIcon} height={sizeIcon} />;
        }
        case 'downloadAsJpeg':
        case 'download': {
            const sizeIcon = getIconSize(type, size);
            return <DownloadIcon width={sizeIcon} height={sizeIcon} />;
        }
        case 'downloadVK': {
            const sizeIcon = getIconSize(type, size);
            return <Icon16DownloadCircleIcon width={sizeIcon} height={sizeIcon} />;
        }
        case 'publish': {
            const sizeIcon = getIconSize(type, size);
            return <LinkIcon width={sizeIcon} height={sizeIcon} />;
        }
        case 'restore': {
            const sizeIcon = getIconSize(type, size);
            return <RestoreIcon width={sizeIcon} height={sizeIcon} />;
        }
        case 'remove': {
            const sizeIcon = getIconSize(type, size);
            return <TrashbinIcon width={sizeIcon} height={sizeIcon} />;
        }
        case 'share': {
            const sizeIcon = getIconSize(type, size);
            return <ShareIcon width={sizeIcon} height={sizeIcon} />;
        }
        case 'favorite': {
            const sizeIcon = getIconSize(type, size);
            return active ? (
                <FilledFavoritesIcon width={sizeIcon} height={sizeIcon} />
            ) : (
                <FavoritesIcon width={sizeIcon} height={sizeIcon} />
            );
        }
    }
};

const handleDoubleClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
};

export const DataListControlComponent = memo(
    // eslint-disable-next-line max-lines-per-function
    ({
        type,
        active,
        hover,
        onClick,
        list,
        hint,
        size = 24,
        onDropdown = noop,
        disabled = false,
        theme,
        listWithArrow = true,
        viewType,
        variant,
        icon,
    }: Props) => {
        const controlRef = useRef<HTMLDivElement | null>(null);

        const [isDropdownOpen, setDropdownOpen] = useState(false);

        const isListWithArrow = list && listWithArrow;

        const handleOnClick = useCallback(
            (e): void => {
                if (onClick) {
                    e.stopPropagation();
                    e.preventDefault();
                    e.nativeEvent.stopImmediatePropagation();
                    sendGa('click', type, viewType);

                    if (list && !isListWithArrow) {
                        handleOnDropdownClick(e);
                    } else {
                        onClick(e);
                    }
                }
            },
            [onClick, type]
        );

        const handleOnDropdownClick = useCallback(
            (e): void => {
                e.stopPropagation();
                e.preventDefault();
                e.nativeEvent.stopImmediatePropagation();
                sendGa('open-dropdown', type);
                const isOpen = !isDropdownOpen;
                setDropdownOpen(isOpen);
                onDropdown(isOpen);
            },
            [isDropdownOpen, type, onDropdown]
        );

        const closeDropdown = useCallback((): void => {
            setDropdownOpen(false);
            onDropdown(false);
        }, [onDropdown]);

        const findDropdownPosition = (dropdownWidth: number): { posX: number; posY: number } => {
            let posX = 0;
            let posY = 0;

            if (controlRef?.current) {
                const elRect = controlRef.current.getBoundingClientRect();

                posY = elRect.height + window.scrollY + elRect.top;
                posX = elRect.left + elRect.width - dropdownWidth + window.scrollX;
            }

            return { posX, posY };
        };

        const renderDropdownItem = (item: ListItem): ReactElement => {
            return <DropdownItemAction text={item.text} />;
        };

        const renderIcon = (): ReactElement => {
            return (
                <div
                    className={classNames(styles.icon, {
                        [styles.vkIcon]: type === 'downloadVK',
                    })}
                    onClick={isListWithArrow ? handleOnClick : noop}
                >
                    {icon ? icon : getIcon(type, size, !!active)}
                </div>
            );
        };

        /*
            font-family: var(--vkui--font_family_base);
         */

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles[type]]: !!type,
                    [styles.root_hover]: hover,
                    [styles.root_active]: active,
                    [styles[`root_${size}`]]: !!size,
                    [styles[`root_${theme}`]]: !!theme,
                    [styles.root_withDropdown]: isListWithArrow,
                    [styles.root_openDropdown]: isDropdownOpen,
                    [styles.disabled]: disabled,
                    [styles.root_noBorder]: variant === EDataListControlVariant.noBorder,
                })}
                onClick={isListWithArrow ? noop : handleOnClick}
                onDoubleClick={handleDoubleClick}
                ref={controlRef}
            >
                {hint ? <Hint text={hint}>{renderIcon()}</Hint> : renderIcon()}
                {list && listWithArrow && (
                    <div className={styles.dropdownIcon} onClick={handleOnDropdownClick}>
                        <DropdownIcon height={16} width={16} />
                    </div>
                )}
                {isDropdownOpen && list && (
                    <DropdownList
                        gaId="datalist-control"
                        list={list}
                        close={closeDropdown}
                        closeOnScroll
                        closeOnMouseLeave
                        posX={0}
                        posY={0}
                        parentRef={controlRef}
                        theme={type === 'downloadAsJpeg' ? DropdownTheme.jpeg : DropdownTheme.small}
                        title={type === 'downloadAsJpeg' ? 'Скачать в' : ''}
                        calcPosition={findDropdownPosition}
                        renderItem={renderDropdownItem}
                    />
                )}
            </div>
        );
    }
);

DataListControlComponent.displayName = 'DataListControl';

export const DataListControl = Object.assign(DataListControlComponent, { New: DataListControlNew });
