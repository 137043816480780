import { Icon16Users2Outline } from '@vkontakte/icons';
import { Caption } from '@vkontakte/vkui';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { loadPromocodes } from 'reactApp/modules/promocode/promocode.module';
import { UserStorageActions, UserStorageSelectors } from 'reactApp/modules/user/userStorage';
import { RootState } from 'reactApp/store';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './FamilySubscriptionPromo.css';

const PROMO_ID = 'family-subscription';

export const FamilySubscriptionPromo = () => {
    const dispatch = useDispatch();

    const wasShown = useSelector((state: RootState) => UserStorageSelectors.get(state, PROMO_ID));

    const onClick = useCallback(() => {
        sendPaymentGa({ action: EPaymentGa.familySubscription, label: 'click' });
        dispatch(loadPromocodes());
        openPopupHelper({
            popupName: popupNames.FAMILY_SUBSCRIPTION_PROMO,
        });
        /** Сохраняем флаг показа в user хранилище в localstorage, чтобы больше не показывать */
        dispatch(UserStorageActions.set(PROMO_ID, '1'));
    }, [dispatch]);

    useEffect(() => {
        if (!wasShown) {
            sendPaymentGa({ action: EPaymentGa.familySubscription, label: 'show' });
        }
    }, []);

    if (wasShown) {
        return null;
    }

    return (
        <div className={styles.root} onClick={onClick}>
            <Icon16Users2Outline />
            <Caption level="2" weight="2">
                Семейная подписка
            </Caption>
        </div>
    );
};
