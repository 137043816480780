import classNames from 'clsx';
import React, { memo, ReactElement, useCallback } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window';
import { GALLERY_TYPES_COUNT } from 'reactApp/ui/VirtualList/VirtualList.constants';
import { getThumbTypeForGallery } from 'reactApp/ui/VirtualList/VirtualList.helpers.new';

import { DATA_LIST_BREADCRUMBS_ID, DATA_LIST_EXTRABLOCKS_ID, DATA_LIST_STORIES_WIDGET_ID } from '../Datalist/DataList';
import { VIRTUAL_LIST_LOADER_ID } from './VirtualList.new';
import styles from './VirtualList.new.css';

export const VirtualListGalleryRow = memo(function VirtualListGridRow(props: ListChildComponentProps): ReactElement | null {
    const { index, style, data } = props;
    const { list, itemActiveIndex, indexes, renderItem } = data;

    const extraBlocksInHead = list.filter((o) => [DATA_LIST_BREADCRUMBS_ID, DATA_LIST_STORIES_WIDGET_ID].includes(o)).length;

    const startIndex = indexes[index].start;
    const lastIndex = Math.min(indexes[index].end, list.length);
    const handleRef = index === itemActiveIndex ? data.handleRef : null;
    const gridType = (extraBlocksInHead ? index - extraBlocksInHead : index) % GALLERY_TYPES_COUNT;

    const singleRowItem =
        startIndex === lastIndex &&
        [DATA_LIST_EXTRABLOCKS_ID, VIRTUAL_LIST_LOADER_ID, DATA_LIST_BREADCRUMBS_ID, DATA_LIST_STORIES_WIDGET_ID].includes(
            list[startIndex]
        );

    const content: (JSX.Element | null)[] = [];

    const renderItemHelper = useCallback(
        (index, gridIndex) => {
            const key = String(typeof list[index] === 'string' ? list[index] : list[index].id);

            return (
                <div
                    key={key + index}
                    className={classNames(styles.colItem, {
                        [styles.singleRow]: singleRowItem,
                        [styles.compensateScroll]: singleRowItem,
                    })}
                >
                    {renderItem(list[index], { index, thumbType: getThumbTypeForGallery(gridType, gridIndex) })}
                </div>
            );
        },
        [list[index], renderItem, gridType]
    );

    for (let i = startIndex; i <= lastIndex; i++) {
        content.push(renderItemHelper(i, i - startIndex));
    }

    return (
        <div style={style} ref={handleRef}>
            <div
                className={classNames(styles.galleryRow, styles.compensateScroll, {
                    [styles[`galleryRow_grid_${gridType}`]]: true,
                    [styles.singleRow]: singleRowItem,
                })}
            >
                {content}
            </div>
        </div>
    );
}, areEqual);
