import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { vkTeamsBridge } from 'reactApp/modules/vkTeam/vkTeam.module';
import { QUOTA_URL } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { SUBSCRIPTIONS_URL } from 'reactApp/sections/SubscriptionsPage/SubscriptionsPage.constants';
import opener from 'reactApp/utils/opener';

interface openPageProps {
    inSameTab?: boolean;
    anchor?: string;
    query?: string;
}

export const openSubscriptionsPage = ({ inSameTab = false, anchor = '', query = '' }: openPageProps = {}) => {
    opener(`${SUBSCRIPTIONS_URL}${query ? `?${query}` : ''}${anchor ? `#${anchor}` : ''}`, inSameTab);
};

export const openPromoQuotaPage = ({ inSameTab = false, anchor = '', query = '' }: openPageProps = {}) => {
    opener(`${QUOTA_URL}${query ? `?${query}` : ''}${anchor ? `#${anchor}` : ''}`, inSameTab);
};

export const openLink = async (link: string, target = '_self') => {
    if (!IS_MY_TEAM) {
        window.open(link, target);
    } else {
        try {
            const bridge = await vkTeamsBridge;
            await bridge.send('OpenLink', { url: link });
        } catch (error) {
            console.error(error);
        }
    }
};
