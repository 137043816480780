import { Icon20ChevronLeftOutline, Icon20ChevronRightOutline } from '@vkontakte/icons';
import { Spacing, Text } from '@vkontakte/vkui';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './SliderModal.css';
import { SliderModalProps } from './SliderModal.types';

export const SliderModal = memo<SliderModalProps>(
    ({ slides, qaId = 'slider-dlg', primaryButtonText, primaryButtonOnClick, onClose, onShow, onCancel }) => {
        const isMobile = EnvironmentSelectors.isPhone();
        const scrollRef = useRef<HTMLDivElement>(null);
        const [activeSlide, setActiveSlide] = useState(0);

        useEffect(() => {
            onShow?.(activeSlide + 1);
        }, [activeSlide, onShow]);

        const handleClose = useCallback(() => {
            onCancel?.(activeSlide + 1);
            onClose?.();
        }, [activeSlide, onClose, onCancel]);

        const content = useMemo(() => {
            return (
                <>
                    <div className={styles.slider}>
                        <Swiper
                            simulateTouch
                            slidesPerView={1}
                            loop={false}
                            navigation={{
                                nextEl: `.${styles.arrowNext}`,
                                prevEl: `.${styles.arrowPrev}`,
                                disabledClass: styles.arrowDisabled,
                            }}
                            pagination={{
                                clickable: true,
                                bulletClass: styles.bullet,
                                bulletActiveClass: styles.bulletActive,
                                clickableClass: styles.bulletWrapper,
                                ...(isMobile && {
                                    el: `.${styles.pagination}`,
                                }),
                            }}
                            modules={[Navigation, Pagination]}
                            onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}
                        >
                            {slides.map((item) => (
                                <SwiperSlide key={item.id}>
                                    <img src={isMobile ? item.srcMobile : item.src} className={styles.image} alt={item.title} />
                                    <div className={styles.content}>
                                        <Text className={styles.title}>{item.title}</Text>
                                        <Spacing size={12} />
                                        <Text className={styles.text}>{item.text}</Text>
                                        <Spacing size={isMobile ? 24 : 32} />
                                        {primaryButtonText && (
                                            <Button
                                                theme="vk"
                                                primary
                                                sizeMode={isMobile ? ButtonSizeMode.big : ButtonSizeMode.small}
                                                className={styles.button}
                                                onClick={() => primaryButtonOnClick?.(activeSlide + 1)}
                                            >
                                                {primaryButtonText}
                                            </Button>
                                        )}
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className={styles.arrowNext}>
                            <Icon20ChevronRightOutline />
                        </div>
                        <div className={styles.arrowPrev}>
                            <Icon20ChevronLeftOutline />
                        </div>
                        {isMobile && <div className={styles.pagination} />}
                    </div>
                </>
            );
        }, [activeSlide, setActiveSlide, slides, primaryButtonOnClick, primaryButtonText, isMobile]);

        if (isMobile) {
            return (
                <MobileDialog
                    id="slider-dlg-mobile"
                    onClose={handleClose}
                    mod="base"
                    topmost
                    scrollRef={scrollRef}
                    contextPadding="zero"
                    disableScrolling
                    closeOnDimmerClick
                >
                    <div ref={scrollRef} className={styles.rootMobile}>
                        {content}
                    </div>
                </MobileDialog>
            );
        }

        return (
            <Dialog
                open
                closeOnDimmerClick={false}
                id="slider-dlg"
                qaId={qaId}
                closable
                size="large"
                className={styles.root}
                onCancel={handleClose}
            >
                {content}
            </Dialog>
        );
    }
);

SliderModal.displayName = 'SliderModal';
