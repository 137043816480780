import { stringify } from 'qs';
import { IS_MOBILE_BROWSER, IS_ONPREMISE, LOGIN_URL } from 'reactApp/appHelpers/configHelpers';

export const showAuth = (params) => {
    if (IS_ONPREMISE) {
        const { successPage: page = location.href, type: action, ...rest } = params;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.location = `${LOGIN_URL}?${stringify({ page, action, ...rest })}`;
        return;
    }

    if (params.when_download) {
        // @ts-ignore
        window.__PH.authForm.once('hide', () => window.__AG?.setSettings?.({ when_download: 0 }));
    }

    window.__PH.authForm.show(params);
};

export const loginShow = (params) => {
    params = params || {};

    const fromStartMobile = (params.fromStart && IS_MOBILE_BROWSER) || false;

    if (!fromStartMobile && window.__PH && window.__PH.authForm) {
        params.closable = params.closable || false;
        params.modal = params.modal || false;

        if (params.formType === 'restore') {
            params.focus = 'password';
        }

        showAuth(params);
    } else if (IS_MOBILE_BROWSER) {
        window.location.href = `https://touch.mail.ru/login?page=${encodeURIComponent(window.location.href)}`;
    } else {
        // eslint-disable-next-line no-console
        console.error('LOGIN');
    }
};

export const loginHide = () => window.__PH.authForm.hide();
