/* eslint-disable max-lines */
/* eslint-disable complexity */
import { PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import sha1 from 'js-sha1';
import { logger } from 'lib/logger';
import { IS_PHONE_BROWSER, IS_PUBLIC_ALBUM, IS_PUBLIC_FOLDER } from 'reactApp/appHelpers/configHelpers';
import { publishHelper, unPublishHelper } from 'reactApp/appHelpers/publishHelper';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { renderComfirmationDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { MAIL_ATTACHES_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { waitUntilLoadedItem } from 'reactApp/modules//storage/storage.saga';
import { attachesViewerLoadMore } from 'reactApp/modules/attaches/attaches.actions';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import { startEditor } from 'reactApp/modules/editor/editor.module';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { requestFacesListFail, requestFacesListSuccess, requestFacesOnPhotoStart } from 'reactApp/modules/faces/faces.module';
import { getFacesListRequestState, hasFolderFileWithFace } from 'reactApp/modules/faces/faces.selectors';
import { getFeaturesAttachesTrialPromo } from 'reactApp/modules/features/features.selectors';
import { feedLoadMoreRequest, feedLoadMoreSuccess } from 'reactApp/modules/feed/feed.module';
import { unpackOnClientController } from 'reactApp/modules/file/unpackOnClientController';
import { getWeblinkFromPublicId, isImage } from 'reactApp/modules/file/utils';
import { galleryLoadMoreRequest, galleryLoadMoreSuccess } from 'reactApp/modules/gallery/gallery.module';
import { loadMoreHomeRequest, loadMoreSuccess } from 'reactApp/modules/home/home.actions';
import {
    moveItemsSuccess,
    publishWeblink,
    removeFileSuccess,
    removeFromFavoritesSuccess,
    unPublishWeblink,
} from 'reactApp/modules/modifying/modifying.actions';
import { MoveItemsSuccessAction } from 'reactApp/modules/modifying/modifying.types';
import { prepareHomeForAttachesClone } from 'reactApp/modules/modifying/sagas/publish.saga';
import { productsController } from 'reactApp/modules/products/products.controller';
import { checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { getIsSinglePublic } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage, isIntegrationStorage } from 'reactApp/modules/storage/storage.helpers';
import { getStorageCurrentFolder, getStorageItemById, groupedIds } from 'reactApp/modules/storage/storage.selectors';
import { CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { getStoryInfoFail, getStoryInfoRequest, getStoryInfoSuccess, markStoryAsViewed } from 'reactApp/modules/stories/stories.module';
import {
    getAdjacentStoryIds,
    getCurrentStory,
    getCurrentStoryFiles,
    getStoryForViewer,
    getStorySummaryById,
} from 'reactApp/modules/stories/stories.selectors';
import { UserStorageSelectors } from 'reactApp/modules/user/userStorage';
import {
    handleDownloadArchiveItemRequest,
    handleRequestArchiveInfo,
    handleRequestArchiveItemInfo,
    handleStartArchiveProlongTimer,
} from 'reactApp/modules/viewer/sagas/archive.saga';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { openDisabledFeaturePopupHelper } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.helpers';
import { DisabledFeature } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.types';
import { renderMobileViewer } from 'reactApp/ui/Mobile/MobileViewer/MobileViewer.helpers';
import { sendViewerDwh } from 'reactApp/ui/ReactViewer/ReactViewer.helpers';
import { TRIAL_ATTACHES_BANNER_ID } from 'reactApp/ui/TrialPromoBanner/TrialPromoBanner';
import { sendGa } from 'reactApp/utils/ga';
import opener from 'reactApp/utils/opener';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { channel } from 'redux-saga';
import { all, call, cancel, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { loadMorePublicFolderRequest, loadMorePublicFolderSuccess } from '../public/public.actions';
import { UserSelectors } from '../user/user.selectors';
import { gotoAfterClose, navigateToItem, pushWeblink } from './sagas/navigate.saga';
import { removeViewerPlaceholder } from './viewer.helpers';
import {
    checkViewerAtIdChange,
    closeViewer,
    downloadArchiveItemRequest,
    getArchiveItemPreviewRequest,
    openMobileViewer as openMobileViewerAction,
    openPdfEditor,
    openViewer,
    publishItem,
    requestArchiveInfo,
    selectItemIndexInArray,
    setIdxs,
    setSelectedItem,
    setViewerActive,
    setViewerItems,
    setViewerStorage,
    startArchiveProlongTimer,
    stopArchiveProlongTimer,
    updateViewerData,
} from './viewer.module';

const LOAD_MORE_IF_LESS_THAN = 10;

function* putNewItemData({
    item,
    itemIdxInArray,
    itemId,
    isOpenAction = false,
}: {
    item: any;
    itemIdxInArray: number | null;
    itemId: string;
    isOpenAction: boolean;
}) {
    const currentItemIdxInArray = Number(itemIdxInArray) >= 0 ? itemIdxInArray : null;
    yield put(setSelectedItem({ item, itemId, itemIdxInArray: currentItemIdxInArray, isOpenAction }));

    const isPhone = yield select(EnvironmentSelectors.isPhone);
    // текущий выделенный файл пока храним также в сторе редактора (для EditorHeader),
    // а работу со списком элементов ведем в сторе viewer
    // Надо отрефакторить EditorHeader и сделать один общий стор
    if (!isPhone) {
        yield put(startEditor({ editorId: itemId, item }));
    }
}

function* getAttachesIdsForViewer(viewerAttaches = false) {
    const items = yield select(viewerAttaches ? AttachesSelectors.getViewerAttaches : AttachesSelectors.getAttaches);

    const itemIdxs = Object.keys(items);

    yield put(setViewerItems({ itemIdxs, filesCount: itemIdxs.length, hasMoreToLoad: !viewerAttaches }));

    return itemIdxs;
}

function* getItemsForViewer(itemStorage) {
    const folder: ReturnType<typeof getStorageCurrentFolder> = yield select(getStorageCurrentFolder, itemStorage as EStorageType);
    const itemIds: string[] = yield select(groupedIds, itemStorage, true);
    const hasMoreToLoad = (folder && 'hasMoreToLoad' in folder && folder.hasMoreToLoad) || false;

    let filesCount;
    if (folder && 'count' in folder) {
        filesCount = folder?.count?.files;
    }
    yield put(setViewerItems({ itemIdxs: itemIds, filesCount, hasMoreToLoad }));

    return itemIds;
}

function* getStoryItemsForViewer() {
    const itemIds = ((yield select(getCurrentStoryFiles)) as ReturnType<typeof getCurrentStoryFiles>) ?? [];

    yield put(setViewerItems({ itemIdxs: itemIds, filesCount: itemIds.length, hasMoreToLoad: true }));

    return itemIds;
}

export function* handleUpdateViewerData(action, skipArchive = false) {
    let item: CloudItem | null | undefined = null;

    try {
        const { itemId, itemStorage, fromCloud } = action.payload;
        let { itemIds } = action.payload;

        item = (yield select(getStorageItemById, itemStorage, itemId)) as ReturnType<typeof getStorageItemById>;
        const { isAttaches, isStory } = getStorage(itemStorage);

        const isArchive = !skipArchive && item && 'ext' in item && item.ext === 'zip';

        yield put(setViewerActive(true));

        if (isArchive) {
            sendGa('archive', 'previewable');
        }

        if (!itemIds || !itemIds.length) {
            if (isAttaches) {
                itemIds = yield getAttachesIdsForViewer(!fromCloud);
            } else if (isStory) {
                itemIds = yield getStoryItemsForViewer();
            } else {
                itemIds = yield getItemsForViewer(itemStorage);
            }
        }
        const itemIdxInArray = itemIds.findIndex((id) => id === itemId);
        // eslint-disable-next-line no-unneeded-ternary
        yield putNewItemData({ item, itemIdxInArray, itemId, isOpenAction: true });

        const isSinglePublic = yield select(getIsSinglePublic);
        if (!isSinglePublic && itemStorage === EStorageType.public) {
            yield checkFacesOnFile(item);
        }
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield cancel();
    } finally {
        yield navigateToItem(item);
    }
}

function* handleOpenViewer(action) {
    try {
        yield call(handleUpdateViewerData, { ...action, payload: { ...action.payload } });

        const isUserWithBilling = yield select(UserSelectors.isUserWithBilling);
        const isPaidUser = yield select(UserSelectors.isPaidUser);
        const trialPromoFeature = yield select(getFeaturesAttachesTrialPromo);
        const storage = yield select(getCurrentStorage);
        const { isAttaches } = getStorage(storage);
        const wasTrialShown = yield select(UserStorageSelectors.get, TRIAL_ATTACHES_BANNER_ID);

        // Грузим список тарифов, чтобы показать промо триала бесплатникам, только на аттачах
        if (isAttaches && !isPaidUser && isUserWithBilling && trialPromoFeature && !wasTrialShown) {
            yield productsController.loadProducts();
        }
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield cancel();
    } finally {
        removeViewerPlaceholder();
    }
}

function* handleCloseViewer() {
    yield put(stopArchiveProlongTimer());
    yield put(checkUpdateLicenseRequest());

    unpackOnClientController.clearCache();

    gotoAfterClose();
}

function* handleSwitchToAdjacentStory(itemIndex) {
    const { prevStoryId, nextStoryId, currentStoryId }: ReturnType<typeof getAdjacentStoryIds> = yield select(getAdjacentStoryIds);
    const switchToId = itemIndex < 0 ? prevStoryId : nextStoryId;

    const storyPrev: ReturnType<typeof getCurrentStory> = yield select(getCurrentStory);

    if (!storyPrev?.viewed && currentStoryId) {
        yield put(markStoryAsViewed({ storyId: currentStoryId }));
    }

    if (switchToId) {
        const summary: ReturnType<typeof getStorySummaryById> = yield select(getStorySummaryById, switchToId);
        yield put(getStoryInfoRequest({ id: summary?.id, type: summary?.type }));

        yield take([getStoryInfoSuccess, getStoryInfoFail]);

        const story: ReturnType<typeof getCurrentStory> = yield select(getCurrentStory);

        if (story?.id) {
            const { content } = ((yield select(getStoryForViewer, '')) as ReturnType<typeof getStoryForViewer>) ?? {};

            if (!content) {
                yield call(handleSwitchToAdjacentStory, itemIndex);
                return;
            }

            sendViewerDwh({
                eventCategory: ECategoryGa.story,
                action: 'story_close',
                id_story: storyPrev?.id,
                source: EStorageType.story,
                type_story: storyPrev?.type,
                type_reason: 'switch',
            });

            sendViewerDwh({
                eventCategory: ECategoryGa.story,
                action: 'story_view',
                count_media: content?.length ?? 0,
                id_story: story.id,
                source: EStorageType.story,
                type_story: story.type,
                type_reason: 'switch',
            });

            yield call(handleUpdateViewerData, {
                payload: {
                    itemId: content[0] && 'file' in content[0] ? content[0]?.file : '',
                    itemStorage: EStorageType.story,
                },
            });
        }
    } else {
        sendViewerDwh({
            eventCategory: ECategoryGa.story,
            action: 'story_close',
            id_story: storyPrev?.id,
            source: EStorageType.story,
            type_story: storyPrev?.type,
            type_reason: 'end',
        });

        yield put(closeViewer());
    }
}

function* handleSelectItemIndex(action) {
    try {
        const { itemIndex } = action.payload;
        const itemStorage: ReturnType<typeof ViewerSelectors.getViewerItemStorage> = yield select(ViewerSelectors.getViewerItemStorage);
        const ids: ReturnType<typeof ViewerSelectors.getViewerItemIds> = yield select(ViewerSelectors.getViewerItemIds);
        const hasMoreToLoad: ReturnType<typeof ViewerSelectors.hasMoreToLoad> = yield select(ViewerSelectors.hasMoreToLoad);

        if (itemStorage === EStorageType.story && (itemIndex < 0 || (ids && itemIndex >= ids?.length))) {
            // В сторисах в конце элементов текущего сториса пытаемся переключится на соседний
            yield call(handleSwitchToAdjacentStory, itemIndex);

            return;
        }

        const itemId = ids?.[itemIndex];
        let item: ReturnType<typeof getStorageItemById> = itemStorage ? yield select(getStorageItemById, itemStorage, itemId) : null;

        if (hasMoreToLoad && itemStorage === EStorageType.viewerAttaches) {
            yield put(attachesViewerLoadMore({ itemId, ids }));
        }

        if (!item) {
            yield waitUntilLoadedItem(itemStorage, itemId);

            item = yield select(getStorageItemById, itemStorage, itemId);
        }

        if (!itemId || !item) {
            /* Закрываем просмотрщик, если нет item */
            yield put(closeViewer());

            return;
        }

        yield putNewItemData({ item, itemIdxInArray: itemIndex, itemId, isOpenAction: false });

        if (itemId) {
            yield navigateToItem(item);
        }

        const offset = ids.length;

        if (hasMoreToLoad && itemIndex >= offset - LOAD_MORE_IF_LESS_THAN) {
            if (itemStorage === EStorageType.home) {
                yield put(loadMoreHomeRequest({ offset, id: item.parent }));
            } else if (itemStorage === EStorageType.feed) {
                yield put(feedLoadMoreRequest());
            } else if (itemStorage === EStorageType.gallery) {
                yield put(galleryLoadMoreRequest());
            } else if (itemStorage === EStorageType.public) {
                // const folder = yield select(getStorageCurrentFolder, itemStorage as EStorageType);
                yield put(loadMorePublicFolderRequest({ offset, id: item.parent }));
            }
        }

        if (itemStorage === EStorageType.public) {
            yield checkFacesOnFile(item);
        }

        yield put(stopArchiveProlongTimer());
    } catch (error) {
        captureException(error);
        logger.error(error);
        yield cancel();
    }
}

function* checkFacesOnFile(item) {
    // На альбомных пабликах пока нет лиц
    if (!isImage(item) || IS_PUBLIC_ALBUM) {
        return;
    }
    const weblink = getWeblinkFromPublicId(item?.weblink);
    const facesListRequestState: ReturnType<typeof getFacesListRequestState> = yield select(getFacesListRequestState);
    if (!facesListRequestState.isLoaded) {
        yield take([requestFacesListSuccess, requestFacesListFail]);
    }
    const hasFaces: ReturnType<typeof hasFolderFileWithFace> = yield select(hasFolderFileWithFace, weblink);
    if (hasFaces && weblink) {
        yield put(requestFacesOnPhotoStart({ id: weblink, path: item.id.replace(weblink, '') }));
    }
}

function* handleDeleteFile() {
    const isActive: ReturnType<typeof ViewerSelectors.isViewerActive> = yield select(ViewerSelectors.isViewerActive);

    if (!isActive) {
        return;
    }

    // сага выполняется после редьюсера, и у нас уже обновленные в редьюсере itemIdxInArray и ItemIds
    const itemIdxInArray: ReturnType<typeof ViewerSelectors.getViewerItemIndexInArray> = yield select(
        ViewerSelectors.getViewerItemIndexInArray
    );

    yield handleSelectItemIndex({ payload: { itemIndex: itemIdxInArray }, type: 'viewer/deleteFile' });
}

function* handleFavoritesDeleteFile() {
    const isActive: ReturnType<typeof ViewerSelectors.isViewerActive> = yield select(ViewerSelectors.isViewerActive);

    if (!isActive) {
        return;
    }

    const itemIdxInArray: ReturnType<typeof ViewerSelectors.getViewerItemIndexInArray> = yield select(
        ViewerSelectors.getViewerItemIndexInArray
    );
    const itemStorage: ReturnType<typeof ViewerSelectors.getViewerItemStorage> = yield select(ViewerSelectors.getViewerItemStorage);

    if (itemStorage === EStorageType.favorites) {
        yield handleSelectItemIndex({
            payload: { itemIndex: itemIdxInArray },
            type: 'viewer/favoritesDeleteFile',
        });
    }
}

function* updateMovedViewerItems(newItem, itemStorage, ids, itemIdxInArray) {
    const item: ReturnType<typeof getStorageItemById> = yield select(getStorageItemById, itemStorage, newItem?.home);

    if (!item) {
        const newIdx = itemIdxInArray === 0 ? 1 : itemIdxInArray - 1;
        yield put(setIdxs(ids.filter((id) => id !== ids[itemIdxInArray])));
        yield handleSelectItemIndex({ payload: { itemIndex: newIdx } });
        return;
    }

    const newIds = ids.map((id, i) => (i === itemIdxInArray ? item.id : id));
    yield put(setIdxs(newIds));
    yield putNewItemData({ item, itemIdxInArray, itemId: item.id, isOpenAction: false });
}

function* handleMoveItem(action: PayloadAction<MoveItemsSuccessAction>) {
    const isActive: ReturnType<typeof ViewerSelectors.isViewerActive> = yield select(ViewerSelectors.isViewerActive);
    const { storage, newItems } = action.payload;

    if (!isActive) {
        return;
    }

    const itemIdxInArray: ReturnType<typeof ViewerSelectors.getViewerItemIndexInArray> = yield select(
        ViewerSelectors.getViewerItemIndexInArray
    );

    if (storage !== EStorageType.home) {
        const itemStorage: ReturnType<typeof ViewerSelectors.getViewerItemStorage> = yield select(ViewerSelectors.getViewerItemStorage);
        const ids = [...(((yield select(ViewerSelectors.getViewerItemIds)) as ReturnType<typeof ViewerSelectors.getViewerItemIds>) ?? [])];
        yield all(newItems.map((item) => call(updateMovedViewerItems, item, itemStorage, ids, itemIdxInArray)));
    }

    yield call(handleSelectItemIndex, { payload: { itemIndex: itemIdxInArray } });
}

function* handleLoadMoreSuccess() {
    const isActive: ReturnType<typeof ViewerSelectors.isViewerActive> = yield select(ViewerSelectors.isViewerActive);

    if (!isActive) {
        return;
    }

    try {
        const itemStorage: ReturnType<typeof ViewerSelectors.getViewerItemStorage> = yield select(ViewerSelectors.getViewerItemStorage);

        yield getItemsForViewer(itemStorage);
    } catch (error) {
        captureException(error);
        logger.error(error);
        yield cancel();
    }
}

function* handlePublish(action) {
    const isActive: ReturnType<typeof ViewerSelectors.isViewerActive> = yield select(ViewerSelectors.isViewerActive);

    if (!isActive) {
        return;
    }

    try {
        const itemStorage: ReturnType<typeof ViewerSelectors.getViewerItemStorage> = yield select(ViewerSelectors.getViewerItemStorage);

        if (itemStorage === EStorageType.home) {
            yield call(pushWeblink, { weblink: action.payload.weblink });
        }
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield cancel();
    }
}

function* handleUnPublish() {
    const isActive: ReturnType<typeof ViewerSelectors.isViewerActive> = yield select(ViewerSelectors.isViewerActive);

    if (!isActive) {
        return;
    }

    try {
        const story = yield select(getCurrentStory);

        const content = story?.header?.content?.[0];
        if (content && 'path' in content) {
            sendViewerDwh({
                eventCategory: ECategoryGa.viewer,
                action: 'close_public',
                id_story: story.id,
                source: EStorageType.story,
                type_story: story.type,
                is_stories: true,
                id_media: sha1(content.path),
                count_media: story.header?.content?.length ?? 0,
            });
        }

        const itemStorage: ReturnType<typeof ViewerSelectors.getViewerItemStorage> = yield select(ViewerSelectors.getViewerItemStorage);

        if (itemStorage === EStorageType.home) {
            yield call(pushWeblink);
        }
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield cancel();
    }
}

function* handlePublishItem(action) {
    const { item, itemStorage } = action.payload;
    let newItem = item;
    let newStorage = itemStorage;
    const isAnonymous = yield select(UserSelectors.isAnonymous);
    const isNewbie = yield select(UserSelectors.isNewbie);
    let publishFrom = 'viewer';

    if (!item) {
        return;
    }

    if (isAnonymous || isNewbie) {
        toolbarActions.clone({
            id: item.id,
            source: 'attaches-autoclone',
            destination: MAIL_ATTACHES_FOLDER_ID,
            autoClone: true,
        });
        return;
    }

    const isAttachesOrStock =
        itemStorage === EStorageType.attaches || itemStorage === EStorageType.viewerAttaches || itemStorage === EStorageType.stock;

    try {
        if (isAttachesOrStock) {
            newItem = yield prepareHomeForAttachesClone(item, itemStorage as EStorageType);

            if (!newItem) {
                return;
            }

            newStorage = EStorageType.home;

            publishFrom = 'viewer-attach';
        }

        if (itemStorage === EStorageType.public && newItem?.home && itemStorage !== EStorageType.stock) {
            renderComfirmationDialog({
                dataQAId: 'confirm-unpublish',
                renderContent: () => `Вы действительно хотите отключить доступ по ссылке?`,
                onSuccess: () => {
                    unPublishHelper({ items: newItem });
                },
            });
            return;
        }

        publishHelper({
            gaSuffix: '_header',
            item: newItem,
            itemStorage: newStorage?.toString(),
            publishFrom,
        });
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield cancel();
    }
}

function* openMobileViewer(action: ReturnType<typeof openMobileViewerAction>) {
    let storage = action.payload.storage;
    if (!storage) {
        storage = (yield select(getCurrentStorage)) as ReturnType<typeof getCurrentStorage>;
        if (storage === EStorageType.attaches) {
            storage = EStorageType.viewerAttaches;
        }
    }
    const viewerList: ReturnType<typeof groupedIds> = yield select(groupedIds, storage, true);
    const itemId = action.payload.id || '';
    const item = yield select(getStorageItemById, storage, itemId);
    const isOpening = yield select(ViewerSelectors.isViewerOpening);
    const currentViewerItemId = yield select(ViewerSelectors.getViewerItemId);

    if (isOpening || !viewerList.includes(itemId)) {
        if (viewerList.includes(itemId) && currentViewerItemId && currentViewerItemId !== itemId) {
            yield call(
                handleUpdateViewerData,
                {
                    payload: {
                        itemId,
                        itemStorage: storage,
                        gaSuffix: 'mobile',
                        itemIds: [],
                    },
                },
                false
            );
        }

        if (isIntegrationStorage(storage)) {
            yield put(setViewerStorage({ itemStorage: storage }));
        }

        return;
    }

    if (storage === EStorageType.home && item.storage === EStorageType.home && !item.isFolder) {
        yield call(pushWeblink, { path: item.home, weblink: item.weblink });
    }

    const viewerChannel = channel();

    yield put(setViewerStorage({ itemStorage: storage }));
    yield call(
        handleUpdateViewerData,
        {
            payload: {
                itemId,
                itemStorage: storage,
                gaSuffix: 'mobile',
                itemIds: [],
            },
        },
        false
    );
    yield renderMobileViewer({
        onClose: () => viewerChannel.put(true),
    });

    removeViewerPlaceholder();

    yield take(viewerChannel);

    yield put(closeViewer());
}

function* handleIdChange(action) {
    try {
        // TODO: routing: при рефакторинге роутинга не забыть обработать эти кейсы (авто открытие и закрытие просмотрщика)
        const { storage, id } = action.payload;

        const isViewerActive: ReturnType<typeof ViewerSelectors.isViewerActive> = yield select(ViewerSelectors.isViewerActive);
        const isViewerOpening: ReturnType<typeof ViewerSelectors.isViewerOpening> = yield select(ViewerSelectors.isViewerOpening);

        const fixedStorage =
            storage === EStorageType.attaches && (isViewerActive || isViewerOpening) ? EStorageType.viewerAttaches : storage;

        const item: ReturnType<typeof getStorageItemById> = yield select(getStorageItemById, fixedStorage, id);

        if ((fixedStorage === EStorageType.public && !IS_PUBLIC_FOLDER) || (!isViewerActive && item?.isFolder)) {
            // Если одиночный паблик или это папка, и просмотрщик закрыт - все ок
            return;
        }

        const isDownloadableFolder = item?.isFolder && item?.weblinkDownloadable;

        if (isViewerActive && (!item || item?.isFolder) && !isDownloadableFolder) {
            // Закрываем просмотрщик, так как элемент по ИД из урла папка, или его нет
            // В онпремисе есть кейс - папка с разрешениеем только для скачивания показывают в просмотрщике, тогда не закрываем
            yield put(updateViewerData({ itemId: '', itemStorage: fixedStorage, gaSuffix: '', itemIds: [] }));
            yield put(closeViewer());
            return;
        }

        if (!isViewerActive && !isViewerOpening && item?.isFolder === false) {
            if (IS_PHONE_BROWSER && (storage === EStorageType.public || storage === EStorageType.stock)) {
                yield put(openMobileViewerAction({ id: item.id, storage }));
                return;
            }
            if (IS_PHONE_BROWSER) {
                // На тачах нужно только обновлять данные, закрывать/открывать - обрабатывается в других местах
                return;
            }

            // Если это файл, и просмотрщик закрыт - откроем его
            yield put(openViewer({ itemId: item.id, itemStorage: fixedStorage, itemIds: [], gaSuffix: '' }));
            return;
        }

        const viewerItemId: ReturnType<typeof ViewerSelectors.getViewerItemId> = yield select(ViewerSelectors.getViewerItemId);

        if (item && viewerItemId && viewerItemId !== item.id) {
            // Если ИД в урле поменялся, а в просмотрщике показан другой - показываем новый файл в просмотрщике
            yield call(handleUpdateViewerData, { payload: { itemId: item.id, itemStorage: fixedStorage } });
        }
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield cancel();
    }
}

function* openPdfEditorSaga(action) {
    const { id, storage } = action.payload;
    const { isFull: isOverquota } = yield select(UserSelectors.getCloudSpace);

    if (isOverquota) {
        openDisabledFeaturePopupHelper({ disabledFeature: DisabledFeature.newFile });
        return;
    }

    opener(`/pdf/edit?id=${encodeURIComponent(id)}&storage=${encodeURIComponent(storage)}`);
}

export function* watchViewerRoot() {
    yield takeEvery(openMobileViewerAction.toString(), openMobileViewer);
    yield takeEvery(openViewer.toString(), handleOpenViewer);
    yield takeEvery(updateViewerData.toString(), handleUpdateViewerData);
    yield takeLatest(closeViewer.toString(), handleCloseViewer);
    yield takeLatest(selectItemIndexInArray.toString(), handleSelectItemIndex);
    yield takeEvery(removeFileSuccess.toString(), handleDeleteFile);
    yield takeEvery(removeFromFavoritesSuccess.toString(), handleFavoritesDeleteFile);
    yield takeEvery(moveItemsSuccess.toString(), handleMoveItem);
    yield takeEvery(
        [loadMoreSuccess.type, feedLoadMoreSuccess.type, galleryLoadMoreSuccess.type, loadMorePublicFolderSuccess.type],
        handleLoadMoreSuccess
    );
    yield takeEvery(publishWeblink.toString(), handlePublish);
    yield takeEvery(unPublishWeblink.toString(), handleUnPublish);
    yield takeEvery(requestArchiveInfo.toString(), handleRequestArchiveInfo);
    yield takeEvery(getArchiveItemPreviewRequest.toString(), handleRequestArchiveItemInfo);
    yield takeEvery(downloadArchiveItemRequest.toString(), handleDownloadArchiveItemRequest);
    yield takeEvery(publishItem.toString(), handlePublishItem);
    yield takeLatest(startArchiveProlongTimer.toString(), handleStartArchiveProlongTimer);
    yield takeEvery(checkViewerAtIdChange.toString(), handleIdChange);
    yield takeEvery(openPdfEditor.toString(), openPdfEditorSaga);
}
