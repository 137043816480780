import React, { memo } from 'react';
import { IStoriesWidgetProps } from 'reactApp/ui/StoriesWidget/Stories.types';
import { noop } from 'reactApp/utils/helpers';
import { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { StoryItemComponent } from './StoriesItem/StoryItem';
import styles from './StoriesWidget.css';

export const StoriesWidgetComponent = memo(function Stub({
    stories,
    onItemClick,
    onNavigatorInit = noop,
    onSwipe = noop,
    onReachEnd = noop,
}: IStoriesWidgetProps): JSX.Element | null {
    return (
        <div className={styles.storiesContainer}>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={16}
                threshold={20}
                mousewheel={true}
                pagination={{
                    clickable: true,
                }}
                onSwiper={(swiper) => {
                    onNavigatorInit({
                        navigateToNext: () => swiper?.slideNext(),
                        navigateToPrev: () => swiper?.slidePrev(),
                        isFirst: () => swiper?.isBeginning,
                        isLast: () => swiper?.isEnd,
                    });
                }}
                onReachEnd={onReachEnd}
                onActiveIndexChange={onSwipe}
                modules={[Mousewheel]}
            >
                {stories.map((storyItem, idx) => (
                    <SwiperSlide key={storyItem.id} virtualIndex={idx} className={styles.slide}>
                        {() => {
                            return <StoryItemComponent item={storyItem} isFirst={idx === 0} onItemClick={onItemClick} />;
                        }}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
});
