import config from 'Cloud/config';
import store from 'lib/store';
import { xray } from 'lib/xray';
import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { unPublishHelper } from 'reactApp/appHelpers/publishHelper';
import { renderComfirmationDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { emptyImageTransparentUrl } from 'reactApp/constants';
import { getAttachPublicId } from 'reactApp/modules/attaches/attaches.helpers';
import { AttachesItem, EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import type { ViewedFile as EmbeddedViewedFile } from 'reactApp/modules/embedded/embedded.types';
import { isImage } from 'reactApp/modules/file/utils';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { EStoryItemType, EStoryType, IStoryFileItem } from 'reactApp/modules/stories/stories.types';
import { Kind } from 'reactApp/types/Tree';
import {
    AVAILABLE_ZOOM_STATES,
    PADDING_FOR_TOOLBARS,
    supportedImages,
    USED_THUMB_SIZE,
} from 'reactApp/ui/ReactViewer/ReactViewer.constants';
import { ZoomState } from 'reactApp/ui/ReactViewer/ReactViewer.types';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';
import { copy } from 'reactApp/utils/copyToClipboard';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

const KEY_STORAGE_FIT_CONTENT = 'viewerFitContent';

const getStoreKey = (email) => `${email}|${KEY_STORAGE_FIT_CONTENT}`;

// Получаем/сохраняем включен или нет режим вписывания картинки в размер просмотрщика без тулбара.
export const getFitContentOption = (email) => store.get(getStoreKey(email));
export const setFitContentOption = (email, isFitContent) =>
    isFitContent ? store.set(getStoreKey(email), true) : store.remove(getStoreKey(email));

// Получаем урл картинки для просмотрщика из тамбов или из url.view
export const getItemImageUrl = (file): string => {
    let url = '';
    if (file?.ext !== 'gif') {
        url = (file?.thumbnails && (file?.thumbnails.original || file?.thumbnails[USED_THUMB_SIZE])) || '';
    }
    if (!url && file.storage === EStorageType.story) {
        url = (file as IStoryFileItem)?.thumb?.xm0;
    }
    if (!url && supportedImages[file?.ext]) {
        url = file?.url?.view;
    }
    if (!url) {
        url = (file?.thumbnails && file?.thumbnails?.original) || file?.url?.view;
    }

    return url;
};

// Урл картинки для заглушки видео
export const getPosterUrl = (file): string => (file?.thumbnails && file?.thumbnails[USED_THUMB_SIZE]) || emptyImageTransparentUrl;

export const tryGetNearestIndexOfValue = (array, value) => {
    let idx = -1;
    array.forEach((zoom, index) => {
        if (zoom && value > zoom) {
            idx = index;
        }
    });
    return idx;
};

// Алгоритмы получения дефолтного состояния зума для картинки и списка доступных значений зума в зависимости от текущего состояния (какой контент, какой режим уже выбран).
export const getDefaultZoomIdx = (isFitContent?, isFitContentPresent?) => (isFitContentPresent ? (isFitContent ? 0 : 1) : 0);
export const calculateZoomValues = ({
    width, // размеры самой картинки
    height, // размеры самой картинки
    file,
    embedded,
    contentRect, // прямоугольник контента, в который надо вписать картинку
}): { range: ZoomState[]; hasFitContentRatio: boolean } => {
    const res: { range: number[]; hasFitContentRatio: boolean } = { range: [], hasFitContentRatio: false };

    if (!width || !height || !contentRect || !isImage(file)) {
        return res;
    }

    // Вьюпорт - прямоугольник, в который вписываем картинку, считаем от контента или окна.
    const viewportWidth = embedded ? contentRect?.width : window.innerWidth || document.documentElement.clientWidth;
    const viewportHeight = embedded ? contentRect?.height : window.innerHeight || document.documentElement.clientHeight;

    // ratio >= 100 - картинка больше вьюпорта, иначе меньше
    // Значение зума для исходного размера картинки.
    const ratio = Math.round(Math.min((100 * viewportHeight) / height, (100 * viewportWidth) / width));

    // Если картинка меньше размеров вьюпорта, то зум не доступен.
    if (ratio >= 100) {
        return res;
    }

    // Это для режима вписывания картинки в контент зону просмотрщика (без тулбаров сверху) FitContentOption
    // В обычном режиме картинка может наезжать на тулбар сверху.
    // При выборе этого значения в списке доступных зумов картинка не будет наезжать на тулбар.
    // Если оно есть, то идет самым первым.
    const ratioFitContent = Math.min(ratio, Math.round((100 * (viewportHeight - 2 * PADDING_FOR_TOOLBARS)) / height));

    // Тут пытаемся построить список доступных значений зума от текущего.
    // В списке будут доступные значения из AVAILABLE_ZOOM_STATES, исходный размер картинки и размер зума для FitContentOption
    // Проверяем, чтобы не было дублей (когда например дефолтный зум или зум исходного размера картинки равен одному из стандартных доступных AVAILABLE_ZOOM_STATES)
    // Значения зума меньше зума исходного размера самой картинки не нужны, так как опции уменьшать картинку нет.
    AVAILABLE_ZOOM_STATES.forEach((value) => {
        if (value && ratioFitContent < value) {
            if (res.range.length === 0) {
                res.range.push(ratioFitContent);
                if (ratio !== ratioFitContent) {
                    res.hasFitContentRatio = true;
                    if (!AVAILABLE_ZOOM_STATES.includes(ratio)) {
                        res.range.push(ratio);
                    }
                }
            }
            res.range.push(value);
        }
    });

    return res;
};

export const processItemPublish = ({ itemStorage, showSnackbar, publishItemAction, item, handleUnpublishSuccess }) => {
    if (itemStorage === EStorageType.public && itemStorage !== EStorageType.stock) {
        if (item?.home) {
            renderComfirmationDialog({
                dataQAId: 'confirm-unpublish',
                renderContent: () => `Вы действительно хотите отключить доступ по ссылке?`,
                onSuccess: () => {
                    unPublishHelper({ items: [item] });
                    handleUnpublishSuccess();
                    showSnackbar({
                        type: SnackbarTypes.success,
                        text: 'Ссылка успешно отключена',
                        id: 'unpublishLink',
                        closable: true,
                    });
                },
            });
        } else {
            copy(window.location.href)
                .then(({ hasBeenCopied }): void => {
                    if (hasBeenCopied) {
                        showSnackbar({
                            type: SnackbarTypes.success,
                            text: 'Ссылка успешно скопирована',
                            id: 'copyLink',
                            closable: true,
                        });
                    }
                })
                .catch(() =>
                    showSnackbar({
                        type: SnackbarTypes.failure,
                        text: 'Не удалось скопировать ссылку',
                        id: 'copyLink',
                        closable: true,
                    })
                );
        }
        return;
    }

    const params = {
        id: item.id,
        target: null,
        placement: tooltipPlacements.BOTTOM_RIGHT,
        offset: { x: 0, y: 7 },
        smartPlacement: false,
        autoPublish: (item && 'weblink' in item && !item.weblink) as boolean,
        gaSuffix: '_header',
    };

    publishItemAction({
        ...params,
        item,
        itemStorage,
        publishFrom: 'viewer',
    });
};

export interface IViewerDwhData {
    eventCategory?: ECategoryGa;
    action: string;
    label?: string;
    type_story?: EStoryType;
    type_content?: EStoryItemType | Kind;
    extension?: string;
    id_story?: string;
    id_media?: string;
    count_media?: number;
    source?: string;
    is_stories?: boolean;
    type_click?: 'next' | 'previous';
    type_reason?: string;
    storyId?: string;
    have_face?: boolean;
    have_faces?: boolean;
    is_archive?: boolean;
    id_public?: string;
    forceSend?: boolean;
    place?: 'touch' | 'web';
    section?: string;
    percent_close?: number;
    watch_percent?: number;
    error_message?: string;
    type_error?: string;
    type_change?: 'auto' | 'manual';
    quality_last?: number;
    quality_now?: number;
    sound_level_last?: number;
    sound_level_now?: number;
    speed_last?: number;
    speed_now?: number;
    type_move?: 'plus' | 'minus';
    is_full_render?: boolean;
    is_edit?: boolean;
    size_files?: number;
    type_stop?: 'user' | 'end';
    free_user?: boolean;
    paid_user?: boolean;
    pro_user?: boolean;
    name_files?: string;
    page_id?: string;
    req_id?: string;
    search_phrase?: string;
    count_files?: number;
    pos?: number | string;
    hash?: string;
    kind?: string;
    type?: string;
    mtime?: number;
    size?: number | string;
    iz_biz?: boolean;
}

export const sendViewerDwh = ({ action, eventCategory = ECategoryGa.viewer, label, ...data }: IViewerDwhData): void => {
    sendDwh({ eventCategory, action, label, dwhData: { iz_biz: IS_B2B_BIZ_USER, ...data } });
};

export const ATTACHES_DOCS_URL = '/attaches/documents';

export const getDocViewData = (
    file: AttachesItem,
    isWopi = false
): {
    login: string;
    o2_host: string;
    corsapi_host: string;
    ext: string;
    id: string;
    name: string;
    message_id?: string;
    content_type: string;
    size: number;
    viewer: string;
    temporary: string;
    wopiUrl?: string;
    public_id?: string;
    htmlencoded?: boolean;
} => {
    const o2_host = config.get('OAUTH_HOST');
    const corsapi_host = config.get('CORS_API_HOST');
    const login = config.get('user.email');
    const viewer = config.get('DOCUMENTS_VIEW_O2_VIEWER');
    const htmlencoded = !config.get('ON_PREMISE');

    const { ext, id, name, attachType, message_id, content_type, size, type } = file;

    const temporary = attachType === EAttachTypes.temporary ? 'true' : 'false';

    const wopiUrl = file?.editors?.myoffice_wopi?.url;

    // B2BCLOUD-543 Донести поле public_id при вызове documents/view для аттачей с типом cloud
    const public_id = type === EAttachTypes.cloud ? getAttachPublicId(id) : undefined;

    if (isWopi) {
        if (wopiUrl) {
            xray.send('got-myofficewopiurl');
        } else {
            xray.send('no-myofficewopiurl', {
                rlog: 'cloud_no-myofficewopiurl',
                rlog_message: {
                    fileId: id,
                    messageId: message_id,
                    wopiUrl: file?.editors?.myoffice_wopi,
                },
            });
        }
    }

    return {
        login,
        o2_host,
        corsapi_host,
        ext,
        id,
        name,
        message_id,
        content_type,
        size,
        viewer,
        temporary,
        wopiUrl,
        public_id,
        htmlencoded,
    };
};

const isSocialLogin = (login: unknown) => typeof login === 'string' && login.includes('@');

export const getDWHParamsOnEntityEvent = ({
    file,
    requestId: request_id,
    userId,
}: {
    file: EmbeddedViewedFile;
    requestId?: string;
    userId?: string;
}) => {
    const { ext, kind, name, size, url } = file;
    return {
        ext,
        kind,
        name,
        request_id,
        size,
        social_login: isSocialLogin(userId) ? userId : undefined,
        url: url.view,
    };
};

/**
 * Функция открытия редактора изображений (опросник).
 *
 * @param file {CloudFile | AttachesItem | IStoryFileItem | null} Файл изображения.
 * @param place {'zoomer' | 'toolbar'} Откуда открыт редактор: тулбар или зумер.
 * @param itemStorage {EStorageType | null} Откуда отправили радар: аттачи или просмотрщик.
 */
export const openImageEditor = ({
    file,
    itemStorage,
    place,
}: {
    file: CloudFile | AttachesItem | IStoryFileItem | null;
    place: 'zoomer' | 'toolbar';
    itemStorage?: EStorageType | null;
}) => {
    const data = {
        size_file: file?.size ?? 0,
        extension: file?.ext ?? '',
        id: file?.id ?? '',
        source: itemStorage,
    };

    sendDwh({
        eventCategory: ECategoryGa.photoEditor,
        action: 'open',
        dwhData: {
            ...data,
            place,
        },
    });

    openPopupHelper({
        popupName: popupNames.IMAGE_EDITOR,
        data,
    });
};

export const getFrameSource = (channelId: string): string | undefined => {
    const path = config.get('BUILD_URLS')?.pdfPrint;

    if (!path) {
        return undefined;
    }

    const hostname = window?.location?.hostname;
    const base = !path?.startsWith('https') ? `https://${hostname}` : '';

    return `${base}${path}?channelId=${channelId}&hostname=${hostname}`;
};
