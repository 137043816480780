import { parse } from 'qs';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import {
    INLINE_INTEGRATION_IFRAME_SRC,
    POPUP_INTEGRATION_IFRAME_SRC,
} from '../../../../public/inline-integration-iframe/components/CloudInterfaceWrapper/hooks/useCloudInterfaceLoader';

export const IntegrationRedirect = () => {
    const navigate = useNavigate();
    const { search } = useLocation();

    useEffect(() => {
        const currentParams = parse(search);
        const popupMode = Boolean(currentParams?.popupMode);
        navigate({
            pathname: `/${popupMode ? POPUP_INTEGRATION_IFRAME_SRC : INLINE_INTEGRATION_IFRAME_SRC}`,
            search,
        });
    }, []);

    return null;
};
