import cloudIcon from 'img/cloud_blue.svg?url';
import { PromoMobileApp } from 'reactApp/modules/promo/promo.types';
import { MobileAppPromoType } from 'reactApp/modules/promo/promoMobileApp/promoMobileApp.types';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { showMobileAppRQ } from 'reactApp/ui/AsidePromoModal/ShowMobileAppRQ';
import { sendDwh } from 'reactApp/utils/ga';

export const showPromoMobileAppSnackbar = ({
    promoData,
    type,
    url,
    from,
    isDarkPopup = false,
    popupText,
    popupTitle,
    bottom,
}: {
    promoData: PromoMobileApp;
    type: MobileAppPromoType;
    url: string;
    from: string;
    isDarkPopup?: boolean;
    popupTitle?: string;
    popupText?: string;
    bottom?: number;
}) =>
    showSnackbarAction({
        disableCloseTimeout: true,

        type: SnackbarTypes.success,
        id: 'mobile-app-promo',
        text: 'Облако в смартфоне — ещё больше места для важных файлов',
        buttonText: 'Установить приложение',
        closable: true,
        onShow: () => {
            promoData.onShow();
            promoData.onMarkShown(type);

            sendDwh({
                eventCategory: 'web_to_mobile',
                label: 'install_snackbar',
                dwhData: {
                    from,
                },
            });
        },
        thumb: {
            data: {
                src: cloudIcon,
            },
        },
        onButtonClick: () => {
            showMobileAppRQ({ bottom, promoData, type, url, from, isDarkPopup, popupText, popupTitle });
        },
    });
