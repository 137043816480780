/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { pickBy } from 'ramda';
import { useSelector } from 'react-redux';
import { IS_BIZ_USER, IS_BLOCKED, IS_PUBLIC_ALBUM, IS_PUBLIC_FOLDER } from 'reactApp/appHelpers/configHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { isAddToAlbumsEnabled, isCreatePublicAlbumsEnabled, isRootAlbumStorage } from 'reactApp/modules/albums/albums.selector';
import { isAlbum } from 'reactApp/modules/albums/albums.types';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { hasFolderFileWithFace } from 'reactApp/modules/faces/faces.selectors';
import { isDefaultRootContent } from 'reactApp/modules/home/home.selectors';
import { getPublicRootWeblink, isOwnPublic, isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isDomainFolder, isMountedFolder } from 'reactApp/modules/storage/folder.helpers';
import { STORAGE_CONFIG } from 'reactApp/modules/storage/storage.config.new';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import {
    getStorageCurrentFolder,
    getStorageItemById,
    getStorageSelectedItems,
    hasParentMountedOrSharedFolder,
    isMountedOrSharedFolder,
    isReadOnlyMountedOrShared,
    isRootCurrentFolder,
} from 'reactApp/modules/storage/storage.selectors';
import { CloudItem, EActions, EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { getCurrentGroup } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import { RootState } from 'reactApp/store';
import { UserQuotaGroupType } from 'reactApp/types/QuotaCleaner';
import { isItemsEmailUrlOk } from 'reactApp/ui/ContextMenu/createContextMenuList';

interface UseActionsConfig {
    showDisabled?: boolean;
    useOnlyCurrentFolder?: boolean;
    id?: string;
    storage?: EStorageType;
    skipUpload?: boolean;
    skipRename?: boolean;
    isMobile?: boolean;
}

export const useActionsConfig = ({
    showDisabled = false,
    useOnlyCurrentFolder = false,
    skipUpload = false,
    skipRename = false,
    storage,
    id,
    isMobile = false,
}: UseActionsConfig = {}): Partial<Record<EActions, boolean>> => {
    const actions: Partial<Record<EActions, boolean>> = {};

    const currentStorage = useSelector(getCurrentStorage);
    const settings = STORAGE_CONFIG[storage || currentStorage];

    const isBizUser = useSelector(UserSelectors.isBizUser);
    const isAnonymous = useSelector(UserSelectors.isAnonymous);

    const {
        isHome,
        isPublic,
        isAttaches,
        isStock,
        isSharedIncoming,
        isSharedLinks,
        isSharedAutodelete,
        isAlbums,
        isPdfEdit,
        isQuotaCleaner,
        isSearch,
    } = getStorage(storage ?? currentStorage);
    const storageItems = useSelector((state: RootState) => getStorageSelectedItems(state, storage ?? currentStorage)) as CloudItem[];
    const itemById = useSelector((state: RootState) => {
        return getStorageItemById(state, storage ?? currentStorage, id);
    });
    const items = useOnlyCurrentFolder ? [] : itemById ? [itemById] : storageItems;
    const isSelectedMounted = items.some(isMountedFolder);
    const selectedCount = items.length;
    const oneItem = selectedCount === 1;
    const isOneItemFolder = oneItem && items[0].isFolder;
    const itemsWithWeblink = items.some((item) => 'weblink' in item && item?.weblink);
    const itemsCloudAttaches = isAttaches && items.some((item) => 'attachType' in item && item?.attachType === EAttachTypes.cloud);
    const currentFolder = useSelector((state: RootState) => getStorageCurrentFolder(state, storage ?? currentStorage));
    const isOwn = useSelector(isOwnPublic);
    const isMountedOrSharedFolderStatus = useSelector((state: RootState) => isMountedOrSharedFolder(state, currentFolder));
    const isCurrentSharedOrMounted = isMountedOrSharedFolderStatus.is;
    const isReadOnly = useSelector((state: RootState) => isReadOnlyMountedOrShared(state, currentFolder));
    const isUploadEnabled = useSelector(isPublicUploadEnabled);
    const itemsOrCurrentFolder = items.length ? items : [currentFolder];
    const isPublicAlbumEnabled = useSelector((state: RootState) => isCreatePublicAlbumsEnabled(state, itemsOrCurrentFolder));
    const isItemAlbum = (oneItem && items.every(isAlbum)) || (selectedCount === 0 && isAlbum(currentFolder));
    const { weblink = '' } = useSelector(getPublicRootWeblink) || {};
    const hasFaces = useSelector((state: RootState) => hasFolderFileWithFace(state, weblink));
    const isAlbumRoot = useSelector(isRootAlbumStorage);
    const isAddToAlbumsAvailable = useSelector((state: RootState) => isAddToAlbumsEnabled(state, itemsOrCurrentFolder));
    const isEmptyFolder = (currentFolder?.childs?.length || 0) === 0;
    const isDefaultContent = useSelector(isDefaultRootContent);
    const isNotRootFolder = Boolean(currentFolder) && currentFolder?.id !== ROOT_FOLDER_ID;
    const itemsWithDomain = items.length ? items.some(isDomainFolder) : currentFolder && isDomainFolder(currentFolder);
    const hasParentMountedOrShared = useSelector((state: RootState) => hasParentMountedOrSharedFolder(state, items));
    const activeDownloadInFavouritesForBizUser = IS_BIZ_USER && (storage ?? currentStorage) === EStorageType.favorites;
    const isRootFolder = useSelector((state: RootState) => isRootCurrentFolder(state, currentStorage));
    const currentGroup = useSelector(getCurrentGroup);

    if (settings?.facesFilter) {
        actions[EActions.facesFilter] = hasFaces && Boolean(IS_PUBLIC_FOLDER);
    }

    if (settings?.back && selectedCount && !isAlbumRoot) {
        actions[EActions.back] = true;
    }

    if (settings?.selectAll) {
        let selectingAvailable = Boolean(currentFolder);

        if (isSharedAutodelete) {
            selectingAvailable = !isEmptyFolder;
        }

        if (isAlbums) {
            selectingAvailable = selectingAvailable && currentFolder?.id !== ROOT_FOLDER_ID;
        }

        actions[EActions.selectAll] = selectingAvailable;
    }

    if (settings?.restoreAll && !isBizUser) {
        actions[EActions.restoreAll] = !selectedCount && !isEmptyFolder;
    }

    if (settings?.restore) {
        actions[EActions.restore] = Boolean(selectedCount);
    }

    if (settings?.clear) {
        actions[EActions.clear] = !isEmptyFolder;
    }

    if (settings?.createFolder) {
        actions[EActions.createFolder] = !isReadOnly;
    }

    if (settings?.publish && !IS_BLOCKED) {
        const canPublishAlbum = !isAlbums && isPublicAlbumEnabled;
        let publishAvailable = (oneItem && !canPublishAlbum) || (isPdfEdit && !!itemById);

        if (isHome) {
            publishAvailable = publishAvailable || (!selectedCount && isNotRootFolder);
        }

        if (isAlbums) {
            publishAvailable = isItemAlbum && !isAlbumRoot && isPublicAlbumEnabled;
        }

        if (isPublicAlbumEnabled) {
            actions[EActions.publishAlbum] = true;
        }

        if (itemsCloudAttaches) {
            publishAvailable = false;
        }

        actions[EActions.publish] = publishAvailable;

        if ((IS_BIZ_USER && selectedCount >= 1 && itemsWithWeblink) || (!publishAvailable && itemsWithWeblink)) {
            actions[EActions.unpublish] = true;
        }
    }

    if (isAddToAlbumsAvailable && !isAlbumRoot) {
        actions[EActions.addToAlbum] = true;
    }

    if (settings?.share) {
        let shareAvailable = isOneItemFolder;

        if (isHome && isNotRootFolder) {
            shareAvailable =
                !itemsWithDomain &&
                (isCurrentSharedOrMounted
                    ? // нельзя "Настроить доступ" у подпапок примонтированной папки
                      !selectedCount && isMountedOrSharedFolderStatus.folderId === currentFolder?.id
                    : !selectedCount || isOneItemFolder);
        } else if (isSharedLinks) {
            shareAvailable = isOneItemFolder;
        } else if (isSharedIncoming) {
            shareAvailable = isOneItemFolder && isSelectedMounted;
        } else if (isSearch) {
            shareAvailable = !hasParentMountedOrShared && isOneItemFolder;
        }

        actions[EActions.share] = shareAvailable;
    }

    if (settings?.shareLink) {
        actions[EActions.shareLink] = oneItem || !selectedCount;
    }

    if (settings?.clone && !isOwn && !itemsCloudAttaches && !IS_BLOCKED) {
        actions[EActions.clone] = isAttaches ? selectedCount > 0 : true;
    }

    if (settings?.download || activeDownloadInFavouritesForBizUser) {
        let downloadAvailable = !!selectedCount;

        if (isAttaches) {
            downloadAvailable = Boolean(selectedCount) && isItemsEmailUrlOk(items);
        } else if (isHome) {
            downloadAvailable = (!isEmptyFolder && !isDefaultContent && !isRootFolder) || (isRootFolder && !!selectedCount);
        } else if (isStock || isPublic) {
            downloadAvailable = !isEmptyFolder;
        } else if (isSharedIncoming) {
            downloadAvailable = isSelectedMounted;
        } else if (isAlbums) {
            downloadAvailable = !isAlbumRoot && !isEmptyFolder;
        } else if (isQuotaCleaner) {
            if (selectedCount > 1 && currentGroup?.groupType === UserQuotaGroupType.Letter) {
                downloadAvailable = false;
            } else {
                downloadAvailable = true;
            }
        }

        actions[EActions.download] = downloadAvailable;
    }

    if (settings?.sendViaMail) {
        actions[EActions.sendViaMail] = Boolean(selectedCount) && isItemsEmailUrlOk(items);
    }

    if (settings?.add) {
        actions[EActions.upload] = ((isPublic && (isOwn || isUploadEnabled)) || !isPublic) && !skipUpload;
    }

    if (settings?.remove) {
        let removeAvailable = Boolean(selectedCount) && !itemsWithDomain;

        if (isHome) {
            removeAvailable =
                !itemsWithDomain &&
                ((selectedCount && !isReadOnly) ||
                    (!selectedCount && !isReadOnly && isNotRootFolder) ||
                    (!selectedCount && isReadOnly && isCurrentSharedOrMounted));
        } else if (isPublic) {
            if (isMobile) {
                removeAvailable = IS_PUBLIC_ALBUM
                    ? removeAvailable && isOwn && !isItemAlbum
                    : (!itemById && Boolean(selectedCount) && isOwn) ||
                      (Boolean(itemById) && !itemById?.isFolder && (isOwn || isUploadEnabled));
            } else {
                removeAvailable = IS_PUBLIC_ALBUM ? removeAvailable && isOwn : removeAvailable && isUploadEnabled && !isAnonymous;
            }
        } else if (isSharedIncoming) {
            removeAvailable = removeAvailable && isOneItemFolder;
        } else if (isAlbums) {
            removeAvailable = !isAlbumRoot;
        }

        actions[EActions.remove] = removeAvailable;
    }

    if (settings?.favorites && !isMountedOrSharedFolderStatus.isMounted) {
        const allInFavorites = (items as CloudItem[]).every((item) => 'isInFavorites' in item && item.isInFavorites);
        const mountedItems = (items as CloudItem[]).filter((item) => 'isMounted' in item && item.isMounted && !item.isFolder);

        actions[EActions.addToFavorites] = isAlbumRoot ? false : !allInFavorites && !mountedItems.length && !hasParentMountedOrShared;
        actions[EActions.removeFromFavorites] = isAlbumRoot ? false : Boolean(selectedCount) && allInFavorites;
    }

    if (settings?.mount) {
        actions[EActions.mount] = !isSelectedMounted && oneItem;
    }

    if (settings?.copy && !itemsWithDomain) {
        let copyAvailable = Boolean(selectedCount);

        if (isHome) {
            copyAvailable = copyAvailable || isNotRootFolder;
        }

        actions[EActions.copy] = copyAvailable;
    }

    if (settings?.move) {
        let moveAvailable = Boolean(selectedCount) && !isReadOnly && !itemsWithDomain;

        if (isHome) {
            moveAvailable =
                moveAvailable ||
                (!selectedCount && ((isNotRootFolder && !itemsWithDomain && !isReadOnly) || (isReadOnly && isCurrentSharedOrMounted)));
        }

        actions[EActions.move] = moveAvailable;
    }

    if (settings?.rename) {
        let renameAvailable = oneItem && !isReadOnly && !itemsWithDomain;

        if (isHome) {
            renameAvailable =
                renameAvailable ||
                (!selectedCount && ((isNotRootFolder && !itemsWithDomain && !isReadOnly) || (isReadOnly && isCurrentSharedOrMounted)));
        }

        if (isAlbums) {
            renameAvailable = isItemAlbum && !isAlbumRoot;
        }

        actions[EActions.rename] = renameAvailable && !skipRename;
    }

    if (settings?.history) {
        actions[EActions.history] = oneItem && !isOneItemFolder && !isReadOnly;
    }

    if (isAlbumRoot) {
        actions[EActions.createAlbum] = true;
    }

    return showDisabled ? actions : pickBy(Boolean, actions);
};
