import { Failure } from '../Failure';

class ClientError extends Failure {
    static radarName = 'failure_{SOURCE}_abstract';

    className = 'ClientError';

    /**
     * @constructor
     * @extends {UploadReason}
     * @param {Error} stack
     * @param {string} source
     */
    constructor({ source }) {
        super(...arguments);

        if (!(this instanceof ClientError)) {
            throw new TypeError(ClientError.ERROR_WRONG_INSTANCE);
        }

        if (this.constructor === ClientError) {
            throw new TypeError(ClientError.ERROR_ABSTRACT_CLASS);
        }
    }

    static ERROR_WRONG_INSTANCE = 'ClientError called on non-instance of ClientError';
    static ERROR_ABSTRACT_CLASS = 'ClientError is abstract class';
}

export { ClientError };
