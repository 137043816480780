import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { addItemsFromCloudToAlbumRequest } from 'reactApp/modules/albums/albums.actions';
import { CloudIcon } from 'reactApp/ui/VKUIIcons';

import styles from '../EmptyFolder.css';

export const useSelectFromCloud = (id: string) => {
    const dispatch = useDispatch();
    
    const Create = useMemo(() => (
        <div className={styles.icon}>
            <CloudIcon />
        </div>
    ), []);

    const handleClickCreateNew = useCallback(() => {
        dispatch(addItemsFromCloudToAlbumRequest({ id }));
    }, [id]);

    return useCallback(() => {
        return {
            title: 'Выбрать из Облака',
            annotation: 'Добавьте в альбом уже загруженные в Облако фотографии и видео',
            onClick: handleClickCreateNew,
            Icon: Create,
        }
    }, [Create, handleClickCreateNew]);
};
