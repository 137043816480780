import { EStorageType } from 'reactApp/modules/storage/storage.types';

export interface IProps {
    onClose?: () => void;
    itemStorage?: EStorageType;
    id: string;
    from: EFrom;
    gaSuffix?: string;
    isPhone?: boolean;
}

export enum ExpiryPeriod {
    Unlimited = 'Unlimited',
    Hour = 'Hour',
    Day = 'Day',
    Week = 'Week',
    Month = 'Month',
    Months3 = 'Months3',
    HalfYear = 'HalfYear',
    Year = 'Year',
    Expiry = 'Expiry',
}

export enum AutoDeletePeriod {
    None = 'None',
    Day = 'Day',
    Week = 'Week',
    Month = 'Month',
    Months3 = 'Months3',
    HalfYear = 'HalfYear',
    Year = 'Year',
    Autodeleting = 'Autodeleting',
}

export interface IExpiresOptions {
    id: ExpiryPeriod;
    text: string;
    getValue: () => number;
}

export interface IAutoDeleteOptions {
    id: AutoDeletePeriod;
    text: string;
    getValue: () => number;
}

export enum EAccessRights {
    DELETE_USER = 'delete_user',
    UNMOUNT_USER = 'unmount_user',
    READ_WRITE = 'read_write',
    READ_ONLY = 'read_only',
}

export interface IAccessRightsOptions {
    text: string;
    key: EAccessRights;
}

export enum AccessStatus {
    ReadOnly = 'ReadOnly',
    Edit = 'Edit',
    Upload = 'Upload',
}

export enum DomainAccess {
    DOMESTIC = 'domestic',
    EXTERNAL = 'external',
}

export enum EFrom {
    WEBLINK = 'weblink',
    SHARING = 'sharing',
    ALBUMS = 'albums',
}

export interface IInfo {
    access: string;
    name?: string;
    home?: string;
    owner: {
        email: string;
    };
}
