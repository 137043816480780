/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { EditorID } from 'Cloud/Application/Editor/types';
import { getFeature } from 'Cloud/Application/FeaturesEs6';
import browser from 'Cloud/browser';
import config from 'Cloud/config';
import { path, pathEq } from 'ramda';
import { connect } from 'react-redux';
import { removeItems } from 'reactApp/appHelpers/appHelpers';
import {
    HIDE_ADS,
    IS_BIZ_USER,
    IS_BLOCKED,
    IS_FAMILY_USER,
    IS_FREE_BIZ_SAAS_USER,
    IS_MOBILE_OR_TABLET_BROWSER,
    IS_ONPREMISE,
    IS_TABLET_BROWSER,
    MYOFFICE_ATTACHES_VIEWER,
    VIEWERS_CONFIG,
} from 'reactApp/appHelpers/configHelpers';
import { isWopiEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { clearRenamedFileIdInsideViewer, getRenamedFileIdInsideViewer } from 'reactApp/appHelpers/updateRenamedFileInsideViewer';
import { isFileAvailableForCreateEditableCopy } from 'reactApp/components/CreateCopyOfNoneditableFileModal/helper';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import { AttachesItem, EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { isDialogVisible } from 'reactApp/modules/dialog/dialog.selectors';
import { getEditorsFor } from 'reactApp/modules/editor/editor.selectors';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { selectFace } from 'reactApp/modules/faces/faces.module';
import { getFacesOnFile } from 'reactApp/modules/faces/faces.selectors';
import { IFaceWithCoordinates } from 'reactApp/modules/faces/faces.types';
import { FavoritesSelectors } from 'reactApp/modules/favorites/favorites.selectors';
import {
    getAstraMetaFeature,
    getFeatureAttachesEnabled,
    getFeatureEditingNoneditableFormats,
    getFeatureMyOfficeViewerPromo,
    getFeatureNewAdFormat,
    getFeaturePdfEdit,
    getFeaturesAttachesTrialPromo,
    getFeatureShowEditBtnForSharedFolder,
    getFeatureShowEditButtonForAttach,
} from 'reactApp/modules/features/features.selectors';
import {
    getParent,
    getWeblinkFromPublicId,
    isDocument,
    isFileForPlayer,
    isImage,
    isPdfEditable,
    isPreviewable,
    isVideo,
} from 'reactApp/modules/file/utils';
import { isReadOnly, isThisOrParentMounted } from 'reactApp/modules/home/home.selectors';
import { Item } from 'reactApp/modules/home/home.types';
import { addToFavorites, removeFromFavorites } from 'reactApp/modules/modifying/modifying.actions';
import { isOwnPublic } from 'reactApp/modules/public/public.selectors';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { newSendSearchRadarAnalytics } from 'reactApp/modules/search/search.analytics';
import { getSearchAnalyticsParams } from 'reactApp/modules/search/search.selectors';
import { setBottomMarginAction, showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { StockItem } from 'reactApp/modules/stock/stock.type';
import { getStorage, isIntegrationStorage } from 'reactApp/modules/storage/storage.helpers';
import {
    getItemById,
    getStorageItemById,
    hasParentMountedOrSharedFolder,
    isMountedOrSharedFolder,
} from 'reactApp/modules/storage/storage.selectors';
import { CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { markStoryAsViewed as markStoryAsViewedAction } from 'reactApp/modules/stories/stories.module';
import { getAdjacentStoryIds, getStoryForViewer } from 'reactApp/modules/stories/stories.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageSelectors } from 'reactApp/modules/user/userStorage';
import {
    closeViewer,
    downloadArchiveItemRequest,
    openPdfEditor,
    publishItem,
    selectItemIndexInArray as selectItemIndexInArrayAction,
    setOvidiusZoom,
} from 'reactApp/modules/viewer/viewer.module';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { isVisiblePromo } from 'reactApp/ui/MyOfficePromo/ViewerSnackbar/ViewerSnackbarPromoContainer';
import { NUMBER_TO_PRELOAD } from 'reactApp/ui/ReactViewer/ReactViewer.constants';
import { getFitContentOption, getItemImageUrl, getPosterUrl, setFitContentOption } from 'reactApp/ui/ReactViewer/ReactViewer.helpers';
import { ReactViewerComponent } from 'reactApp/ui/ReactViewer/ReactViewerComponent';
import { TRIAL_ATTACHES_BANNER_ID } from 'reactApp/ui/TrialPromoBanner/TrialPromoBanner';
import { noopVoid } from 'reactApp/utils/helpers';
import { closeViewerUrl } from 'reactApp/utils/urlHelper';
import { getAvailableEditorsFor } from 'server/helpers/editors/getEditors';
import { ServerEditor } from 'server/helpers/editors/types';

/**
 * TypeGuard: проверка на то, что файл аттач любого вида
 */
export const isAttachOrStockFile = (file: CloudItem): file is AttachesItem | StockItem => {
    return (
        ([EStorageType.attaches, EStorageType.viewerAttaches].includes(file?.storage) &&
            [EAttachTypes.attach, EAttachTypes.cloud, EAttachTypes.temporary, EAttachTypes.cloudStock].includes(
                (file as AttachesItem)?.attachType
            )) ||
        file?.storage === EStorageType.stock
    );
};

const r7WopiFeature = getFeature('r7wopi');

const isR7WopiFeatureAvailable =
    typeof r7WopiFeature === 'object' && (typeof r7WopiFeature?.edit?.length || typeof r7WopiFeature?.view?.length);

const replaceViewer = (viewer: ServerEditor) => VIEWERS_CONFIG.filter((v) => v.id !== EditorID.R7_WOPI).concat(viewer);

// eslint-disable-next-line max-lines-per-function
const mapStateToProps = (state, props) => {
    const promoTrialFeatures = getFeaturesAttachesTrialPromo(state);
    const file = ViewerSelectors.getViewerItem(state);
    const indexInArray = ViewerSelectors.getViewerItemIndexInArray(state);
    const itemStorage = ViewerSelectors.getViewerItemStorage(state) ?? file?.storage;
    const isActionOpen = ViewerSelectors.isViewerActionOpen(state);
    const ids = ViewerSelectors.getViewerItemIds(state);
    const itemCount = ViewerSelectors.getViewerItemCount(state);
    const hasMoreToLoad = ViewerSelectors.hasMoreToLoad(state);
    const gaSuffix = ViewerSelectors.getViewerGa(state);
    const activeArchiveItem = ViewerSelectors.getArchiveActiveItem(state);
    const ovidiusZoom = ViewerSelectors.getOvidiusZoom(state, file?.id);
    const isAuthorized = !UserSelectors.isAnonymous(state);
    const isAttachesEnabled = !!getFeatureAttachesEnabled(state);
    const preloadUrls: { url: string; id: string }[] = [];

    const editingNoneEditableFormats =
        !!file && !!itemStorage && isFileAvailableForCreateEditableCopy(file) && !(browser.isIpadOs() || IS_TABLET_BROWSER)
            ? getFeatureEditingNoneditableFormats(state, { file, storage: itemStorage })
            : undefined;
    const isEditable =
        getEditorsFor(
            state,
            editingNoneEditableFormats
                ? {
                      ...file,
                      ext: editingNoneEditableFormats.ext,
                  }
                : file
        )?.length > 0;

    const email = UserSelectors.getEmail(state);
    const searchParams = getSearchAnalyticsParams(state);
    const isOwner = isOwnPublic(state);
    const isAttaches = itemStorage === EStorageType.attaches || itemStorage === EStorageType.viewerAttaches;
    const attachesSearchState = AttachesSelectors.getSearchState(state);
    const attachesQuery = attachesSearchState?.query;
    const attachesSearchedItem = file && !!attachesSearchState && AttachesSelectors.getAttachById(state, file.id);
    const attachesSearchedItemPos = attachesSearchedItem ? attachesSearchedItem?.pos : undefined;
    const isDialogShown = isDialogVisible(state);
    const isMountedFolder = isThisOrParentMounted(state, file?.parent);
    const hideAdsUsersNonCorp =
        (UserSelectors.isPaidUser(state) || UserSelectors.isBizUser(state) || IS_FAMILY_USER) && !UserSelectors.isCorpUser(state);
    const hideAdsbyMailFlag = UserSelectors.isCorpUser(state) && UserSelectors.isHideAdInMail(state);
    const hideAdsOnAttaches = hideAdsbyMailFlag || hideAdsUsersNonCorp;
    const showBottomAds = (itemStorage === EStorageType.public || itemStorage === EStorageType.stock) && !HIDE_ADS;
    const isNewAdFormat = getFeatureNewAdFormat(state);
    const disableClose = props.disableClose || IS_BLOCKED;
    const isPublic = itemStorage === EStorageType.public;
    const isStock = itemStorage === EStorageType.stock;
    const isAttach = itemStorage === EStorageType.attaches || itemStorage === EStorageType.viewerAttaches;
    const isSearch = itemStorage === EStorageType.search;
    const attachType = file && 'type' in file && file.type;
    const isAttachesCloudStock = isAttach && attachType === EAttachTypes.cloudStock;
    const isAttachesCloud = isAttach && attachType === EAttachTypes.cloud;

    /** TODO: CLOUDWEB-16228 сохранить возможность использовать просмотрщики
     *  по умолчанию на аттачах
     */
    const isAttachOrStock = file && isAttachOrStockFile(file);
    const isR7WopiEditorActive = isR7WopiFeatureAvailable && VIEWERS_CONFIG.find((v) => v.id !== EditorID.R7_WOPI);
    const viewers = isAttachOrStock && isR7WopiEditorActive ? replaceViewer(MYOFFICE_ATTACHES_VIEWER) : VIEWERS_CONFIG;

    const featureMyOfficePromoData = getFeatureMyOfficeViewerPromo(state);
    const viewerApi =
        file &&
        getAvailableEditorsFor({
            item: file,
            editors: viewers,
            isEdit: false,
            storage: itemStorage,
            isWopiEnabled,
        })[0];
    const isMyOfficePromo = isVisiblePromo(viewerApi, featureMyOfficePromoData);
    const isTablet = IS_TABLET_BROWSER || browser.isIpadOs();

    /**
     * TODO Убрать костыль-проверку на editingNoneEditableFormats, когда будем рефакторить овидиус.
     * Сейчас мы не можем добавить расширение, которое не является документом, например, .txt
     * На данном этапе добавлена эта проверка, чтобы отображать кнопку редактирования для недокументов для дальнейшей конвертации.
     */
    const isPublicCopyEditable = isPublic && !isTablet && ((isEditable && isDocument(file)) || editingNoneEditableFormats);
    const trialWasShown = UserStorageSelectors.get(state, TRIAL_ATTACHES_BANNER_ID);
    const showTrialPromo =
        !trialWasShown && Boolean(file) && !isMyOfficePromo && isAttaches && promoTrialFeatures && !isFileForPlayer(file);
    const isNewbie = UserSelectors.isNewbie(state);

    let faces: IFaceWithCoordinates[] = [];
    if (itemStorage === EStorageType.public) {
        faces = getFacesOnFile(state, file?.id?.replace(getWeblinkFromPublicId(file?.id) ?? '', ''));
    }

    let isPreviewableItem = isPreviewable(file, itemStorage, viewers);

    if (isAttaches && pathEq(['subKind'], 'archive', file) && !isAttachesCloudStock && !isAttachesCloud) {
        // В аттачах все просматриваем, идем в почтовое апи
        // На клауд стоки только зип, и  нужный isPreviewableItem уже выставлен.
        isPreviewableItem = attachType !== EAttachTypes.temporary;
    }

    if (isPreviewableItem && file?.kind === 'document' && isAttaches && !isAttachesCloudStock && !isAttachesCloud) {
        isPreviewableItem = config.get('DOCUMENTS_VIEW_O2_VIEWER')?.length > 0;
    }

    // Файл не надо увеличивать/уменьшать, если мы его не показываем
    const disableZoom = !isPreviewableItem || itemStorage === EStorageType.story;

    // Не показываем кнопку редактирования на аттачах типа cloud и cloud_stock
    const isAttachType = attachType === EAttachTypes.attach;
    const showEditButtonAttachCondition = isAttaches ? isAttachType && getFeatureShowEditButtonForAttach(state) : true;

    // Условие показа кнопки редактирования для файлов в шаренных папках
    const sharedFolderState = isMountedOrSharedFolder(state, file);
    const sharedFolderFile = sharedFolderState.isMounted;
    const sharedFolderFileBtnFeature = getFeatureShowEditBtnForSharedFolder(state);

    /**
     * Показ кнопки редактирования на mounted и (public не для владельцев) папках всегда регулируется фичей,
     * а для шареных папок показываем всегда (шареных значит для владельцев показываем всегда)
     */
    const showEditButtonForFileInSharedFolder = sharedFolderFile ? sharedFolderFileBtnFeature : true;

    // B2BCLOUD-416 скрываем кнопку для файлов из поиска, в случае, если они находятся в readonly папке
    const homeFileFolder = getParent(file?.home || '/');
    const isReadonlyHomeFolder = isReadOnly(state, homeFileFolder);
    const showEditButtonInRoFolderFromSearch = IS_BIZ_USER && isSearch ? !isReadonlyHomeFolder : true;
    // Выключаем кнопку на ONPREMISE, если папка readOnly
    const { folderId, isMounted } = isMountedOrSharedFolder(state, file);
    const folder = folderId && getItemById(state, folderId);
    const disableButtonInReadOnlyFolder = isMounted && folder && 'isReadOnly' in folder && folder.isReadOnly && IS_ONPREMISE;

    const item = (activeArchiveItem ?? file) as Item;
    const hasParentMountedOrShared = item && hasParentMountedOrSharedFolder(state, [item]);
    /**
     * От этого параметра зависит показ кнопки "Редактировать" поэтому отображаем ее, даже когда просмотр файла
     * не поддерживается (отображается заглушка), но этот файл является конвертируемым.
     */
    const allowEdit =
        !isPublic &&
        !isStock &&
        (isPreviewableItem || editingNoneEditableFormats) &&
        !path(['isReadOnly'], file) &&
        showEditButtonAttachCondition &&
        showEditButtonForFileInSharedFolder &&
        showEditButtonInRoFolderFromSearch &&
        !disableButtonInReadOnlyFolder &&
        !IS_FREE_BIZ_SAAS_USER;
    /**
     * TODO Убрать костыль-проверку на editingNoneEditableFormats, когда будем рефакторить овидиус.
     * Сейчас мы не можем добавить расширение, которое не является документом, например, .txt
     * На данном этапе добавлена эта проверка, чтобы отображать кнопку редактирования для недокументов для дальнейшей конвертации.
     */
    const showEditButton = allowEdit && isEditable && (isDocument(file) || editingNoneEditableFormats);
    const showPdfEditButton =
        !isIntegrationStorage(itemStorage) &&
        allowEdit &&
        isPdfEditable(file) &&
        getFeaturePdfEdit(state) &&
        !(IS_MOBILE_OR_TABLET_BROWSER || EnvironmentSelectors.isIpadOs());

    // TODO: Move preloadUrls update to store or saga?
    // in viewer start and selectIndex?
    if (props.enablePreload && indexInArray !== null && itemStorage && ids) {
        for (let i = indexInArray; i < indexInArray + NUMBER_TO_PRELOAD && i < ids.length; i++) {
            const item = getStorageItemById(state, itemStorage, ids[i]);

            preloadUrls.push({
                url: isVideo(item) ? getPosterUrl(item) : isImage(item) && isPreviewable(item, itemStorage) ? getItemImageUrl(item) : '',
                id: item?.id || '',
            });
        }
    }

    let backItemIndexInArray: number | null = null;
    let forwardItemIndexInArray: number | null = null;
    if (indexInArray !== null && indexInArray !== undefined) {
        backItemIndexInArray = !!ids && indexInArray > 0 ? indexInArray - 1 : null;
        forwardItemIndexInArray = !!ids && indexInArray >= 0 && indexInArray + 1 < ids.length ? indexInArray + 1 : null;

        if (itemStorage === EStorageType.story) {
            const { prevStoryId, nextStoryId } = getAdjacentStoryIds(state);
            backItemIndexInArray = backItemIndexInArray ?? (prevStoryId ? -1 : null);
            forwardItemIndexInArray = forwardItemIndexInArray ?? (nextStoryId ? ids?.length ?? null : null);
        }

        // Для интеграции выключаем возможость листать слайды, пока не будет поддержки всех файлов
        if (isIntegrationStorage(itemStorage)) {
            backItemIndexInArray = null;
            forwardItemIndexInArray = null;
        }
    }

    let storyType, bottomTitle, bottomSubTitle, topSubTitle, topTitle, storyId, isStoryViewed;
    if (itemStorage === EStorageType.story) {
        const data = getStoryForViewer(state, file?.id);
        storyType = data?.type;
        bottomTitle = data?.bottomTitle;
        bottomSubTitle = data?.bottomSubTitle;
        topTitle = data?.topTitle;
        topSubTitle = data?.topSubTitle;
        storyId = data?.id;
        isStoryViewed = data?.viewed;
    }

    const isSearchRadar = itemStorage === EStorageType.search || (!!attachesSearchedItem && isAttaches && !!attachesQuery);

    const astraMetaFeature = getAstraMetaFeature(state);

    return {
        file,
        viewers,
        activeArchiveItem,
        ids,
        indexInArray,
        itemStorage,
        backItemIndexInArray,
        forwardItemIndexInArray,
        preloadUrls,
        itemCount,
        hasMoreToLoad,
        gaSuffix: gaSuffix ? `_${gaSuffix}` : '',
        isActionOpen,
        isPreviewableItem,
        isAuthorized,
        isPublicCopyEditable,
        isAttaches,
        isNewbie,
        getFitContentState: () => getFitContentOption(email),
        setFitContentState: (isFitContent) => setFitContentOption(email, isFitContent),
        sendSearchRadar: ({ dwhData, items }) => {
            if (isAttaches) {
                dwhData.attaches_query = attachesQuery;
            }

            newSendSearchRadarAnalytics({
                ...dwhData,
                searchParams,
                items,
            });
        },
        isAttachesEnabled,
        isDialogShown,
        isMountedFolder,
        hideAdsOnAttaches,
        showBottomAds,
        showEditButton,
        sharedFolderFile,
        sharedFolderFileBtnFeature,
        isEditable,
        editingNoneEditableFormats,
        storyId,
        storyType,
        bottomTitle,
        bottomSubTitle,
        topSubTitle,
        topTitle,
        disableZoom,
        isStoryViewed,
        faces,
        isNewAdFormat,
        isSearchRadar,
        showPdfEditButton,
        attachesSearchedItemPos,
        disableClose,
        isOwner,
        showTrialPromo,
        hasParentMountedOrShared,
        ovidiusZoom,
        astraMetaFeature,
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    showSnackbar: (data) => dispatch(showSnackbarAction(data)),
    publishItemAction: (data) => dispatch(publishItem(data)),
    markStoryAsViewed: ({ storyId, lastViewedId }) =>
        dispatch(
            markStoryAsViewedAction({
                storyId,
                lastViewedId,
            })
        ),
    selectItemIndexInArray: ({ itemIndex }) => dispatch(selectItemIndexInArrayAction({ itemIndex })),
    toggleFavorite: (item): void => {
        dispatch((_, getState) => {
            const state = getState();
            const storage = getCurrentStorage(state);

            const { isFavorites } = getStorage(storage);

            const favoritesStatistics = FavoritesSelectors.getFavoritesStatistics(state);

            /*
                Если в избранном всего один файл,
                то при удалении его из избранного через viewer нужно подчистить урл
            */
            if (isFavorites && item.isInFavorites && favoritesStatistics.all === 1) {
                const closeUrl = closeViewerUrl(location);
                // TODO: routing
                dispatch(historyPush({ id: closeUrl }));
            }

            if (item.isInFavorites) {
                dispatch(removeFromFavorites({ items: [item] }));
            } else {
                dispatch(
                    addToFavorites({
                        items: [item],
                        from: 'viewer',
                    })
                );
            }
        });
    },
    deleteFile: (item: CloudItem, itemStorage?: EStorageType, onSuccess = noopVoid) =>
        removeItems({
            items: [item],
            onSuccess,
        }),
    onClose: () => {
        if (typeof props.onClose === 'function') {
            props.onClose();
        }
        dispatch(closeViewer(props.file?.id));
    },
    downloadArchiveItemAction: (data) => dispatch(downloadArchiveItemRequest(data)),
    selectFace: (id) => {
        dispatch(selectFace({ id }));
    },
    getRenamedFileIdInsideViewer: () => dispatch(getRenamedFileIdInsideViewer()),
    clearRenamedFileIdInsideViewer: () => dispatch(clearRenamedFileIdInsideViewer()),
    openPdfEditor: (file: any) => {
        if (!file?.home) {
            return;
        }
        dispatch(openPdfEditor({ id: file.home, storage: EStorageType.home }));
    },
    setBottomMargin: (value: number) => dispatch(setBottomMarginAction(value)),
    setOvidiusZoom: ({ id, value }: { id: string; value: number }) => dispatch(setOvidiusZoom({ id, value })),
});

export const ReactViewerConnected = connect(mapStateToProps, mapDispatchToProps)(ReactViewerComponent);

export default ReactViewerConnected;
