import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFeatureShowNewStoriesCounter } from 'reactApp/modules/features/features.selectors';
import { getLastFilesForWidget } from 'reactApp/modules/feed/feed.selectors';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getStoryListLoadMore, showStory } from 'reactApp/modules/stories/stories.module';
import { getNewStoriesCount, getStorySummaryCovers, hasSummariesLoadMore } from 'reactApp/modules/stories/stories.selectors';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { TabMenu } from 'reactApp/ui/Menu/TabMenu';
import { ETabMenuAlign, ETabMenuMod } from 'reactApp/ui/Menu/TabMenu.types';
import { TabMenuItem } from 'reactApp/ui/Menu/TabMenuItem';
import { IStorySummaryItem, ISwiperNavigator } from 'reactApp/ui/StoriesWidget/Stories.types';
import styles from 'reactApp/ui/StoriesWidget/StoriesWidget.css';
import { sendStoryWidgetDwh } from 'reactApp/ui/StoriesWidget/StoriesWidget.helpers';
import { StoriesWidgetComponent } from 'reactApp/ui/StoriesWidget/StoriesWidgetComponent';
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon } from 'reactApp/ui/VKUIIcons';
import { noop } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';

enum EStoryTab {
    stories = 'stories',
    feed = 'feed',
}

const menuTabs = [
    {
        name: 'Истории',
        key: EStoryTab.stories,
    },
    { name: 'Последние файлы', key: EStoryTab.feed },
];

interface IProps {
    stories?: IStorySummaryItem[];
    onExpand?: (value: boolean) => void;
}

export const STORIES_WIDGET_LOCAL_STORAGE_KEY = 'storycollapsed';

/* eslint-disable max-lines-per-function */
export const StoriesWidget = memo(function StoriesWidget({ stories, onExpand }: IProps): JSX.Element | null {
    const [currentTab, setCurrentTab] = useState(EStoryTab.stories);
    const [navState, setNavState] = useState({ isStart: true, isLast: false });
    const [navigator, setNavigator] = useState<ISwiperNavigator | null>(null);
    const dispatch = useDispatch();

    const [isClosed, setClosed] = useState(storeHelper.getValue(STORIES_WIDGET_LOCAL_STORAGE_KEY));
    const showCounter = useSelector(getFeatureShowNewStoriesCounter);
    const storeStories = useSelector(getStorySummaryCovers);
    const newStoriesCount = useSelector(getNewStoriesCount);
    const hasStoriesLoadMore = useSelector(hasSummariesLoadMore);
    if (!stories) {
        stories = storeStories;
    }
    const lastFiles = useSelector(getLastFilesForWidget);

    useEffect(() => {
        if (stories?.length) {
            sendStoryWidgetDwh({
                action: isClosed ? 'view-stories_hide' : 'view-stories_open',
                count_stories: stories?.length,
                source: EStorageType.story,
            });
        }
    }, [isClosed, stories?.length]);

    useEffect(() => {
        if (currentTab === EStoryTab.feed) {
            sendStoryWidgetDwh({
                action: 'view-last-files',
                source: EStorageType.story,
            });
        }
    }, [currentTab]);

    useEffect(() => {
        onExpand?.(!isClosed);
    }, [isClosed, onExpand]);

    const handleTabClick = useCallback(
        (value) => {
            setCurrentTab(value);
            sendStoryWidgetDwh({
                action: `click_to_${value === EStoryTab.feed ? 'last_files' : 'stories'}`,
                source: EStorageType.story,
            });
        },
        [setCurrentTab]
    );

    const handleExpand = useCallback(() => {
        const newValue = !isClosed;
        setClosed(newValue);

        storeHelper.setValue(STORIES_WIDGET_LOCAL_STORAGE_KEY, newValue);

        sendStoryWidgetDwh({
            action: newValue ? 'view-stories_close' : 'view-stories_reopen',
            source: EStorageType.story,
        });
    }, [isClosed, setClosed]);

    const handleFeedSeeAll = useCallback(() => {
        opener('/feed', true);
    }, []);

    const handleStoryClick = useCallback(
        ({ id, type }) => {
            dispatch(showStory({ storyId: id, type }));
        },
        [dispatch]
    );

    const updateNavState = useCallback(() => {
        setNavState({ isStart: navigator?.isFirst() as boolean, isLast: navigator?.isLast() as boolean });
    }, [navigator, setNavState]);

    const handleSetNavigator = useCallback(
        (navigator) => {
            setNavigator(navigator);
        },
        [setNavigator, updateNavState]
    );

    useEffect(() => {
        updateNavState();
    }, [navigator, updateNavState]);

    const handlePrevStory = useCallback(() => {
        navigator?.navigateToPrev();
        updateNavState();
    }, [navigator, updateNavState]);
    const handleNextStory = useCallback(() => {
        navigator?.navigateToNext();
        if (navigator?.isLast() && hasStoriesLoadMore) {
            dispatch(getStoryListLoadMore());
        }
        updateNavState();
    }, [dispatch, navigator, updateNavState, hasStoriesLoadMore]);

    const handleReachEnd = useCallback(() => {
        if (navigator?.isLast() && hasStoriesLoadMore) {
            dispatch(getStoryListLoadMore());
        }
    }, [navigator, dispatch, hasStoriesLoadMore]);

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.collapser} onClick={handleExpand}>
                    <ChevronDownIcon
                        className={classNames({
                            [styles.expandIcon]: true,
                            [styles.expandIconExpanded]: !isClosed,
                        })}
                    />
                </div>
                <div className={styles.tabs} onClick={() => setClosed(false)}>
                    <TabMenu align={ETabMenuAlign.left} mod={ETabMenuMod.widget}>
                        {menuTabs.map(({ name, key }) =>
                            lastFiles?.length < 5 && key === EStoryTab.feed ? null : (
                                <TabMenuItem
                                    key={key}
                                    name={name}
                                    badge={showCounter && key === EStoryTab.stories && newStoriesCount ? newStoriesCount.toString() : ''}
                                    badgeHint={showCounter && key === EStoryTab.stories ? 'Ваши непросмотренные истории' : ''}
                                    value={key}
                                    active={currentTab === key}
                                    onClick={handleTabClick}
                                    changeHash={false}
                                />
                            )
                        )}
                    </TabMenu>
                </div>
                {!isClosed && (
                    <div className={styles.leftControls}>
                        {currentTab === EStoryTab.stories && (
                            <div>
                                <div
                                    className={classNames({
                                        [styles.navButton]: true,
                                        [styles.navButton_left]: true,
                                        [styles.navButton_disabled]: navState.isStart,
                                    })}
                                    onClick={handlePrevStory}
                                >
                                    <ArrowLeftIcon />
                                </div>
                                <div
                                    className={classNames({
                                        [styles.navButton]: true,
                                        [styles.navButton_right]: true,
                                        [styles.navButton_disabled]: navState.isLast && !hasStoriesLoadMore,
                                    })}
                                    onClick={handleNextStory}
                                >
                                    <ArrowRightIcon />
                                </div>
                            </div>
                        )}
                        {currentTab === EStoryTab.feed && (
                            <div className={styles.feedAll} onClick={handleFeedSeeAll}>
                                Посмотреть все
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className={classNames({ [styles.content]: true, [styles.content_hidden]: isClosed })}>
                {currentTab === EStoryTab.stories && (
                    <StoriesWidgetComponent
                        stories={stories}
                        onItemClick={handleStoryClick}
                        onNavigatorInit={handleSetNavigator}
                        onSwipe={updateNavState}
                        onReachEnd={handleReachEnd}
                    />
                )}
                {currentTab === EStoryTab.feed && (
                    <div className={styles.lastFiles} data-qa-name="lastFiles">
                        <DataList
                            goTo={noop}
                            storage={EStorageType.feed}
                            viewMode={EViewMode.thumbs}
                            gaSuffix="wjfeed"
                            disableCenteredLoader
                            limitItemsNumber={10}
                            disableLoadOnScroll
                            disableTips
                            itemsList={lastFiles?.map((item) => item.id)}
                            storiesWidget
                        />
                    </div>
                )}
            </div>
        </div>
    );
});
