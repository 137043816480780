import classNames from 'clsx';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { changeFeedCategory, feedLoadRequest } from 'reactApp/modules/feed/feed.module';
import { getFeedCategories } from 'reactApp/modules/feed/feed.selectors';
import { FeedCategoryType } from 'reactApp/modules/feed/feed.types';
import { historyPush } from 'reactApp/modules/router/router.module';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { PageTabMenu } from 'reactApp/ui/PageTabMenu/PageTabMenu';
import { createGaSender } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './FeedPage.css';

const sendGa = createGaSender('feed');

export const FeedPage: React.VFC<{ initialItemId?: string }> = ({ initialItemId = '' }) => {
    const dispatch = useDispatch();
    const categories = useSelector(getFeedCategories);
    const { type } = useSelector(SettingsSelectors.getQueryParams);
    const currentCategory: FeedCategoryType = (type && FeedCategoryType[type]) || FeedCategoryType.all;

    useEffect(() => {
        sendGa('show');
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'feed' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'feed' });
        dispatch(feedLoadRequest({ category: FeedCategoryType[currentCategory] }));
    }, []);

    const handleMenuTabClick = useCallback(
        (category) => {
            dispatch(changeFeedCategory({ category: FeedCategoryType[category] }));
            dispatch(resetSelect());
            dispatch(historyPush({ id: '/feed', search: `?type=${FeedCategoryType[category]}` }));
        },
        [dispatch]
    );

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_new_portal_header]: isNewPortalHeader.client,
            })}
        >
            <div
                className={classNames({
                    [styles.headerContainer_new_portal_header]: isNewPortalHeader.client,
                })}
            >
                <BreadcrumbsContainer />
                <div className={styles.menu}>
                    <PageTabMenu current={currentCategory} tabs={categories} onClick={handleMenuTabClick} />
                </div>
            </div>
            <DataList goTo={noop} initialItemId={initialItemId} selectableClassName={styles.datalistSelectable} />
        </div>
    );
};
