import { ServerError } from './ServerError';

class ConnectionError extends ServerError {
    static CONNECTION_FAILURE = 0;
    static radarName = 'failure_{SOURCE}_connection';

    className = 'ConnectionError';
    message = 'Разрыв соединения.';

    isRetryable = true;
    maxRetries = 3;
    retryTimeout = 10000;

    /**
     * @constructor
     * @extends {UploadFail}
     * @param {Error} stack
     * @param {string} source
     */
    constructor() {
        super(...arguments);
    }
}

export { ConnectionError };
