import { Space } from '@mail/cross-sizes-utils';
import { requestPayment } from 'Cloud/Application/Payment';
import emergencySwaImage from 'img/emergencySwaImage.jpg';
import promocodeImage from 'img/promocodePopup.jpg';
import saveSubsImage from 'img/saveSubsImage.jpg';
import React from 'react';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { IProduct } from 'reactApp/modules/products/products.types';
import { getInfoForSaveSubscriptionsPromo } from 'reactApp/ui/Mobile/SplashScreen/helpers/SaveSubscriptionPromo.helpers';
import { renderModalDialog } from 'reactApp/utils/createDialogToolkit';
import { noopVoid } from 'reactApp/utils/helpers';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './WhatsNewDialog.css';

export const renderWhatsNewDialog = renderModalDialog(WhatsNewDialog, 'WhatsNewDialog');

export const renderPromocodePopup = ({ onSuccess: handleSuccess = noopVoid, onClose: handleClose = noopVoid }) => {
    const additionalParams = {
        details_popup: 'ok',
        name_popup: 'promocode-success',
        eventCategory: EPaymentGa.popup,
    };

    const onShow = () => sendPaymentGa({ label: 'open', ...additionalParams });

    const onClick = () => {
        sendPaymentGa({ label: 'click', ...additionalParams });
        handleSuccess();
    };

    const onClose = () => {
        sendPaymentGa({ label: 'close', ...additionalParams });
        handleClose();
    };

    openPopupHelper({
        popupName: popupNames.WHATS_NEW_DIALOG,
        onClose,
        data: {
            title: 'Промокод успешно активирован',
            text: 'Дополнительные гигабайты будут начислены в ближайшее время',
            primaryButtonText: 'Спасибо',
            imageUrl: promocodeImage,
            onClick,
            onShow,
        },
    });
};

export const renderEmergencySwaPopup = ({ onSuccess: handleSuccess = noopVoid, onClose: handleClose = noopVoid, link }) => {
    const additionalParams = {
        details_popup: 'ok',
        name_popup: 'emergency-swa',
        eventCategory: EPaymentGa.popup,
    };

    const onShow = () => sendPaymentGa({ label: 'open', ...additionalParams });

    const onClick = () => {
        sendPaymentGa({ label: 'click', ...additionalParams });
        handleSuccess();
    };

    const onClose = () => {
        sendPaymentGa({ label: 'close', ...additionalParams });
        handleClose();
    };

    openPopupHelper({
        popupName: popupNames.WHATS_NEW_DIALOG,
        onClose,
        data: {
            link,
            title: 'Проблемы с авторизацией',
            text: 'Могут возникнуть проблемы авторизации. Для доступа к Облаку\nскопируйте себе ссылку и используйте для входа.',
            imageUrl: emergencySwaImage,
            onClick,
            onShow,
        },
    });
};

export const renderSaveSubsPopup = ({
    onClick: handleClick = noopVoid,
    onClose: handleClose = noopVoid,
    onShow: handleShow = noopVoid,
    source,
    product,
    oldSubscriptionSpace,
    isTransit,
}: {
    onClick?: () => void;
    onClose: () => void;
    onShow: () => void;
    source: string;
    product: IProduct;
    oldSubscriptionSpace: Space;
    isTransit: boolean;
}) => {
    const { text, buttonText, title } = getInfoForSaveSubscriptionsPromo({ product, source, oldSubscriptionSpace, isTransit });
    const onClick = () => {
        handleClick();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        requestPayment({
            productId: product.id,
            forceFastCheckout: true,
        });
    };

    openPopupHelper({
        popupName: popupNames.WHATS_NEW_DIALOG,
        data: {
            title,
            content: <div className={styles.content_saveSubscription}>{text}</div>,
            imageUrl: saveSubsImage,
            onClick,
            onShow: handleShow,
            closable: true,
            primaryButtonText: buttonText,
            primaryButtonColor: 'black',
            closeByPrimaryButton: true,
            onCloseButtonClick: handleClose,
        },
    });
};
