import { EAttachesType } from 'reactApp/modules/attaches/attaches.constants';
import { RequiredSignUpTouchSource } from 'reactApp/modules/requiredAuthorization/constants';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ESearchOptionSource, ESearchOptionType } from 'reactApp/ui/WebSearch/WebSearch.data';

export enum EViewMode {
    thumbs = 'thumbs',
    list = 'list',
    gallery = 'gallery',
    galleryGrid3 = 'galleryGrid3',
    vkEmbeddedList = 'vkEmbeddedList',
    vkEmbeddedThumbs = 'vkEmbeddedThumbs',
    squares = 'squares',
    squares180 = 'squares180',
}

export enum EViewStoreType {
    photo = 'ui.photoView',
    photoMobile = 'ui.photoViewMobile',
    files = 'ui.filesView',
    attaches = 'ui.attachesView',
    quotaCleaner = 'ui.quotaCleanerView',
}

export interface SettingsState {
    viewMode: EViewMode;
    prevViewMode: EViewMode;
    viewModePhoto: EViewMode;
    viewModePhotoMobile: EViewMode;
    viewModeAttaches: EViewMode;
    viewModeQuotaCleaner: EViewMode;
    queryParams: QueryParams;
    sidebarOpened: boolean;
}

export interface QueryParams {
    query?: string;
    folderId?: string;
    productId?: string;
    tariffId?: string;
    weblink?: string;
    type?: EAttachesType;
    fromAuth?: boolean;
    sort?: string;
    item?: string;
    action?: string;
    diskoKey?: string;
    id?: string;
    searchType?: ESearchOptionType;
    searchSource?: ESearchOptionSource;
    token?: string;
    max_size?: number;
    total_size?: number;
    afterReload?: boolean;
    from?: string;
    /** Флаг, по которому определяем является ли аттач cloud_stock.
     *  Нужен для взаимодействия почты и облака внутри MailPopup
     */
    isCloudStock?: boolean;
    isAcceptanceLA?: boolean;
    fromCloud?: boolean;
    activeFolder?: string;
    activeItem?: string;
    dialog?: string;
    searchSection?: string;
    required_sign_up_touch_source?: RequiredSignUpTouchSource;
    isFromExternal?: string;
    utm_source?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_medium?: string;
}

export interface ISetViewModeForStorageAction {
    viewMode: EViewMode;
    storage: EStorageType;
    isMediaFolder?: boolean;
}

export interface ISetViewModeAction {
    viewMode: EViewMode;
}
