import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
/* tempexp_14812-next-line */
import { FeatureSelector } from 'reactApp/modules/features/components/FeatureSelector';
/* tempexp_14812-next-line */
import { getFeatureNewEmptyStates } from 'reactApp/modules/features/features.selectors';
import { loadSharedAutoDeleteRequest } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.module';
import { getSharedAutoDeleteIds, getSharedAutoDeleteLoadingState } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { AutoDeletePromo } from 'reactApp/sections/SharedAutoDeletePage/AutoDeletePromo/AutoDeletePromo';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
/* tempexp_14812-next-line */
import { Empty } from 'reactApp/ui/Empty/Empty';
import { SharedTabs } from 'reactApp/ui/SharedTabs/SharedTabs';
import { sendGa } from 'reactApp/utils/ga';
import opener from 'reactApp/utils/opener';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './SharedAutoDeletePage.css';

export const SharedAutoDeletePage = memo((): ReactElement => {
    const dispatch = useDispatch();

    const items = useSelector(getSharedAutoDeleteIds);
    const { isLoaded } = useSelector(getSharedAutoDeleteLoadingState);

    const showAutoDeletePromo = isLoaded && !items?.length;

    const handleItemClick = useCallback(({ id }) => {
        opener(`/home${encodeURIComponent(id)}`, !id);
    }, []);

    useEffect(() => {
        sendGa('autodelete-page', 'show');
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'autodelete' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'autodelete' });

        dispatch(loadSharedAutoDeleteRequest());
    }, [dispatch]);

    if (showAutoDeletePromo) {
        return (
            <div
                className={classNames(styles.root, {
                    [styles.root_new_portal_header]: isNewPortalHeader.client,
                })}
            >
                {/* tempexp_14812-next-line */}
                <FeatureSelector
                    selector={getFeatureNewEmptyStates}
                    control={
                        <>
                            <div className={classNames({ [styles.headerContainer_new_portal_header]: isNewPortalHeader.client })}>
                                {isLoaded && <BreadcrumbsContainer />}
                                <SharedTabs />
                            </div>
                            <AutoDeletePromo
                                className={classNames({ [styles.autoDeletePromo_new_portal_header]: isNewPortalHeader.client })}
                            />
                        </>
                    }
                    variant1={
                        <div className={classNames({ [styles.headerContainer_new_portal_header]: isNewPortalHeader.client })}>
                            {isLoaded && <BreadcrumbsContainer />}
                            <SharedTabs />
                            <Empty.New storage={EStorageType.sharedAutodelete} />
                        </div>
                    }
                />
            </div>
        );
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_new_portal_header]: isNewPortalHeader.client,
            })}
        >
            <div className={classNames({ [styles.headerContainer_new_portal_header]: isNewPortalHeader.client })}>
                {isLoaded && <BreadcrumbsContainer />}
                <SharedTabs />
            </div>
            <DataList goTo={handleItemClick} storage={EStorageType.sharedAutodelete} selectableClassName={styles.datalistSelectable} />
        </div>
    );
});

SharedAutoDeletePage.displayName = 'SharedAutoDeletePage';
