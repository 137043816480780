import { captureException } from '@sentry/browser';
import { logger } from 'lib/logger';
import { removeItemsWithoutDialog } from 'reactApp/appHelpers/appHelpers';
import { IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { showVirusDlg } from 'reactApp/modules/popup/popup.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { gotoAfterClose } from 'reactApp/modules/viewer/sagas/navigate.saga';
import { openVirusPopupHelper } from 'reactApp/ui/VirusDialog/VirusDialog.helpers';
import { ActionName } from 'reactApp/ui/VirusDialog/VirusDialog.types';
import { channel } from 'redux-saga';
import { cancel, put, select, take, takeEvery } from 'redux-saga/effects';

function* handleShowVirusDlg(action) {
    try {
        const { items, actionName = ActionName.download, name, doNotChangeRoute = false } = action.payload;

        const storage = items[0]?.storage || (yield select(getCurrentStorage));
        const { isPublicOrStock, isQuotaCleaner } = getStorage(storage);

        const removeChannel = channel();

        openVirusPopupHelper({
            isHome: !isPublicOrStock && !isQuotaCleaner,
            isPublic: isPublicOrStock,
            isMobile: IS_MOBILE_BROWSER,
            actionName,
            items,
            onRemoveSuccess: (data) => removeChannel.put(removeItemsWithoutDialog(data)),
            onClose: () => {
                if (!doNotChangeRoute) {
                    gotoAfterClose();
                }

                removeChannel.close();
            },
            name,
        });

        const removeAction = yield take(removeChannel);
        yield put(removeAction);
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield cancel();
    }
}

export function* watchDialogsRoot() {
    yield takeEvery(showVirusDlg.toString(), handleShowVirusDlg);
}
