import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadItem } from 'reactApp/appHelpers/appDownload';
import { publishHelper } from 'reactApp/appHelpers/publishHelper';
import { addToFavorites, removeFromFavorites } from 'reactApp/modules/modifying/modifying.actions';
import { isInlineEditInProgress } from 'reactApp/modules/modifying/modifying.selectors';
import { selectOne } from 'reactApp/modules/selections/selections.actions';
import { logHandler } from 'reactApp/ui/Datalist/DataList.helpers';

export const useHandlers = ({
    item,
    id,
    uploadingItem,
    storage,
    denyDoAction,
    isMobile,
    isSelected,
    isDraggable,
    onClick,
    onSelect,
    onContextMenu,
    onMouseDown,
    onDraggingOver,
    onMouseLeave,
}) => {
    const [wasMouseDown, setMouseDown] = useState(false);
    const dispatch = useDispatch();
    const isInlineEditing = useSelector(isInlineEditInProgress);

    const weblink = item?.weblink;
    const isInFavorites = item?.isInFavorites;

    const onDownload = useCallback(() => {
        downloadItem({ itemOrId: item, storage: item.storage });
    }, [item]);

    const onFavorites = useCallback(() => {
        if (!item) {
            return;
        }

        if (isInFavorites) {
            dispatch(removeFromFavorites({ items: [item] }));
        } else {
            dispatch(addToFavorites({ items: [item], from: 'datalist' }));
        }
        // item не добавляется для оптимизации, так как обновлять onFavorites достаточно только при изменении isInFavorites
    }, [id, isInFavorites]);

    const onPublish = useCallback(() => {
        logHandler({
            radarName: 'item_click_publish',
        });

        if (uploadingItem?.cloudPath) {
            // если uploadingItem есть, значит клик был после загрузки элемента
            logHandler({
                radarName: 'item_click_publish_after_upl',
            });
        }

        publishHelper({ item, publishFrom: 'datalist', itemStorage: storage });
    }, [id, storage, weblink]);

    const handleOnClick = useCallback(
        (event) => {
            event.preventDefault();
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();

            if ((!item || denyDoAction) && item?.kind !== 'folder') {
                return;
            }

            onClick(id, item);
        },
        [id, item, onClick, denyDoAction]
    );

    const handleOnSelect = useCallback(
        (event) => {
            event.preventDefault();

            if (!wasMouseDown || denyDoAction) {
                return;
            }

            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
            onSelect({ event, id });
        },
        [id, onSelect, wasMouseDown, denyDoAction]
    );

    const handleOnContextMenu = useCallback(
        (event) => {
            event.preventDefault();
            event.stopPropagation();

            if (isMobile || denyDoAction) {
                return;
            }

            if (!isSelected) {
                dispatch(selectOne({ selectedIdx: id, storage }));
            }

            onContextMenu(event);
        },
        [id, isSelected, storage, denyDoAction, onContextMenu]
    );

    const handleOnCheckboxClick = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();
            event.nativeEvent.stopImmediatePropagation();

            if (denyDoAction) {
                return;
            }

            onSelect({ event, id, isCheckbox: true });
        },
        [id, onSelect, denyDoAction]
    );

    const handleOnMouseDown = useCallback(
        (event) => {
            if (!event?.target?.getAttribute('contenteditable') && !isInlineEditing) {
                event.preventDefault();
            }

            if (denyDoAction) {
                return;
            }

            onMouseDown(event, { item });

            setMouseDown(true);
        },
        [onMouseDown, item, setMouseDown, denyDoAction, isInlineEditing]
    );

    const handleMouseMove = useCallback(
        (event) => {
            if (!event?.target?.getAttribute('contenteditable')) {
                event.preventDefault();
            }

            if (!isDraggable || denyDoAction) {
                return;
            }

            onDraggingOver({ item });
        },
        [item, isDraggable, onDraggingOver, denyDoAction]
    );

    const handleMouseLeave = useCallback(
        (event) => {
            if (denyDoAction) {
                return;
            }

            setMouseDown(false);

            if (event.buttons > 0) {
                onMouseLeave(event);
            }
        },
        [onMouseLeave, setMouseDown, denyDoAction]
    );

    return {
        onDownload,
        onFavorites,
        onPublish,
        handleOnClick,
        handleOnSelect,
        handleOnContextMenu,
        handleOnCheckboxClick,
        handleOnMouseDown,
        handleMouseLeave,
        handleMouseMove,
    };
};
