import classnames from 'clsx';
import { logger } from 'lib/logger';
import PropTypes from 'prop-types';
import { stringify } from 'qs';
import { equals, path } from 'ramda';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { attachesLoadMoreRequest, attachesPageStart, attachFoldersRequest } from 'reactApp/modules/attaches/attaches.actions';
import { AttachesFolders } from 'reactApp/modules/attaches/attaches.constants';
import { sendAttachesGa } from 'reactApp/modules/attaches/attaches.helpers';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import { historyPush } from 'reactApp/modules/router/router.module';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { setQueryParams } from 'reactApp/modules/settings/settings.module';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { getStorageCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { getParams } from 'reactApp/sections/AttachesPage/helpers';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import Content from 'reactApp/ui/Content/Content';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import BizPageTabMenu from 'reactApp/ui/DataListItemRow/components/BizPageTabMenu/BizPageTabMenu';
import { PageTabMenu } from 'reactApp/ui/PageTabMenu/PageTabMenu';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './AttachesPage.css';

const tabs = [
    {
        name: 'Все',
        value: AttachesFolders.all,
    },
    {
        name: 'Входящие',
        value: AttachesFolders.inbox,
    },
    {
        name: 'Отправленные',
        value: AttachesFolders.sent,
    },
];

const mapStateToProps = (state) => {
    const params = SettingsSelectors.getQueryParams(state);
    const viewMode = SettingsSelectors.getViewByStorage(state, EStorageType.attaches);
    const isSearch = !!path(['query'], params);
    const isViewerActive = ViewerSelectors.isViewerActive(state);
    const loadingState = AttachesSelectors.getAttachLoadingState(state);
    const folder = getStorageCurrentFolder(state, EStorageType.attaches);
    const currentFolder = params?.folderId || AttachesFolders.all;

    return {
        login: UserSelectors.getEmail(state),
        isLoading: loadingState.isLoading,
        params,
        isSearch,
        hasError: !!loadingState.error,
        gridView: viewMode === EViewMode.thumbs,
        isViewerActive,
        folder,
        currentFolder,
    };
};

const mapDispatchToProps = (dispatch) => ({
    initPage: () => dispatch(attachesPageStart({})),
    getAttaches: (params) => dispatch(attachesLoadMoreRequest(params)),
    getAttachFolders: () => dispatch(attachFoldersRequest()),
    resetSelect: () => dispatch(resetSelect()),
    routeTo: (params) => {
        dispatch(historyPush({ id: '/attaches', search: `?${stringify(params, { skipNulls: true })}` }));
        dispatch(setQueryParams(params));
    },
});

@connect(mapStateToProps, mapDispatchToProps)
class AttachesPage extends PureComponent {
    static propTypes = {
        login: PropTypes.string,
        initialItemId: PropTypes.string,
        params: PropTypes.object,
        isLoading: PropTypes.bool,
        getAttaches: PropTypes.func,
        getAttachFolders: PropTypes.func,
        items: PropTypes.array,
        list: PropTypes.array,
        gridView: PropTypes.bool,
        hasError: PropTypes.bool,
        resetSelect: PropTypes.func,
        isSearch: PropTypes.bool,
        isViewerActive: PropTypes.bool,
        folder: PropTypes.object,
        currentFolder: PropTypes.string,
    };

    componentDidMount() {
        sendAttachesGa('open');
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'from-mail' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'from-mail' });

        this.props.initPage();
        this.promise = this.checkAndGetAttaches();
    }

    componentDidUpdate(prevProps) {
        const { params = {} } = this.props;
        const { params: prevParams = {} } = prevProps;
        const isParamsChanged = !equals(params, prevParams);

        if (isParamsChanged) {
            this.promise = this.checkAndGetAttaches();
        }
    }

    checkAndGetAttaches = () => {
        const { getAttaches, isSearch, getAttachFolders, params, resetSelect, isViewerActive } = this.props;

        if (isSearch && params) {
            sendAttachesGa('search', params.type || 'all');
        }

        if (isViewerActive) {
            return Promise.resolve();
        }

        resetSelect();

        const deferred = this.promise && !this.promise.resolved ? this.promise : Promise.resolve();

        return deferred
            .then(() =>
                Promise.all([getAttaches(getParams({ params }) ?? {}), getAttachFolders()]).then(() => {
                    this.promise = null;
                    window.scrollTo(0, 0);
                })
            )
            .catch((error) => logger.error('checkAndGetAttaches:', error));
    };

    handleOnFolderSelect = (folderId) => {
        const { params, routeTo } = this.props;

        if (folderId === AttachesFolders.all) {
            routeTo({});
        } else {
            const attachesParmas = {
                ...params,
                folderId,
            };
            routeTo(attachesParmas);
        }
    };

    render() {
        const { gridView, hasError, currentFolder, initialItemId, folder } = this.props;
        const showBizPageTabMenu =
            currentFolder === AttachesFolders.all ? folder.childs.length > 0 : folder.folders[currentFolder]?.messages_with_attachments > 0;

        return (
            <Content
                hasError={hasError}
                wrapClass={classnames(styles.root, {
                    [styles.root_new_portal_header]: isNewPortalHeader.client,
                })}
            >
                <div className={classnames({ [styles.headerContainer_new_portal_header]: isNewPortalHeader.client })}>
                    <div className={styles.title}>
                        <BreadcrumbsContainer isSearch={this.props.isSearch} />
                    </div>
                    {!IS_BIZ_USER && (
                        <div className={styles.tabs}>
                            <PageTabMenu current={currentFolder} onClick={this.handleOnFolderSelect} tabs={tabs} />
                        </div>
                    )}
                    {showBizPageTabMenu && <BizPageTabMenu noMarginBottom />}
                </div>
                <div
                    className={classnames({
                        [styles.list]: !gridView,
                        [styles.grid]: gridView,
                        [styles.dataListContainer_new_portal_header]: isNewPortalHeader.client,
                    })}
                    onContextMenu={this.handleOnContextMenu}
                >
                    <DataList initialItemId={initialItemId} />
                </div>
            </Content>
        );
    }
}

export default AttachesPage;
