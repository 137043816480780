import notAvailableImageDark from 'img/errors/dark/error-403.png';
import notFoundImageDark from 'img/errors/dark/error-404.png';
import toManyRequestsImageDark from 'img/errors/dark/error-429.png';
import internalServerErrorImageDark from 'img/errors/dark/error-500.png';
import gatewayTimeoutImageDark from 'img/errors/dark/error-504.png';
import badBrowserImageDark from 'img/errors/dark/error-bad-browser.png';
import notAvailableImage from 'img/errors/error-403.png';
import notFoundImage from 'img/errors/error-404.png';
import toManyRequestsImage from 'img/errors/error-429.png';
import internalServerErrorImage from 'img/errors/error-500.png';
import gatewayTimeoutImage from 'img/errors/error-504.png';
import badBrowserImage from 'img/errors/error-bad-browser.png';
import somethingWrong from 'img/errors/error-something-wrong.png';
import React from 'react';

import styles from './ErrorPage.css';
import { ErrorInfo, EStatus } from './ErrorPage.types';

export const isErrorPageStatus = (error: any): error is EStatus => {
    return Object.values(EStatus).includes(error);
};

const commonData = {
    buttonText: 'Перейти на главную',
    buttonLink: '/home/',
};

export const ERROR_STATUS_MAP: Record<EStatus, ErrorInfo> = {
    [EStatus.NOT_AVAILABLE]: {
        ...commonData,
        title: 'Ограниченный доступ',
        annotation: (
            <>
                Чтобы перейти по ссылке, войдите в рабочий аккаунт. Узнать причины ограниченного доступа можно{' '}
                <a className={styles.helpUrl} target="_blank" href="https://help.mail.ru/cloud_web" rel="noreferrer">
                    тут
                </a>
                .
            </>
        ),
        imageSrc: notAvailableImage,
        imageSrcDark: notAvailableImageDark,
        metaTitle: '403',
        downloadLink: 'https://trk.mail.ru/c/v2nah4',
    },
    [EStatus.NOT_FOUND]: {
        ...commonData,
        title: 'Такой страницы нет',
        annotation: 'Возможно, вы\u00A0ввели неправильный адрес или страница была удалена.',
        imageSrc: notFoundImage,
        imageSrcDark: notFoundImageDark,
        metaTitle: '404',
        downloadLink: 'https://trk.mail.ru/c/gi7h46',
    },
    [EStatus.TOO_MANY_REQUESTS]: {
        ...commonData,
        title: 'Превышено количество запросов',
        annotation: 'Попробуйте обновить страницу через несколько минут или вернитесь на\u00A0главную.',
        imageSrc: toManyRequestsImage,
        imageSrcDark: toManyRequestsImageDark,
        metaTitle: '429',
        downloadLink: 'https://trk.mail.ru/c/d8q806',
    },
    [EStatus.INTERNAL_SERVER_ERROR]: {
        ...commonData,
        title: 'Технические работы',
        annotation: 'Мы\u00A0знаем о\u00A0проблеме и\u00A0уже решаем\u00A0её. Попробуйте обновить страницу позже.',
        imageSrc: internalServerErrorImage,
        imageSrcDark: internalServerErrorImageDark,
        metaTitle: '500',
        downloadLink: 'https://trk.mail.ru/c/dpaf96',
    },
    [EStatus.GATEWAY_TIMEOUT]: {
        ...commonData,
        title: 'Сервис недоступен',
        annotation: 'Попробуйте обновить страницу через несколько минут или вернитесь на\u00A0главную.',
        imageSrc: gatewayTimeoutImage,
        imageSrcDark: gatewayTimeoutImageDark,
        metaTitle: '504',
        downloadLink: 'https://trk.mail.ru/c/kh6my3',
    },
    [EStatus.BAD_BROWSER]: {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        title: 'Браузер устарел',
        annotation: 'К\u00A0сожалению, ваш браузер не\u00A0поддерживает доступ к\u00A0сервису. Обновите браузер и\u00A0попробуйте снова.',
        imageSrc: badBrowserImage,
        imageSrcDark: badBrowserImageDark,
        metaTitle: 'Браузер устарел',
        downloadLink: 'https://trk.mail.ru/c/t9xnk8',
        ...commonData,
    },
    [EStatus.BAD_BROWSER_ATOM]: {
        title: 'Браузер устарел',
        annotation:
            'К\u00A0сожалению, ваш браузер не\u00A0поддерживает доступ к\u00A0сервису. Попробуйте Atom от\u00A0Mail.ru — там\u00A0заработает.',
        imageSrc: badBrowserImage,
        imageSrcDark: badBrowserImageDark,
        buttonText: 'Установить браузер',
        buttonLink: 'https://browser.ru',
        metaTitle: 'Браузер устарел',
        downloadLink: 'https://trk.mail.ru/c/f39zz4',
    },
    [EStatus.SOMETHING_WRONG]: {
        title: 'Что-то сломалось',
        imageSrc: somethingWrong,
        metaTitle: '500',
        downloadLink: 'https://trk.mail.ru/c/dpaf96',
        buttonText: 'Обновить',
        buttonLink: '',
        annotation: (
            <>
                Мы очень скоро всё починим. Пожалуйста,
                <br />
                попробуйте повторить позже.
            </>
        ),
        onClickHandler: () => {
            window.location.reload();
        },
        isHidePromo: true,
    },
} as const;

export const disableAdvButtonHref = '/subscriptions/?utm_source=cloud-subs-ads-error-buy&utm_medium=referral&utm_campaign=subs-ads-error';
