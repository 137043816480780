import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isReadOnly } from 'reactApp/appHelpers/appHelpers';
import { activitiesFeature } from 'reactApp/appHelpers/featuresHelpers';
import { useActionPanel } from 'reactApp/hooks/useActionPanel';
import { getSelectedItems } from 'reactApp/modules/selections/selections.items.selectors';
import { FileFolderChangesLog } from 'reactApp/ui/FilesFoldersLogViewer/FileFolderChangesLog/FileFolderChangesLog';
import { FileFoldersVersionLog } from 'reactApp/ui/FilesFoldersLogViewer/FileFoldersVersionLog';
import { FilesFolderLogViewerStub } from 'reactApp/ui/FilesFoldersLogViewer/FilesFolderLogViewerStub/FilesFolderLogViewerStub';
import { TabMenu } from 'reactApp/ui/Menu/TabMenu';
import { ETabMenuAlign, ETabMenuMod } from 'reactApp/ui/Menu/TabMenu.types';
import { TabMenuItem } from 'reactApp/ui/Menu/TabMenuItem';

import styles from './FilesFolderLogViewer.css';

const enum TAB_KEYS {
    CHANGES = 'changes',
    VERSIONS = 'versions',
}

const tabItemsDefaults = {
    changeHash: false,
    allowClickOnActive: false,
};

const tabItems = [
    {
        name: 'Изменение настроек',
        value: TAB_KEYS.CHANGES,
    },
    {
        name: 'Версии',
        value: TAB_KEYS.VERSIONS,
    },
].map((item) => ({ ...tabItemsDefaults, ...item }));

export const FilesFoldersLogViewer = () => {
    const { setHeader } = useActionPanel();
    const [activeTab, setActiveTab] = useState(TAB_KEYS.CHANGES);
    const selectedItems = useSelector(getSelectedItems);
    const item = selectedItems.length === 1 && selectedItems[0];
    const { id = undefined, isFolder = false, name = '', deletedAt = undefined, home = '' } = { ...item };
    const itemId = (deletedAt && home) || id;
    const headerCaption = (name && `История ${isFolder ? 'папки' : 'файла'} «${name}»`) || '';
    const onlyReadAccess = isReadOnly(item);

    useEffect(() => {
        setHeader(headerCaption);
    }, [setHeader, headerCaption]);

    useEffect(() => {
        setActiveTab((state) => {
            return (isFolder && state === TAB_KEYS.VERSIONS) || onlyReadAccess ? TAB_KEYS.CHANGES : state;
        });
    }, [isFolder, onlyReadAccess]);

    const tabMenuItemClickHandler = useCallback((tabKey: TAB_KEYS) => {
        setActiveTab(tabKey);
    }, []);

    const renderContent = (itemId: string) => {
        const top =
            (!isFolder && (
                <>
                    <header className={styles.header}>
                        Вы можете восстановить любую версию настроек за последние {activitiesFeature.period} дней
                    </header>
                    <TabMenu className={styles.tabs} align={ETabMenuAlign.left} mod={ETabMenuMod.buttons}>
                        {tabItems
                            .filter(
                                (item) =>
                                    ((onlyReadAccess || deletedAt) && item.value === TAB_KEYS.CHANGES) || (!onlyReadAccess && !deletedAt)
                            )
                            .map((props) => (
                                <TabMenuItem
                                    {...props}
                                    key={props.value}
                                    active={props.value === activeTab}
                                    onClick={tabMenuItemClickHandler}
                                />
                            ))}
                    </TabMenu>
                </>
            )) ||
            null;

        return (
            <>
                {top}
                {{
                    [TAB_KEYS.VERSIONS]: <FileFoldersVersionLog itemId={itemId} />,
                    [TAB_KEYS.CHANGES]: <FileFolderChangesLog itemId={itemId} isFolder={isFolder} />,
                }[activeTab] || null}
            </>
        );
    };

    return <div className={styles.root}>{(itemId && renderContent(itemId)) || <FilesFolderLogViewerStub />}</div>;
};
