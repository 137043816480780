import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { advExpId } from 'reactApp/appHelpers/featuresHelpers';
import { getFeatureABDisableAdsSnackbar } from 'reactApp/modules/features/features.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { getAdvDisableLink } from 'reactApp/ui/AdvBanners/AdvBanners.helpers';
import { sendXray } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { EPromoAction, sendSplashscreenAnalytics } from 'reactApp/utils/splashscreenAlertGa';

import { EButtonAction, sendDisableAdsAnalytics } from './DisableButton.analytics';
import { DisableButtonComponent } from './DisableButtonComponent';

interface IProps {
    text?: string;
    className?: string;
    onClick?: () => void;
    id: string;
    theme?: 'light' | 'dark';
}

// места где показываем кнопку https://jira.vk.team/browse/CLOUDWEB-12562
export const DisableButton = ({ text = 'Отключить рекламу', className = '', onClick = noop, id, theme = 'light' }: IProps) => {
    const isAnonymous = useSelector(UserSelectors.isAnonymous);

    // tempexp_13536-start
    const storage = useSelector(getCurrentStorage);
    const snackbarFeatureName = useSelector(getFeatureABDisableAdsSnackbar);
    const shouldShowButton = snackbarFeatureName && snackbarFeatureName !== 'b';

    useEffect(() => {
        if (shouldShowButton) {
            sendSplashscreenAnalytics({
                action: EPromoAction.SHOW,
                name: 'disable-adv-button',
                source: storage,
            });
        }

        sendDisableAdsAnalytics({
            action: EButtonAction.SHOW,
            place: id,
        });

        sendXray(`noads-button-show-${id}`, advExpId ? `${advExpId}` : null);
    }, []);

    const handleOnClick = useCallback(() => {
        if (shouldShowButton) {
            sendSplashscreenAnalytics({
                action: EPromoAction.CLICK,
                name: 'disable-adv-button',
                source: storage,
                name_button: 'disable-adv',
            });
        }

        sendDisableAdsAnalytics({
            action: EButtonAction.CLICK,
            place: id,
        });

        sendXray(`noads-button-click-${id}`, advExpId ? `${advExpId}` : null);

        onClick();
    }, []);

    if (snackbarFeatureName === 'b') {
        return null;
    }
    // tempexp_13536-end

    const href = getAdvDisableLink({ isAnonymous });

    return <DisableButtonComponent text={text} href={href} onClick={handleOnClick} className={className} theme={theme} />;
};
