import { Icon20Cancel } from '@vkontakte/icons';
import React, { memo } from 'react';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from './Invitation.css';

interface InvitationProps {
    onAccept: React.MouseEventHandler<HTMLElement>;
    onReject: React.MouseEventHandler<SVGSVGElement>;
}

export const Invitation = memo<InvitationProps>(function Invitation({ onAccept, onReject }) {
    return (
        <div className={styles.root}>
            <Hint text="Принять приглашение">
                <div className={styles.button} onClick={onAccept} data-qa-id="accept">
                    Принять
                </div>
            </Hint>
            <Hint text="Отклонить приглашение">
                <Icon20Cancel className={styles['clickable-icon']} onClick={onReject} data-qa-id="reject" />
            </Hint>
        </div>
    );
});
