import * as OAuth from '@mail/oauth';
import { prepareCategoryByURL } from '@mail-core/dashboard/keeper/metrics.api';
import { captureMessage } from '@sentry/browser';
import axios, { AxiosResponse } from 'axios';
import { getFeature } from 'Cloud/Application/FeaturesEs6';
import config from 'Cloud/config';
import qs from 'qs';
import { getLoadMoreLimit } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendKaktamLog, sendXray } from 'reactApp/utils/ga';

const CLIENT_ID = config.get('CORS_API_CLIENT_ID');
const OAUTH_HOST = config.get('OAUTH_HOST');
const CORSAPI_HOST = config.get('CORS_API_HOST');
const login = config.get('user.email');
const ATTACHES_LOAD_LIMIT = getLoadMoreLimit(EStorageType.attaches);
let OAuthToken: string | null = '';
export const AF_API_HOST = getFeature('af-api-host') || 'https://af.attachmail.ru/cgi-bin/readmsg/';
const OAUTH_TOKEN_TTL = getFeature('oauth-token-ttl');
/** Время жизни токена */
const oauthTokenTTL = new Date();

const CORS_API_BASE_URL = `https://${CORSAPI_HOST}/api/v1/`;

const COMMON_HEADERS = {
    'Content-Type': 'application/x-www-form-urlencoded',
} as const;

const axiosInstance = axios.create({
    baseURL: CORS_API_BASE_URL,
    method: 'POST',
    headers: COMMON_HEADERS,
    withCredentials: false,
});

const updateOauthTokenTTL = (date: Date, minutes: number) => {
    date.setMinutes(date.getMinutes() + minutes);

    return date;
};

export function checkAuth(fromCache = false) {
    const clientId = CLIENT_ID;
    const timeout = setTimeout(() => {
        sendXray('oauth', 'timeout');
    }, 5000);

    // инициализация OAuth клиента
    OAuth.init({
        clientId,
        login,
    });
    OAuth.setOAuthHost(OAUTH_HOST);

    return new Promise((resolve, reject) => {
        // Возвращаем токен из кеша, если прошло меньше ttl и токен существует
        if (fromCache && OAuthToken && OAUTH_TOKEN_TTL && oauthTokenTTL > new Date()) {
            clearTimeout(timeout);
            sendXray('oauth', 'connected');

            return resolve(OAuthToken);
        }
        // запрос за авторизацией, работает только с доменов, привязанных к clientId
        OAuth.auth((state) => {
            clearTimeout(timeout);
            sendXray('oauth', state.status);

            const isAuth = state.status === 'connected';
            OAuthToken = state.access_token;

            if (isAuth) {
                updateOauthTokenTTL(oauthTokenTTL, OAUTH_TOKEN_TTL);
                resolve(OAuthToken);
            } else {
                sendKaktamLog(
                    {
                        state,
                        login,
                        clientId,
                    },
                    'cloud_oauth'
                );

                reject(`oauth: ${state.status}`);
            }
        });
    });
}

function api(method, data?, options = { getFullResponse: false }): Promise<AxiosResponse | any> {
    return new Promise(function (resolve, reject) {
        const category = prepareCategoryByURL(method);
        axiosInstance(method, {
            data: (function (data) {
                data.access_token = data.access_token || OAuthToken;

                return Object.keys(data)
                    .filter((key) => data[key] !== undefined && data[key] !== '')
                    .map(function (p) {
                        return `${encodeURIComponent(p)}=${encodeURIComponent(data[p])}`;
                    })
                    .join('&');
            })(data || {}),
        })
            .then(function (response) {
                if (response.status === 200) {
                    resolve(options?.getFullResponse ? response : response.data);
                } else {
                    reject(response);
                    sendKaktamLog(response, 'cloud_corsapi');
                }

                sendXray('corsapi', `${category}_${response.status}`);
            })
            .catch(function (error) {
                sendKaktamLog(error, 'cloud_corsapi');
                sendXray('corsapi', `${category}_error`);

                reject(error);
            });
    });
}

export function getFolders() {
    return checkAuth()
        .then(function () {
            return api('folders');
        })
        .then(function (response) {
            return response.body;
        });
}

export function getAttaches({
    folderId = -1,
    after = '',
    before = '',
    limit = ATTACHES_LOAD_LIMIT,
    extraThumbs = { image: '167x167' },
    query = '',
    type = '',
} = {}) {
    return checkAuth().then(() =>
        api(
            'messages/attaches/search/clear',
            {
                folder_id: folderId,
                extra_thumbs: JSON.stringify(extraThumbs),
                after,
                before,
                limit,
                query,
                type,
            },
            { getFullResponse: true }
        )
    );
}

export function getMessageAttaches({ messageId, email }: { messageId?: string; email?: string } = {}) {
    return checkAuth()
        .then(function () {
            return api('messages/attaches', {
                id: messageId,
                email,
            });
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.body || response.map((a) => a.body);
            }
            sendKaktamLog(
                { messageId, text: 'getMessageAttaches api error', status: response.status, body: response.body },
                'attaches_api'
            );
            captureMessage(`getMessageAtt error: status ${response.status} `, { extra: response.body });
            sendXray(['getmessageatt', 'status', response.status]);
            return null; // TODO: show message?
        });
}

export function getTemporaryMessageAttaches({ messageId, email }: { messageId?: string; email?: string } = {}) {
    return checkAuth()
        .then(function () {
            return api('messages/attaches/temporary', {
                message_id: messageId,
                email,
            });
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.body || response.map((a) => a.body);
            }
            sendKaktamLog(
                { messageId, text: 'getTemporaryMessageAttaches api error', status: response.status, body: response.body },
                'attaches_api'
            );
            captureMessage(`getTempMessage error: status ${response.status} `, { extra: response.body });
            sendXray(['gettempmessage', 'status', response.status]);
            return null;
        });
}

export function getMessage({ messageId, email, folderId }: { messageId?: string; email?: string; folderId?: string } = {}) {
    return checkAuth()
        .then(function () {
            return api('messages/message', {
                id: messageId,
                email,
                folder_id: folderId || undefined,
            });
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.body || response.map((a) => a.body);
            }
            sendKaktamLog({ messageId, text: 'getMessage api error', status: response.status, body: response.body }, 'attaches_api');
            captureMessage(`getMessage error: status ${response.status} `, { extra: response.body });
            sendXray(['getmessage', 'status', response.status]);
            return null;
        });
}

export function getDocumentUrl({ url }: { url?: string } = {}) {
    return checkAuth()
        .then(function () {
            return api(url);
        })
        .then(function (response) {
            return response.body.url;
        });
}

export const cloneAttach = async ({ ids, folder, email }: { ids?: any; folder?: any; email?: string } = {}) => {
    let token = await checkAuth(true);
    const requestMaxAttempts = 3;
    const reloadRequestStatus = [403, 500];

    for (let requestAttempts = 1; requestAttempts <= requestMaxAttempts; requestAttempts += 1) {
        const response = await api('attaches/add/to-cloud', {
            folder,
            email,
            ids: JSON.stringify(ids),
            htmlencoded: false,
            access_token: token,
        });

        const body = response.body || response.map((r) => r.body);

        if (response.status === 200) {
            return body;
        }

        if (reloadRequestStatus.includes(response.status) && requestAttempts !== requestMaxAttempts) {
            token = await checkAuth(true);
            continue;
        }

        sendXray(`clone_attach_err_${response.status}`);
        sendKaktamLog({ ids, folder, text: 'cloneAttach api error', status: response.status, body: response.body }, 'attaches_api');
        return Promise.reject({ body, status: response.status });
    }
};

export const downloadDocumentApi = (url: string): Promise<{ data: Nullable<ArrayBuffer> }> =>
    checkAuth().then(() =>
        axios
            .post(url, qs.stringify({ access_token: OAuthToken }), {
                headers: COMMON_HEADERS,
                responseType: 'arraybuffer',
            })
            .catch((error) => {
                sendXray('down_doc_err');
                sendKaktamLog(error, 'down_doc_err');

                sendKaktamLog({ url, text: 'downloadDocumentApi api error', error }, 'down_doc_err');
                return { data: null };
            })
    );

export const removeMessages = (email: string, ids: string[]) =>
    checkAuth().then(() =>
        api('messages/remove', {
            email,
            ids: JSON.stringify(ids),
        })
    );
/*
    Урл файла для скачивания аттача через ajax
 */
export const getDocumentUrlByAttachId = ({ attachId, fileName }: { attachId: string; fileName: string }) => {
    //  См. af api https://confluence.vk.team/pages/viewpage.action?pageId=54013226
    // https://confluence.vk.team/pages/viewpage.action?pageId=542591183
    return `${AF_API_HOST}${fileName}?id=${encodeURIComponent(attachId)}&notype=1&project=cloud`;
};

export const getDocsView = (url?: string | null) => {
    if (!url) {
        return Promise.reject();
    }

    return api(url);
};
