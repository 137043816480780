import { Failure } from '../Failure';

class ServerError extends Failure {
    static ERROR_WRONG_INSTANCE = 'ServerError called on non-instance of ServerError';
    static ERROR_ABSTRACT_CLASS = 'ServerError is abstract class';

    static radarName = 'failure_{SOURCE}_abstract';

    /**
     * @param {string} html
     * @returns {string}
     */
    static removeHTML = html => {
        return html?.replace(/<\/?[^>]*(>|$)/g, '');
    };

    className = 'ServerError';
    toLogStringPattern = '[{TIME}] {USER} x-page-id={PAGE_ID} id={ID} {SOURCE} {CLASS_NAME} { {META} }: {STATUS} – {RESPONSE_TEXT}';

    status = -1;
    responseText = '';
    requestUrl = '';

    /**
     * @constructor
     * @extends {Failure}
     * @param {string} status
     * @param {string} requestUrl
     * @param {Object} response
     */
    constructor({ status, requestUrl, response }) {
        super(...arguments);

        if (!(this instanceof ServerError)) {
            throw new TypeError(ServerError.ERROR_WRONG_INSTANCE);
        }

        if (this.constructor === ServerError) {
            throw new TypeError(ServerError.ERROR_ABSTRACT_CLASS);
        }

        this.response = response;
        this.responseText = ServerError.removeHTML(JSON.stringify(response) || '')
            .trim()
            .slice(0, 1000);
        this.requestUrl = requestUrl;
        this.status = status;
    }

    toLogString() {
        let result = super.toLogString(...arguments);

        result = result.replace('{RESPONSE_TEXT}', this.responseText);
        result = result.replace('{URL}', this.requestUrl);
        result = result.replace('{STATUS}', this.status);

        return result;
    }

    getDetails() {
        let details = super.getDetails(...arguments);

        details.responseText = String(this.responseText);
        details.status = String(this.status);
        details.requestUrl = String(this.requestUrl);

        return details;
    }
}

export { ServerError };
