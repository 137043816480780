import { Promo } from 'Cloud/Application/Promo';
import { promoController } from 'Cloud/Application/PromoController';
import { logger } from 'lib/logger';
import { HIDE_ADS, IS_FRAME_PAGES, IS_PUBLIC_FOLDER, IS_REACT_PROMO_NEW_YEAR } from 'reactApp/appHelpers/configHelpers';
import { promoMobileAppPublic } from 'reactApp/appHelpers/featuresHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getIntegrationClient } from 'reactApp/modules/integration/integration.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { showPromoMobileAppSnackbar } from 'reactApp/modules/promo/promoMobileApp/promoMobileApp.helpers';
import { initInitMobAppPromo } from 'reactApp/modules/promo/promoMobileApp/promoMobileApp.saga';
import { MobileAppPromoType } from 'reactApp/modules/promo/promoMobileApp/promoMobileApp.types';
import { initAutosaveAsidePromoModal, showAutosaveAsidePromoModal } from 'reactApp/modules/promo/sagas/autosave.saga';
import { initBannerPromo, showBannerPromo } from 'reactApp/modules/promo/sagas/banner.saga';
import { initBindingSpherumPromoModal, showBindingSpherumPromoModal } from 'reactApp/modules/promo/sagas/bindingSpherum.saga';
import { initDownloadAppPromo, showDownloadAppPromo } from 'reactApp/modules/promo/sagas/downloadApp.saga';
import { initEpubPromo } from 'reactApp/modules/promo/sagas/epub.saga';
import {
    initExpiredDeviceSubscriptionPromo,
    showExpiredDeviceSubscriptionPromo,
} from 'reactApp/modules/promo/sagas/expiredDeviceSubscription.saga';
import { initFaceFilterPromo } from 'reactApp/modules/promo/sagas/faceFilter.saga';
import { initMobileFaceFilterPromo, showMobileFaceFilterPromo } from 'reactApp/modules/promo/sagas/mobileFaceFilter.saga';
import { initMobilePublicBannerPromo, showMobilePublicBannerPromo } from 'reactApp/modules/promo/sagas/mobilePublicBanner.saga';
import { initMobileSearchBanner, showMobileSearchBanner } from 'reactApp/modules/promo/sagas/mobileSearchBanner.saga';
import { initMobileSearchHistoryBanner, showMobileSearchHistoryBanner } from 'reactApp/modules/promo/sagas/mobileSearchHistoryBanner.saga';
import { initOverQuotaPromo, showOverquotaPopup } from 'reactApp/modules/promo/sagas/overQuota.saga';
import { initPublicUploadPromo } from 'reactApp/modules/promo/sagas/publicUpload.saga';
import { initQuotaPromo, showQuotaPopup } from 'reactApp/modules/promo/sagas/quota.saga';
import {
    initMobileSaveSubscriptionPromo,
    initSaveSubscriptionPopup,
    initSaveSubscriptionTopBanner,
    showMobileSaveSubscriptionPromo,
    showSaveSubscriptionPopup,
} from 'reactApp/modules/promo/sagas/saveSubscriptionPromo.saga';
import { initSecondClickPromo } from 'reactApp/modules/promo/sagas/secondClick.saga';
import { initSpacePromo } from 'reactApp/modules/promo/sagas/space.saga';
import { initWhatsNewPromo, showWhatsNewPromo } from 'reactApp/modules/promo/sagas/whatsNew.saga';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { setBottomMarginAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { setUserData } from 'reactApp/modules/user/user.actions';
import { getUserIsLoaded, UserSelectors } from 'reactApp/modules/user/user.selectors';
import { showMobileAppRQ } from 'reactApp/ui/AsidePromoModal/ShowMobileAppRQ';
import { cancel, delay, put, race, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { SagaGenerator, select } from 'typed-redux-saga';

import {
    clearCurrentPromo,
    clearQueue,
    initPromoController,
    promoShown,
    removePromo,
    showMobileAppPromo,
    showPromo,
    skipPromo,
} from './promo.module';
import { PromoSelectors } from './promo.selectors';
import { initCloudNewFeaturesDesktopPromo } from './sagas/cloudNewFeaturesDesktop.saga';
import { initCloudNewFeaturesMobilePromo } from './sagas/cloudNewFeaturesMobile.saga';
import { initDisableAdsBanner } from './sagas/disableAdsBanner';
import { initDiskOAsidePromoModal, showDiskOAsidePromoModal } from './sagas/diskOAsidePromoModal';
import { initFamilyAsidePromoModal, showFamilyAsidePromoModal } from './sagas/familyAsidePromoModal';
import { closeCSATServey, initCSATServey, showCSATServey } from './sagas/initCSATServey';
import { initSafetyAsidePromoModal, showSafetyAsidePromoModal } from './sagas/safetyAsidePromoModal';
import { initSearchTooltipPromo } from './sagas/searchTooltipPromo.saga';
import { initSuggestionsLawPromo, showSuggestionsLawPromo } from './sagas/suggestionsLaw.saga';
import { initTopBanner } from './sagas/topBanner.saga';

const PERIOD_BETWEEN_PROMO = 1 * 1000;
const PROMO_WAIT_PERIOD = 500;
const PROMO_CONTROLLER_CHECK_INTERVAL = 500;

function* awaitUserLoad() {
    const isLoaded = yield* select(getUserIsLoaded);
    if (!isLoaded) {
        yield take(setUserData);
    }
}

function* addOneOf(...args: (() => SagaGenerator<any, any> | void)[]) {
    const queueAtBeginning = yield* select(PromoSelectors.getPromoQueue);
    for (const arg of args) {
        yield arg();
        const queue = yield* select(PromoSelectors.getPromoQueue);
        if (queue.length > queueAtBeginning.length) {
            return;
        }
    }
}

// stops old promoController
promoController.register(
    1,
    Object.assign(new Promo('for-wait'), {
        isEnabled() {
            return true;
        },
    })
);

function firebasePromo() {
    promoController.markAsShown('for-wait');
    (window as any).__FB?.requestPermission();
}

function* fillQueue() {
    const isPhone = yield* select(EnvironmentSelectors.isPhone);
    // заполняем queue, приоритет промок в зависимости от последовательности
    // https://confluence.vk.team/pages/viewpage.action?pageId=915075918

    /**
     * ATTENTION!
     * ALARM!
     * Проверь можно ли твоему баннеру светиться в вэбвью или других узких кейсах как viewContent
     * Полезные флаги: IS_WEBVIEW, isViewContentOnly
     */

    if (isPhone) {
        // Mobile
        yield addOneOf(
            initSuggestionsLawPromo, // https://jira.vk.team/browse/CLOUDWEB-15845
            initMobileSaveSubscriptionPromo,
            initFamilyAsidePromoModal, // https://jira.vk.team/browse/CLOUDWEB-15528
            initSafetyAsidePromoModal,
            initOverQuotaPromo,
            initExpiredDeviceSubscriptionPromo,
            initCloudNewFeaturesMobilePromo,
            initMobilePublicBannerPromo,
            initMobileSearchHistoryBanner,
            initMobileSearchBanner,
            initMobileFaceFilterPromo,
            initDownloadAppPromo
        );
    } else {
        // Desktop
        // Добавлены выше addOneOf(), т.к. немного отличаются в поведении и должны показываться чаще.
        yield initCSATServey(); // https://jira.vk.team/browse/CLOUDWEB-14332
        yield initSecondClickPromo();

        yield addOneOf(
            initSuggestionsLawPromo, // https://jira.vk.team/browse/CLOUDWEB-15845
            initBindingSpherumPromoModal, // https://jira.vk.team/browse/CLOUDWEB-14883
            initSaveSubscriptionPopup, // https://jira.vk.team/browse/CLOUDWEB-14713
            initFamilyAsidePromoModal, // https://jira.vk.team/browse/CLOUDWEB-15528
            initSafetyAsidePromoModal, // https://jira.vk.team/browse/CLOUDWEB-14549
            initOverQuotaPromo, // https://jira.vk.team/browse/CLOUDWEB-12757
            initQuotaPromo, // https://jira.vk.team/browse/CLOUDWEB-11514
            initSpacePromo, // https://jira.vk.team/browse/CLOUDWEB-11345
            initBannerPromo, // https://jira.vk.team/browse/CLOUDWEB-9392
            initWhatsNewPromo, // https://jira.vk.team/browse/CLOUDWEB-12239
            initInitMobAppPromo, // https://jira.vk.team/browse/CLOUDWEB-16735
            initCloudNewFeaturesDesktopPromo,
            // tempexp_SRCH-70119-next-line
            initSearchTooltipPromo,
            initDiskOAsidePromoModal, // https://jira.vk.team/browse/CLOUDWEB-14630
            initAutosaveAsidePromoModal,
            // Мой офис
            initPublicUploadPromo,
            initFaceFilterPromo,
            firebasePromo
        );

        yield initEpubPromo(); // https://jira.vk.team/browse/CLOUDWEB-15467
    }
}

function* queueProcessing() {
    while (true) {
        const queue = yield* select(PromoSelectors.getPromoQueue);
        const current = yield* select(PromoSelectors.getCurrentPromo);
        let promosIndex = 0;
        let isPromoShown = false;

        if (!current && queue.length > 0) {
            // цикл в один прогон по всем промо (выход если покажем хоть одно из них)
            while (promosIndex < queue.length && !isPromoShown) {
                // заполняем currentPromo
                yield put(showPromo(queue[promosIndex].type));

                // ждём отклика от логики (c таймаутом)
                const { done, skip, timeout } = yield race({
                    done: take(promoShown),
                    skip: take(skipPromo),
                    timeout: delay(PROMO_WAIT_PERIOD),
                });

                // небыло отклика или промо скипнули
                if (timeout || skip) {
                    promosIndex++;
                }

                // промо показали, выходим из цикла
                if (done) {
                    isPromoShown = true;
                }
            }

            // вышли из цикла выше с показом промо
            if (isPromoShown) {
                yield race({
                    done: take(removePromo),
                    skip: take(skipPromo),
                });
            } else {
                yield put(clearCurrentPromo());
            }

            yield delay(PERIOD_BETWEEN_PROMO);
        } else if (current) {
            // висит промо по системе pullPromo
            yield delay(PROMO_CONTROLLER_CHECK_INTERVAL);
        } else {
            // очередь пуста
            yield cancel();
        }
    }
}

function* handleInitPromos({ payload }: ReturnType<typeof initPromoController>) {
    if (payload?.disable) {
        // не является попапом и промкой с точки зрения промо контроллета
        closeCSATServey();
    }

    if (IS_REACT_PROMO_NEW_YEAR || IS_FRAME_PAGES || payload?.disable) {
        return;
    }

    try {
        yield awaitUserLoad();
        yield put(clearQueue());
        yield fillQueue();
        yield initSaveSubscriptionTopBanner();
        // tempexp_13536-next-line
        yield initDisableAdsBanner();
        yield initTopBanner();

        yield queueProcessing();
        // недосягаемый код, так как в queueProcessing бесконечный цикл!
    } catch (error) {
        logger.error(error);
        yield cancel();
    }
}

// eslint-disable-next-line complexity
function* handleShowPromo({ payload }: ReturnType<typeof showPromo>) {
    const isNewbie = yield* select(UserSelectors.isNewbie);
    const isFrozen = yield* select(UserSelectors.isFrozen);
    const storage = yield* select(getCurrentStorage);
    const { isPublic, isInlineIntegration, isIntegration } = getStorage(storage);
    const { isTutoria } = yield* select(getIntegrationClient);
    /*
        Промки могут встать в очередь, но не показываем ничего, пока не принято ЛС(не считая пабликов) или если пользователь заморожен
        или если мы в инлайновой интеграции или если интеграция и интеграционный клиент - тутория
    */
    if ((isNewbie && !isPublic) || isFrozen || isInlineIntegration || (isIntegration && isTutoria)) {
        return;
    }

    // Если промка не попала в эти ифы по EPromoType, то значит она показывается где-то в компоненте с помощью PromoSelectors.getPromo()
    if (payload === EPromoType.banner) {
        yield showBannerPromo();
    } else if (payload === EPromoType.whatsNew) {
        yield showWhatsNewPromo();
    } else if (payload === EPromoType.downloadApp) {
        yield showDownloadAppPromo();
    } else if (payload === EPromoType.overQuota) {
        yield showOverquotaPopup();
    } else if (payload === EPromoType.expiredDeviceSubscription) {
        yield showExpiredDeviceSubscriptionPromo();
    } else if (payload === EPromoType.mobileFaceFilter) {
        yield showMobileFaceFilterPromo();
    } else if (payload === EPromoType.mobileSearchHistoryBanner) {
        yield showMobileSearchHistoryBanner();
    } else if (payload === EPromoType.mobileSearchBanner) {
        yield showMobileSearchBanner();
    } else if (payload === EPromoType.mobilePublicBanner) {
        yield showMobilePublicBannerPromo();
    } else if (payload === EPromoType.quota) {
        yield showQuotaPopup();
    } else if (payload === EPromoType.csatServey) {
        yield showCSATServey();
    } else if (payload === EPromoType.mobileSaveSubs) {
        yield showMobileSaveSubscriptionPromo();
    } else if (payload === EPromoType.saveSubsPopup) {
        yield showSaveSubscriptionPopup();
    } else if (payload === EPromoType.diskOAsidePromo) {
        yield showDiskOAsidePromoModal();
    } else if (payload === EPromoType.safetyAsidePromo) {
        yield showSafetyAsidePromoModal();
    } else if (payload === EPromoType.bindingSpherum) {
        yield showBindingSpherumPromoModal();
    } else if (payload === EPromoType.autosave) {
        yield showAutosaveAsidePromoModal();
    } else if (payload === EPromoType.suggestionsLaw) {
        yield showSuggestionsLawPromo();
    } else if (payload === EPromoType.familyAsidePromo) {
        yield showFamilyAsidePromoModal();
    }
}

const FOOTER_HEIGHT = 43;
const ADD_BANNER_HEIGHT = 64;

function* handleShowMobileAppPromo({ payload }: ReturnType<typeof showMobileAppPromo>) {
    const isAnonym = yield select(UserSelectors.isAnonymous);
    const promoData = yield select(PromoSelectors.pullPromo(EPromoType.mobileAppPromo));

    const bottom = IS_PUBLIC_FOLDER ? 0 : FOOTER_HEIGHT + (HIDE_ADS ? 0 : ADD_BANNER_HEIGHT);

    yield put(setBottomMarginAction(bottom));

    if (promoMobileAppPublic === 'a' && !isAnonym && promoData && promoData.tipType?.includes(MobileAppPromoType.public)) {
        yield put(
            showPromoMobileAppSnackbar({
                promoData,
                type: MobileAppPromoType.public,
                url: IS_PUBLIC_FOLDER ? 'https://trk.mail.ru/c/jzwg36' : ' https://trk.mail.ru/c/b7v392',
                from: 'download-public',
                isDarkPopup: payload.isDark,
                popupTitle: 'Наведите камеру на\u00A0QR-код',
                popupText: 'Чтобы скачать Облако на смартфон',
                bottom,
            })
        );
    }

    if (promoMobileAppPublic === 'b' && !isAnonym && promoData && promoData.tipType?.includes(MobileAppPromoType.public)) {
        showMobileAppRQ({
            bottom,
            promoData,
            type: MobileAppPromoType.public,
            url: IS_PUBLIC_FOLDER ? 'https://trk.mail.ru/c/gpvru3' : 'https://trk.mail.ru/c/klxhw8',
            from: 'download-public',
            isDarkPopup: payload.isDark,
            popupTitle: 'Файл скачивается',
            popupText: 'Установите Облако на смартфон, чтобы увеличить память телефона',
        });
    }
}

export function* promoRoot() {
    yield takeEvery(initPromoController.toString(), handleInitPromos);
    yield takeEvery(showPromo.toString(), handleShowPromo);
    yield takeLatest(showMobileAppPromo.toString(), handleShowMobileAppPromo);
}
