import { parse } from 'qs';
import { useCallback, useEffect, useState } from 'react';

interface Error {
    type: 'error';
    message: string;
}

interface Xray {
    type: 'xray';
    event: string;
}

interface Info {
    type: 'ready' | 'start';
}

type Message = Error | Xray | Info;

export interface MessageBase {
    type: string;
}

export type OnMessage<T extends MessageBase = Message> = (data: (Message | T) & { channelId: string }) => void;

interface Props<T extends MessageBase = Message> {
    iframeRef?: HTMLIFrameElement | null;
    isFrameLoaded?: boolean;
    onMessage: OnMessage<T>;
    channelId?: string;
    sendToOrigin?: string;
    isInsideIframe?: boolean;
}

const search = parse(window.location.search, { ignoreQueryPrefix: true });

const checkIframeOrigin = (origin: string) => origin === 'https://cloud.imgsmail.ru';
const checkCloudOrigin = (origin: string) => origin.endsWith('.mail.ru') || origin.endsWith('.mini-mail.ru');

export const useIframeChannel = <T extends MessageBase = Message>({
    iframeRef,
    isFrameLoaded = false,
    onMessage,
    channelId = search?.channelId,
    sendToOrigin = `https://${search?.hostname}`,
    isInsideIframe = true,
}: Props<T>) => {
    const [queue, setQueue] = useState<(Message | T)[]>([]);

    const onMessageHandler = useCallback(
        ({ data, origin }: { data: (Message | T) & { channelId: string }; origin: string }) => {
            // iframe принимает сообщения только от поддоменов Mail
            if (isInsideIframe && process.env.NODE_ENV === 'production' && (sendToOrigin !== origin || !checkCloudOrigin(origin))) {
                return;
            }

            // Облако принимает сообщения только от поддоменов CDN
            if (!isInsideIframe && process.env.NODE_ENV === 'production' && (sendToOrigin !== origin || !checkIframeOrigin(origin))) {
                return;
            }

            if (!data.type || channelId !== data.channelId) {
                return;
            }

            onMessage(data);
        },
        [onMessage, sendToOrigin, isInsideIframe, channelId]
    );

    useEffect(() => {
        window.addEventListener('message', onMessageHandler);
        return () => window.removeEventListener('message', onMessageHandler);
    }, [onMessageHandler]);

    const postMessage = useCallback(
        (content: Message | T) => {
            if (!isInsideIframe && iframeRef && isFrameLoaded) {
                iframeRef?.contentWindow?.postMessage(
                    { channelId, ...content },
                    process.env.NODE_ENV === 'production' ? sendToOrigin : '*'
                );
            } else if (!isInsideIframe) {
                setQueue((arr) => [...arr, { ...content }]);
            } else if (isInsideIframe) {
                parent.postMessage({ channelId, ...content }, process.env.NODE_ENV === 'production' ? sendToOrigin : '*');
            }
        },
        [channelId, iframeRef, isFrameLoaded, isInsideIframe, sendToOrigin]
    );

    useEffect(() => {
        if (!isInsideIframe && iframeRef && isFrameLoaded && queue.length) {
            queue.forEach((item) => {
                postMessage(item);
            });

            setQueue([]);
        }
    }, [iframeRef, isFrameLoaded, isInsideIframe, queue]);

    return { postMessage };
};
