import { ClientError } from './ClientError';

class CancelError extends ClientError {
    static radarName = 'failure_{SOURCE}_cancel';

    className = 'CancelError';
    message = 'Запрос отменен';

    /**
     * @constructor
     * @extends {CancelError}
     * @param {Error} stack
     * @param {string} source
     */
    constructor() {
        super(...arguments);
    }
}

export { CancelError };
