import { AccessStatus, EFrom } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import { AlbumItem } from 'reactApp/modules/albums/albums.types';
import { EditorItem } from 'reactApp/modules/editor/editor.types';
import { FavoriteFile } from 'reactApp/modules/favorites/favorites.types';
import { FeedItem } from 'reactApp/modules/feed/feed.types';
import { GalleryItem } from 'reactApp/modules/gallery/gallery.types';
import { HomeItem } from 'reactApp/modules/home/home.types';
import { WeblinkDownloadsCounts } from 'reactApp/modules/public/public.types';
import { SearchItem } from 'reactApp/modules/search/search.types';
import { SharedItem } from 'reactApp/modules/shared/shared.type';
import { CloudFile, CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { AccessRights, Kind, Sort, VirusScan } from 'reactApp/types/Tree';

export interface IRenameItem {
    item: CloudItem;
    newId: string;
}

export type PublishItem = FavoriteFile | HomeItem | EditorItem | SharedItem | FeedItem | GalleryItem | SearchItem | AlbumItem;

export interface AddFile extends WeblinkDownloadsCounts {
    virus_scan: VirusScan;
    type: 'file';
    home: string;
    weblink?: string;
    size: number;
    name: string;
    kind: Kind;
    hash: string;
    id: string;
    mtime: number;
    descriptorId?: string;
}

interface AddFolder extends WeblinkDownloadsCounts {
    virus_scan: VirusScan;
    type: 'folder';
    home?: string;
    tree: string;
    rev: number;
    grev: number;
    weblink?: string;
    size: number;
    name: string;
    kind: Kind;
    id: string;
    mtime: number;
    count: {
        folders: number;
        files: number;
    };
}

export type TItem = 'folder' | 'file';

export type AddItem = AddFile | AddFolder;

export interface AddFileSuccessAction {
    parent: string;
    storage: EStorageType;
    items: AddItem[];
    reason?: EModifyReason;
    allowedExt?: string[];
}

export interface UploadFileEndedAction {
    ids: string[];
}

export interface RemoveFileEndedAction {
    ids: string[];
}

export interface CopyFileEndedAction {
    ids: string[];
}

export interface RemoveFileSuccessAction {
    ids: string[];
    reason?: EModifyReason;
}

export interface MoveItemsSuccessAction {
    storage: EStorageType;
    oldIds: string[];
    newItems: CloudItem[];
    newParent: string;
}

export interface UpdateItemAction {
    count?: {
        folders: number;
        files: number;
    };
    tree: string;
    name: string;
    grev: number;
    kind: Kind;
    rev: number;
    type: TItem;
    hash?: string;
    home: string;
    mtime: number;
    size: number;
}

export interface SetSortAction {
    sort: Sort;
    id: string;
}

export interface MountActionSuccess {
    id: string;
    home?: string;
}

export interface ToggleWeblinkAccessRights {
    id: string;
    type?: AccessRights;
}

export interface ToggleWeblinkDownloadable {
    id: string;
    downloadable?: boolean;
}

export interface UpdateWeblinkCountDownloads {
    id: string;
    count_downloads: number;
}

export interface ResetWeblinkCountDownloads {
    id: string;
}

export interface UnmountActionSuccess {
    id: string;
}

export interface UpdateWeblinkAction {
    id: string;
    weblink: string;
    item?: CloudItem;
}

export interface UnpublishWeblinkAction {
    ids: string[];
}

export interface UpdateWeblinkAutoDelete {
    id: string;
    autoDelete: boolean;
}

export interface UpdateWeblinkAutoDeleteRequest {
    item: PublishItem;
    expires: number;
}

export interface UpdateWeblinkExpires {
    id: string;
    expires: number;
    expiresTimeSize?: number;
}

export interface UpdateWeblinkExpiresRequest {
    item: PublishItem;
    expires: number;
    expiresTimeSize?: number;
}

export interface DeleteWeblinkExpiresRequest {
    item: PublishItem;
}

export interface ToggleWeblinkDomesticRequest {
    item: PublishItem;
}

export interface ToggleWeblinkDomestic {
    id: string;
    domestic: boolean;
}

export interface ToggleWeblinkEditableRequest {
    id?: AccessStatus;
    item: PublishItem;
}

export interface ToggleWeblinkDownloadableRequest {
    item: PublishItem;
    isUnpublishRequest?: boolean;
}

export interface UpdateWeblinkCountDownloadsRequest {
    item: PublishItem;
    count_downloads: number;
}

export interface ToggleWeblinkUploadRequest {
    item: PublishItem;
    id?: AccessStatus;
}

export interface PublishAutoDeleteFolderAction {
    item: PublishItem;
}

export interface ICreateFolderAction {
    parentFolderId: string;
    showCreateButton?: boolean;
    showShareButton?: boolean;
    autodelete?: boolean;
    gaPrefix?: string;
    storage?: EStorageType;
    onCreateFolder?: (folderName: string) => void;
    onClose?: () => void;
}

export interface ICreateFolderSuccess {
    folderName: string;
    share?: boolean;
    gaPrefix?: string;
    storage?: EStorageType;
}

export enum EModifyReason {
    copy = 'copy',
    move = 'move',
    remove = 'remove',
    add = 'add',
    rename = 'rename',
    favorites = 'favorites',
}

export interface ICopyItems {
    destination: string;
    items: CloudItem[];
}

export interface IMoveItems {
    destination: string;
    items: CloudItem[];
}

export interface IState {
    isModifyingInProgress: boolean;
    isInlineEditInProgress: boolean;
    // tempexp_14373-next-line
    inlineEditItemId?: string;
}

export enum ESnackbarType {
    COPY = 'copy',
    MOVE = 'move',
    REMOVE = 'remove',
    SAVE_TO_CLOUD = 'saveToCloud',
}

export interface IRemoveItems {
    items: CloudItem[];
    // Поднимать диалог удаления
    withRemoveDialog: boolean;
    onSuccess?(): void;
}

export interface IDownloadItem {
    id?: string;
    storage?: EStorageType;
}

export interface ICreateNewItem {
    type: string;
    from: string;
    showShareOnlyButton?: boolean;
    showShareButton?: boolean;
    inHomeRoot?: boolean;
    gaPrefix?: string;
}

export interface IRenameItemSuccess {
    newItem: CloudItem;
    oldId: string;
}

export interface IModifyingStopData {
    id?: string;
    storage?: EStorageType;
    reason?: EModifyReason;
}

export interface ILoadMoreRequest {
    id: string;
    storage: EStorageType;
    offset?: number;
    loadOnlyIfLessThanLimit?: boolean;
}

export interface IPublishRequest {
    item: PublishItem;
    itemStorage?: EStorageType;
    publishFrom?: string;
    respectDomesticByDefault?: boolean;
}

export interface IUnPublishRequest {
    items: PublishItem[];
}

export interface IOpenPublishDialog {
    item: any;
    publishFrom?: string;
    defaultAccessRights?: AccessStatus;
    gaSuffix?: string;
    autoPublish?: boolean;
    itemStorage?: EStorageType;
    onClose?: () => void;
    from?: EFrom;
}

export interface IMountRequest {
    item: PublishItem;
}

export interface IUnmountRequest {
    item: PublishItem;
    onUnmount?: () => void;
}

export interface IShareFolderSuccess {
    id: string;
}

export interface IUnshareFolderSuccess {
    id: string;
}

export interface IAddToFavoritesSuccess {
    ids: string[];
}

export interface IAddToFavoritesRequest {
    items: CloudItem[];
    from: string;
}

export interface IRemoveFromFavoritesSuccess {
    ids: string[];
}

export interface IRemoveFromFavoritesRequest {
    items: CloudItem[];
}

export interface ICloneToCloudRequest {
    items: CloudItem[];
    autoClone?: boolean;
    noOkSnackbar?: boolean;
    destination?: string;
    source?: string;
}

export interface EditPublicCopyItem {
    item: CloudFile;
    source: string;
    storage: EStorageType;
}

export interface EditPdfCopyItem {
    item: CloudFile;
    onPdfEdit: (item: CloudFile | null) => void;
}

export enum EInlineEditPlace {
    breadcrumbs = 'breadcrumbs',
    datalistRow = 'datalist_row',
    datalistThumbs = 'datalist_thumbs',
}

export interface IInlineEditSuccess {
    id: string;
    place: EInlineEditPlace;
}
