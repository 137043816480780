import dateTime from 'lib/dateTime';

class Failure {
    static ERROR_WRONG_INSTANCE = 'Failure called on non-instance of Failure';
    static ERROR_ABSTRACT_CLASS = 'Failure is abstract class';
    static ERROR_WRONG_SOURCE = 'wrong Failure source';

    static SOURCE_SERVER = 'server';
    static SOURCE_CLIENT = 'client';
    static SOURCE_USER = 'user';

    /**
     * @type {string}
     */
    static radarName = 'failure_{SOURCE}_abstract';

    /**
     * @constructor
     * @param {Error} stack – ошибка для быстрой навигации по коду.
     * @param {string} source – источник проблемы: backend, web-backend, web-client.
     */
    constructor({ source, id, pageId, user, stack }) {
        if (!(this instanceof Failure)) {
            throw new TypeError(Failure.ERROR_WRONG_INSTANCE);
        }

        if (this.constructor === Failure) {
            throw new TypeError(Failure.ERROR_ABSTRACT_CLASS);
        }

        this.meta = {};

        this.timeStamp = Date.now();
        this.stack = stack || new Error(this.className).stack;
        this.source = source;
        this.id = id;
        this.user = user;
        this.pageId = pageId;
        this.radarName = this.constructor.radarName.replace('{SOURCE}', source);
    }

    /**
     * @type {string}
     */
    className = 'Failure';

    /**
     * @type {string}
     */
    message = 'Произошла ошибка.';

    /**
     * Источник ошибки: server, client, user.
     * @type {string}
     */
    source = 'abstract';

    /**
     * @type {number}
     */
    timeStamp = -1;

    /**
     * Возможность повторить попытку.
     * @type {boolean}
     */
    isRetryable = false;

    maxRetries = 1;
    retryTimeout = 0;

    /**
     * Ошибка для быстрой навигации по коду.
     * @type {Error}
     */
    stack = null;

    /**
     * operation.id
     * @type {string}
     */
    id = '';

    pageId = '';
    user = '';

    /**
     * @type {Object}
     */
    meta = null;

    /**
     * @type {string}
     */
    timePattern = 'hh:ss:mm.SSS(z)';

    /**
     * @type {string}
     */
    toStringPattern = '{MESSAGE}';

    toString() {
        let result = this.toStringPattern;

        result = result.replace('{MESSAGE}', this.message);

        return result;
    }

    /**
     * @param {string} id
     */
    setId(id) {
        this.id = id;
    }

    /**
     * @param {string} name
     * @param {string} value
     */
    setMeta(name, value) {
        this.meta[name] = value;
    }

    _getMetaString() {
        let meta = this.meta;
        let metaString = [];

        Object.keys(meta).forEach(function(name) {
            metaString.push(name + '=' + meta[name]);
        });

        return metaString.join(', ');
    }

    /**
     * @type {string}
     */
    toLogStringPattern = '[{TIME}] {USER} x-page-id={PAGE_ID} id={ID} {SOURCE} {CLASS_NAME} { {META} }';

    toLogString() {
        let time = dateTime.format(this.timeStamp, this.timePattern);
        let result = this.toLogStringPattern;

        result = result.replace('{TIME}', time);
        result = result.replace('{ID}', this.id);
        result = result.replace('{PAGE_ID}', this.pageId);
        result = result.replace('{USER}', this.user || 'anonym');
        result = result.replace('{SOURCE}', this.source);
        result = result.replace('{CLASS_NAME}', this.className);
        result = result.replace('{META}', this._getMetaString());

        return result;
    }

    getErrorStack() {
        return this.stack;
    }

    /**
     * @returns {Object}
     */
    getDetails({ stack }) {
        let details = {
            id: this.id,
            pageId: this.pageId,
            user: this.user,
            source: this.source,
            type: this.className,
            timeStamp: this.timeStamp,
            meta: this.meta,
        };

        if (stack) {
            details.stack = this.getErrorStack();
        }

        return details;
    }
}

export { Failure };
