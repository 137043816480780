import { Icon28Rectangle2Outline, Icon28Square4Outline } from '@vkontakte/icons';
import { ReactComponent as UploadIcon } from 'img/icons/mobile/add.svg';
import { ReactComponent as AuthorsIcon } from 'img/icons/mobile/authors.svg';
import { ReactComponent as CloneIcon } from 'img/icons/mobile/clone.svg';
import { ReactComponent as CopyIcon } from 'img/icons/mobile/copy.svg';
import { ReactComponent as FacesIcon } from 'img/icons/mobile/faces.svg';
import { ReactComponent as InfoIcon } from 'img/icons/mobile/info.svg';
import { ReactComponent as LinkIcon } from 'img/icons/mobile/linkAccess.svg';
import { ReactComponent as MoveIcon } from 'img/icons/mobile/move.svg';
import { ReactComponent as RemoveIcon } from 'img/icons/mobile/remove.svg';
import { ReactComponent as RenameIcon } from 'img/icons/mobile/rename.svg';
import { ReactComponent as RestoreIcon } from 'img/icons/mobile/restore.svg';
import { ReactComponent as DownloadIcon } from 'img/icons/mobile/save.svg';
import { ReactComponent as ShareIcon } from 'img/icons/mobile/share.svg';
import { ReactComponent as ShareLinkIcon } from 'img/icons/mobile/shareLink.svg';
import { ReactComponent as SortDateIcon } from 'img/icons/mobile/sortDate.svg';
import { ReactComponent as SortNameIcon } from 'img/icons/mobile/sortName.svg';
import { ReactComponent as SortSizeIcon } from 'img/icons/mobile/sortSize.svg';
import { ReactComponent as ThumbsIcon } from 'img/icons/mobile/thumbs.svg';
import { ReactComponent as SortAscIcon } from 'img/icons/sortAsc.svg';
import { ReactComponent as SortDescIcon } from 'img/icons/sortDesc.svg';
import React from 'react';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { MAIL_ATTACHES_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { VIEW_MODE_THUMBS_TEXT } from 'reactApp/constants/viewMode';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { ESortOder, ESortTypes } from 'reactApp/modules/sort/sort.types';
import { EActions, EStorageType } from 'reactApp/modules/storage/storage.types';
import { AuthorsCounter } from 'reactApp/ui/Mobile/AuthorsCounter/AuthorsCounter';
import { FacesCounter } from 'reactApp/ui/Mobile/FacesCounter/FacesCounter';
import { renderFileInfoModal } from 'reactApp/ui/Mobile/FileInfoModal/FileInfoModal.helpers';
import { renderFilterFacesModal } from 'reactApp/ui/Mobile/FilterFacesModal/FilterFacesModal.helpers';
import { IOptionBlock, IOptionItem } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.types';
import { UploadInput } from 'reactApp/ui/Mobile/UploadInput/UploadInput';
import { noopVoid } from 'reactApp/utils/helpers';

import { renderFilterAuthorsModal } from '../FilterAuthorsModal/FIlterAuthorsModal.helpers';
import styles from './OptionsModal.css';

export const sortHeaderMap: Record<ESortOder, { sortHeader: string; SortIcon: typeof SortDescIcon }> = {
    asc: {
        sortHeader: 'По возрастанию',
        SortIcon: SortAscIcon,
    },
    desc: {
        sortHeader: 'По убыванию',
        SortIcon: SortDescIcon,
    },
};

export const optionsItems: { [key: string]: IOptionItem } = {
    viewThumbs: {
        id: 'viewThumbs',
        text: VIEW_MODE_THUMBS_TEXT,
        icon: <Icon28Square4Outline />,
        onClick: () => toolbarActions.view(EViewMode.thumbs),
    },
    viewList: {
        text: 'Списком',
        id: 'viewList',
        icon: <Icon28Rectangle2Outline />,
        onClick: () => toolbarActions.view(EViewMode.list),
    },
    viewGalleryGrid3: {
        text: '3 объекта',
        id: 'viewGalleryGrid3',
        icon: <ThumbsIcon />,
        onClick: () => toolbarActions.view(EViewMode.galleryGrid3),
    },
    sortName: {
        id: 'sortName',
        text: 'По алфавиту',
        icon: <SortNameIcon />,
    },
    sortSize: {
        id: 'sortSize',
        text: 'По размеру',
        icon: <SortSizeIcon />,
    },
    sortDate: {
        id: 'sortDate',
        text: 'По дате',
        icon: <SortDateIcon />,
    },
    authors: {
        id: 'authors',
        text: 'По участникам',
        icon: <AuthorsIcon />,
        rightItem: <AuthorsCounter />,
    },
    faces: {
        id: 'faces',
        text: 'Люди на фото',
        icon: <FacesIcon />,
        rightItem: <FacesCounter isOptionsModal />,
    },
    onlyMine: {
        id: 'onlyMine',
        text: 'Показывать только мои файлы',
        icon: <AuthorsIcon />,
    },
    remove: {
        id: 'remove',
        text: 'Удалить',
        icon: <RemoveIcon />,
    },
    info: {
        id: 'info',
        icon: <InfoIcon />,
        text: 'Информация о',
    },
    more: {
        id: 'more',
        icon: <InfoIcon />,
        text: 'Больше действий...',
    },
};

export const getActionsItems = ({
    isAnonymous,
    onUpload,
    onDownload,
    id,
    storage,
    weblink,
    sendAnalytics,
}): Partial<Record<EActions, IOptionItem>> => {
    const isAttaches = storage === EStorageType.attaches || storage === EStorageType.viewerAttaches;

    return {
        [EActions.restore]: {
            id: EActions.restore,
            text: 'Восстановить',
            icon: <RestoreIcon height={28} width={28} />,
            onClick: () => toolbarActions.restore(id),
        },
        [EActions.upload]: {
            id: EActions.upload,
            text: 'Загрузить файлы',
            icon: <UploadIcon height={28} width={28} />,
            onClick: onUpload,
            component: isAnonymous ? undefined : (
                <div className={styles.upload}>
                    <UploadInput inputName="public-options" />
                </div>
            ),
        },
        [EActions.shareLink]: {
            id: EActions.shareLink,
            text: 'Отправить ссылку',
            icon: <ShareLinkIcon height={28} width={28} />,
            onClick: () => toolbarActions.shareLink({ id }),
        },
        [EActions.clone]: {
            id: EActions.clone,
            icon: <CloneIcon height={28} width={28} />,
            text: 'Сохранить в Облако',
            onClick: () =>
                toolbarActions.clone({
                    id,
                    storage,
                    source: 'modal',
                    destination: isAttaches ? MAIL_ATTACHES_FOLDER_ID : '',
                }),
        },
        [EActions.download]: {
            id: EActions.download,
            text: 'Скачать',
            icon: <DownloadIcon height={28} width={28} />,
            onClick: onDownload,
        },
        [EActions.publish]: {
            id: EActions.publish,
            text: weblink ? 'Настроить доступ' : 'Поделиться',
            icon: weblink ? <LinkIcon height={28} width={28} /> : <ShareIcon height={28} width={28} />,
            onClick: () => {
                sendAnalytics?.({ action: 'new_public' });
                sendAnalytics?.({ action: 'open_public' });
                toolbarActions.publish('options', id, storage);
            },
        },
        [EActions.rename]: {
            id: EActions.rename,
            text: 'Переименовать',
            icon: <RenameIcon height={28} width={28} />,
            onClick: () => toolbarActions.rename(id),
        },
        [EActions.move]: {
            id: EActions.move,
            text: 'Переместить',
            icon: <MoveIcon height={28} width={28} />,
            onClick: () => {
                toolbarActions.move(id, sendAnalytics);
            },
        },
        [EActions.copy]: {
            id: EActions.copy,
            text: 'Копировать',
            icon: <CopyIcon height={28} width={28} />,
            onClick: () => {
                toolbarActions.copy(id, sendAnalytics);
            },
        },
    };
};

export const getActionsBlock = ({ onlyActions, actionsList }): IOptionBlock => ({
    id: 'actions',
    header: onlyActions ? '' : 'Действия с папкой',
    items: actionsList,
});

export const getSortBlock = ({ changeSortOrder, sort }): IOptionBlock => {
    const { sortHeader, SortIcon } = sortHeaderMap[sort.order || ESortOder.asc];

    return {
        id: 'sort',
        header: 'Сортировка',
        items: [
            {
                ...optionsItems.sortDate,
                active: sort.type === ESortTypes.mtime,
                onClick: () => toolbarActions.sort(`${ESortTypes.mtime}-${sort.order}`),
            },
            {
                ...optionsItems.sortSize,
                active: sort.type === ESortTypes.size,
                onClick: () => toolbarActions.sort(`${ESortTypes.size}-${sort.order}`),
            },
            {
                ...optionsItems.sortName,
                active: sort.type === ESortTypes.name,
                onClick: () => toolbarActions.sort(`${ESortTypes.name}-${sort.order}`),
            },
        ],
        headerComponent: (
            <div className={styles.headerComponent} onClick={changeSortOrder} data-qa-order={sort.order}>
                {sortHeader}
                <div className={styles.sortIcon}>{<SortIcon height={20} width={20} />}</div>
            </div>
        ),
    };
};

export const getFilteredAuthorsBlock = ({ resetFilter }): IOptionBlock => ({
    id: 'filter',
    header: 'Фильтр',
    items: [
        {
            ...optionsItems.authors,
            onClick: renderFilterAuthorsModal,
        },
    ],
    headerComponent: (
        <div className={styles.headerComponent} onClick={resetFilter}>
            Сбросить
        </div>
    ),
});

export const getRemoveBlock = ({ id, sendAnalytics }): IOptionBlock => ({
    id: 'remove',
    items: [
        {
            ...optionsItems.remove,
            onClick: () => {
                toolbarActions.remove(id, sendAnalytics);
            },
        },
    ],
});

export const getViewBlock = ({ viewMode, storage, isNewMobileGalleryGridEnabled }) => {
    const blockInfo = {
        id: 'view',
        header: 'Формат отображения',
        items: [
            {
                ...optionsItems.viewThumbs,
                active: viewMode === EViewMode.thumbs,
            },
            {
                ...optionsItems.viewList,
                active: viewMode === EViewMode.list,
            },
        ],
    };

    /**
     * TODO: CLOUDWEB-13281. Cетка 'galleryGrid3' плохо перформит.
     * Убрали под фичу.
     */
    if (storage === EStorageType.gallery && isNewMobileGalleryGridEnabled) {
        const galleryViewModes = [
            {
                ...optionsItems.viewGalleryGrid3,
                active: viewMode === EViewMode.galleryGrid3,
            },
        ];

        blockInfo.items = [...galleryViewModes, ...blockInfo.items];
    }

    return blockInfo;
};

export const getFilterBlock = ({ filtered, items, resetFilter = noopVoid }): IOptionBlock => ({
    id: 'filter',
    header: 'Фильтр',
    items,
    headerComponent: filtered ? (
        <div className={styles.headerComponent} onClick={resetFilter}>
            Сбросить
        </div>
    ) : undefined,
});

export const getFileInfoItem = ({ id, isFolder, storage, isArchive, callback }): IOptionItem => ({
    ...optionsItems.info,
    text: `${optionsItems.info.text}${isFolder ? ' папке' : isArchive ? 'б архиве' : ' файле'}`,
    onClick: () => {
        callback();
        return renderFileInfoModal({ id, storage, isArchiveInfo: isArchive });
    },
});

export const getFacesOnFileItem = ({ fileId }): IOptionItem => ({
    ...optionsItems.faces,
    rightItem: undefined,
    onClick: () => renderFilterFacesModal({ fileId }),
});

export const getMoreBlock = () => ({
    id: 'more',
    items: [
        {
            ...optionsItems.more,
            onClick: () => {
                toolbarActions.more();
            },
        },
    ],
});
