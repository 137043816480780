import { IS_DOCUMENTS_DOMAIN } from 'reactApp/appHelpers/configHelpers';
import { changeDocumentDomainToCloud } from 'reactApp/utils/documentsDomain';

export const PRODUCT_ID = 'W128G1m_pro_promo_mail';

export enum EFeatures {
    space = 'space',
    ad = 'ad',
    mail = 'mail',
    support = 'support',
    upload = 'upload',
    safety = 'safety',
    interest = 'interest',
    document = 'document',
    gift = 'gift',
    settings = 'settings',
    quota = 'quota',
    singleQuota = 'singleQuota',
    control = 'control',
}

export enum ETabs {
    quota = 'quota',
    features = 'features',
    tariffs = 'tariffs',
    questions = 'questions',
    apps = 'apps',
    feedback = 'feedback',
    help = 'help',
    cleaner = 'cleaner',
    family = 'family',
}

export const QuotaLandingHashTabs = {
    '#price': ETabs.tariffs,
    '#start': ETabs.quota,
    '#cleaner': ETabs.cleaner,
    '#faq': ETabs.questions,
    '#benefits': ETabs.features,
};

export const QUOTA_LANDING_SIMPLE_TABS = [ETabs.features, ETabs.questions];

export const QUOTA_BILLING_SOURCE = 'union_quota_landing';

export const QUOTA_SUBS_NAME = 'Mail Space';

export const QUOTA_CLEANER_URL = IS_DOCUMENTS_DOMAIN
    ? `${changeDocumentDomainToCloud(window.location.origin)}/promo/quota/#cleaner`
    : `${window.location.origin}/promo/quota/#cleaner`;

export const QUOTA_START_URL = IS_DOCUMENTS_DOMAIN
    ? `${changeDocumentDomainToCloud(window.location.origin)}/promo/quota/#start`
    : `${window.location.origin}/promo/quota/#start`;

export const QUOTA_URL = IS_DOCUMENTS_DOMAIN
    ? `${changeDocumentDomainToCloud(window.location.origin)}/promo/quota`
    : `${window.location.origin}/promo/quota`;
