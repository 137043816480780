import { useEffect, useMemo, useState } from 'react';
import { isAttachFile } from 'reactApp/modules/attaches/attaches.helpers';
import { Ovidius } from 'reactApp/modules/ovidius';
import { OvidiusPart } from 'reactApp/modules/ovidius/types';

import { FileError, UseOvidiusData, UseOvidiusError, UseOvidiusProps } from './types';

export const useHomeOvidius = ({ file, storage, sendRadar }: UseOvidiusProps): UseOvidiusData => {
    const [partsAmount, setPartsAmount] = useState<number>(0);
    const [content, setContent] = useState<ArrayBuffer[]>([]);
    const [partsInfo, setPartsInfo] = useState<Array<OvidiusPart>>([]);
    const [error, setError] = useState<UseOvidiusError | null>(null);

    const attachType = useMemo(() => (isAttachFile(file) ? file.attachType : undefined), [file]);

    useEffect(() => {
        setPartsAmount(0);
        setContent([]);
        setPartsInfo([]);
        setError(null);
    }, [file, storage]);

    useEffect(() => {
        if (attachType) {
            return;
        }

        const controller = new AbortController();

        (async () => {
            const { partsInfo, firstPart } = await Ovidius.getPdfFileInfo({ file, storage, signal: controller.signal });

            if (!partsInfo) {
                sendRadar('no-info', false, true);
                setError(FileError.Info);
                return;
            }

            if (!firstPart) {
                sendRadar('no-f-part', false, true);
                setError(FileError.FirstPart);
                return;
            }

            const { parts, parts_total: partsTotal } = partsInfo;

            setPartsInfo(parts);
            setContent([firstPart]);
            setPartsAmount(partsTotal);

            if (parts.length === 1) {
                sendRadar('one-part');
            } else {
                sendRadar('first-part');
            }
        })();

        return () => {
            controller.abort();
        };
    }, [file, storage]);

    useEffect(() => {
        if (!partsInfo.length) {
            return;
        }

        if (partsInfo.length === 1) {
            sendRadar('all-parts');
            return;
        }

        const controller = new AbortController();

        (async () => {
            const getPdfFilePartInfo = Ovidius.getPdfFilePart({ file, storage, signal: controller.signal });

            for (let i = 1; i < partsInfo.length; i++) {
                const { id } = partsInfo[i];
                const { content: newPart } = await getPdfFilePartInfo(id);

                if (!newPart) {
                    sendRadar('no-part', false, true);
                    setError(FileError.SecondPart);
                    return;
                }

                setContent((oldContent) => [...oldContent, newPart]);
            }

            sendRadar('all-parts');
        })();

        return () => {
            controller.abort();
        };
    }, [partsInfo]);

    return {
        content,
        partsAmount,
        error,
    };
};
