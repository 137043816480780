import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Icon20CloudOutline } from '@vkontakte/icons';
import { Caption, Spacing, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, ReactEventHandler, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ACTION_PROMO, BASEQUOTA } from 'reactApp/appHelpers/configHelpers';
import { crossedFullPriceInTariffCard, upsaleMonthTariffPromo } from 'reactApp/appHelpers/featuresHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { useBuyClick } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/useBuyClick';
import { TariffCard } from 'reactApp/ui/TariffCardNew/TariffCard';
import { ETariffCardSizeButtonSize, ETariffCardWidth } from 'reactApp/ui/TariffCardNew/TariffCard.types';
import { useButtonAdditionalText } from 'reactApp/ui/TariffSectionNew/hooks/useButtonAdditionalText';
import { useFeatures } from 'reactApp/ui/TariffSectionNew/hooks/useFeatures';
import { usePrice } from 'reactApp/ui/TariffSectionNew/hooks/usePrice';
import { useSwitchProducts } from 'reactApp/ui/TariffSectionNew/hooks/useSwitchProducts';
import { useTariffLabel } from 'reactApp/ui/TariffSectionNew/hooks/useTariffLabel';
import { noopVoid } from 'reactApp/utils/helpers';
import { isMonthPeriod, isYearPeriod } from 'reactApp/utils/Period';

import styles from './TariffPlansCard.css';
import { Props } from './TariffPlansCard.types';

export const TariffInfoBlock = ({
    newQuota,
    isBase,
    isMobile,
    isSkeleton = false,
}: {
    newQuota: number;
    isBase?: boolean;
    isMobile?: boolean;
    isSkeleton?: boolean;
}) => (
    <>
        {!isMobile && <Spacing size={24} />}
        {isBase ? (
            <Spacing size={32} />
        ) : (
            <div className={styles.infoBlock}>
                {isSkeleton ? (
                    <div className={styles.skeleton} />
                ) : (
                    <>
                        <Icon20CloudOutline />{' '}
                        <Caption level="1" weight="2">
                            Ваш объём составит {bytesToNDigits(newQuota, 3).value}
                        </Caption>
                    </>
                )}
            </div>
        )}
        {isMobile && <Spacing size={30} />}
    </>
);

export const TariffPlansCard = memo<Props>(
    ({
        tariff,
        isVisible = true,
        isIOSPromo = false,
        isAndroidPromo = false,
        setCarouselLoaded,
        isMobilePayAvailable,
        isMobilePayFromGetParam,
        activeTab,
        isNewLanding = false,
    }) => {
        const totalUserQuota = useSelector(UserQuotaSelectors.getTotal);
        // tempexp_16521-start
        const { isLoaded: isQuotaLoaded } = useSelector(UserQuotaSelectors.getLoadingState);
        const userBaseQuota = useSelector(UserSelectors.getBasicSpace) || totalUserQuota?.original || BASEQUOTA;
        const isAnonymous = useSelector(UserSelectors.isAnonymous);
        // tempexp_16521-end
        const isMobile = EnvironmentSelectors.isMobile();

        const { tariffId, products, isBase, isRecommended } = tariff;

        const { showSwitch, product, onClickSwitch, switchChecked, switchOptions, yearSavingDiscount, monthSavingDiscount } =
            useSwitchProducts({
                products,
                activeTab,
                withSwitch: !isNewLanding,
            });
        const monthDiscountPercent = crossedFullPriceInTariffCard.month && isMonthPeriod(product.period) ? monthSavingDiscount : 0;

        const [priceAsText, discount, discountPriceAsText, productMonthPriceAsText, defaultMonthPrice] = usePrice({
            product,
            periodDivider: isNewLanding ? 'в' : '/',
        });
        const [showLabel, label, labelKind] = useTariffLabel({
            product,
            skipDefaultLabel: true,
            isRecommended: isRecommended && !discount,
            primaryLabel: isRecommended && isNewLanding ? 'Выгодно' : '',
            isBase: isBase && isNewLanding,
            showDiscount: !isNewLanding,
        });

        const [featureList] = useFeatures(product, isNewLanding, isNewLanding);

        const buttonAdditionalText = useButtonAdditionalText(product, crossedFullPriceInTariffCard);

        const showCrossedPrice =
            (isYearPeriod(product.period) && crossedFullPriceInTariffCard.year) ||
            (isMonthPeriod(product.period) && crossedFullPriceInTariffCard.month);

        const onBuyClick = useBuyClick({
            isMobilePayAvailable,
            isMobilePayFromGetParam,
            isIOSPromo,
            isAndroidPromo,
        });

        const onButtonClick: ReactEventHandler = useCallback(
            (event) => {
                event.stopPropagation();

                if (upsaleMonthTariffPromo && isMonthPeriod(product.period)) {
                    openPopupHelper({
                        popupName: popupNames.UPSALE_MONTH_TARIFF,
                        data: {
                            monthProduct: products.monthProduct,
                            yearProduct: products.yearProduct,
                            yearSavingDiscount,
                            activePeriod: upsaleMonthTariffPromo,
                            resolve: (productId: string, price: number) => {
                                onBuyClick({ productId, price, paySource: 'modal_choose_tariff' });
                            },
                        },
                    });
                } else {
                    onBuyClick({
                        productId: product.id,
                        price: product.price,
                    });
                }
            },
            [product.period, product.id, product.price, products.monthProduct, products.yearProduct, yearSavingDiscount, onBuyClick]
        );

        useEffect(() => {
            /** Это сделано именно здесь для того, чтобы дожидаться рендера тарифов со всем контентом внутри
             * для корректного скролла по якорю из ссылки на лендинг единой квоты
             * В родительских элементах useEffect срабатывает слишком рано, до формирования листа тарифов */
            if (isMobile) {
                return;
            }
            setCarouselLoaded?.(true);
        }, []);

        return (
            <div
                className={classNames(styles.tariffCard, {
                    [styles.tariffCardMobile]: isMobile && !isNewLanding,
                    [styles.tariffCard_invisible]: !isVisible,
                })}
            >
                <TariffCard
                    onClick={noopVoid}
                    dataQa="QuotaLpTariffCard"
                    tariffId={tariffId}
                    width={ETariffCardWidth.fluid}
                    focused={isRecommended}
                    buttonPrimary={(!isBase && !isNewLanding) || (isNewLanding && isRecommended)}
                    buttonDisabled={isBase}
                    buttonText={isBase ? 'Уже включено' : `Купить за ${discountPriceAsText || priceAsText}`}
                    onClickButton={onButtonClick}
                    space={{
                        space: product.space.space,
                        units: product.space.units,
                    }}
                    featureListOptions={featureList}
                    {...(isNewLanding && isRecommended && Boolean(ACTION_PROMO)
                        ? {
                              action: true,
                          }
                        : null)}
                    {...(isBase
                        ? {
                              headerPrimaryText: 'Бесплатный',
                              headerPrimaryTextLarge: true,
                              showSpaceWithPlus: false,
                          }
                        : null)}
                    {...(isNewLanding
                        ? {
                              buttonText: (
                                  <div>
                                      {showCrossedPrice && (
                                          <span
                                              className={classNames(styles.crossedOldPrice, {
                                                  [styles.crossedOldPriceFocuced]: isRecommended,
                                              })}
                                          >
                                              {defaultMonthPrice}
                                          </span>
                                      )}
                                      {productMonthPriceAsText}
                                  </div>
                              ),
                              compact: true,
                              accentFeature: true,
                              disable: Boolean(isBase),
                              withoutButton: Boolean(isBase),
                              buttonPrimaryLight: true,
                              buttonInFooter: !isBase,
                              buttonSize: ETariffCardSizeButtonSize.big,
                              buttonAdditionalText,
                              footerText: isBase ? (
                                  <div>
                                      <Spacing size={42} />
                                      <Title level="3">Бесплатно</Title>
                                      <Spacing size={20} />
                                  </div>
                              ) : (
                                  <></>
                              ),
                              infoBlock: (
                                  <TariffInfoBlock
                                      isBase={isBase}
                                      newQuota={userBaseQuota + product.space.original}
                                      isSkeleton={!isAnonymous && !isQuotaLoaded}
                                  />
                              ),
                              savingInButton: isMonthPeriod(product.period) ? monthDiscountPercent : yearSavingDiscount,
                              headerPrimaryText: '',
                          }
                        : null)}
                    {...(showSwitch
                        ? {
                              onClickSwitch,
                              switchChecked,
                              switchOptions,
                          }
                        : null)}
                    {...(showLabel
                        ? {
                              label,
                              labelKind,
                          }
                        : null)}
                    {...(discount && !isNewLanding
                        ? {
                              buttonAdditionalText: (
                                  <>
                                      Вместо <span className={styles.crossedOutPrice}>{priceAsText}</span>
                                  </>
                              ),
                              buttonSize: ETariffCardSizeButtonSize.big,
                          }
                        : null)}
                />
            </div>
        );
    }
);

TariffPlansCard.displayName = 'TariffPlansCard';
