import { extInfo } from 'lib/extInfo';
import { getAlbumAllowedExtensions } from 'reactApp/modules/albums/albums.selector';
import { getAllDocumentsAlllowedExt } from 'reactApp/modules/allDocuments/allDocuments.helpers';
import { getFeatureAllDocuments } from 'reactApp/modules/features/features.selectors';
import { getDocumentAllowedExtensions } from 'reactApp/modules/personalDocuments/personalDocuments.helpers';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getUploadState = (state) => state.upload;

export const isUploaderVisible = createSelector(getUploadState, (upload) => !upload.hideUploader && !upload.isUploadPanelOpen);

export const isUploaderExpanded = createSelector(getUploadState, (upload) => upload.isExpanded);

export const isUploadPanelOpen = createSelector(getUploadState, (upload) => upload.isUploadPanelOpen);

export const getShowInput = createSelector(getUploadState, (upload) => upload.showInput);

export const isUploadFolderAllowed = createSelector(getCurrentStorage, (storage) => storage !== EStorageType.documents);

export const getAllowedExtensions = createSelector(
    (state: RootState, storage?: EStorageType) => storage || getCurrentStorage(state),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getFeatureAllDocuments,
    (storage, docExtensions: string[]) => {
        if (storage === EStorageType.documents) {
            return getDocumentAllowedExtensions();
        }

        if (storage === EStorageType.albums) {
            return getAlbumAllowedExtensions();
        }

        if (storage === EStorageType.alldocuments) {
            if (window.location.pathname === '/alldocuments/trashbin') {
                return [];
            }
            return getAllDocumentsAlllowedExt(docExtensions);
        }
    }
);

export const getAllowedMimeTypes = createSelector(
    (state: RootState, storage?: EStorageType) => storage || getCurrentStorage(state),
    getAllowedExtensions,
    (storage, extension) => {
        if (!extension?.length) {
            return;
        }

        if (storage === EStorageType.alldocuments) {
            return extension.map((ext) => `.${ext}`);
        }

        return extension.map((ext) => extInfo.getContentType(ext)).filter((ext) => Boolean(ext)) as string[];
    }
);

export const selectButtonTypeClick = (state) => state.upload.buttonTypeClick;

export const selectUploadFrom = (state) => state.upload.uploadFrom;

export const isProvocationShown = (state) => state.upload.isProvocationShown;
