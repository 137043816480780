import { captureException } from '@sentry/browser';
import { Rubles } from 'Cloud/Application/Rubles';
import config from 'Cloud/config';
import classNames from 'clsx';
import React, { memo, ReactElement } from 'react';
import { ADS_TIMER_MS } from 'reactApp/constants';
import { useShowAdsBannerTimer } from 'reactApp/hooks/useShowAdsBannerTimer';

import styles from './ReactViewer.css';

const DOM_ID_VIEWER_SIDEBAR = config.get('DOM_ID_VIEWER_SIDEBAR');

export const AttachesSidebarBanner = memo((): ReactElement | null => {
    const updateAds = () => {
        try {
            Rubles?.updateViewerAttachesSidebar();
        } catch (error) {
            captureException(error);
        }
    };

    const renderItem = () => <div className={classNames(styles.attachesSidebarBanner)} id={DOM_ID_VIEWER_SIDEBAR} />;

    return useShowAdsBannerTimer({
        adsUpdateTimerMs: ADS_TIMER_MS,
        updateAds,
        renderItem,
    });
});

AttachesSidebarBanner.displayName = 'AttachesSidebarBanner';

AttachesSidebarBanner.whyDidYouRender = true;
