import { ACTION_PROMO } from 'reactApp/appHelpers/configHelpers';
import { Product } from 'reactApp/types/Billing';
import { ETariffLabelKind } from 'reactApp/ui/TariffCardNew/components/TariffLabel.types';
import { getDiscount } from 'reactApp/utils/getDiscount';

export const useTariffLabel = ({
    product,
    isSpecial,
    isBase,
    isRecommended,
    skipDefaultLabel,
    primaryLabel,
    showDiscount = true,
}: {
    product: Product;
    isSpecial?: boolean;
    isBase?: boolean;
    isRecommended?: boolean;
    skipDefaultLabel?: boolean;
    primaryLabel?: string;
    showDiscount?: boolean;
}) => {
    const {
        space: { space: quota },
        discountPrice,
        price,
        isProfessional,
        isDisko,
    } = product;

    let label = '';
    let labelKind = ETariffLabelKind.blue;

    switch (quota) {
        case 64:
            labelKind = ETariffLabelKind.blue;
            break;
        case 128:
            label = 'Популярный';
            labelKind = ETariffLabelKind.green;
            break;
        case 256:
            label = 'Оптимальный';
            labelKind = ETariffLabelKind.blue;
            break;
        case 512:
            label = isSpecial ? 'Популярный' : '';
            labelKind = ETariffLabelKind.yellow;
            break;
        case 1:
            label = isSpecial ? 'Специальный' : '';
            labelKind = ETariffLabelKind.violet;
            break;
        case 2:
            label = isSpecial ? 'Выгодный' : '';
            labelKind = ETariffLabelKind.blue;
            break;
        case 4:
            label = isSpecial ? 'Выгодный' : '';
            labelKind = ETariffLabelKind.pink;
            break;
    }

    if (skipDefaultLabel) {
        label = '';
    }

    if (isProfessional && isDisko) {
        label = 'PRO';
        labelKind = ETariffLabelKind.blue;
    }

    if (isRecommended) {
        label = 'Рекомендуем';
        labelKind = ETariffLabelKind.blue;
    }

    if (discountPrice && showDiscount) {
        const { discount } = getDiscount({ discountPrice, price });
        if (discount) {
            label = `Скидка ${discount}%`;
        }
    }

    if (isBase) {
        labelKind = ACTION_PROMO ? ETariffLabelKind.white : ETariffLabelKind.grey;
    }

    if (primaryLabel) {
        label = primaryLabel;
        labelKind = ETariffLabelKind.blue;
    }

    return [!!label, label, labelKind] as const;
};
