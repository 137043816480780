import { Spacing } from '@vkontakte/vkui';
import React, { useRef, VFC } from 'react';
import { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { formatDefaultDate } from 'reactApp/utils/formatDate';

import styles from './CancelAutoRenewalModal.css';
import { DialogProps } from './CancelAutoRenewalModal.types';
import { useHandlers } from './hooks/useHandlers';
import { useSendAnalyticAfterOpen } from './hooks/useSendAnalyticsAfterOpen';
import { PrivilegeList } from './PrivilegeList/PrivilegeList';

type Props = IPropsWithPopup<DialogProps>;

export const CancelAutoRenewalModal: VFC<Props> = ({ currentSpace, expires, id, dwhData, onClose }) => {
    const scrollRef = useRef<HTMLDivElement>(null);

    useSendAnalyticAfterOpen(dwhData);

    const { handleCloseSubscription, handleGoToAllSubscriptions } = useHandlers(dwhData, onClose);

    return (
        <MobileDialog
            id="mobile-cancel-auto-renewal-modal"
            onClose={onClose}
            closeOnDimmerClick
            mod="confirm"
            topmost
            title={
                <span className={styles.title}>
                    Отключить
                    <br />
                    автопродление?
                </span>
            }
            scrollRef={scrollRef}
            footer={
                <>
                    <Spacing size={8} />
                    <Button primary onClick={handleGoToAllSubscriptions} theme="vk" sizeMode={ButtonSizeMode.big} className={styles.button}>
                        Сохранить продление
                    </Button>
                    <Spacing size={12} />
                    <Button
                        secondary
                        onClick={() => handleCloseSubscription(id)}
                        theme="vk"
                        sizeMode={ButtonSizeMode.big}
                        className={styles.button}
                    >
                        Отключить
                    </Button>
                </>
            }
        >
            {expires && <span className={styles.subtitle}>С {formatDefaultDate(expires)} вы потеряете:</span>}
            <PrivilegeList ref={scrollRef} currentSpace={currentSpace} />
        </MobileDialog>
    );
};
