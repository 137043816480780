import { Spacing, Text, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import hotkeys from 'hotkeys-js';
import { parse, stringify } from 'qs';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ESources, useDownloadEbook } from 'reactApp/hooks/useDownloadEbook';
import { useHotKeyScope } from 'reactApp/hooks/useHotkeyScope';
import { getIsDropdownOpen } from 'reactApp/modules/dialog/dialog.selectors';
import { getIsOptionDlgShown } from 'reactApp/modules/ebook/ebook.selectors';
import { historyPush } from 'reactApp/modules/router/router.module';
import { selectOne } from 'reactApp/modules/selections/selections.actions';
import { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { noAuthUser } from 'reactApp/modules/user/userStorage';
import { closeViewer } from 'reactApp/modules/viewer/viewer.module';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { usePostMessage } from 'reactApp/ui/EbookReaderDialog/hooks/UsePostMessage';
import { useDimoutUITimer } from 'reactApp/ui/EbookReaderDialog/useDimoutUITimer';
import { Header } from 'reactApp/ui/Header/Header';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { useRenderTimeout } from 'reactApp/ui/ViewerEbook/useRenderTimeout';
import { getIframeSource } from 'reactApp/ui/ViewerEbook/ViewerEbook.helpers';
import { Icon28FileBrokenOutline } from 'reactApp/ui/VKUIIcons';
import { fullScreenProcessor } from 'reactApp/utils/fullScreenProcessor';
import { sendXray } from 'reactApp/utils/ga';
import { generateUuidV4 } from 'reactApp/utils/helpers';

import styles from './EbookReaderDialog.css';

const DIALOG_ID_EBOOKREADER = 'EbookReaderDialog';

interface IProps {
    onClose: () => void;
    file: CloudFile;
    source: ESources;
    itemStorage: EStorageType;
}

export const EbookReaderDialog = memo<IProps>(({ onClose, file, source, itemStorage }) => {
    const postChanelId = useMemo(() => `viewer-ebook-${generateUuidV4()}`, []);
    const [isRenderBookError, setIsRenderBookError] = useState(false);
    const [isTimeOutError, setIsTimeOutError] = useState(false);
    const [isFrameError, setIsFrameError] = useState(false);

    const dispatch = useDispatch();

    const email = useSelector(UserSelectors.getEmail) || noAuthUser;
    const isOptionDlgShown = useSelector(getIsOptionDlgShown);
    const isDropdownOpen = useSelector(getIsDropdownOpen);

    const { isInactive, showUIAndSetupTimer } = useDimoutUITimer();
    const { content, isError: isDownloadEbookError } = useDownloadEbook({ file, source, itemStorage });
    const iframeSrc = getIframeSource(postChanelId, file.ext, itemStorage);

    const showStub = isDownloadEbookError || isTimeOutError || isFrameError || isRenderBookError || !iframeSrc;

    const errorRadar = useMemo(() => {
        if (isDownloadEbookError) {
            return 'dwnld';
        }
        if (isTimeOutError) {
            return 'tmout';
        }
        if (isFrameError) {
            return 'frm';
        }
        if (isRenderBookError) {
            return 'rndr';
        }
        if (!iframeSrc) {
            return 'frsrc';
        }
        return '';
    }, [isDownloadEbookError, isTimeOutError, isFrameError, isRenderBookError, iframeSrc]);

    const clearRenderTimeout = useRenderTimeout(source, showStub, setIsTimeOutError);

    const { isFrameLoaded, isReady, iframeRef, theme, handleBookmark } = usePostMessage({
        showUIAndSetupTimer,
        content,
        iframeSrc,
        clearRenderTimeout,
        file,
        postChanelId,
        setIsRenderBookError,
        email,
    });

    const isLoading = !isFrameLoaded || content?.byteLength === 0 || !isReady;

    const handleBack = useCallback(
        (e?) => {
            const queryParams = parse(location.search, { ignoreQueryPrefix: true }) || {};

            // Делаем это тут, чтобы перед кодом в return () => ХЪ в эффекте ниже, иначе черный просм-к моргает.
            if (queryParams.from !== 'viewer') {
                dispatch(closeViewer());
            }

            e?.preventDefault();
            onClose();
        },
        [onClose, file.id]
    );

    const handleFullScreen = useCallback((e) => {
        e.preventDefault();
        fullScreenProcessor.switchFullscreen();
    }, []);

    const handleOnBlur = useCallback(() => {
        if (window.getSelection) {
            if (window.getSelection()?.empty) {
                // Chrome
                window.getSelection()?.empty();
            } else if (window.getSelection()?.removeAllRanges) {
                // Firefox
                window.getSelection()?.removeAllRanges();
            }
        }
    }, []);

    useEffect(() => {
        sendXray(['ebook', 'reader', 'show']);

        return () => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { from, dialog, ...rest } = parse(location.search, { ignoreQueryPrefix: true }) || {};

            let storage = file.storage.toString();
            if (file.storage === EStorageType.viewerAttaches) {
                storage = 'attaches/';
            } else if (file.storage === EStorageType.public || file.storage === EStorageType.stock) {
                storage = `${file.storage}/`;
            }

            let path = file.id;
            if (from !== 'viewer' && file.storage !== EStorageType.viewerAttaches) {
                path = file.parent || file.id;
            }

            dispatch(
                historyPush({
                    id: `${storage}${path}`,
                    search: `?${stringify(rest)}`,
                })
            );
        };
    }, []);

    useHotKeyScope(DIALOG_ID_EBOOKREADER);
    useEffect(() => {
        hotkeys('escape', DIALOG_ID_EBOOKREADER, handleBack);

        hotkeys('f', DIALOG_ID_EBOOKREADER, handleFullScreen);

        return () => {
            hotkeys.unbind('escape', DIALOG_ID_EBOOKREADER, handleBack);
            hotkeys.unbind('f', DIALOG_ID_EBOOKREADER, handleFullScreen);
        };
    }, [handleBack]);

    useEffect(() => {
        // Чтобы тулбар работал с этим файлом
        dispatch(selectOne({ selectedIdx: file.id, storage: itemStorage }));
    }, [file.id, itemStorage]);

    useEffect(() => {
        if (errorRadar) {
            sendXray(['ebook', 'reader', 'error', errorRadar]);
        }
    }, [errorRadar]);

    return (
        <div
            className={classNames(styles.root, {
                [styles[`root_${theme.toLowerCase()}`]]: Boolean(theme),
            })}
            onMouseMove={showUIAndSetupTimer}
            onBlur={handleOnBlur}
            tabIndex={0}
        >
            <div
                className={classNames(styles.header, styles.dimoutable, {
                    [styles.dimout]: isInactive && !showStub && !isOptionDlgShown && !isDropdownOpen,
                })}
            >
                <Header openedFileId={file?.name} onBackClick={handleBack} isEbookReader theme={theme} onBookmark={handleBookmark} />
            </div>
            {!!showStub && (
                <div className={styles.error}>
                    <div className={styles.description}>
                        <div className={styles.icon}>
                            <Icon28FileBrokenOutline />
                        </div>
                        <Spacing size={28} />
                        <Title>Файл повреждён</Title>
                        <Spacing size={12} />
                        <Text>При открытии произошла ошибка, для просмотра скачайте файл или обратитесь в поддержку</Text>
                        <Spacing size={36} />
                        <Button theme="octavius" sizeMode={ButtonSizeMode.small} onClick={handleBack}>
                            <Text weight="2">Вернуться в Облако</Text>
                        </Button>
                    </div>
                </div>
            )}
            {isLoading && !showStub && <Spinner />}
            {!showStub && (
                <iframe
                    loading="lazy"
                    onError={() => setIsFrameError(true)}
                    className={styles.wrapperIframe}
                    /** ITSEC_REVIEW Для iframe устанавливаем возможные действия через белый список sandbox */
                    sandbox="allow-same-origin allow-scripts"
                    src={iframeSrc}
                    ref={iframeRef}
                />
            )}
        </div>
    );
});

EbookReaderDialog.displayName = 'EbookReaderDialog';
