import { BReactComponent } from 'blocks-cloud/b-react-component';
import app from 'Cloud/Application/app';
import { IS_REACT_PAGE } from 'reactApp/appHelpers/configHelpers';
import { closePopupHelper, openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { Popups } from 'reactApp/ui/Popup/Popups';
import { noopVoid } from 'reactApp/utils/helpers';

let el;
let toolkitBlock;

const init = () => {
    if (el) {
        return;
    }

    el = document.createElement('div');

    toolkitBlock = new BReactComponent({
        ReactComponent: Popups,
        renderMode: 'replace',
    });

    document.body.appendChild(el);
    toolkitBlock.renderTo(el);
};

export const renderReactTooltip = (tooltipName) => ({ onClose = noopVoid, onAction = noopVoid, ...rest }) => {
    const close = () => {
        if (!toolkitBlock && !IS_REACT_PAGE) {
            return;
        }

        closePopupHelper(tooltipName);
    };

    if (!toolkitBlock && !IS_REACT_PAGE) {
        init();
    }
    close();
    openPopupHelper({
        popupName: tooltipName,
        onClose: (...params) => {
            close();
            onClose(...params);
        },
        data: {
            ...rest,
            onAction: (...params) => {
                close();
                onAction(...params);
            },
        },
    });
    app.on('routeChangeStart', close);
    return close;
};

app.on('page:start', init);
