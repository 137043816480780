import { ServerError } from './ServerError';

class WrongResponseError extends ServerError {
    static radarName = 'failure_{SOURCE}_wrong-response';

    /**
     * @constructor
     * @extends {ServerError}
     * @param {string} source
     * @param {string} response
     */
    constructor() {
        super(...arguments);
    }

    isRetryable = true;
    retryTimeout = 1000;

    className = 'WrongResponseError';
}

export { WrongResponseError };
