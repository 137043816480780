import {
    BUILD_URLS,
    IS_MOBILE_BROWSER,
    IS_ONPREMISE,
    IS_PHONE_BROWSER,
    IS_PUBLIC_ALBUM,
    IS_REACT_PAGE,
} from 'appHelpers/configHelpers';
import config from 'Cloud/config';
import { isFolder } from 'reactApp/modules/file/utils';
import { getPublicUrl } from 'reactApp/utils/urlHelper';
import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
const { openPopup, openTab } = require('Cloud/Application/Window');

const MAIL_HOST = IS_PHONE_BROWSER || IS_MOBILE_BROWSER ? 'touch.mail.ru' : config.get('MAIL_HOST');

const sendItem = {
    id: 'email',
    name: 'Отправить',
    url(data) {
        const subject = encodeURIComponent(data.title);
        const body = encodeURIComponent(data.url);
        return IS_MY_TEAM
            ? `vkteams://miniapp/mail/?path=compose&Subject=${subject}&Body=${body}`
            : `https://${MAIL_HOST}/compose/?Subject=${subject}&Body=${body}`;
    },
    fullsize: true,
};

const socials = IS_ONPREMISE
    ? [
        sendItem,
    ]
    : [
        sendItem,
        {
            id: 'vk',
            name: 'ВКонтакте',
            url(data) {
                return `${`https://vk.com/share.php?url=`}${encodeURIComponent(data.url)}`;
            },
        },
        {
            id: 'ok',
            name: 'Одноклассники',
            url(data) {
                return `${`https://connect.ok.ru/offer?url=`}${encodeURIComponent(data.url)}`;
            },
        },
        {
            id: 'tw',
            name: 'Twitter',
            url(data) {
                return `${`https://twitter.com/share?&url=`}${encodeURIComponent(data.url)}&counturl=${encodeURIComponent(data.url)}`;
            },
        },
        {
            id: 'mm',
            name: 'Мой Мир',
            url(data) {
                return `${`https://connect.mail.ru/share?url=`}${encodeURIComponent(data.url)}`;
            },
        },
    ];

function popup(id, action, item) {
    const data = {
        url: item ? getPublicUrl(item, IS_PUBLIC_ALBUM) : 'https://cloud.mail.ru/promo/',

        image: item && item.thumbnails ? item.thumbnails['320x240'] : `${BUILD_URLS.staticPath}img/share.jpg`,

        title: item ? `${isFolder(item) ? 'Папка' : 'Файл'} из Облака Mail.ru` : '25Гб бесплатно в Облаке!',

        descr: `Облако Mail.ru - это ваше персональное надежное хранилище в интернете. Все нужные файлы всегда под рукой, доступны в любой точке мира с компьютера или смартфона.`,
    };

    if (id !== 'email') {
        data.url += `?utm_source=${id}&utm_medium=post&utm_campaign=${action}`;
    }

    const social = socials.find(item => item.id === id);

    if (social) {
        if (social.fullsize) {
            openTab(social.url(data));
        } else {
            openPopup(social.url(data));
        }

        RADAR(`app_social_${action}-${id}_${IS_REACT_PAGE ? 'react' : 'old' }`);
    } else {
        RADAR.error(`social-${id}`);
    }
}

export const social = {
    list: socials,

    publishItem(id, item) {
        popup(id, 'publish-file', item);
    },
};

export default function(app) {
    Object.assign(app, {
        social,
    });
}
