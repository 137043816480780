/* eslint-disable max-lines-per-function */
import app from 'Cloud/Application/app';
import classNames from 'clsx';
import { equals, path } from 'ramda';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE, IS_BIZ_USER, IS_DOCUMENTS_DOMAIN, PUBLIC_SHOVE } from 'reactApp/appHelpers/configHelpers';
import { publishHelper } from 'reactApp/appHelpers/publishHelper';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { getAlbumsBreadcrumbs } from 'reactApp/modules/albums/albums.selector';
import { getAllDocumentsBreadcrumbs, getAllDocumentsDomainBreadcrumbs } from 'reactApp/modules/allDocuments/allDocuments.selectors';
import { getFeatureFaces } from 'reactApp/modules/features/features.selectors';
import { setDomainFoldersFilterActive } from 'reactApp/modules/home/home.actions';
import { getCurrentFolderHome, getDomainFoldersFilterActive, getHomeBreadcrumbs } from 'reactApp/modules/home/home.selectors';
import { getIntegrationQueryString } from 'reactApp/modules/integration/integration.selectors';
import { getPersonalDocumentsBreadcrumbs } from 'reactApp/modules/personalDocuments/personalDocuments.selectors';
import { getCurrentPublicItem, getPublicBreadcrumbs } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isIntegrationStorage } from 'reactApp/modules/storage/storage.helpers';
import { getBreadCrumbs } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { isWelcomePromoShow } from 'reactApp/modules/welcome/welcome.selectors';
import { RootState } from 'reactApp/store';
import { Kind } from 'reactApp/types/Tree';
import { AttachesTooltip } from 'reactApp/ui/AttachesTooltip/AttachesTooltip';
import { BreadCrumbs } from 'reactApp/ui/Breadcrumbs/Breadcrumbs';
import { BreadcrumbItem } from 'reactApp/ui/Breadcrumbs/Breadcrumbs.types';
import {
    autodeleteCrumb,
    favoritesCrumb,
    feedCrumb,
    galleryCrumb,
    recomendCrumb,
    trashbinCrumb,
} from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer.data';
import { FaceFilter } from 'reactApp/ui/FaceFilter/FaceFilterComponent';
import { FilesCounter } from 'reactApp/ui/FilesCounter/FilesCounter';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { sendGa } from 'reactApp/utils/ga';

import styles from './BreadcrumbsContainer.css';

const sendAnalytics = (action: string, label?: string) => sendGa('breadcrumbs', action, label);

export interface BreadcrumbsListItem {
    name: string;
    id: string;
    kind: Kind;
    storage: EStorageType;
    weblink?: string;
    showWeblink?: boolean;
    showShare?: boolean;
    href?: string;
    search?: string;
}

let prevBreadCrumbsList: BreadcrumbsListItem[] = [];

// TODO: use deepEqualSelector to prevent rerenders
export const getBreadCrumbsList = (state: RootState, storage?: EStorageType, isSearch?: boolean): BreadcrumbsListItem[] => {
    const currentStorage = getCurrentStorage(state);

    storage = storage || currentStorage;

    const isShared = IS_BIZ_USER
        ? storage === EStorageType.sharedAutodelete
        : storage === EStorageType.sharedLinks || storage === EStorageType.sharedAutodelete || storage === EStorageType.sharedIncoming;

    if (storage === EStorageType.feed) {
        return [
            {
                ...feedCrumb[0],
                name: IS_BIZ_USER ? 'Последние файлы' : 'Недавние',
            },
        ];
    } else if (storage === EStorageType.alldocuments) {
        return IS_DOCUMENTS_DOMAIN ? getAllDocumentsDomainBreadcrumbs(state) : getAllDocumentsBreadcrumbs(state);
    } else if (storage === EStorageType.recommend) {
        return recomendCrumb;
    } else if (storage === EStorageType.trashbin) {
        return trashbinCrumb;
    } else if (storage === EStorageType.favorites) {
        return favoritesCrumb;
    } else if (storage === EStorageType.home) {
        return getHomeBreadcrumbs(state);
    } else if (storage === EStorageType.attaches) {
        return [
            {
                kind: 'storage',
                name: isSearch ? 'Результаты поиска' : 'Из почты',
                id: '/',
                storage: EStorageType.attaches,
                href: '/attaches',
            },
        ];
    } else if (storage === EStorageType.public && app.browser.isTablet()) {
        const item = getCurrentPublicItem(state);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return getBreadCrumbs(state, item?.id || undefined);
    } else if (storage === EStorageType.public) {
        return getPublicBreadcrumbs(state);
    } else if (storage === EStorageType.gallery) {
        return galleryCrumb;
    } else if (isShared) {
        return [
            {
                name: 'Совместные',
                id: '/',
                kind: 'storage',
                storage,
            },
        ];
    } else if (storage === EStorageType.sharedAutodelete) {
        return autodeleteCrumb;
    } else if (storage === EStorageType.documents) {
        return getPersonalDocumentsBreadcrumbs(state);
    } else if (storage === EStorageType.albums) {
        return getAlbumsBreadcrumbs(state);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const list = getBreadCrumbs(state, undefined);

    if (prevBreadCrumbsList?.length === list?.length && equals(prevBreadCrumbsList, list)) {
        return prevBreadCrumbsList;
    }

    prevBreadCrumbsList = list;

    return list;
};

// eslint-disable-next-line max-lines-per-function
export const BreadcrumbsContainer = memo(
    ({ storage, className, isSearch }: { storage?: EStorageType; className?: string; isSearch?: boolean }) => {
        const dispatch = useDispatch();
        const list = useSelector((state: RootState) => getBreadCrumbsList(state, storage, isSearch));
        const currentStorage = useSelector(getCurrentStorage);
        const welcomePromo = useSelector(isWelcomePromoShow);
        const IsFacesFeature = useSelector(getFeatureFaces);
        const domainFoldersFilterActive = useSelector(getDomainFoldersFilterActive);
        const currentFolder = useSelector(getCurrentFolderHome);
        const integrationQueryString = useSelector(getIntegrationQueryString);
        const isFollowToSharedFolders =
            currentFolder?.kind === 'domain-folder' || currentFolder?.kind === 'mounted' || domainFoldersFilterActive;

        storage = storage || currentStorage;

        const currentId = path(['id'], list[list.length - 1]);

        const showCounter =
            storage === EStorageType.home ||
            storage === EStorageType.integration ||
            storage === EStorageType.inlineIntegration ||
            storage === EStorageType.sharedLinks ||
            storage === EStorageType.sharedAutodelete ||
            storage === EStorageType.public ||
            storage === EStorageType.favorites;

        const showFacesFilter = storage === EStorageType.public && IsFacesFeature;

        const goToSharedFolders = useCallback(() => {
            dispatch(setDomainFoldersFilterActive(true));
        }, [dispatch]);

        const breadcrumbs = useMemo(() => {
            return list.map<BreadcrumbItem>((item) => {
                const isHome = item.storage === EStorageType.home;
                const isRoot = item.id === ROOT_FOLDER_ID;

                const id = !isHome && isRoot ? `/${item.storage}` : item.id;

                return {
                    text: item.name,
                    id,
                    kind: item.kind,
                    showWeblink: item.showWeblink,
                    weblink: isHome ? item.weblink : '',
                    showShare: item.showShare,
                    storage: item.storage,
                    href: item.href,
                    search: item.search || (isIntegrationStorage(storage) ? integrationQueryString : ''),
                };
            });
        }, [list, currentId, storage, domainFoldersFilterActive]);

        useEffect(() => {
            sendAnalytics('show');
        }, []);

        const handleOnShareClick = useCallback((e, breadcrumb) => {
            sendAnalytics('share-click');
            toolbarActions.share(breadcrumb.id);
        }, []);

        const handleOnPublishClick = useCallback(
            (e, breadcrumb) => {
                sendAnalytics('publish-click');
                publishHelper({ item: breadcrumb, publishFrom: 'breadcrumb' });
            },
            [breadcrumbs]
        );

        if (storage === EStorageType.start || welcomePromo) {
            return null;
        }

        return (
            <>
                <div
                    className={classNames(styles.root, className, {
                        [styles.shove]: !!PUBLIC_SHOVE,
                        [styles.rootResponsive]: ENABLE_FULL_RESPONSIVE,
                        [styles.nomargin]: isIntegrationStorage(storage),
                    })}
                >
                    <div className={styles.breadcrumbsWrapper}>
                        <BreadCrumbs
                            breadcrumbs={breadcrumbs}
                            onShareClick={handleOnShareClick}
                            onPublishClick={handleOnPublishClick}
                            storage={storage}
                            isFollowToSharedFolders={isFollowToSharedFolders}
                            goToSharedFolders={goToSharedFolders}
                        />
                        {storage === EStorageType.attaches && (
                            <div className={styles.faceWrapper}>
                                <AttachesTooltip />
                            </div>
                        )}
                        {showFacesFilter && !PUBLIC_SHOVE && (
                            <div className={styles.faceWrapper}>
                                <Hint text="Теперь вы можете фильтровать фото по распознанным лицам в альбоме">
                                    <FaceFilter />
                                </Hint>
                            </div>
                        )}
                    </div>
                    {showCounter && <FilesCounter />}
                </div>
                {showFacesFilter && !!PUBLIC_SHOVE && (
                    <div
                        className={classNames(styles.root, {
                            [styles.shove]: !!PUBLIC_SHOVE,
                        })}
                    >
                        <div className={styles.faceWrapper}>
                            <Hint text="Теперь вы можете фильтровать фото по распознанным лицам в альбоме">
                                <FaceFilter />
                            </Hint>
                        </div>
                    </div>
                )}
            </>
        );
    }
);

BreadcrumbsContainer.displayName = 'BreadcrumbsContainer';

BreadcrumbsContainer.whyDidYouRender = true;
