import { Headline } from '@vkontakte/vkui';
import classNames from 'clsx';
import { ReactComponent as CloudLogo } from 'img/icons/cloud_icon_inline_integration.svg';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { useQuotaInfo } from 'reactApp/ui/Space/SingleSpace/hooks/useQuotaInfo';
import { Tooltip } from 'reactApp/ui/Tooltip/Tooltip';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';

import styles from './InlineIntegrationQuotaHeader.css';

export const InlineIntegrationQuotaHeader = () => {
    const [isHovered, setIsHovered] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    const { total, usedTotal } = useQuotaInfo(false);
    const { usedPercent } = useSelector(UserQuotaSelectors.getUserQuotaState);

    const getTooltipText = () => {
        switch (true) {
            case usedPercent >= 80 && usedPercent < 100:
                return 'Облако почти заполнено, увеличьте объём памяти';
            case usedPercent === 100:
                return 'Облако заполнено, увеличьте объём памяти';
            case usedPercent > 100:
                return 'Облако переполнено, увеличьте объём памяти';
            default:
                return '';
        }
    };

    const tooltipText = getTooltipText();

    return (
        <div className={styles.header}>
            <CloudLogo width={32} height={32} />
            <Headline level="2" weight="2">
                Облако
            </Headline>
            <div
                ref={ref}
                className={classNames(styles.loadStatus, { [styles.isFull]: usedPercent >= 80 })}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {usedTotal && (
                    <Headline level="2">
                        Занято {usedTotal?.value} из {total?.value}
                    </Headline>
                )}
                {/* TODO: добавить, когда по клику будет открываться лендинг квоты под социальным аккаунтом*/}
                {/* <Icon16ChevronOutline />*/}
            </div>

            {tooltipText && isHovered && (
                <Tooltip
                    theme="inlineIntegration"
                    target={ref}
                    minWidth={false}
                    placement={tooltipPlacements.BOTTOM}
                    smartPlacement={false}
                >
                    <div className={styles.tooltip}>{tooltipText}</div>
                </Tooltip>
            )}
        </div>
    );
};
