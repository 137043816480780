import * as OAuth from '@mail/oauth';
import { AxiosRequestHeaders } from 'axios';
import { OAUTH_HOST } from 'reactApp/appHelpers/configHelpers';

export class O2Auth {
    private tokenPromise: Promise<string | null>;
    private loading = false;

    constructor({ clientId, login }: { clientId: string; login: string }) {
        OAuth.init({
            clientId,
            login,
        });

        OAuth.setOAuthHost(OAUTH_HOST);

        this.tokenPromise = this.requestToken();
    }

    private requestToken() {
        if (this.loading) {
            return this.tokenPromise;
        }

        this.loading = true;

        return new Promise<string | null>((resolve, reject) => {
            OAuth.auth(({ status, access_token }) => {
                const isAuth = status === 'connected';

                if (isAuth) {
                    resolve(access_token);
                } else {
                    reject();
                }

                this.loading = false;
            });
        });
    }

    public getToken() {
        return this.tokenPromise;
    }

    public async getHeaters() {
        const token = await this.tokenPromise;

        return O2Auth.prepareAuthHeader(token);
    }

    public static prepareAuthHeader(token: string | null): AxiosRequestHeaders {
        return token ? { Authorization: `Bearer ${token}` } : {};
    }

    public refreshToken(): Promise<string | null> {
        this.tokenPromise = this.requestToken();

        return this.tokenPromise;
    }
}
