import { cancel, expandRequest, publishSelected } from 'reactApp/modules/integration/integration.actions';
import { handleCancel } from 'reactApp/modules/integration/sagas/cancel.saga';
import { handleExpandRequest } from 'reactApp/modules/integration/sagas/expandRequest.saga';
import { handlePublishSelected } from 'reactApp/modules/integration/sagas/publishSelected.saga';
import { takeEvery } from 'redux-saga/effects';

export function* watchIntegration() {
    yield takeEvery(publishSelected.toString(), handlePublishSelected);
    yield takeEvery(cancel.toString(), handleCancel);
    yield takeEvery(expandRequest.toString(), handleExpandRequest);
}
