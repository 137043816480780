import { EditorID } from 'Cloud/Application/Editor/types';
import { extInfo } from 'lib/extInfo';
import { IS_DOCUMENTS_DOMAIN, IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { isAllDocumentsReturn, isR7WopiCoEdit } from 'reactApp/appHelpers/featuresHelpers';
import { ALL_DOCUMENTS_PATH } from 'reactApp/modules/allDocuments/allDocuments.constants';
import {
    getFeatureAllDocuments,
    getFeatureShareMountEditAccessDisable,
    getFeatureWopiCoEdit,
} from 'reactApp/modules/features/features.selectors';
import { isMountedOrSharedFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';
import { calculateMaxEditableOrPreviewableSizeForEditor } from 'server/helpers/editors/calculateMaxEditableOrPreviewableSizeForEditor';

import { getAvailableSectionInAllDocuments } from '../allDocuments/allDocuments.helpers';
import { getAvailableEditorsForItem, isValidEditableItem } from './editor.helpers';
import { CreatorsDescRecord, EditorState } from './editor.types';

export const getEditorState = (state: RootState): EditorState => state.editor;

const CO_EDITORS = [EditorID.MYOFFICE, EditorID.R7, EditorID.R7_WOPI];

export const getEditorItemById = createSelector(
    getEditorState,
    (_: RootState, id: string) => id,
    (state, id) => {
        if (state.item && state.item.id === id) {
            return state.item;
        }
    }
);

const getDefaultCoEditorId = createSelector(
    (state: RootState) => state,
    (_, item: any) => item,
    (state: RootState, item: any) => isMountedOrSharedFolder(state, item),
    getEditorState,
    (_, item, folderState, editor): string | undefined | null => {
        if (!item) {
            return null;
        }
        const { sharedExtensions, extensions, availableEditors } = editor;
        const hasSharedEditor = editor.hasSharedEditor && sharedExtensions[item.ext];
        const isSharedFolder = hasSharedEditor && folderState.is;

        const editors: EditorID[] = extensions[(IS_ONPREMISE && item.ext ? item.ext.toLowerCase() : item.ext) as string]?.editors || [];

        return editors.find((editorId) => {
            if (!CO_EDITORS.includes(editorId)) {
                return false;
            }
            const editor = availableEditors[editorId];

            return hasSharedEditor && isSharedFolder
                ? editor.default !== 'shared-folder'
                : editor.force || editor.default || editors.length === 1;
        });
    }
);

export const getIsFileForCoEditing = createSelector(
    (state: RootState) => state,
    (_: RootState, item: any) => item,
    (state: RootState, item: any) => getDefaultCoEditorId(state, item),
    (state) => getFeatureWopiCoEdit(state),
    (state) => getFeatureShareMountEditAccessDisable(state),
    (state, item: any) => isMountedOrSharedFolder(state, item),
    (_, item, defaultEditor, wopiCoEdit, isShareMountEditAccessDisable, isSharedOrMouted) => {
        if (!item || item.isFolder) {
            return false;
        }

        switch (defaultEditor) {
            case EditorID.R7:
                return true;
            case EditorID.R7_WOPI:
                if (!isR7WopiCoEdit) {
                    return false;
                }
                // запрещаем шарить на редактирование документ, если мы не владельцы
                return isShareMountEditAccessDisable ? !item.isMounted : true;
            case EditorID.MYOFFICE: {
                if (!wopiCoEdit) {
                    return false;
                }

                if (!isSharedOrMouted.isMounted) {
                    return true;
                }

                // запрещаем шарить на редактирование документ, если мы не владельцы
                return isShareMountEditAccessDisable ? !item.isMounted : true;
            }
        }
    }
);

export const getCreateDescriptions = createSelector(getEditorState, ({ extensions }) =>
    Object.values(extensions).reduce(
        (acc, ext) => (ext?.creatorsDesc && ext.editors.length > 0 ? acc.concat(ext.creatorsDesc) : acc),
        [] as CreatorsDescRecord[]
    )
);
export const getEditorItem = createSelector(getEditorState, (state) => state.item);

export const getEditorsFor = createSelector(
    (state: RootState) => state,
    (_: RootState, item: any) => item,
    (state: RootState, item) => isMountedOrSharedFolder(state, item),
    (state: RootState) => state.editor,
    (_, item, folderState, editor) => {
        if (!isValidEditableItem(item)) {
            return [];
        }

        const editors = getAvailableEditorsForItem(item, editor, folderState);

        const { ext, size } = item;
        const extData = extInfo.get(ext);

        return editors.filter((editor) => {
            const maxSize = calculateMaxEditableOrPreviewableSizeForEditor(editor.id, extData.maxEditableSize);

            return maxSize && size <= maxSize;
        });
    }
);

export const getAvailableConfiguredEditorsFor = createSelector(
    (state: RootState) => state,
    (_: RootState, item: any) => item,
    (state: RootState, item) => isMountedOrSharedFolder(state, item),
    (state: RootState) => state.editor,
    (_, item, folderState, editor) => {
        return getAvailableEditorsForItem(item, editor, folderState);
    }
);

export const getEditorsByExtension = createSelector(
    getEditorState,
    (_: RootState, ext: string) => ext,
    ({ extensions }, ext) => (extensions[ext] || { editors: [] }).editors
);

/**
 * Проверяет доступность переименования файла
 * **Важно:** Пока доступно только для WOPI-версии Моего Офиса
 */
export const isEnableRenamingInsideEditor = createSelector(
    (state: RootState) => ({ state, editor: getEditorState(state) }),
    ({ state, editor: { editorId, item } }) => {
        if (!editorId || !item) {
            return false;
        }

        if (editorId === EditorID.MYOFFICE) {
            return ViewerSelectors.isWopiEnabled(state, item.ext.toLowerCase());
        }

        if (editorId === EditorID.R7) {
            return true;
        }

        return false;
    }
);

export const isEditorStorage = (storage: EStorageType): boolean =>
    [
        EStorageType.editor,
        EStorageType.office,
        EStorageType.r7,
        EStorageType.myoffice,
        EStorageType.myofficeAttaches,
        EStorageType.r7wopi,
    ].includes(storage);

export const getBackUrl = createSelector(
    getFeatureAllDocuments,
    (_: RootState, file: any) => file,
    (_: RootState, isPublic: any) => isPublic,
    (_: RootState, file, isPublic) => {
        const section = getAvailableSectionInAllDocuments(file.ext);

        if (isAllDocumentsReturn && section) {
            if (IS_DOCUMENTS_DOMAIN) {
                return `/${section}`;
            }

            return `${ALL_DOCUMENTS_PATH}/${section}`;
        }

        return isPublic ? '/home' : `/home${file?.parent}`;
    }
);

export const getLlmDialogState = createSelector(getEditorState, (state) => state.llmDialog);
