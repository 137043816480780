import { logger } from 'lib/logger';
import { IS_DOCUMENTS_DOMAIN } from 'reactApp/appHelpers/configHelpers';
import { getFeaturePublicAlbumTemporaryUrl } from 'reactApp/modules/features/features.selectors';
import { getExtension } from 'reactApp/modules/file/utils';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { changeDocumentDomainToCloud } from 'reactApp/utils/documentsDomain';

export const getVerifyNumberUrl = () => {
    const currentUrl = window.location.href;
    const backUrl = `${currentUrl}${currentUrl.includes('?') ? '&' : '?'}action=unfreeze`;
    const domain = `https://account.mail.ru`;

    return `${domain}/user/phones/bind?utm_campaign=user_phones&utm_source=cloud&utm_medium=redirect&utm_content=cloud&backUrl=${encodeURIComponent(
        backUrl
    )}`;
};

export const getSignUpUrl = (backUrl?: string) => {
    const baseUrl = 'https://account.mail.ru/signup';
    const back = backUrl || `${window.location.href}`;

    return `${baseUrl}?back=${encodeURIComponent(back)}`;
};

// /public/A8Tg/YFdgjFrPg/... -> A8Tg/YFdgjFrPg
export const getPublicParentUrl = () => window.location.pathname.split('/').slice(2, 4).join('/');

export const _path_join = function (...args) {
    let url = args[0] || '';

    for (let i = 1, len = args.length; i < len; i++) {
        const part2 = args[i] || '';

        if (part2 == null) {
            continue;
        }

        const hasIt1 = url.charAt(url.length - 1) === '/';
        const hasIt2 = part2.charAt(0) === '/';

        if (hasIt1 && hasIt2) {
            url = url + part2.slice(1);
        } else if (!hasIt1 && !hasIt2) {
            url = `${url}/${part2}`;
        } else {
            url = url + part2;
        }
    }

    return url;
};

export const getFilePathFromUrl = (pathname?: string, basePathParts = 1) => {
    if (!pathname) {
        return '';
    }
    const pathNameSplit = pathname.split('/');

    // Тк файл может быть без расширения, проверяем что в пути помимо стораджа есть что-то еще.
    const hasFileInUrl = pathNameSplit.length !== basePathParts + 1 && pathNameSplit[pathNameSplit?.length - 1];

    return hasFileInUrl ? `/${pathNameSplit?.slice(basePathParts + 1).join('/')}` : '';
};

export const getPathWithoutFile = (pathname?: string) => {
    if (!pathname) {
        return '';
    }
    const pathNameSplit = pathname.split('/');
    const pathNameLengthIndex = pathNameSplit?.length - 1;
    const fileName = pathNameSplit[pathNameLengthIndex];
    return getExtension(fileName) ? `${pathNameSplit?.slice(0, pathNameLengthIndex).join('/')}` : `${pathNameSplit.join('/')}`;
};

export const closeViewerUrl = (location?: { pathname: string }) => `${getPathWithoutFile(location?.pathname)}`;

export const getHomeFolderPath = (path) => `/home${path}`;
export const getStorageAwareFolderPath = (storage: EStorageType, path) => `/${storage}${path}`;

export const getPathName = () => window.location.pathname;

export const getEncodePath = (path) => {
    try {
        return encodeURIComponent(path).replace(/%2F/g, '/') || ''; // слеши не кодируем, иначе роутинг сломается
    } catch (error) {
        logger.error('Cannot encode', path, error);
    }
    return path;
};

export const decodeURIComponentHelper = (path: string) => {
    try {
        return decodeURIComponent(path ?? '');
    } catch (error) {
        logger.error('Cannot decode', path, error);
    }
    return path;
};

export const getQuotaFromTariff = (tariff: string) => {
    const exponent = decodeURIComponent(tariff).toUpperCase().includes('ГБ') ? 3 : 4;

    return parseInt(tariff) * 1024 ** exponent;
};

interface IItem {
    weblink?: string;
    stock?: string;
    id?: string;
    storage?: EStorageType;
}

export const getPublicUrl = (item?: IItem | null, isAlbum = false) => {
    let weblink;
    if (item) {
        if ('weblink' in item && item.weblink) {
            weblink = `/public/${item.weblink}`;

            if (isAlbum && getFeaturePublicAlbumTemporaryUrl({})) {
                weblink = `/album/${item.weblink}`;
            }
        } else if (item.storage === EStorageType.stock) {
            if ('stock' in item && item.stock) {
                weblink = `/stock/${item.stock}`;
            } else {
                weblink = `/stock/${item.id}`;
            }
        }

        if (weblink) {
            const host = IS_DOCUMENTS_DOMAIN ? changeDocumentDomainToCloud(window.location.origin) : window.location.origin;
            const encodedWeblink = encodeURIComponent(weblink).replace(/%2F/g, '/');
            return `${host}${encodedWeblink.replace(/\/{2,}/g, '/')}`;
        }
    }

    return '';
};

export const getInitialId = (path: string, basePathParts?: number) => decodeURIComponentHelper(getFilePathFromUrl(path, basePathParts));

export const getParameterFromUrl = (url: string | URL, parameterName: string) => {
    const urlObject = new URL(url);
    return urlObject.searchParams.get(parameterName);
};
