export interface IPromoInfo {
    header: string;
    subHeader: string;
    benefits: string[];
    link: string;
}

export const promoInfo: Record<string, IPromoInfo> = {
    pro: {
        header: 'Автоудаление папок доступно только в PRO тарифах',
        subHeader: 'А еще с PRO тарифом вы получите:',
        benefits: [
            'Загрузку файлов до 100 ГБ',
            'Отсутствие рекламы в облаке',
            'Полный доступ к Диск-О:',
            'Подписку на VK Combo',
            'До -50% на все курсы GeekBrains',
        ],
        link: '/subscriptions?utm_source=cloud_autodelete&utm_medium=referral&utm_campaign=autodelete_pro#pro',
    },
    paid: {
        header: 'Автоудаление папок доступно только в платных тарифах',
        subHeader: 'А еще с платным тарифом вы получите:',
        benefits: ['Загрузку файлов до 32 ГБ', '30 дней истории изменений'],
        link: '/subscriptions?utm_source=cloud_autodelete&utm_medium=referral&utm_campaign=autodelete_tariff#year',
    },
};
