import { Icon16CopyOutline } from '@vkontakte/icons';
import { Caption, Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, useCallback, useRef } from 'react';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { copy as copyApiCall } from 'reactApp/utils/copyToClipboard';

import styles from './CopyInput.css';

interface IProps {
    value: string;
    onCopy: () => void;
    disabled?: boolean;
    successNotificationText?: string;
    disabledText?: string;
}

export const CopyInput = memo<IProps>(({ value, onCopy, successNotificationText, disabled, disabledText }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleCopy = useCallback(
        (e?: React.MouseEvent<HTMLInputElement | SVGSVGElement>) => {
            e?.stopPropagation();

            if (!inputRef?.current) {
                return;
            }

            const processCopy = (notification): void => {
                if (notification) {
                    snackbarController.showSnackbar(notification);
                }

                if (inputRef.current) {
                    inputRef.current.select();
                    inputRef.current.setSelectionRange(0, inputRef.current.value.length);
                }

                onCopy();
            };

            copyApiCall(inputRef.current.value)
                .then((resp): void => {
                    const hasBeenCopied = resp ? resp.hasBeenCopied : true;
                    return processCopy(
                        hasBeenCopied && Boolean(successNotificationText)
                            ? {
                                  type: SnackbarTypes.success,
                                  text: successNotificationText,
                                  id: 'copyInput',
                                  closable: true,
                              }
                            : null
                    );
                })
                .catch((): void =>
                    processCopy({ type: SnackbarTypes.failure, text: 'Не удалось скопировать', id: 'copyInput', closable: true })
                );
        },
        [onCopy, successNotificationText]
    );

    return (
        <>
            <div
                className={classNames(styles.root, {
                    [styles.root_disabled]: disabled,
                })}
            >
                <input
                    disabled={disabled}
                    onClick={handleCopy}
                    ref={inputRef}
                    className={styles.input}
                    readOnly
                    value={value}
                    title="Скопировать"
                />
                {!disabled && (
                    <div className={styles.icon}>
                        <Icon16CopyOutline />
                    </div>
                )}
            </div>
            {disabled && Boolean(disabledText) && (
                <>
                    <Spacing size={4} />
                    <Caption level="1" className={styles.disabledText}>
                        {disabledText}
                    </Caption>
                </>
            )}
        </>
    );
});

CopyInput.displayName = 'CopyInput';
