import { Icon20Dropdown } from '@vkontakte/icons';
import classNames from 'clsx';
import { ReactComponent as DropdownIcon } from 'mrg-icons/src/mailru/navigation/drop_down.svg';
import React, { MouseEvent, PureComponent, ReactElement, ReactNode } from 'react';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { noop } from 'reactApp/utils/helpers';

import styles from './ViewerHeaderButton.css';

export interface Props {
    title: string | ReactNode;
    icon?: ReactNode;
    onClick: (e: MouseEvent) => void;
    onClickDropdown?: (e: MouseEvent) => void;
    textRef?: any;
    primary?: boolean;
    secondary?: boolean;
    forceText?: boolean;
    showDropDown?: boolean;
    isDropdownOpen?: boolean;
    isFilled?: boolean;
    hint?: string;
    id?: string;
    collapsableText?: boolean;
    iconOnly?: boolean;
    textOnIcon?: string;
    isPdf?: boolean;
}

const isString = (value: string | React.ReactNode): value is string => {
    return typeof value === 'string';
};

export class ViewerHeaderButton extends PureComponent<Props> {
    static defaultProps = {
        onClick: noop,
        primary: false,
        secondary: false,
        forceText: false,
        collapsableText: false,
    };

    handleClick = (e: MouseEvent): void => {
        const { onClick } = this.props;
        onClick(e);
    };

    handleClickDropdown = (e: MouseEvent): void => {
        const { onClickDropdown = noop } = this.props;
        onClickDropdown(e);

        e.stopPropagation();
        e.preventDefault();
    };

    render(): ReactElement {
        const {
            icon,
            title,
            textRef,
            primary,
            secondary,
            forceText,
            hint,
            isDropdownOpen,
            showDropDown,
            isFilled = true,
            id,
            collapsableText,
            iconOnly,
            textOnIcon,
            isPdf,
        } = this.props;

        return (
            <Hint text={hint ?? ((iconOnly || isPdf) && isString(title) ? title : '')} theme="dark" showDelay>
                <div
                    className={classNames({
                        [styles.root]: true,
                        [styles.root_primary]: primary,
                        [styles.root_secondary]: secondary,
                        [styles.root_icon]: icon && (!title || iconOnly),
                        [styles.icon_filled]: icon && isFilled,
                        [styles.root_collapsable]: collapsableText,
                        [styles.root_force_text]: forceText,
                    })}
                    onMouseDown={this.handleClick}
                    ref={textRef}
                    data-qa-id={id}
                >
                    {icon && (
                        <div className={styles.icon}>
                            {icon}
                            {textOnIcon ? <div className={styles.textOnIcon}>{textOnIcon}</div> : ''}
                        </div>
                    )}
                    {!iconOnly && <div className={styles.title}>{title}</div>}
                    {isPdf && (
                        <Icon20Dropdown className={classNames({ [styles.dropdownPdf]: true, [styles.dropdownUp]: isDropdownOpen })} />
                    )}
                    {Boolean(showDropDown) && (
                        <div className={styles.dropdown} onMouseDown={this.handleClickDropdown}>
                            <DropdownIcon className={classNames({ [styles.dropdownUp]: isDropdownOpen })} />
                        </div>
                    )}
                </div>
            </Hint>
        );
    }
}
