import { ReactElement, ReactEventHandler } from 'react';
import { ITariffFeatureListProps } from 'reactApp/ui/TariffCardNew/components/TariffFeatureList.types';
import { ITariffLabelProps } from 'reactApp/ui/TariffCardNew/components/TariffLabel.types';
import { ITariffSpaceProps } from 'reactApp/ui/TariffCardNew/components/TariffSpace.types';
import { ISwitchProps } from 'reactApp/ui/TariffCardNew/components/TariffSwitch.types';

export enum ETariffCardWidth {
    w360 = 'w360',
    fluid = 'fluid',
}

export enum ETariffCardSizeButtonSize {
    big = 'big',
}

export enum EButtonTextSize {
    middle = 'middle',
}

export interface ITariffCardProps {
    dataQa?: string;
    width?: ETariffCardWidth;
    compact?: boolean;
    disable?: boolean;
    action?: boolean;
    tariffId: string;
    buttonText: ReactElement | string;
    buttonSize?: ETariffCardSizeButtonSize;
    buttonTextSize?: EButtonTextSize;
    infoBlock?: ReactElement;
    footerText?: ReactElement;
    headerPrimaryText?: string;
    headerPrimaryTextCrossedOut?: boolean;
    headerPrimaryTextLarge?: boolean;
    headerPrimaryTextLight?: boolean;
    buttonPrimary?: boolean;
    buttonPrimaryLight?: boolean;
    buttonDisabled?: boolean;
    buttonInFooter?: boolean;
    buttonAdditionalText?: ReactElement | string;
    withoutButton?: boolean;
    accentFeature?: boolean;
    focused?: boolean;
    discountLabel?: number;
    label?: string;
    labelKind?: ITariffLabelProps['kind'];
    space: Pick<ITariffSpaceProps, 'space' | 'units'>;
    showSpaceWithPlus?: boolean;
    giftSpace?: Pick<ITariffSpaceProps, 'space' | 'units'>;
    onClickButton: ReactEventHandler;
    onClick?: ReactEventHandler;
    onClickSwitch?: ISwitchProps['onClick'];
    switchChecked?: ISwitchProps['checked'];
    switchOptions?: ISwitchProps['options'];
    featureListOptions?: ITariffFeatureListProps['items'];
    footerFeatureListOptions?: ITariffFeatureListProps['items'];
    savingInButton?: number;
}
