import { ReactComponent as FireIcon } from 'img/icons/fire.svg';
import React, { memo, ReactElement, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import {
    ELabelTheme,
    SidebarSubscriptionItem,
} from 'reactApp/sections/SubscriptionsPage/ui/SidebarSubcriptionItem/SidebarSubscriptionItem';
import { RootState } from 'reactApp/store';
import { formatSubscriptionData } from 'reactApp/utils/formatDate';
import { getDiscount } from 'reactApp/utils/getDiscount';

import styles from './DiscountPromocodesList.css';

interface Props {
    id: string;
    productId: string;
    expires: number;
    onItemClick: ({ productId, isTrial }: { productId: string; isTrial: boolean }) => void;
}

export const DiscountPromocodeItem = memo(({ productId, expires, onItemClick }: Props): ReactElement | null => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const product = useSelector((state: RootState) => ProductsSelectors.getProductById(state, productId));
    const {
        space,
        hasTrial: isTrial,
        price,
        discountPrice,
    } = product || {
        space: { value: '' },
        hasTrial: false,
        price: 0,
        discountPrice: 0,
    };
    const { roundedDiscount } = getDiscount({ discountPrice, price });

    const title = useMemo(
        () => (
            <>
                {roundedDiscount ? 'Скидка' : 'Промокод'} на <span className={styles.space}>{space.value}</span>
            </>
        ),
        [roundedDiscount, space.value]
    );

    const description = useMemo(
        () => (
            <>
                <span className={styles.activate}>Активировать</span> до {formatSubscriptionData(expires)}
            </>
        ),
        [expires]
    );

    const handleOnClick = useCallback(() => {
        onItemClick({ productId, isTrial });
    }, [onItemClick, productId, isTrial]);

    if (!product) {
        return null;
    }

    return (
        <SidebarSubscriptionItem
            icon={<FireIcon width={20} height={20} />}
            title={title}
            description={description}
            label={roundedDiscount ? `-${roundedDiscount}%` : ''}
            labelTheme={ELabelTheme.discount}
            onClick={handleOnClick}
        />
    );
});

DiscountPromocodeItem.displayName = 'DiscountPromocodeItem';
