import { IS_MOBILE_BROWSER, PUBLIC_SHOVE } from 'reactApp/appHelpers/configHelpers';
import { SUBSCRIPTIONS_URL } from 'reactApp/sections/SubscriptionsPage/SubscriptionsPage.constants';

const prefix = IS_MOBILE_BROWSER ? 'touch' : 'up';
const postfix = IS_MOBILE_BROWSER ? 'touch' : 'upline';

export const getAdvDisableLink = ({ isAnonymous }) => {
    if (PUBLIC_SHOVE) {
        return isAnonymous
            ? `/promo/?utm_source=cloud-subs-ads-branded&utm_medium=referral&utm_campaign=subs-ads-branded`
            : `${SUBSCRIPTIONS_URL}?utm_source=cloud-subs-ads-branded-buy&utm_medium=referral&utm_campaign=subs-ads-branded#year`;
    }

    return isAnonymous
        ? `/promo/?utm_source=cloud-ads-${prefix}&utm_medium=referral&utm_campaign=ads-${postfix}#year`
        : `${SUBSCRIPTIONS_URL}?utm_source=cloud-subs-ads-up-buy&utm_medium=referral&utm_campaign=subs-ads-${postfix}#year`;
};
