import { userKeeper } from '@mail-core/dashboard';
import { Rubles } from 'Cloud/Application/Rubles';
import classNames from 'clsx';
import React, { memo, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { getFeatureABDisableAdsSnackbar } from 'reactApp/modules/features/features.selectors';
import { isWeblinkDownloadable } from 'reactApp/modules/public/public.selectors';
import { loadPublicAlbumPageRequest } from 'reactApp/modules/public/publicAlbum.actions';
import { selectStatusPage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ErrorPage } from 'reactApp/sections/ErrorPage/ErrorPage';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';
import { File } from 'reactApp/ui/Public/Components/File/File';
import { Folder } from 'reactApp/ui/Public/Components/Folder/Folder';
import { initDisableAdAnalytics } from 'reactApp/ui/Public/Public.helpers';
import { ShovePublic } from 'reactApp/ui/Public/ShovePublic/ShovePublic';
import ReactViewerConnected from 'reactApp/ui/ReactViewer/ReactViewer';
import { startAdsUpdateTime } from 'reactApp/utils/helpers';

import styles from './Public.css';

interface Props {
    storage: EStorageType;
    noAds: boolean;
    isFolder: boolean;
    isShove: boolean;
    isAlbum: boolean;
}

export const Public = memo(function Public({ storage, noAds, isFolder, isShove, isAlbum }: Props): ReactElement {
    const showDisableButton = useSelector(getFeatureABDisableAdsSnackbar) !== 'b';
    const dispatch = useDispatch();
    const statusPage = useSelector(selectStatusPage);
    const isDownloadable = useSelector(isWeblinkDownloadable);

    isFolder = isFolder && !isDownloadable;

    useRouteChangeProcessing({
        storage,
        path: location.pathname.replace(`/${isAlbum ? 'album' : storage}/`, ''),
        search: location.search,
    });

    useEffect(() => {
        userKeeper.timeEnd('cloud-public-ready');

        initDisableAdAnalytics(storage, showDisableButton);

        dispatch(loadPublicAlbumPageRequest());

        if (isShove || noAds) {
            return;
        }

        const stopAdsIpdate = startAdsUpdateTime(() => Rubles.updateRightCol());

        return () => {
            stopAdsIpdate?.();
        };
    }, [isShove, noAds, showDisableButton]);

    if (statusPage) {
        return <ErrorPage status={statusPage} />;
    }

    if (isShove) {
        return <ShovePublic storage={storage} />;
    }

    return (
        <div className={classNames(styles.root, { [styles.root_signlePublic]: !isFolder && !isShove })}>
            <div className={styles.content}>
                {isFolder ? (
                    <div
                        className={classNames(styles.folderContent, {
                            [styles.folderContent_shove]: isShove,
                            [styles.folderContent_right]: !noAds,
                            [styles.folderContent_newHeader]: isNewPortalHeader.client,
                        })}
                    >
                        <Folder noTopBanner={noAds} />
                    </div>
                ) : (
                    <div
                        className={classNames(styles.fileContent, {
                            [styles.fileContent_shove]: isShove,
                        })}
                    >
                        {isShove ? (
                            <File />
                        ) : (
                            <ReactViewerConnected
                                embedded
                                isViewer
                                disableClose={!IS_MY_TEAM}
                                showLogo={!IS_MY_TEAM}
                                gaCategory="indpnewv"
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
});
