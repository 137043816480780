import classNames from 'clsx';
import React, { memo } from 'react';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from './Access.css';

interface AccessProps {
    access: 'read_write' | 'read_only';
    marginRight?: boolean;
}

const AccessMap = new Map<'read_write' | 'read_only', [string, string]>()
    .set('read_only', ['Открыт доступ на просмотр', 'Просмотр'])
    .set('read_write', ['Открыт доступ на редактирование', 'Редактирование']);

export const Access = memo<AccessProps>(function Access({ access, marginRight = false }) {
    const [hint, text] = AccessMap.get(access) || ['', ''];

    return (
        <Hint text={hint}>
            <div className={classNames(styles.root, { [styles.marginRight]: marginRight })}> {text}</div>
        </Hint>
    );
});
