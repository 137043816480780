import { EStatus, isFutureSubscription, ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import { Product } from 'reactApp/types/Billing';
import { formatDefaultDate } from 'reactApp/utils/formatDate';
import { getPeriodName } from 'reactApp/utils/Period';

export const getDescription = ({
    expires,
    autorenewal,
    renewAt,
    status,
    start,
}: {
    expires?: number;
    autorenewal?: boolean;
    renewAt?: number;
    status?: string;
    start?: number;
}) => {
    if (status === EStatus.FINISHED) {
        return 'Завершена';
    }

    if (start) {
        return `Начнёт действовать ${formatDefaultDate(start)}`;
    }

    if (autorenewal && renewAt) {
        return `Списание ${formatDefaultDate(renewAt)}`;
    } else if (expires) {
        return `Действует до ${formatDefaultDate(expires)}`;
    }

    return 'Подключено';
};

export const composeContent = ({ subscription, product }: { subscription: ISubscription; product?: Product }) => {
    const { autorenewal, renewAt, expires, isTrial, space, partner, status, icon } = subscription;
    const period = (isTrial && !product?.isForceDiscountTrial && product?.trialPeriod) || subscription?.period || product?.period;
    const periodText = period ? ` на ${getPeriodName(period, false, true)}` : '';
    const title = `${space.value}${periodText}`;
    const showWaiting = [EStatus.FINISHED, EStatus.SUSPENDED].includes(status as EStatus);

    return {
        title,
        description: getDescription({
            autorenewal,
            expires,
            renewAt,
            status,
            start: (isFutureSubscription(subscription) && subscription.start) || 0,
        }),
        icon,
        partner,
        showWaiting,
    };
};
