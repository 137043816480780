export interface IntegrationState {
    parentOrigin: string; // origin страницы, открывшей окно облака (параметр валидируется на webapi и в бивере и нужен для отправки postMessage)
    client: string; // идентификатор клиента, открывшего окно облака (нужен для аналитики)
    channel: string; // идентификатор канала, открывшего окно облака (нужен для аналитики)
    p: string; // p, открывшего окно облака (нужен для аналитики)
    userId: string; // идентификатор пользователя внешнего сервиса (нужен для аналитики)
    authType: string; // тип авторизации
    authOrigin: string; // ориджин провайдера
}

export type File = {
    name: string;
    link: string;

    size: number;

    shareID?: string;
};

export type CommunicatorPackedMessage = {
    name: string;
    payload?: string;
    __cloudMessage: true;
};

export enum EIntegrationClients {
    tutoria = 'tutoria',
}

export interface IIntegrationClient {
    isTutoria: boolean;
}
