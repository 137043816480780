import { Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import format from 'date-fns/format';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { trialPrice } from 'reactApp/appHelpers/featuresHelpers';
import { BuyContent } from 'reactApp/components/BuyModal/BuyContent';
import { EBuyContentSize, EBuyContentType } from 'reactApp/components/BuyModal/BuyContent.types';
import { getPretextPeriodForProduct } from 'reactApp/modules/products/products.helpers';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { RootState } from 'reactApp/store';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { defaultDateFormat } from 'reactApp/utils/formatDate';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { getPeriodName, getPeriodNameAsWord, getTrialOrDiscountDateEnd, getTypeOfPeriodName } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

import styles from './BuyModalTrial.css';
import { sendModalGa } from './BuyModalTrial.helpers';
import { EBuyModalTheme, IProps } from './BuyModalTrial.types';

const INITIAL_HEIGHT = 324; // Начальная высота карточки оплаты

export const BuyModalTrial = memo(function BuyModalTrial(props: IProps) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const product = useSelector((state: RootState) => ProductsSelectors.getProductById(state, props.id));
    const { isForceDiscountTrial, isTransit, space, discountPeriod, discountPrice, trialPeriod, isSingleQuota, price, period } = product;
    const trialPeriodName = getPeriodName(trialPeriod || '1m', false, true);
    const trialExpires = format(getTrialOrDiscountDateEnd(trialPeriod), defaultDateFormat);
    const [height, setHeight] = useState<number>(INITIAL_HEIGHT);
    const [isSuccess, setSuccess] = useState<boolean>(false);
    const [orderAmount, setOrderAmount] = useState<string | null>(null);
    const [orderAmountVisible, setOrderAmountVisible] = useState(false);
    const onDmrResizeEvent = useCallback((params) => setHeight(Math.max(params?.height || 0, INITIAL_HEIGHT)), []);
    const modalTheme = EBuyModalTheme.LIGHT;

    const onSuccess = useCallback(() => {
        props.onSuccess();
        setSuccess(true);
        sendModalGa(modalTheme, 'success');
    }, []);

    const onClose = useCallback(() => {
        props.onClose();
        sendModalGa(modalTheme, 'close');
        sendPaymentGa({ action: EPaymentGa.trialBuyModal, label: 'close', tariff: product.space.original });
    }, []);

    const onCancel = useCallback(() => {
        props.onClose();
        sendModalGa(modalTheme, 'close');
        sendPaymentGa({ action: EPaymentGa.trialBuyModal, label: 'cancel', tariff: product.space.original });
    }, []);

    const onBuyClick = useCallback(() => {
        sendPaymentGa({ action: EPaymentGa.trialBuyModal, label: 'click_pay', tariff: product.space.original });
    }, []);

    useEffect(() => {
        sendModalGa(modalTheme, 'show');
        sendPaymentGa({ action: EPaymentGa.trialBuyModal, label: 'view', tariff: product.space.original });
    }, []);

    const description = useMemo(() => {
        if (isForceDiscountTrial && discountPeriod && trialPeriod) {
            return (
                <p className={styles.text}>
                    {isTransit && (
                        <>
                            Тариф начнёт действовать после окончания предыдущего.
                            <br />
                        </>
                    )}
                    {getPeriodName(trialPeriod, true)} бесплатно,
                    <br />
                    затем {formatPrice(discountPrice)}&nbsp;₽&nbsp;/&nbsp;
                    {getPeriodName(discountPeriod)} с&nbsp;автопродлением
                </p>
            );
        }

        return (
            <>
                <p className={styles.text}>
                    Привяжите карту
                    <br /> для активации
                    <br /> бесплатного периода
                </p>

                <p className={styles.space}>И получите +{space.value} свободной памяти</p>
            </>
        );
    }, [isForceDiscountTrial, discountPeriod, trialPeriod, space.value, isTransit, discountPrice]);

    const footer = useMemo(() => {
        if (isForceDiscountTrial) {
            return (
                <p className={styles.la}>
                    {isTransit && 'Тариф начнёт действовать после окончания предыдущей подписки. '}
                    {getPretextPeriodForProduct(period)} {getPeriodNameAsWord(period, true)} оплата составит {formatPrice(price)}&nbsp;₽
                    в&nbsp;{getTypeOfPeriodName(period)}.
                </p>
            );
        }

        return (
            <p className={styles.la}>
                Стоимость тарифа {formatPrice(price)}&nbsp;₽/&nbsp;
                {getPeriodName(period)}, начиная с&nbsp;{trialExpires}. Подписка продлевается автоматически, её можно отменить в любой
                момент.
            </p>
        );
    }, [isForceDiscountTrial, isTransit, period, price, trialExpires]);

    return (
        <Dialog
            id="buy-trial"
            open={true}
            disableDarkTheme
            onCancel={onClose}
            className={classNames({
                [styles.root]: true,
                [styles[`root_theme_${modalTheme}`]]: true,
                [styles.root_forceTrial]: isForceDiscountTrial,
            })}
        >
            <h3 className={styles.title}>
                {isForceDiscountTrial
                    ? `Оплата тарифа ${product.space.value}`
                    : `Активировать ${product.space.value} на ${trialPeriodName} бесплатно`}
            </h3>
            <div
                className={styles.content}
                style={{ maxHeight: `${height + 75}px` }} // 75 - высота заголовка "Введите данные карты"
            >
                <div className={styles.aside}>
                    {description}

                    <ol className={styles.list}>
                        <li>Загрузка файла до {isSingleQuota ? '100' : '32'} ГБ</li>
                        <li>Версионирование</li>
                    </ol>

                    {footer}
                </div>

                <div
                    className={styles.buyContent}
                    style={{ maxHeight: `${height + 34}px` }} // 34 - высота заголовка "Введите данные карты"
                >
                    {!isSuccess && <h4 className={styles.buyHeader}>Введите данные карты</h4>}
                    {orderAmountVisible && (
                        <Text className={styles.note} data-qa-id="note-check-payment">
                            Для проверки карты мы спишем и сразу же вернём&nbsp;
                            {formatPrice(parseInt(orderAmount || '0') || trialPrice)}&nbsp;₽.
                        </Text>
                    )}
                    <Spacing size={16} />
                    <BuyContent
                        id={props.id}
                        link={props.link}
                        source={props.source}
                        onSuccess={onSuccess}
                        onClose={onClose}
                        onCancel={onCancel}
                        onBuyClick={onBuyClick}
                        size={EBuyContentSize.large}
                        type={EBuyContentType.TRIAL}
                        onDmrResizeEvent={onDmrResizeEvent}
                        setCardCheckPrice={(price) => {
                            setOrderAmount(price);
                            setOrderAmountVisible(true);
                        }}
                    />
                </div>
            </div>
        </Dialog>
    );
});
