import 'reactApp/theme.css';

import React from 'react';
import { Provider } from 'react-redux';
import { store } from 'reactApp/store';

import { ComponentRenderer } from './ComponentRenderer';

if (process.env.NODE_ENV === 'dev') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render').default;
    whyDidYouRender && whyDidYouRender(React, { notifier: () => 0 });
}

export function renderComponentInExternalDom(ReactComponent, propsStore) {
    return (
        <Provider store={store}>
            <ComponentRenderer ReactComponent={ReactComponent} propsStore={propsStore} />
        </Provider>
    );
}

export function dispatch(...params) {
    return store.dispatch(...params);
}
