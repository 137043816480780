import { Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { useMemo, VFC } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import { DiscountLabel } from 'reactApp/ui/DiscountLabel/DiscountLabel';
import { TariffFeatureList } from 'reactApp/ui/TariffCardNew/components/TariffFeatureList';
import { TariffLabel } from 'reactApp/ui/TariffCardNew/components/TariffLabel';
import { ETariffLabelKind, ETariffLabelRadius } from 'reactApp/ui/TariffCardNew/components/TariffLabel.types';
import { TariffSpace } from 'reactApp/ui/TariffCardNew/components/TariffSpace';
import { TariffSwitch } from 'reactApp/ui/TariffCardNew/components/TariffSwitch';

import styles from './TariffCard.css';
import { ETariffCardWidth, ITariffCardProps } from './TariffCard.types';

export const TariffCard: VFC<ITariffCardProps> = ({
    dataQa,
    onClick,
    width = ETariffCardWidth.w360,
    tariffId,
    space,
    showSpaceWithPlus = true,
    giftSpace,
    buttonText,
    buttonSize,
    buttonTextSize,
    headerPrimaryText,
    headerPrimaryTextCrossedOut,
    headerPrimaryTextLarge,
    headerPrimaryTextLight,
    buttonPrimary,
    buttonPrimaryLight,
    buttonDisabled,
    buttonAdditionalText,
    focused,
    label,
    labelKind,
    onClickButton,
    onClickSwitch,
    switchOptions,
    switchChecked,
    featureListOptions,
    footerFeatureListOptions,
    buttonInFooter,
    compact,
    discountLabel,
    infoBlock,
    savingInButton,
    disable,
    action,
    withoutButton,
    accentFeature,
    footerText,
}) => {
    const button = useMemo(
        () =>
            !withoutButton && (
                <>
                    <div className={styles.buttonWrapper}>
                        {discountLabel && (
                            <div className={styles.discountLabel}>
                                <DiscountLabel discount={discountLabel} />
                            </div>
                        )}
                        <Button
                            data-qa={dataQa && 'button'}
                            data-qa-primary={dataQa && buttonPrimary}
                            data-qa-disabled={dataQa && buttonDisabled}
                            fluid
                            onClick={onClickButton}
                            disabled={buttonDisabled}
                            className={classNames(styles.cardButton, {
                                [styles.cardButton_primary]: buttonPrimary,
                                [styles.cardButton_disabled]: buttonDisabled,
                                [styles.cardButton_primaryLight]: buttonPrimaryLight,
                                [styles[`cardButton_${buttonSize}`]]: Boolean(buttonSize),
                                [styles.cardButton_action]: action,
                            })}
                        >
                            <span
                                data-qa={dataQa && 'buttonText'}
                                className={classNames({
                                    [styles[`buttonText_${buttonTextSize}`]]: Boolean(buttonTextSize),
                                })}
                            >
                                {buttonText}
                            </span>
                            {Boolean(buttonAdditionalText) && (
                                <div className={styles.buttonAdditionalText} data-qa={dataQa && 'buttonAdditionalText'}>
                                    {buttonAdditionalText}
                                </div>
                            )}
                        </Button>
                        {Boolean(savingInButton) && <div className={styles.saving}>-{savingInButton}%</div>}
                    </div>
                </>
            ),
        [
            buttonAdditionalText,
            buttonDisabled,
            buttonPrimary,
            buttonPrimaryLight,
            buttonSize,
            buttonText,
            dataQa,
            discountLabel,
            onClickButton,
        ]
    );

    return (
        <div
            data-qa={dataQa}
            data-qa-focused={dataQa && focused}
            data-qa-tariffid={dataQa && tariffId}
            className={classNames(styles.card, {
                [styles.card_focused]: focused,
                [styles.card_compact]: compact,
                [styles.card_disable]: disable,
                [styles.card_action]: action,
                [styles[`card_${width}`]]: width,
            })}
            onClick={onClick}
        >
            {label && (
                <div className={styles.label}>
                    <TariffLabel
                        dataQa={dataQa && 'label'}
                        radius={focused ? ETariffLabelRadius.r22 : ETariffLabelRadius.r24}
                        kind={focused && action ? ETariffLabelKind.actionBlack : labelKind}
                    >
                        {label}
                    </TariffLabel>
                </div>
            )}
            <div className={styles.cardHeader}>
                <TariffSpace
                    dataQa={dataQa && 'space'}
                    units={space.units}
                    space={space.space}
                    giftSpace={giftSpace}
                    giftKind={labelKind}
                    showSpaceWithPlus={showSpaceWithPlus}
                />
                {headerPrimaryText && (
                    <div
                        data-qa={dataQa && 'headerPrimaryText'}
                        data-qa-crossedout={dataQa && headerPrimaryTextCrossedOut}
                        data-qa-large={dataQa && headerPrimaryTextLarge}
                        className={classNames(styles.cardHeaderPrimaryText, {
                            [styles.cardHeaderPrimaryText_crossedOut]: headerPrimaryTextCrossedOut,
                            [styles.cardHeaderPrimaryText_large]: headerPrimaryTextLarge,
                            [styles.cardHeaderPrimaryText_light]: headerPrimaryTextLight,
                        })}
                    >
                        {headerPrimaryText}
                    </div>
                )}
                {infoBlock}
                {switchOptions && (
                    <div className={styles.switch}>
                        <TariffSwitch
                            dataQa={dataQa && 'tariffSwitch'}
                            checked={switchChecked}
                            name={`tariff_${tariffId}`}
                            onClick={onClickSwitch}
                            options={switchOptions}
                        />
                    </div>
                )}
            </div>
            {!buttonInFooter && button}
            {featureListOptions && (
                <div
                    className={classNames(styles.featureList, {
                        [styles.featureList_white]: compact && !disable,
                    })}
                >
                    <TariffFeatureList accent={accentFeature} dataQa={dataQa && 'featureList'} showIcon items={featureListOptions} />
                </div>
            )}
            {footerFeatureListOptions && (
                <>
                    <div className={styles.delimiter} data-qa={dataQa && 'featureDelimiter'} />
                    <div className={styles.featureList}>
                        <TariffFeatureList
                            accent={accentFeature}
                            dataQa={dataQa && 'footerFeatureList'}
                            showIcon
                            items={footerFeatureListOptions}
                        />
                    </div>
                </>
            )}
            {footerText && <div className={styles.footerText}>{footerText}</div>}
            {buttonInFooter && (
                <>
                    <Spacing size={36} />
                    {button}
                </>
            )}
        </div>
    );
};
