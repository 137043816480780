import { LoadingState } from 'reactApp/types/commonStates';

export enum BillingBuyTemplates {
    CHECKOUT_VKPAY = 'CHECKOUT_VKPAY',
    CHECKOUT_VKPAY_NOOFFER = 'CHECKOUT_VKPAY_NOOFFER',
}

export enum BillingBuyViewTemplates {
    checkout = 'checkout',
}

export type CommonBuyApiParams = {
    skin?: BillingBuyTemplates;
    view?: BillingBuyViewTemplates;
    pay_method?: string;
    source?: string;
    partner?: string;
    hide_pay_methods_menu?: boolean;
    description?: string;
};

export type BuyApiParams = CommonBuyApiParams & {
    product_id: string;
};

export type RenewApiParams = CommonBuyApiParams & {
    sub_id: string;
};

export type State = {
    link: string;
    renewLink: string;
    requestId: string;
} & LoadingState;

export type SetLinkAction = {
    link: string;
    requestId: string;
};

export type HasErrorAction = {
    requestId: string;
};

export type StartLoadingAction = {
    requestId: string;
};

export type BuyAction = {
    id: string;
    isMobile?: boolean;
    payMethod?: string;
    source?: string;
    isQuick?: boolean;
    xraySource?: string;
    isMidas?: boolean;
    description?: string;
};
