import classNames from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { IS_DOCUMENTS_DOMAIN } from 'reactApp/appHelpers/configHelpers';
import { isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { ALL_DOCUMENTS_DOMAIN_ROUTES, ALL_DOCUMENTS_PATH } from 'reactApp/modules/allDocuments/allDocuments.constants';
import { allDocumentsHideSpaceRadar } from 'reactApp/modules/allDocuments/allDocuments.helpers';
import { allDocumentsLoadRequest, resetCurrentAllDocumentType } from 'reactApp/modules/allDocuments/allDocuments.module';
import { AllDocumentsSections, EAllDocumentsType } from 'reactApp/modules/allDocuments/allDocuments.types';
import { isAllDocumentsHideSpace } from 'reactApp/modules/features/features.helpers';
import { getFeatureAllDocumentsHideSections } from 'reactApp/modules/features/features.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { useGetAllDocumentsPageDwhData } from 'reactApp/sections/AllDocuments/hooks/useGetAllDocumentsPageDwhData';
import { MainPage } from 'reactApp/sections/MainPage/MainPage';
import { TrashbinPage } from 'reactApp/sections/TrashbinPage/TrashbinPage';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { sendDwh, sendXray } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './AllDocumentsPage.css';

interface Props {
    documentType: AllDocumentsSections;
}

const AllDocumentsPageContent: FC<Props> = ({ documentType }) => {
    const dispatch = useDispatch();
    const dwhData = useGetAllDocumentsPageDwhData(documentType);
    const [isSent, setIsSent] = useState(false);
    const [prevDocumentType, setPrevDocumentType] = useState<string | null>(null);
    const isTrashbinSection = documentType === 'trashbin';

    useEffect(() => {
        if (isSent || !dwhData) {
            return;
        }

        const { platform, cnt_file, size_file } = dwhData;

        setIsSent(true);

        sendPaymentGa({
            eventCategory: ECategoryGa.entered,
            action: 'page-entered',
            source: 'docs',
        });

        sendDwh({
            eventCategory: ECategoryGa.entered,
            action: 'docs',
            dwhData: {
                platform,
                cnt_file,
                size_file,
            },
        });
    }, [dwhData, isSent]);

    useEffect(() => {
        if (!isTrashbinSection) {
            dispatch(allDocumentsLoadRequest(documentType));
        }

        sendXray(['page', 'enter', 'alldocs', documentType]);

        allDocumentsHideSpaceRadar(isAllDocumentsHideSpace);

        return () => {
            dispatch(resetCurrentAllDocumentType());
        };
    }, [documentType, dispatch, isTrashbinSection]);

    useEffect(() => {
        if (!dwhData || prevDocumentType === dwhData?.place || isTrashbinSection) {
            return;
        }

        setPrevDocumentType(dwhData.place);

        sendDwh({
            eventCategory: ECategoryGa.entered,
            action: 'docs-section',
            dwhData,
        });
    }, [dwhData, isTrashbinSection, prevDocumentType]);

    return (
        <MainPage storage={EStorageType.alldocuments} renderWorm showUploader={!isTrashbinSection} showSpace={!isAllDocumentsHideSpace}>
            {isTrashbinSection ? (
                <TrashbinPage />
            ) : (
                <div className={styles.root}>
                    <div className={classNames({ [styles.headerContainer_new_portal_header]: isNewPortalHeader.client })}>
                        <BreadcrumbsContainer />
                    </div>
                    <div className={classNames({ [styles.datalistContainer_new_portal_header]: isNewPortalHeader.client })}>
                        <DataList goTo={noop} storage={EStorageType.alldocuments} />
                    </div>
                </div>
            )}
        </MainPage>
    );
};

export const AllDocumentsPage = () => {
    const { documentType = EAllDocumentsType.document } = (useParams() || {}) as { documentType: AllDocumentsSections };
    const hiddenSections = useSelector(getFeatureAllDocumentsHideSections);

    return hiddenSections.includes(documentType) ? (
        <Navigate
            replace
            to={`${IS_DOCUMENTS_DOMAIN ? ALL_DOCUMENTS_DOMAIN_ROUTES.document : ALL_DOCUMENTS_PATH}${window.location.search}`}
        />
    ) : (
        <AllDocumentsPageContent documentType={documentType} />
    );
};
