import { Spacing, Text } from '@vkontakte/vkui';
import Config from 'Cloud/config';
import classNames from 'clsx';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { trialPrice } from 'reactApp/appHelpers/featuresHelpers';
import { DmrError } from 'reactApp/errors/serviceErrors/DmrError';
import { CardSelectors } from 'reactApp/modules/creditCard/creditCard.selectors';
import { CreditCardDmrLinkActions, CreditCardDmrLinkSelectors } from 'reactApp/modules/creditCardDmrLink/creditCardDmrLink.module';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import DMR from 'reactApp/ui/DMR/DMR';
import { noop } from 'reactApp/utils/helpers';
import { formatPrice } from 'reactApp/utils/priceHelpers';
import { Radar } from 'reactApp/utils/Radar';
import { getParameterFromUrl } from 'reactApp/utils/urlHelper';
import { Ref } from 'semantic-ui-react';

import styles from './AddCardModal.css';

const mapDispatchToProps = (dispatch) => ({
    addCard: () => dispatch(CreditCardDmrLinkActions.addCard()),
});

const mapStateToProps = (state) => {
    const { addCardLink: link, isLoading, hasError } = CreditCardDmrLinkSelectors.getDmrData(state);
    const { card } = CardSelectors.getCard(state);

    return {
        link,
        isLoading,
        hasError,
        hasCard: !isNil(card),
    };
};

const radar = new Radar({
    logFile: 'credit-card-dmr',
    groupName: 'credit-card-dmr',
});

@connect(mapStateToProps, mapDispatchToProps)
export class AddCardModal extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        link: PropTypes.string,
        hasError: PropTypes.bool,
        isMobile: PropTypes.bool,
        size: PropTypes.string,
        isLoading: PropTypes.bool,
        addCard: PropTypes.func,
        onSuccess: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
        hasCard: PropTypes.bool,
        onUnlinkCard: PropTypes.func,
    };

    static defaultProps = {
        size: 'small',
        isMobile: false,
        onCancel: noop,
    };

    state = {
        node: '',
        dmrLoading: false,
        error: false,
        frameHeight: 'auto',
        orderAmount: null,
    };

    componentDidMount() {
        this.props.addCard();

        if (this.dmr) {
            this.dmr.destroy();
            this.dmr = null;
        }
    }

    componentDidUpdate() {
        if (this.props.link && !this.dmr) {
            const startTime = new Date();
            const errorConf = {
                url: this.props.link,
                source: 'credit-card',
                pageId: Config.get('x-page-id'),
                user: Config.get('user.email'),
            };

            this.setState({
                dmrLoading: true,
            });
            this.dmr = new DMR({ el: this.state.node });

            this.dmr.on(this.dmr.EVENT_FORM_SEND, () => {
                RADAR.ga('send', 'event', {
                    eventCategory: 'billing',
                    eventAction: 'creditCardFormSend',
                });
            });
            this.dmr.on(this.dmr.EVENT_RESIZE, (eventData) => {
                if (eventData?.height) {
                    this.setState({
                        frameHeight: eventData.height,
                    });
                }
            });
            this.dmr.on(this.dmr.EVENT_PAYMENT_SUCCESS, () => {
                RADAR.ga('send', 'event', {
                    eventCategory: 'billing',
                    eventAction: 'creditCardFormSuccess',
                });
                radar.addCounter('success');
                radar.send();
                this.props.onSuccess();
            });
            this.dmr.on(this.dmr.EVENT_CLOSE, () => {
                radar.addCounter('close');
                radar.send();
                this.props.onClose();
            });
            // eslint-disable-next-line sonarjs/no-identical-functions
            this.dmr.on(this.dmr.EVENT_PAYMENT_CANCEL, () => {
                radar.addCounter('close');
                radar.send();
                this.props.onCancel();
                this.props.onClose();
            });
            this.dmr.on(this.dmr.EVENT_LOADED, () => {
                RADAR.ga('send', 'event', {
                    eventCategory: 'billing',
                    eventAction: 'creditCardFormShow',
                });
                radar.addCounter('load-success');
                radar.addCounter('load-time', new Date() - startTime);
                radar.send();
                this.setState({ dmrLoading: false });

                const iFrameSrc = this.state.node?.children[0]?.src;
                if (iFrameSrc) {
                    this.setState({ orderAmount: getParameterFromUrl(iFrameSrc, 'order_amount') });
                }
            });
            this.dmr.on(this.dmr.EVENT_MESSAGE, ({ type, action }) => {
                radar.addCounter(`type_${this.cameToKebabCase(type)}_action_${this.cameToKebabCase(action)}`);
                radar.send();
            });
            this.dmr.on(this.dmr.EVENT_LOAD_ERROR, ({ type }) => {
                const loadTime = new Date() - startTime;
                errorConf.loadTime = loadTime;
                const error = new DmrError(errorConf);

                radar.addLogRecord(error);
                radar.addCounter(`error_type_${type}`, loadTime);
                radar.send();

                this.setState({
                    dmrLoading: false,
                    hasError: true,
                });
            });
            this.dmr.on(this.dmr.EVENT_REMOVE_ADDED_CARD, () => {
                this.props.onUnlinkCard?.();
            });
            this.dmr.load(this.props.link).catch(() => {});
        }
    }

    componentWillUnmount() {
        if (this.dmr) {
            this.dmr.destroy();
            this.dmr = null;
        }
    }

    cameToKebabCase = (str) => str.replace(/[A-Z]/g, (a, i) => (i ? '-' : '') + a.toLowerCase());

    handleRef = (node) => this.setState({ node });

    retry = () => {
        this.setState({
            hasError: false,
        });

        if (this.dmr) {
            this.dmr.destroy();
            this.dmr = null;
        }

        this.props.addCard();
    };

    render() {
        const { onClose, hasCard, isMobile, size, hasError, isLoading } = this.props;
        const { dmrLoading, frameHeight, hasError: hasErrorState } = this.state;
        const showError = hasErrorState || hasError;
        const showLoader = dmrLoading || isLoading;

        const header = <div className={styles.header}>{hasCard ? 'Изменение карты' : 'Добавление карты'}</div>;

        return (
            <Dialog
                id="add-card"
                size={size}
                disableDarkTheme
                open
                header={header}
                onCancel={onClose}
                mod={isMobile ? 'mobile' : 'frame'}
                scrollingContent={false}
                className={isMobile ? styles.mobileModal : undefined}
            >
                <div
                    className={classNames(styles.root, {
                        [styles.root_error]: showError,
                    })}
                >
                    <Content
                        isModal
                        prerenderContent
                        buttonText="Попробовать ещё раз"
                        isLoading={showLoader}
                        hasError={showError}
                        retry={this.retry}
                        wrapClass={styles.content}
                    >
                        <Text className={styles.note} data-qa-id="note-auto-payment">
                            C&nbsp;привязанной карты будет списываться автоплатёж для всех активных подписок, приобретаемых на&nbsp;сайте.
                            Отменить автоплатёж можно в&nbsp;настройках подписки.
                        </Text>
                        <Spacing size={16} />
                        <Text className={styles.note} data-qa-id="note-check-payment">
                            Для проверки карты мы спишем и сразу же вернём&nbsp;
                            {formatPrice(this.state.orderAmount || trialPrice)}&nbsp;₽.
                        </Text>
                        <Spacing size={16} />
                        <div
                            className={styles.frame}
                            style={{
                                height: frameHeight,
                            }}
                        >
                            <Ref innerRef={this.handleRef}>
                                <div />
                            </Ref>
                        </div>
                    </Content>
                </div>
            </Dialog>
        );
    }
}
