import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import { Product } from 'reactApp/types/Billing';

import { plural } from '../../public/js/lib/plural';

const MAP_PERIODS = {
    d: 'дня',
    m: 'месяца',
    w: 'недели',
    y: 'года',
};

const MAP_PERIODS_PLURAL = {
    d: ['день', 'дня', 'дней'],
    m: ['месяц', 'месяца', 'месяцев'],
    w: ['неделя', 'недели', 'недель'],
    y: ['год', 'года', 'лет'],
};

const MAP_NUMBERS = {
    1: ['первого', 'первой', ''],
    2: ['второго', 'второй', 'два', 'две'],
    3: ['третьего', 'третьей', 'три'],
    4: ['четвертого', 'четвертой', 'четыре'],
    5: ['пятого', 'пятой', 'пять'],
    6: ['шестого', 'шестой', 'шесть'],
    7: ['седьмого', 'седьмой', 'семь'],
    8: ['восьмого', 'восьмой', 'восемь'],
    9: ['девятого', 'девятой', 'девять'],
    10: ['десятого', 'десятой', 'десять'],
    11: ['одиннадцатого', 'одиннадцатой', 'одиннадцать'],
};

export const parsePeriod = (period: string) => period && period.match(/(^\d*)+(\D)$/);

export const simplifyPeriod = (count, name) => {
    let resultCount = count;
    let resultName = name;

    if (count === 7 && name === 'd') {
        resultCount = 1;
        resultName = 'w';
    }

    if (count % 12 === 0 && name === 'm') {
        resultCount = count / 12;
        resultName = 'y';
    }

    return [resultCount, resultName] as const;
};

export const getPeriodName = (period: string, showOne = false, genetive = false, skipSimplify = false): string => {
    if (!period) {
        return '';
    }

    const periodMatches = parsePeriod(period);

    if (!periodMatches) {
        return '';
    }

    let count = Number(periodMatches[1]);
    let name = periodMatches[2];
    const text = {
        d: [...MAP_PERIODS_PLURAL['d']],
        w: genetive ? ['неделю'] : [...MAP_PERIODS_PLURAL['w']],
        m: [...MAP_PERIODS_PLURAL['m']],
        y: [...MAP_PERIODS_PLURAL['y']],
    };

    if (!count || !name) {
        return '';
    }

    [count, name] = skipSimplify ? [count, name] : simplifyPeriod(count, name);

    return `${(count === 1 && !showOne) || (count === 6 && name === 'm') ? '' : `${count}\u00A0`}${
        count === 6 && name === 'm' ? 'полгода' : text[name][plural(count)]
    }`;
};

export const isYearPeriod = (period: string): boolean => {
    const periodMatches = parsePeriod(period);
    if (!periodMatches) {
        return false;
    }

    const count = Number(periodMatches[1]);
    let name = periodMatches[2];

    if (count % 12 === 0 && name === 'm') {
        name = 'y';
    }

    return name === 'y';
};

export const isMonthPeriod = (period: string): boolean => period === '1m';
export const isMonth3Period = (period: string): boolean => period === '3m';
export const isMonth6Period = (period: string): boolean => period === '6m';
export const isYear2Period = (period: string): boolean => period === '2y';

export const getMonthes = (period: string): number => {
    const periodMatches = parsePeriod(period);
    if (!periodMatches) {
        return 0;
    }

    let count = Number(periodMatches[1]);
    const name = periodMatches[2];

    if (name === 'y') {
        count *= 12;
    } else if (name !== 'm') {
        return 0;
    }

    return count;
};

export const getYearPrice = (product?: Product): number | null => {
    if (!product) {
        return null;
    }
    const koeff = isYearPeriod(product.period) ? 1 : isMonth3Period(product.period) ? 4 : 12;
    return koeff * product.price;
};

export const getTariffProductsByPeriods = (products: Product[]): { yearProduct: Product; monthProduct: Product } => {
    const yearProduct = products.filter((product): boolean => isYearPeriod(product.period))[0];
    const monthProduct = products.filter((product): boolean => isMonthPeriod(product.period))[0];

    return {
        yearProduct,
        monthProduct,
    };
};

export const getTrialOrDiscountDateEnd = (period, startDate: Date | number = new Date()) => {
    let method = addYears;
    switch (period.slice(-1)) {
        case 'm':
            method = addMonths;
            break;
        case 'd':
            method = addDays;
            break;
        default:
            break;
    }
    return method(startDate, parseInt(period));
};

export const getTypeOfPeriodName = (period: string) => {
    const periodMatches = parsePeriod(period);
    if (!periodMatches) {
        return '';
    }

    const names = {
        d: 'день',
        w: 'неделю',
        m: 'месяц',
        y: 'год',
    };

    return names[periodMatches[2]];
};

export const getPeriodNameAsWord = (periodString: string, next = false, isPlural = false) => {
    const periodMatches = parsePeriod(periodString);
    if (!periodMatches) {
        return '';
    }
    let count = Number(periodMatches[1]);
    let name = periodMatches[2];

    if (!count || !name) {
        return '';
    }

    [count, name] = simplifyPeriod(count, name);

    if (next) {
        count = count + 1;
    }

    let countIndex = 0;

    if (isPlural) {
        countIndex = name === 'w' && count === 2 ? 3 : 2;
    } else if (name === 'w') {
        countIndex = 1;
    }

    const number = MAP_NUMBERS?.[count]?.[countIndex] || (isPlural ? '' : count);

    return `${number && `${number} `}${isPlural ? MAP_PERIODS_PLURAL[name][plural(count)] : MAP_PERIODS[name]}`;
};

export const getFirstSomePeriodsAsWord = (num: number, period: 'd' | 'm' | 'w' | 'y' | string) => {
    const [count, name] = simplifyPeriod(num, period);
    const prefix = name === 'w' ? ['первая', 'первые', 'первые'][plural(count)] : ['первый', 'первые', 'первые'][plural(count)];

    return `${prefix} ${getPeriodNameAsWord(`${count}${name}`, false, true)}`;
};

export const nowIsTrialOrDiscountPeriod = (period, startDate) => {
    if (!period) {
        return false;
    }

    const end = getTrialOrDiscountDateEnd(period, startDate);
    const now = new Date();

    return now < end;
};
