import { Failure } from '../Failure';

class DmrError extends Failure {
    static radarName = 'failure_{SOURCE}_dmr';

    className = 'DmrError';

    constructor({ url, loadTime, component }) {
        super(...arguments);

        this.component = component;
        this.url = url;
        this.loadTime = loadTime;
    }

    getDetails() {
        const details = super.getDetails(...arguments);

        details.url = this.url;
        details.loadTime = this.loadTime;
        details.component = this.component;

        return details;
    }
}

export { DmrError };
