import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { useCallback, useMemo, VFC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { TABS_MAP } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.constants';
import { useTariffBuyDialog } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.hooks';
import { RootState } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';

import { DISCOUNTS } from './DiscountCard.constant';
import styles from './DiscountCard.css';
import { formatTitle, sendAnalytics } from './DiscountCard.helpers';
import type { Props } from './DiscountCard.types';

export const DiscountCard: VFC<Props> = ({ title, text, type, buttonText, link, id }) => {
    const product = useSelector((state: RootState) => ProductsSelectors.getProductById(state, id));

    const isPromoType = type === DISCOUNTS.promo;
    const { space, titleWithoutSpace } = formatTitle(title);

    const { openTariffBuy } = useTariffBuyDialog();

    const onClick = useCallback(() => {
        closePopupHelper(popupNames.MOBILE_DISCOUNTS);

        if (type === DISCOUNTS.promocode) {
            sendAnalytics('activate-promocode', title);
            return;
        }

        if (type === DISCOUNTS.promo) {
            window.location.hash = TABS_MAP[DISCOUNTS.special];
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }

        sendAnalytics('action-click', title);

        if (product) {
            const price = product?.hasTrial ? 0 : product?.discountPrice || product?.price || 0;

            openTariffBuy({ id, price, period: product?.period });
        }
    }, [id, openTariffBuy, product, title, type]);

    const button = useMemo(
        () => (
            <Button
                size="tiny"
                theme="vk"
                middle
                className={classNames(styles.button, { [styles.buttonSecondary]: !isPromoType })}
                primary={isPromoType}
                onClick={onClick}
            >
                {buttonText}
            </Button>
        ),
        [buttonText, isPromoType, onClick]
    );

    if (id && id !== 'promocode' && id !== 'promo' && !product) {
        return null;
    }

    return (
        <div className={classNames(styles.root, styles[`root_type_${type}`])}>
            <div className={styles.title}>
                {space && <span className={styles[`${type}_type_units`]}>{space}</span>}
                <span>{titleWithoutSpace}</span>
            </div>
            <Text className={styles.text}>{text}</Text>
            {link ? <Link to={link}>{button}</Link> : button}
        </div>
    );
};
