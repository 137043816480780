import classNames from 'clsx';
import React, { useEffect, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { loadIncomingStartRequest } from 'reactApp/modules/incoming/incoming.module';
import { getIncomingLoadingState } from 'reactApp/modules/incoming/incoming.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { SharedTabs } from 'reactApp/ui/SharedTabs/SharedTabs';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './SharedIncomingPage.css';

export const SharedIncomingPage: VFC = () => {
    const dispatch = useDispatch();
    const { isLoaded } = useSelector(getIncomingLoadingState);
    const showSharedTabs = !IS_BIZ_USER;

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'shared-incoming' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'shared-incoming' });
        dispatch(loadIncomingStartRequest());
    }, []);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_new_portal_header]: isNewPortalHeader.client,
            })}
        >
            <div className={classNames({ [styles.headerContainer_new_portal_header]: isNewPortalHeader.client })}>
                {isLoaded && <BreadcrumbsContainer />}
                {showSharedTabs && <SharedTabs />}
            </div>
            <DataList goTo={noop} storage={EStorageType.sharedIncoming} selectableClassName={styles.datalistSelectable} />
        </div>
    );
};
