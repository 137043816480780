import config from 'Cloud/config';
import { useEffect, useMemo, useRef, useState } from 'react';
import { USER_EMAIL } from 'reactApp/appHelpers/configHelpers';
import { isAttachFile } from 'reactApp/modules/attaches/attaches.helpers';
import { Ovidius } from 'reactApp/modules/ovidius';
import { OvidiusAttachesOriginal, OvidiusPart } from 'reactApp/modules/ovidius/types';
import { ATTACHES_DOCS_URL } from 'reactApp/ui/ReactViewer/ReactViewer.helpers';

import { AttachError, FileError, UseOvidiusData, UseOvidiusError, UseOvidiusProps } from './types';

const OAUTH_HOST = config.get('OAUTH_HOST');
const CORSAPI_HOST = config.get('CORS_API_HOST');

export const useAttachOvidius = ({ file, storage, sendRadar }: UseOvidiusProps): UseOvidiusData => {
    const isPdfConvertAttachDataSended = useRef(false);
    const isPdfConvertAttachDataReceived = useRef(false);

    const [partsAmount, setPartsAmount] = useState<number>(0);
    const [content, setContent] = useState<ArrayBuffer[]>([]);
    const [partsInfo, setPartsInfo] = useState<Array<OvidiusPart>>([]);
    const [error, setError] = useState<UseOvidiusError | null>(null);
    const [attachHash, setAttachHash] = useState<null | string>(null);

    const attachType = useMemo(() => (isAttachFile(file) ? file.attachType : undefined), [file]);
    const ovidiusUrl = useMemo(() => (isAttachFile(file) ? file.editors?.ovidius?.url : undefined), [file]);

    useEffect(() => {
        isPdfConvertAttachDataSended.current = false;
        isPdfConvertAttachDataReceived.current = false;

        setPartsAmount(0);
        setContent([]);
        setPartsInfo([]);
        setError(null);
        setAttachHash(null);
    }, [file, storage]);

    useEffect(() => {
        if (!attachType) {
            return;
        }

        if (!ovidiusUrl) {
            sendRadar('no-url', false, true);
            setError(AttachError.Url);
            return;
        }

        let cancel = false;

        const iframe = document.createElement('iframe');
        iframe.src = ATTACHES_DOCS_URL;
        const listener = (event: MessageEvent<{ type: string; docViewData: OvidiusAttachesOriginal }>) => {
            if (cancel) {
                return;
            }

            const isSendingPostMessageAllowed = iframe?.contentWindow === event.source;
            if (isSendingPostMessageAllowed && !isPdfConvertAttachDataSended.current) {
                iframe.contentWindow?.postMessage(
                    {
                        type: 'docsData',
                        docsData: {
                            ovidiusUrl,
                            login: USER_EMAIL,
                            o2_host: OAUTH_HOST,
                            corsapi_host: CORSAPI_HOST,
                        },
                    },
                    `${window.location.origin}${ATTACHES_DOCS_URL}`
                );
                isPdfConvertAttachDataSended.current = true;
                setAttachHash(null);
            }

            if (!isPdfConvertAttachDataReceived.current && event.data.type === 'documentsViewUrl') {
                isPdfConvertAttachDataReceived.current = true;
                if (!event.data.docViewData?.post_params?.parts_info) {
                    sendRadar('no-info', false, true);
                    setError(AttachError.Info);
                    return;
                }

                const { parts_total: partsTotal, hash, parts } = event.data.docViewData.post_params.parts_info || {};
                if (!hash) {
                    sendRadar('no-hash', false, true);
                    setError(AttachError.Hash);
                    return;
                }

                sendRadar('info');

                setAttachHash(hash);
                setPartsAmount(partsTotal);
                setPartsInfo(parts);
            }
        };

        document.body.appendChild(iframe);
        window.addEventListener('message', listener);

        return () => {
            cancel = true;
            iframe?.remove();
            window.removeEventListener('message', listener);
        };
    }, [file, storage]);

    useEffect(() => {
        if (!partsInfo.length || !attachHash) {
            return;
        }

        const controller = new AbortController();

        (async () => {
            const getPdfFilePartInfo = Ovidius.getPdfFilePart({ file, storage, hash: attachHash, signal: controller.signal });

            for (let i = 0; i < partsInfo.length; i++) {
                const { id } = partsInfo[i];
                const { content: newPart } = await getPdfFilePartInfo(id);

                if (!newPart) {
                    sendRadar('no-part', false, true);
                    setError(FileError.SecondPart);
                    return;
                }

                setContent((oldContent) => [...oldContent, newPart]);
            }

            sendRadar('all-parts');
        })();

        return () => {
            controller.abort();
        };
    }, [partsInfo, attachHash]);

    return {
        content,
        partsAmount,
        error,
    };
};
