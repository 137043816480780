import { Spacing, Text, Title } from '@vkontakte/vkui';
import coverImage from 'img/familySubscriptionPromo.jpg';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { PromocodesSelectors } from 'reactApp/modules/promocode/promocode.selectors';
import { CopyInput } from 'reactApp/ui/CopyInput/CopyInput';
import { Placeholder } from 'reactApp/ui/Placeholder/Placeholder';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './FamilySubscriptionPromo.css';

interface IProps {
    onClose: () => void;
}

const onCopy = () => sendPaymentGa({ action: EPaymentGa.familySubscription, label: 'copy-promo' });

export const FamilySubscriptionPromoDialog = memo<IProps>(({ onClose }) => {
    const promocodes = useSelector(PromocodesSelectors.getPromocodesList);
    const { isLoading } = useSelector(PromocodesSelectors.getLoadingState);

    const onButtonClick = useCallback(() => {
        sendPaymentGa({ action: EPaymentGa.familySubscription, label: 'click-promo' });
        onClose();
    }, [onClose]);

    const content = useMemo(
        () => (
            <div>
                <Title className={styles.title} level="1" weight="2">
                    Дарим скидку на семейную подписку
                </Title>
                <Spacing size={18} />
                <Text className={styles.text}>
                    Воспользуйтесь промокодами, чтобы получить бесплатное место в хранилище для вашей семьи.
                </Text>
                <Spacing size={16} />

                {isLoading ? (
                    <div className={styles.promocodesBlock}>
                        <div className={styles.loader}>
                            <Placeholder />
                        </div>
                        <div className={styles.loader}>
                            <Placeholder />
                        </div>
                    </div>
                ) : (
                    <div className={styles.promocodesBlock}>
                        {promocodes.map((promocode) => (
                            <div className={styles.promocode} key={promocode.code}>
                                <CopyInput
                                    value={promocode.code}
                                    onCopy={onCopy}
                                    successNotificationText="Промокод скопирован"
                                    disabledText="Промокод активирован"
                                    disabled={!promocode.active}
                                />
                            </div>
                        ))}
                    </div>
                )}

                <Spacing size={30} />
            </div>
        ),
        [isLoading, promocodes]
    );

    useEffect(() => {
        sendPaymentGa({ action: EPaymentGa.familySubscription, label: 'view-promo' });
    }, []);

    return (
        <WhatsNewDialog
            closable
            onClick={onButtonClick}
            onClose={onClose}
            primaryButtonText="Отлично, спасибо"
            primaryButtonColor="grey"
            content={content}
            imageUrl={coverImage}
            title=""
            dialogSize="middle"
        />
    );
});

FamilySubscriptionPromoDialog.displayName = 'FamilySubscriptionPromoDialog';
