import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class ComponentRenderer extends PureComponent {
    state = {
        componentProps: {},
    };

    static propTypes = {
        propsStore: PropTypes.object,
        ReactComponent: PropTypes.func,
    };

    constructor(props) {
        super(...arguments);
        const { propsStore } = props;

        this.state = { componentProps: propsStore.getProps() };
    }

    componentDidMount() {
        const { propsStore } = this.props;
        propsStore.on('change', this._handleComponentPropsChange);
    }

    _handleComponentPropsChange = newProps => {
        this.setState({ componentProps: newProps });
    };

    render() {
        const {
            props: { ReactComponent },
            state: { componentProps },
        } = this;
        return (
            <>
                <ReactComponent {...componentProps} />
            </>
        );
    }
}
