import { requestPayment } from 'Cloud/Application/Payment';
import { useCallback } from 'react';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import { EShowTariffType, IModalCallbacks, TariffInfo } from '../UploadBigFileDialog.types';

export const useBigFileModalCallbacks = ({
    closeModal,
    skipFileLoading,
    continueFileLoading,
    sendGa,
    tariffInfo,
    fileSizeBytes,
}): IModalCallbacks => {
    const { trial, usualProduct, showTariffType } = <TariffInfo>tariffInfo;

    const sendPayment = useCallback(
        (label) => {
            sendPaymentGa({ action: EPaymentGa.bigFileModal, label, size_offer: fileSizeBytes });
        },
        [fileSizeBytes]
    );

    const onSkip = useCallback(() => {
        sendGa('skip', showTariffType);
        sendPayment('cancel');
        skipFileLoading();
        closeModal();
    }, [showTariffType]); // closeModal передается из старого кода, коллбек пересоздавать не нужно при его изменении

    const onClose = useCallback(() => {
        sendGa('close', showTariffType);
        sendPayment('close');
        skipFileLoading();
        closeModal();
    }, [showTariffType]);

    const onRequestPayment = useCallback(() => {
        const onSuccess = (gaLabel) => () => {
            closeModal();
            sendGa(gaLabel);
        };

        sendGa('request-payment', showTariffType);
        sendPayment('click');
        // Пользователю с неактивированным триалом в 128ГБ
        // показываем окно с предложением оплатить триал.
        // Оверквотеру триал не показываем.
        if (showTariffType === EShowTariffType.TRIAL) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            requestPayment({
                productId: trial.id,
                forceFastCheckout: true,
                onSuccess: onSuccess('buy-trial'),
                afterUpdate: continueFileLoading,
            });

            return;
        }

        // Бесплатному пользователю
        // показываем окно фастчекаута
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        requestPayment({
            forceFastCheckout: true,
            onSuccess: onSuccess('buy-tariff'),
            afterUpdate: continueFileLoading,
            tariff: usualProduct?.id,
        });
    }, [showTariffType, usualProduct?.id, trial?.id]);

    return {
        onSkip,
        onRequestPayment,
        onClose,
    };
};
