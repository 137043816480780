import { PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

export const storagesNeededHomeStore = [
    EStorageType.home,
    EStorageType.feed,
    EStorageType.gallery,
    EStorageType.favorites,
    EStorageType.trashbin,
    EStorageType.recommend,
    EStorageType.alldocuments,
    EStorageType.sharedLinks,
    EStorageType.sharedIncoming,
    EStorageType.sharedAutodelete,
    EStorageType.integration,
    EStorageType.inlineIntegration,
];

export const TRIAL_OFFER_CONFIG = {
    id: 'trialOffer',
    icon: 'https://r.mradx.net/img/11/6518A6.svg',
    title: '128 ГБ на месяц за 1 рубль',
    text: 'Свободное место для десятков тысяч фото и документов',
    button: 'Попробовать за 1 рубль',
    tariffId: PROMO_TARIFFS?.default128GbTrial,
    theme: 'banner_light',
};
