import { Snackbar } from 'reactApp/modules/snackbar/snackbar.constants';
import { SnackbarProps } from 'reactApp/ui/Snackbars/components/Snackbar';

export enum SnackbarTypes {
    success = 'success',
    failure = 'failure',
    time = 'time',
    warning = 'warning',
    loading = 'loading',
    protect = 'protect',
}

export type SnackbarItem = Pick<
    SnackbarProps,
    'title' | 'text' | 'closable' | 'buttonText' | 'onClose' | 'onButtonClick' | 'oneline' | 'thumb' | 'wrapText' | 'alignTop'
> & {
    id: string;
    type?: SnackbarTypes;
    closeTimeout?: number;
    disableCloseTimeout?: boolean;
    onShow?: () => void;
};

export type SnackbarListType = Record<string, SnackbarItem> | Record<string, never>;

export interface State {
    list: SnackbarListType;
    bottomMargin?: number;
}

interface SnackbarShowAction {
    type: typeof Snackbar.SHOW;
    payload: SnackbarItem;
}

interface SnackbarHideAction {
    type: typeof Snackbar.HIDE;
    payload: string;
}

interface SnackbarSetBottomMarginAction {
    type: typeof Snackbar.SET_BOTTOM_MARGIN;
    payload: number;
}

export type SnackbarActionTypes = SnackbarShowAction | SnackbarHideAction | SnackbarSetBottomMarginAction;
