import { Icon20CheckAlt } from '@vkontakte/icons';
import { Caption, Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ACTION_PROMO, BASEQUOTA } from 'reactApp/appHelpers/configHelpers';
import { getFeatureABSubNewCard } from 'reactApp/modules/features/features.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { TABS_MAP } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.constants';
import { useIntersecObsrWithParams } from 'reactApp/sections/QuotaLanding/hooks/useIntersecObsrWithParams';
import { sendQuotaBlockViewDWH } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { TariffInfoBlock } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/TariffPlansCard';
import { Button } from 'reactApp/ui/Button/Button';
import { useYearSaving } from 'reactApp/ui/TariffSectionNew/hooks/useYearSaving';
import { ChevronDownIcon } from 'reactApp/ui/VKUIIcons';
import { sendIosFavorableTariffsAnalytics } from 'reactApp/utils/analytics';
import { getFirstSomePeriodsAsWord, getPeriodName, isMonthPeriod } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

import styles from './TariffCard.css';
import { getButtonText, getDiscountText, getFeatureList, getYearPriceWithMonthProduct } from './TariffCard.helpers';
import { IProps, ITariffCardProduct } from './TariffCard.types';

export const TariffCard = memo(function TariffCard(props: IProps): ReactElement | null {
    // tempexp_13600-next-line countSnapshotsBySpace, openAbCard
    const {
        countSnapshotsBySpace,
        openAbCard,
        space,
        products,
        onClick,
        fullPrice,
        oldPrice,
        fullPricePeriod,
        economy,
        tariffTypeLabel,
        savingLable,
        hasOldPriceYear,
        isOldCard = false,
        giftSpace,
        infoText,
        theme,
        isSingleQuota,
        alwaysExpand,
        showPriceByMonth,
        skipBenefitsFeature,
        setCarouselLoaded,
        headerHeight = 0,
        crossFullPrice,
        // tempexp_16948-next-line
        moveYearlyFullPriceToMonthlyButton = false,
    } = props;

    const isOneProduct = products.length === 1;

    // tempexp_13600-start
    const isAbExpNewCards13600 = useSelector(getFeatureABSubNewCard) && !isOldCard;
    const [openSpoiler, setOpenSpoiler] = useState(openAbCard || alwaysExpand);
    const features = getFeatureList(countSnapshotsBySpace, isSingleQuota, skipBenefitsFeature);
    const storage = useSelector(getCurrentStorage);
    const { isSubscription } = getStorage(storage);

    const expandCard = openSpoiler || alwaysExpand;

    const location = useLocation();
    const isAction = Boolean(ACTION_PROMO) && (!location.hash || location.hash === TABS_MAP.special || (location.hash && !isSubscription));

    // tempexp_13600-end

    const [, yearSavingDiscount] = useYearSaving({
        monthProduct: products[0],
        yearProduct: products[1],
    });

    // tempexp_16521-start
    const { isLoaded: isQuotaLoaded } = useSelector(UserQuotaSelectors.getLoadingState);
    const userBaseQuota = useSelector(UserSelectors.getBasicSpace) || BASEQUOTA;
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    // tempexp_16521-end

    const onButtonClick = useCallback(
        (product: ITariffCardProduct, products: ITariffCardProduct[]) => () => {
            sendIosFavorableTariffsAnalytics(['tariff_click']);

            if (isMonthPeriod(product.period)) {
                const yearProduct = products?.find((p) => p.period === '1y');
                onClick(product, yearProduct, yearSavingDiscount);
            } else {
                onClick(product);
            }
        },
        [onClick, yearSavingDiscount]
    );

    useEffect(() => {
        /** Это сделано именно здесь для того, чтобы дожидаться рендера тарифов со всем контентом внутри
         * для корректного скролла по якорю из ссылки на лендинг единой квоты
         * В родительских элементах useEffect срабатывает слишком рано, до формирования листа тарифов */
        setCarouselLoaded?.(true);
    }, []);

    const onIntersecting = useCallback(() => {
        if (!isSubscription) {
            sendQuotaBlockViewDWH(`tariff-${space.space}`);
        }
    }, [isSubscription, space.space]);

    const refIntersection = useIntersecObsrWithParams({
        onIntersecting,
        isMobile: false,
        headerHeight,
    });

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles[`root_space_${space.space}`]]: Boolean(space.space),
                [styles[`root_theme_${theme}`]]: Boolean(theme),
                [styles[`root_old_price_year`]]: hasOldPriceYear,
                // tempexp_13600-start
                [styles.root_ab_new_cards]: isAbExpNewCards13600,
                // tempexp_13600-end
                [styles.root_action]: isAction,
            })}
            ref={refIntersection}
        >
            {/* tempexp_13600-next-line */}
            {isAbExpNewCards13600 && !isSingleQuota && <div className={styles.bgicon_ab_new_cards} />}
            {/* tempexp_13600-next-line !isAbExpNewCards13600 скрываем на эксперимент лейбл */}
            {!ACTION_PROMO && (
                <div className={styles.lables}>
                    {Boolean(savingLable) && <div className={styles.savingLable}>{savingLable}</div>}
                    {Boolean(economy) && <div className={styles.economy}>Экономия {formatPrice(economy, 'ru')} ₽</div>}
                    {Boolean(tariffTypeLabel) && <div className={styles.economy}>{tariffTypeLabel}</div>}
                </div>
            )}

            {/* tempexp_13600-next-line isAbExpNewCards13600 В эксперименте показываем новый вариант заголовка*/}
            {isAbExpNewCards13600 && !alwaysExpand ? (
                <div
                    onClick={() => setOpenSpoiler(!openSpoiler)}
                    className={classNames(styles.title, styles.title_ab_new_cards, {
                        [styles.title_ab_new_cards_opened]: expandCard,
                    })}
                >
                    + {space.space} {space.units}
                    <ChevronDownIcon width={30} height={30} />
                </div>
            ) : (
                <div
                    className={classNames(styles.title, {
                        [styles.title_withGift]: Boolean(giftSpace),
                    })}
                >
                    <div className={styles.space}>{space.space}</div>
                    <div className={styles.unit}>{space.units}</div>
                    {giftSpace && (
                        <div className={styles.gift}>
                            <div className={styles.plus}>+</div>
                            <div className={styles.space}>{giftSpace.space}</div>
                            <div className={styles.unit}>* {giftSpace.units}</div>
                        </div>
                    )}
                </div>
            )}

            {isAbExpNewCards13600 && expandCard && isSingleQuota && (
                <TariffInfoBlock isMobile newQuota={userBaseQuota + space.original} isSkeleton={!isAnonymous && !isQuotaLoaded} />
            )}

            {/* tempexp_13600-start Добавляем список фичей */}
            {isAbExpNewCards13600 && expandCard && (
                <div className={styles.features_ab_new_cards}>
                    {features.map((value, index) => (
                        <div
                            key={index}
                            className={classNames(styles.features_li, {
                                [styles[`features_li_space_${space.space}`]]: !!space.space,
                            })}
                        >
                            <Icon20CheckAlt />
                            <div>{value}</div>
                        </div>
                    ))}
                </div>
            )}
            {/* tempexp_13600-end */}

            {Boolean(fullPrice) && Boolean(fullPricePeriod) && fullPrice && fullPricePeriod && (
                <div className={styles.discount}>{getDiscountText(fullPrice, fullPricePeriod)}</div>
            )}

            {infoText && <div className={styles.infoText}>{infoText}</div>}

            <div className={styles.buttons}>
                {products.map((product) => (
                    <div className={styles.buttonWrapper} key={product.id}>
                        <Button
                            className={classNames(styles.button, {
                                // tempexp_13600-next-line Делаем кнопку более белой
                                [styles.button_ab_new_cards]: isAbExpNewCards13600,
                                [styles.button_column]: isSingleQuota,
                                [styles.button_action]: isAction,
                            })}
                            theme="octavius"
                            primary={product.primary}
                            onClick={onButtonClick(product, products)}
                        >
                            <div>
                                {product.period === '1m' && crossFullPrice?.month && product.discountPrice && product.discountPeriod && (
                                    <span className={styles.crossedOldPrice}>{formatPrice(product.price, 'ru')} ₽</span>
                                )}
                                {getButtonText(
                                    product.discountPrice && product.discountPeriod ? product.discountPrice : product.price,
                                    product.discountPrice && product.discountPeriod ? product.discountPeriod : product.period,
                                    isOneProduct,
                                    product,
                                    showPriceByMonth ||
                                        (Boolean(product.discountPrice) && product.period === '1y'
                                            ? crossFullPrice?.year
                                            : crossFullPrice?.month)
                                )}
                            </div>

                            {/* tempexp_16948-start */}
                            {oldPrice &&
                            hasOldPriceYear &&
                            !product?.hasTrial &&
                            product.period === '1m' &&
                            !showPriceByMonth &&
                            !crossFullPrice?.year &&
                            moveYearlyFullPriceToMonthlyButton ? (
                                <span className={styles.oldPriceMonth}>или {getYearPriceWithMonthProduct(products)}</span>
                            ) : null}
                            {/* tempexp_16948-end */}

                            {oldPrice &&
                                hasOldPriceYear &&
                                !product?.hasTrial &&
                                product.period === '1y' &&
                                (showPriceByMonth || crossFullPrice?.year ? (
                                    <>
                                        <Spacing size={2} />
                                        <Caption level="2">при покупке на год</Caption>
                                    </>
                                ) : (
                                    // tempexp_16948-next-line добавлено условие !moveYearlyFullPriceToMonthlyButton &&
                                    !moveYearlyFullPriceToMonthlyButton && (
                                        <span className={styles.oldPrice}>
                                            {getButtonText(oldPrice, product.period, isOneProduct, product)}
                                        </span>
                                    )
                                ))}
                            {product.period === '1m' && crossFullPrice?.month && product.discountPrice && product.discountPeriodCount && (
                                <>
                                    <Spacing size={2} />
                                    <Caption className={styles.priceInfo} level="2">
                                        {getFirstSomePeriodsAsWord(product.discountPeriodCount, 'm')}
                                    </Caption>
                                </>
                            )}
                            {isSingleQuota &&
                                product.period === '1y' &&
                                !product.discountPrice &&
                                !product?.hasTrial &&
                                (showPriceByMonth ? (
                                    <>
                                        <Spacing size={2} />
                                        <Spacing size={2} />
                                        <Caption level="2">при покупке на год</Caption>
                                    </>
                                ) : (
                                    <>
                                        <Spacing size={2} />
                                        <Caption level="2" className={styles.yearPrice}>
                                            {getYearPriceWithMonthProduct(products)}
                                        </Caption>
                                    </>
                                ))}
                            {product.hasTrial && product.trialPeriod && (
                                <>
                                    <Spacing size={2} />
                                    <Caption level="2">
                                        далее {formatPrice(product.discountPrice || product.price, 'ru')} ₽ в{' '}
                                        {getPeriodName(product.discountPeriod || product.period)}
                                    </Caption>
                                </>
                            )}
                        </Button>
                        {product.saving && (
                            <div
                                className={classNames(styles.saving, {
                                    [styles.saving_action]: isAction,
                                })}
                            >
                                -{product.saving}%
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
});
