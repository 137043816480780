import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUploadFromAction } from 'reactApp/modules/upload/upload.module';
import { getAllowedMimeTypes } from 'reactApp/modules/upload/upload.selectors';
import { useUploadInputHandlers } from 'reactApp/modules/uploading/hooks/useUploadInputHandlers';
import { UploadIcon } from 'reactApp/ui/VKUIIcons';
import { sendGa } from 'reactApp/utils/ga';

import styles from '../EmptyFolder.css';
import { EItemType, gaSuffix } from '../EmptyFolder.types';

const getText = (isAlbum?: boolean) => {
    if (isAlbum) {
        return {
            title: 'Загрузить с компьютера',
            annotation: 'Перетащите или нажмите сюда для загрузки фотографий и видео',
        };
    }

    return {
        title: 'Загрузить файлы',
        annotation: 'Вы также можете перетащить файлы для загрузки в эту папку',
    };
};

export const useUpload = (isAlbum?: boolean) => {
    const dispatch = useDispatch();
    const inputRef = useRef<HTMLInputElement>(null);
    const allowedMimeTypes = useSelector(getAllowedMimeTypes)?.join(',');

    useUploadInputHandlers({ input: inputRef });

    const Upload = useMemo(
        () => (
            <div className={styles.icon}>
                <input type="file" multiple className={styles.input} ref={inputRef} accept={allowedMimeTypes} />
                <UploadIcon />
            </div>
        ),
        [allowedMimeTypes]
    );

    const handleUpload = useCallback(() => {
        sendGa(gaSuffix, 'upload');
        dispatch(setUploadFromAction('empty-folder'));
    }, []);

    return useCallback(() => {
        return {
            ...getText(isAlbum),
            onClick: handleUpload,
            type: EItemType.UPLOAD,
            Icon: Upload,
        };
    }, [Upload, handleUpload, isAlbum]);
};
