import config from 'Cloud/config';
import classNames from 'clsx';
import React, { ForwardedRef, forwardRef, memo, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isAbNewSharingFolder } from 'reactApp/appHelpers/featuresHelpers';
import { EFrom, IProps } from 'reactApp/components/SharingWindow/Sharing.types';
import { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { isOwnPublic as isOwnPublicSelector } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isDomainFolder, isMountedFolder } from 'reactApp/modules/storage/folder.helpers';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getItemById, getStorageItemById, isMountedOrSharedFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { RootState } from 'reactApp/store';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { useSnackBarBottomMargin } from 'reactApp/ui/Snackbars/hooks/useSnackbarBottomMargin';

import { getTitle } from '../Sharing.constants';
import { SharingNewSharing } from '../SharingNew/Sharing/SharingNewSharing';
import styles from '../SharingNew/SharingNew.css';
import { SharingNewWeblink } from '../SharingNew/Weblink/SharingNewWeblink';

export const SharingContent = memo(
    forwardRef((props: IPropsWithPopup<IProps>, ref: ForwardedRef<HTMLDivElement>) => {
        const { itemStorage, id, from, onClose, gaSuffix, isPhone } = props;
        const refRoot = useRef<HTMLDivElement | null>(null);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const getItem = useCallback(
            (state) => (itemStorage ? getStorageItemById(state, itemStorage, id) : getItemById(state, id)),
            [id, itemStorage]
        );

        useSnackBarBottomMargin(isPhone ? refRoot : null, 20);

        const item = useSelector(getItem) as PublishItem;
        const isFolder = item?.isFolder;
        const isMounted = isMountedFolder(item);
        const isSharedOrMouted = useSelector((state: RootState) =>
            isFolder ? isMountedOrSharedFolder(state, item) : ({} as ReturnType<typeof isMountedOrSharedFolder>)
        );
        const isDomainItem: boolean = isDomainFolder(item);
        const storage = useSelector(getCurrentStorage);
        const { isSharedIncoming, isStart, isStock, isInlineIntegration } = getStorage(storage);
        const isPublic = !!config.get('PUBLIC');
        const isOwnPublic = useSelector((state: RootState) => isPublic && isOwnPublicSelector(state));
        const isAlbum = from === EFrom.ALBUMS || storage === EStorageType.albums;
        const noSwitch = isStock ? false : isPublic && !isOwnPublic;
        // tempexp_15661-start
        const abSharing = Boolean(!isPhone && isAbNewSharingFolder && item?.name && (isFolder || isAlbum) && !isMounted);
        const title = getTitle({
            isPublic: isStock ? false : isPublic,
            isMounted,
            name: item?.name,
            isAbNewSharingFolder: abSharing,
        });
        // tempexp_15661-end

        const showSharing =
            !isAlbum &&
            isFolder &&
            !isDomainItem &&
            !isStart &&
            (!(isSharedOrMouted.isMounted || isSharedOrMouted.isShared) || item?.id === isSharedOrMouted.folderId);

        return (
            <div className={classNames(styles.root, { [styles.root_phone]: isPhone })} ref={refRoot}>
                <div className={styles.header}>
                    {title}
                    {
                        /* tempexp_15752-start */
                        abSharing && <NameComponent name={`«${item?.name}»`} truncate showTooltip />
                        /* tempexp_15752-end */
                    }
                </div>
                <div className={styles.content} ref={ref}>
                    {!isSharedIncoming && (
                        <SharingNewWeblink
                            item={item}
                            from={from}
                            isSharedOrMouted={isSharedOrMouted}
                            gaSuffix={gaSuffix}
                            noSwitch={noSwitch}
                            isOwn={!isPublic || isOwnPublic}
                            noExpiresControl={isPublic}
                            isPhone={isPhone}
                            onClose={onClose}
                        />
                    )}

                    {/* Отключаем фичу 'Пользователям облака' в Тутории*/}
                    {!isInlineIntegration && showSharing && (
                        <SharingNewSharing onClose={onClose} item={item} from={from} isPhone={isPhone} isMounted={isMounted} />
                    )}
                </div>
            </div>
        );
    })
);

SharingContent.displayName = 'SharingContent';
