import { confirmActionWithSuspiciousItems } from 'Cloud/Application/Suspicious';
import { logger } from 'lib/logger';
import { parse, stringify } from 'qs';
import { pathOr } from 'ramda';
import { IS_MY_TEAM, IS_PHONE_BROWSER, IS_PUBLIC_ALBUM, USER_EMAIL } from 'reactApp/appHelpers/configHelpers';
import { PUBLIC_URL_REGEXP } from 'reactApp/modules/file/utils';
import { settingsController } from 'reactApp/modules/settings/settings.controller';
import { getLoadMoreLimit } from 'reactApp/modules/storage/storage.helpers';
import { getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { store } from 'reactApp/store';
import { sendGa } from 'reactApp/utils/ga';
import opener from 'reactApp/utils/opener';
import { getPublicUrl } from 'reactApp/utils/urlHelper';

import { CORSAPI_HOST, DOMAIN_HOST, MAIL_HOST, RE_AMP, TOUCH_MAIL_HOST } from './attaches.constants';
import { AttachesFolderItem, AttachesItem } from './attaches.types';

export const isAttachFile = (file: any): file is AttachesItem => !!(file as AttachesItem)?.attachType;

export const fixAttachUrl = (url: string, noProject = false): string => {
    if (!url) {
        return url;
    }
    if (!noProject && url.includes(CORSAPI_HOST)) {
        url = `${url}&project=cloud`;
    }
    let res = url.replace(CORSAPI_HOST, MAIL_HOST).replace(RE_AMP, '&');

    if (USER_EMAIL) {
        res = `${res}&x-email=${encodeURIComponent(USER_EMAIL)}`;
    }

    return res;
};

export const downloadAttachItem = (item: AttachesItem): Promise<void> => {
    // need to clone item because it will be modified inside
    return confirmActionWithSuspiciousItems({ ...item }, 'download').then(function (): void {
        const downloadUrl = item?.url?.download;

        if (downloadUrl) {
            window.open(fixAttachUrl(downloadUrl, true), '_self');
        } else {
            logger.error('can not download');
        }
    });
};

export const downloadAttachesByIds = (ids: string[]): void => {
    const params = {
        partids: ids.join('_'),
        'x-email': USER_EMAIL,
        mode: 'attachment',
        fname: `attachments_${Date.now()}`,
    };

    if (Array.isArray(ids)) {
        window.open(`https://af.${DOMAIN_HOST}/cgi-bin/getattachment?${stringify(params)}`, '_self');
    }
};

export const openLink = (url: string) => {
    if (!IS_MY_TEAM) {
        window.open(url, '_blank');
    } else {
        import('reactApp/modules/vkTeam/vkTeam.module').then((module) => {
            module.vkTeamsBridge.send('OpenLink', { url }).catch((error) => {
                console.error(error);
            });
        });
    }
};

export const forwardItems = (idxs: string[]): void => {
    if (Array.isArray(idxs)) {
        const url = IS_MY_TEAM
            ? `vkteams://miniapp/mail/?path=compose/${idxs.join(',')}/forward-file/`
            : `https://${MAIL_HOST}/compose/${idxs.join(',')}/forward-file/`;

        openLink(url);
    }
};

export const getSendViaMailruLink = (idxs: (string | CloudItem)[], storage: EStorageType): string => {
    if (!Array.isArray(idxs)) {
        idxs = [idxs];
    }

    const state = store.getState();
    const eitherAlbumOrPublic = storage === EStorageType.public || storage === EStorageType.albums;

    const ids = idxs.reduce(
        (collection: { attaches: string[]; links: string[] }, idOrItem: string | CloudItem) => {
            const item = typeof idOrItem === 'string' ? getStorageItemById(state, storage, idOrItem) : idOrItem;

            if (item) {
                try {
                    if (eitherAlbumOrPublic) {
                        if ('home' in item && item.home) {
                            collection.attaches.push(encodeURIComponent(item.home));
                        } else {
                            collection.links.push(getPublicUrl(item, IS_PUBLIC_ALBUM) || item.id || '');
                        }
                    } else {
                        collection.attaches.push(encodeURIComponent(item.id || ''));
                    }
                } catch (error) {}
            }

            return collection;
        },
        { attaches: [], links: [] }
    );

    if (ids.attaches.length || ids.links.length) {
        const attachValues = (ids.attaches.length && `cloud_files_ids=${JSON.stringify(ids.attaches)}`) || '';
        const linkValues = (ids.links.length && `body=${ids.links.join(encodeURIComponent('\n'))}`) || '';
        const uriComponent = [attachValues, linkValues].filter(Boolean).join('&');
        return IS_MY_TEAM ? `vkteams://miniapp/mail/?path=compose&${uriComponent}` : `https://${MAIL_HOST}/compose/?${uriComponent}`;
    }

    return '';
};

export const parseAttachId = (id) => {
    if (!id) {
        return {};
    }

    const [, messageId, partId = ''] = id.replace(/(\d)\/\/public\//, '$1;/public/').match(/^(?:\/?)([A-Za-z0-9]+)(?:;|\/)(.+)/) ?? [];

    const [, emlId, partId2] = partId.match(/^([0-9;]+);([0-9]+;[0-9]+;[0-9]+;[0-9]+)/) ?? [];

    const isEml = partId2 && emlId;

    return { messageId: isEml ? `${messageId};${emlId}` : messageId, partId: isEml ? partId2 : partId };
};

export const parseAttachStockId = (id) => {
    if (!id) {
        return {};
    }
    const [, messageId, partId1, partId2] = id.match(/^([0-9]+)[;|/](.+):(\w+)/) ?? [];

    if (!messageId || !partId1 || !partId2) {
        return {};
    }
    return { messageId, partId1, partId2 };
};

export const getStockIdFromStockAttachId = (id) => {
    const { partId1, partId2 } = parseAttachStockId(id);
    if (!partId1 || !partId2) {
        return null;
    }
    return `${partId1}/${partId2}`;
};

export const getStockIdOfFile = (attachId, fileName) => `${getStockIdFromStockAttachId(attachId)}/${fileName}`;
export const getAttachPublicId = (attachId: string) => `/public/${attachId?.split('/public/')[1]?.split(':')[0]}`;

// Message&Attaches formats:
// - 16316049042072761468;0;4 - MessageID: UIN64 20chars max
// - 16316049042072761468;0;4;0;1 - for attaches in eml attaches
// - f02Ef297f06EF55d9a3F9B15f1D92d6D/TWbF2FU6zIE00aYp - temp attaches: , [0-9A-F]/[a-zA-Z0-9]
//        - ? HEX 32chars
// - 16303380380947561217/jU17XUMi36tA3vX2h5PKehFB:zVPaBNHwLTa - stock attaches: UIN64/[a-zA-Z0-9]:[\w] или UIN64;[a-zA-Z0-9]:[\w]
// - 16481082681743083297;/public/bmbD%2FRpJYi9c54:7797764:normal.jpg - cloud attach example with public link

export const normalizeAttachId = (id) => id?.replace(/^\//, '');

const compare = (a: string, b: string): number => (a < b ? -1 : a > b ? 1 : 0);

export const compareAttachFolderItems = (folder1: AttachesFolderItem, folder2: AttachesFolderItem): number => {
    if (folder2.type === 'inbox' && folder2.system) {
        return 1;
    }
    if (folder1.type === 'inbox' && folder1.system) {
        return -1;
    }

    if (folder1.system && folder2.system) {
        return compare(folder1.name, folder2.name);
    }

    if (folder1.system) {
        return 1;
    } else if (folder2.system) {
        return -1;
    }

    return compare(folder1.name, folder2.name);
};

export const ancestorsOrSelf = (id, list: Record<string, AttachesFolderItem>): string[] => {
    const result: string[] = [];
    let item: AttachesFolderItem;

    while ((item = list[id])) {
        result.unshift(id);
        id = item.parent;
    }

    return result;
};

export function hasMoreToLoad(attaches) {
    return attaches?.length === getLoadMoreLimit(EStorageType.attaches);
}

interface FindMsg {
    messageId: string;
    itemId: string;
}

interface SidesMessages {
    before: FindMsg | undefined;
    after: FindMsg | undefined;
}

export function getSidesMessages(itemId: string, ids: string[]): SidesMessages {
    const itemIndex = ids.findIndex((item) => item === itemId);
    const { messageId } = parseAttachId(itemId);

    let before, after;

    for (let i = itemIndex - 1; i >= 0; i--) {
        const { messageId: mesId } = parseAttachId(ids[i]);
        if (messageId !== mesId) {
            before = { messageId: mesId, itemId: ids[i] };
            break;
        }
    }

    for (let i = itemIndex + 1; i < ids.length; i++) {
        const { messageId: mesId } = parseAttachId(ids[i]);
        if (messageId !== mesId) {
            after = { messageId: mesId, itemId: ids[i] };
            break;
        }
    }

    return {
        before,
        after,
    };
}

export const filterAttaches = (attaches: AttachesItem[]): AttachesItem[] =>
    attaches.filter(
        (attach): boolean =>
            !/^text\/calendar/.test(attach.content_type) && // скрываем приглашения из календаря (.ics)
            !(attach.flags && attach.flags.hidden) && // скрываем то, что скрыто в Почте
            !(pathOr(0, ['size', 'original'], attach) < 16) && // скрываем файлы меньше 16 байт
            !/^(signature.asc|smime.p7s|encrypted.asc|encrypted.p7s|winmail.dat)$/.test(attach.name) // скрываем системные файлы почты CLOUDWEB-9362
    );

export const getWeblinkFromCloudAttachGetUrl = (url) => url?.match(PUBLIC_URL_REGEXP)?.[1];

export const getAttachUrl = (id: string, email: string | null, skipFirstSlash = false): string => {
    const { folderId, type } = parse(window.location.search, { ignoreQueryPrefix: true });
    const { messageId, partId } = parseAttachId(id);
    const attachPath = encodeURIComponent(`${messageId};${partId}`);

    return `${skipFirstSlash ? '' : '/'}attaches/${attachPath}?${stringify({
        'x-email': email,
        folderId,
        type,
        fromCloud: true,
    })}`;
};

export const openAttachLetter = ({ message_id, folderId }: { message_id: string; folderId?: string }) => {
    if (!message_id) {
        return;
    }
    opener(`//${IS_PHONE_BROWSER ? TOUCH_MAIL_HOST : MAIL_HOST}/message/${message_id}/${folderId ? `?folder_id=${folderId}` : ''}`);
};

export const sendAttachesGa = (action, label = '', iData = null) => {
    const viewMode = settingsController.getViewMode();
    sendGa(`attaches-${viewMode}`, action, label, iData);
};
