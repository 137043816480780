import { FeedItemBase } from 'reactApp/modules/feed/feed.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

export enum EAllDocumentsType {
    document = 'document',
    spreadsheet = 'spreadsheet',
    presentation = 'presentation',
    pdf = 'pdf',
}

export interface AllDocumentsItem extends FeedItemBase {
    storage: EStorageType.alldocuments;
}

export interface IAllDocumentsCategory {
    name: string;
    id: EAllDocumentsType;
    value: EAllDocumentsType;
    childs: string[];
    cursor: string;
    isLoaded: boolean;
    isLoading: boolean;
    hasMoreToLoad: boolean;
}

export interface IAllDocumentsState {
    currentDocType: EAllDocumentsType | null | undefined;
    idxs: string[];
    docsSize: number;
    isLoading: boolean;
    isLoaded: boolean;
    error?: string | null;
    cursor: string;
    hasMoreToLoad: boolean;
}

export type AllDocumentsSections = EAllDocumentsType | 'trashbin';

export enum AllDocumentsXrayTypes {
    onSuccess = 'onSuccess',
    onError = 'onError',
}
