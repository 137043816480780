import { requestPayment } from 'Cloud/Application/Payment';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderBizMigrationDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { initFamilyInviteModal, initFamilyOnboarding } from 'reactApp/modules/family/family.actions';
import { isUserFamilyOwner } from 'reactApp/modules/family/family.selectors';
import { getAbFreeUserSpaceLink, getFeatureMarketingPromoFastcheckout } from 'reactApp/modules/features/features.selectors';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUserQuotaRequest } from 'reactApp/modules/userQuota/userQuota.module';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { TSendAnalytics } from 'reactApp/ui/Space/SingleSpace/SingleMenu.analytics';
import { TGoToSubs } from 'reactApp/ui/Space/Space.types';
import { sendXray } from 'reactApp/utils/ga';

type TUseCallbacks = (props: {
    isMobile: boolean;
    countdownTariff?: string;
    goToSubscriptions: TGoToSubs;
    sendAnalytics: TSendAnalytics;
    isMarketingPromo?: boolean;
    showFamilyButton?: boolean;
}) => {
    onPayment: () => void;
    onFamilyClick?: () => void;
    updateQuota: () => void;
};

export const useCallbacks: TUseCallbacks = ({ isMobile, countdownTariff, goToSubscriptions, sendAnalytics, isMarketingPromo }) => {
    const isOldBizUser = useSelector(UserSelectors.isOldBizUser);
    const isPaidUser = useSelector(UserSelectors.isPaidUser);
    const isAlmostFull = useSelector(UserQuotaSelectors.isAlmostFull);
    const isFull = useSelector(UserQuotaSelectors.isFull);
    const showFastCheckout = useSelector(getFeatureMarketingPromoFastcheckout);

    const dispatch = useDispatch();
    const isFamilyOwner = useSelector(isUserFamilyOwner);

    // tempexp_15071-next-line
    const abFreeUserSpaceLink = useSelector(getAbFreeUserSpaceLink);

    const onPayment = useCallback(() => {
        if (isOldBizUser) {
            renderBizMigrationDialog();
            return;
        }

        sendAnalytics('click');

        if (isMobile) {
            goToSubscriptions();
            return;
        }

        if (isPaidUser && !(isAlmostFull || isFull)) {
            goToSubscriptions();
            return;
        }

        const paymentOptions: { tariff?: string; goToSubscriptions?: TGoToSubs } = {
            goToSubscriptions,
        };

        if (countdownTariff) {
            paymentOptions.tariff = countdownTariff;
        }

        // // tempexp_15071-start
        if (abFreeUserSpaceLink && !countdownTariff) {
            goToSubscriptions({ isNewTariffsPage: true });
            return;
        }
        // // tempexp_15071-end

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        requestPayment({ ...paymentOptions, forceFastCheckout: isMarketingPromo && showFastCheckout });
    }, [isOldBizUser, sendAnalytics, isMobile, isPaidUser, isAlmostFull, isFull, goToSubscriptions, countdownTariff, abFreeUserSpaceLink]);

    const onFamilyClick = useCallback(() => {
        sendAnalytics('click', true);

        closePopupHelper(popupNames.MOBILE_LEFT_MENU);

        if (isFamilyOwner) {
            dispatch(initFamilyInviteModal());
        } else {
            dispatch(initFamilyOnboarding());
        }
    }, [isFamilyOwner, dispatch, sendAnalytics]);

    const updateQuota = useCallback(() => {
        sendXray(['space', 'reload', 'family', 'quota']);
        dispatch(loadUserQuotaRequest());
    }, []);

    return { onPayment, onFamilyClick, updateQuota };
};
