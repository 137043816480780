import { xray } from 'lib/xray';
import { HomeItem } from 'reactApp/modules/home/home.types';
import { sendSuccess } from 'reactApp/modules/integration/communicator';
import { getIntegrationState } from 'reactApp/modules/integration/integration.selectors';
import type { File, IntegrationState } from 'reactApp/modules/integration/integration.types';
import { publishRequest } from 'reactApp/modules/modifying/sagas/publish.saga';
import { getSelectedItems } from 'reactApp/modules/storage/storage.selectors';
import { appendQueryParams } from 'reactApp/utils/appendQueryParams';
import { getPublicUrl } from 'reactApp/utils/urlHelper';
import { all, call, select } from 'redux-saga/effects';

export function* handlePublishSelected() {
    const state: IntegrationState = yield select(getIntegrationState);

    const selectedItems: HomeItem[] = yield select(getSelectedItems);
    if (!selectedItems?.length) {
        return;
    }

    yield all(selectedItems.map((item) => call(publishRequest, { item })));

    const updatedSelectedItems: HomeItem[] = yield select(getSelectedItems);

    const files: File[] = updatedSelectedItems.map((item) => ({
        name: item.name,
        link: appendQueryParams(getPublicUrl(item), {
            integration_client: state.client,
            integration_channel: state.channel,
        }),
        size: item.size,
        shareID: item.weblink,
    }));

    sendSuccess(state.parentOrigin, files);

    xray.send('publish-ok');
}
