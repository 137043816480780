import { sendAppReady } from 'lib/app-ready';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadDomainFoldersStartRequest } from 'reactApp/modules/domainFolders/domainFolders.actions';
import { setPhListner } from 'reactApp/modules/ph/ph.thunkActions';
import { initPromoController } from 'reactApp/modules/promo/promo.module';
import { applicationStart } from 'reactApp/modules/router/router.module';
import { loadSettingsRequest } from 'reactApp/modules/settings/settings.module';
import { loadUser } from 'reactApp/modules/user/user.thunkActions';
import { loadUserQuotaRequest } from 'reactApp/modules/userQuota/userQuota.module';
import { showWelcome } from 'reactApp/modules/welcome/welcome.module';

export const useInit = ({ setIsLoading }: { setIsLoading: (isLoading: boolean) => void }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        sendAppReady();
        dispatch(applicationStart());

        (dispatch(loadUser()) as any).finally(() => {
            dispatch(initPromoController());
            dispatch(setPhListner());
            dispatch(loadSettingsRequest());
            dispatch(loadUserQuotaRequest());
            dispatch(loadDomainFoldersStartRequest());

            setIsLoading(false);

            dispatch(showWelcome());
        });
    }, []);
};
