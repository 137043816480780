import React, { ReactEventHandler, useCallback } from 'react';
import { TariffSwitch } from 'reactApp/ui/TariffCardNew/components/TariffSwitch';
import { SwitchOption } from 'reactApp/ui/TariffCardNew/components/TariffSwitch.types';
import { ETabsName } from 'reactApp/ui/TariffsSection/TariffSection.constants';

const switchOptions: [SwitchOption, SwitchOption] = [
    {
        value: ETabsName.month,
        text: 'На месяц',
    },
    {
        value: ETabsName.year,
        text: 'На год',
    },
];

interface Props {
    checkedOption: ETabsName;
    onClickSwitch: (tab: ETabsName) => void;
}

export const TariffPeriodSwitch = ({ checkedOption, onClickSwitch }: Props) => {
    const onTariffSwitchClick: ReactEventHandler = useCallback(
        (event) => {
            event.stopPropagation();
            onClickSwitch(checkedOption === ETabsName.year ? ETabsName.month : ETabsName.year);
        },
        [checkedOption, onClickSwitch]
    );

    return <TariffSwitch dark checked={checkedOption === ETabsName.year} onClick={onTariffSwitchClick} options={switchOptions} />;
};
