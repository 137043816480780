import classNames from 'clsx';
import { ReactComponent as IconDropdown } from 'mrg-icons/src/mailru/navigation/drop_down.svg';
import PropTypes from 'prop-types';
import React, { createElement, Fragment, PureComponent } from 'react';
import { Portal } from 'react-portal';
import { Tooltip } from 'reactApp/ui/Tooltip/Tooltip';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants.js';
import { noop } from 'reactApp/utils/helpers';
import { isIosDevice } from 'reactApp/utils/scrollLock';
import { Ref } from 'semantic-ui-react';

import styles from './Dropdown.css';

export class Dropdown extends PureComponent {
    state = {
        isOpen: false,
        target: this.props.target,
    };

    #timeoutId = undefined;

    static propTypes = {
        children: PropTypes.node,
        content: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
        as: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]),
        isOpen: PropTypes.bool,
        onOpen: PropTypes.func,
        target: PropTypes.object,
        placement: PropTypes.string,
        qaValue: PropTypes.string,
        qaValueContent: PropTypes.string,
        theme: PropTypes.oneOf(['', 'octavius']),
        onClose: PropTypes.func,
        onClickItem: PropTypes.func,
        pinned: PropTypes.bool,
        withinDialog: PropTypes.bool,
        controlled: PropTypes.bool,
        smartPlacement: PropTypes.bool,
        fitWindow: PropTypes.bool,
        disabled: PropTypes.bool,
        tooltipOffset: PropTypes.object,
        animatedTooltip: PropTypes.bool,
        tooltipClassName: PropTypes.string,
        showOnMount: PropTypes.bool,
    };

    static defaultProps = {
        children: null,
        isOpen: false,
        onOpen: noop,
        target: undefined,
        qaValue: '',
        qaValueContent: '',
        as: IconDropdown,
        pinned: false,
        placement: tooltipPlacements.TOP_RIGHT,
        onClose: noop,
        onClickItem: noop,
        theme: '',
        withinDialog: false,
        controlled: false,
        smartPlacement: true,
        fitWindow: false,
        disabled: false,
        animatedTooltip: false,
        showOnMount: false,
    };

    el = React.createRef();

    componentDidMount() {
        if (this.props.showOnMount) {
            this.open();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const openChanged = this.props.controlled ? this.props.isOpen !== prevProps.isOpen : this.state.isOpen !== prevState.isOpen;
        if (openChanged) {
            const isOpen = this.props.controlled ? this.props.isOpen : this.state.isOpen;

            if (isOpen) {
                this.addDocClickListener();
            } else {
                this.removeDocClickListener();
            }
        }
    }

    componentWillUnmount() {
        this.removeDocClickListener();
        clearTimeout(this.#timeoutId);
    }

    removeDocClickListener = () => {
        document.removeEventListener('click', this.onDocumentClick);
        this.onClickAdded = false;
    };

    addDocClickListener = () => {
        if (!this.onClickAdded) {
            this.onClickAdded = true;
            // Подписываемся на лисенер по таймауту, для того, чтобы пропустить первый клик из просморщика,
            // который как раз и открывает модаку и дробдаун его ловит
            this.#timeoutId = setTimeout(() => {
                document.addEventListener('click', this.onDocumentClick, { capture: true });
            }, 1000);
        }
    };

    onDocumentClick = (event) => {
        if (this.el && this.el.current && !this.el.current.contains(event.target) && !this.node.contains(event.target)) {
            this.close();
        }
    };

    setRef = (node) => {
        this.node = node;
    };

    open = () => {
        this.setState({
            isOpen: true,
            target: this.props.target ? this.props.target : this.node,
        });

        this.props.onOpen();
    };

    handleOnItemClick = (id = null) => {
        if (id) {
            this.setState(
                {
                    isOpen: false,
                },
                () => {
                    this.props.onClickItem(id);
                }
            );
        }
    };

    handleOnClick = () => {
        const { controlled, isOpen: isOpenProps } = this.props;
        const { isOpen: isOpenState } = this.state;
        const isOpen = controlled ? isOpenProps : isOpenState;

        if (isOpen) {
            this.close();
        } else {
            this.open();
        }
    };

    close = () => {
        this.props.onClose();

        this.setState((curState) => ({ isOpen: !curState.isOpen }));
    };

    render() {
        const {
            as,
            children,
            content,
            placement,
            pinned,
            theme,
            qaValue,
            qaValueContent,
            withinDialog,
            controlled,
            isOpen: isOpenProps,
            smartPlacement,
            fitWindow,
            tooltipOffset,
            animatedTooltip,
            tooltipClassName,
            ...rest
        } = this.props;
        const { isOpen: isOpenState, target } = this.state;
        const isOpen = controlled ? isOpenProps : isOpenState;

        return (
            <>
                <Ref innerRef={this.setRef}>
                    {createElement(
                        as,
                        {
                            onClick: this.handleOnClick,
                            isActive: isOpen,
                            ...rest,
                            theme,
                            qaValue,
                        },
                        ...children
                    )}
                </Ref>
                {isOpen && !!target && (
                    <Portal>
                        <Tooltip
                            target={target}
                            smartPlacement={smartPlacement}
                            fitWindow={fitWindow}
                            noArrow
                            fluid
                            pinned={pinned}
                            placement={placement}
                            theme={theme}
                            onClose={this.props.showOnMount ? noop : this.close}
                            withinDialog={withinDialog}
                            offset={tooltipOffset}
                            animated={animatedTooltip}
                            className={tooltipClassName}
                            fixed={withinDialog && isIosDevice()}
                        >
                            <div
                                className={classNames(styles.content, { [styles[`content_${theme}`]]: !!theme })}
                                ref={this.el}
                                data-qa-value={qaValueContent}
                            >
                                {typeof content === 'function' ? content(this.handleOnItemClick) : content}
                            </div>
                        </Tooltip>
                    </Portal>
                )}
            </>
        );
    }
}
