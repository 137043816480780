import { ISaveSubscriptionProps } from 'reactApp/components/VkBuyModalWithSidebar/SaveSubscriptionSidebar/SaveSubscriptionSidebar';
import { Tariff } from 'reactApp/types/Billing';

export interface IProps {
    productId: string;
    onClose: () => void;
    onSuccess?: () => void;
    onDmrResize?: (height) => void;
    type: EPaymentModalType;
    tariff?: Tariff;
    sidebarProps?: ISaveSubscriptionProps;
    isMarketingPromo?: boolean;
    isFrame?: boolean;
    withoutSidebar?: boolean;
    paymentDescription?: string;
}

export enum EPaymentModalType {
    trial = 'trial',
    fastcheckout = 'fastcheckout',
    saveSubscription = 'saveSubscription',
    attachesTrial = 'attachesTrial',
}

export const PaymentModalEventCategory = {
    [EPaymentModalType.fastcheckout]: 'fastCheckout',
    [EPaymentModalType.trial]: 'sdbar-trial',
    [EPaymentModalType.attachesTrial]: 'sdbar-atchs-trial',
    [EPaymentModalType.saveSubscription]: 'sdbar-save-modal',
} as const;

export const PaymentModalFrameEventCategory = {
    [EPaymentModalType.fastcheckout]: 'buy-frame',
} as const;
