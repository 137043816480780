import classNames from 'clsx';
import throttle from 'lodash.throttle';
import React, { createRef, Fragment, PureComponent, ReactElement, Ref, RefObject } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { dropdownOpen } from 'reactApp/modules/dialog/dialog.module';
import { dispatchNewSearchRadar } from 'reactApp/modules/dwh/dwh.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { RootState, store } from 'reactApp/store';
import { ChevronRightIcon } from 'reactApp/ui/VKUIIcons';
import { getMinHorizontalPosition } from 'reactApp/utils/contextMenu';
import { createGaSender } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { sendNewUserGa } from 'reactApp/utils/newUserGa';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import styles from './DropdownList.css';

export enum DropdownTheme {
    small = 'small',
    medium = 'medium',
    large = 'large',
    breadcrumbs = 'breadcrumbs',
    jpeg = 'jpeg',
    dark = 'dark',
    suggest = 'suggest',
    space = 'space',
    smallFluid = 'small-fluid',
}

export enum DropdownFont {
    mailSans = 'mailSans',
}

export interface ListItem {
    [key: string]: any;
    id: string;
    list?: ListItem[];
    onClick?: (id, cb, e?) => void;
    divider?: boolean;
    borderedArrow?: boolean;
    openNestedByArrow?: boolean;
    theme?: DropdownTheme;
    mod?: 'new' | 'remove';
    disablePreventDefault?: boolean;
    isSelected?: boolean;
}

interface Props {
    posX?: number;
    posY?: number;
    list: ListItem[];
    close: () => void;
    calcPosition?: (dropdownWidth: number, dropdownHeight?: number) => { [key: string]: any };
    calcSize?: () => void;
    theme?: DropdownTheme | DropdownTheme[];
    parentRef?: RefObject<HTMLElement>;
    renderItem: (item: ListItem, index: number) => ReactElement | null;
    closeOnScroll?: boolean;
    closeOnResize?: boolean;
    observePositionAndSize?: boolean;
    closeOnMouseLeave?: boolean;
    closeOnItemClick?: boolean;
    openNestedOnHover?: boolean;
    dropdownOpen?(isOpen: boolean): void;
    gaId: string;
    gaSuffix?: string;
    fixedPosition?: boolean;
    title?: string;
    doNotGaSendId?: boolean;
    font?: DropdownFont;
    isNewUser?: boolean;
    animated?: boolean;
    rootRef?: RefObject<HTMLDivElement>;
    dataQAId?: string;
    maxDropdownListHeight?: number;
    className?: string;
}

interface State {
    opened: string | null;
    visible?: boolean;
}

const PADDING = 20;
const INNER_PADDING = 10;

const mapStateToProps = (state: RootState): { isNewUser: boolean } => ({
    isNewUser: UserSelectors.isNewUser(state),
});

const mapDispatchToProps = (dispatch) => ({
    dropdownOpen: (isOpen: boolean) => dispatch(dropdownOpen(isOpen)),
});

export class DropdownListComponent extends PureComponent<Props> {
    private portalNode = document.querySelector('#react-dropdown');
    private rootRef = this.props.rootRef || createRef<HTMLDivElement>();
    private nestedRef = createRef<HTMLDivElement>();
    private mouseEnterTimerId: number | undefined;
    private sendGa = createGaSender(this.props.gaId);

    public readonly state = {
        opened: null,
        visible: true,
    };

    public static defaultProps = {
        closeOnItemClick: true,
        dataQAId: 'dropdownList',
    };

    public componentDidMount(): void {
        const { observePositionAndSize, closeOnResize, closeOnScroll, list, doNotGaSendId, gaSuffix, dropdownOpen } = this.props;

        list.forEach((item) => this.sendGa('item-show', doNotGaSendId ? '' : item.id, gaSuffix));

        this.setPosition();

        document.addEventListener('click', this.handleOnDocumentClick, { capture: true });

        if (closeOnResize || observePositionAndSize) {
            window.addEventListener?.('resize', this.handleOnResize);
        }
        if (closeOnScroll) {
            window.addEventListener?.('scroll', this.handleOnScroll);
        }

        dropdownOpen?.(true);
    }

    public componentDidUpdate(prevProps, prevState): void {
        if (prevProps.fixedPosition !== this.props.fixedPosition) {
            this.setPosition();
        }
        if (this.state.opened && prevState.opened !== this.state.opened) {
            const item = this.props?.list.find((item) => item.id === this.state.opened);
            if (Array.isArray(item?.list)) {
                item?.list.forEach((item) => this.sendGa('item-show', this.props.doNotGaSendId ? '' : item.id, this.props.gaSuffix));
            }
        }
    }

    public componentWillUnmount(): void {
        this.unbindDocumentEvents();

        this.props.dropdownOpen?.(false);
    }

    private unbindDocumentEvents = () => {
        document.removeEventListener('click', this.handleOnDocumentClick, { capture: true });
        window.removeEventListener('scroll', this.handleOnScroll);
        window.removeEventListener('resize', this.handleOnResize);
    };

    private setSize = (): void => {
        const { calcSize } = this.props;

        if (this.rootRef.current && calcSize) {
            this.rootRef.current.style.width = `${calcSize()}px`;
        }
    };

    private setPosition = (): void => {
        if (this.rootRef.current) {
            const { posY = 0, posX = 0 } = this.props;
            const el = this.rootRef.current;
            const elRect = el.getBoundingClientRect();
            let left = posX;
            let top = posY;

            if (this.props.calcPosition) {
                const { posX: x, posY: y, maxHeight: maxHeightValue } = this.props.calcPosition(elRect.width, elRect.height);
                left = x;
                top = y;
                this.rootRef.current.style.maxHeight = maxHeightValue;
            } else {
                const { clientHeight } = document.body;
                if (posX + elRect.width + PADDING > window.innerWidth) {
                    left = getMinHorizontalPosition(posX, elRect.width, PADDING);
                }
                if (posY + elRect.height > clientHeight + window.pageYOffset) {
                    top = clientHeight + window.pageYOffset - elRect.height;
                }
            }

            this.rootRef.current.style.top = `${top}px`;
            this.rootRef.current.style.left = `${left}px`;
        }
    };

    private handleClose = () => {
        const { close, animated } = this.props;

        if (!animated) {
            close();
            return;
        }

        this.setState({ visible: false });
        setTimeout(close, 100);
    };

    private handleResize = () => {
        const { observePositionAndSize, closeOnResize } = this.props;

        if (closeOnResize) {
            this.handleClose();
        }

        if (observePositionAndSize) {
            this.setPosition();
            this.setSize();
        }
    };

    private handleOnScroll = throttle(this.handleClose, 100);

    private handleOnResize = throttle(this.handleResize, 100);

    private handleOnDocumentClick = (e): void => {
        let closeIt = true;
        let target = e.target;
        while (target && target.parentNode !== document) {
            if (
                target === this.rootRef.current ||
                target === this.nestedRef.current ||
                (this.props.parentRef && target === this.props.parentRef.current)
            ) {
                closeIt = false;
                break;
            }
            target = target.parentNode;
        }

        if (closeIt) {
            this.handleClose();
            e.preventDefault();
        }
    };

    private findNestedListPosition = (currentTarget): void => {
        if (this.nestedRef.current && this.rootRef.current) {
            const elRect = currentTarget.getBoundingClientRect();
            const rootRect = this.rootRef.current.getBoundingClientRect();
            const nestedEl = this.nestedRef.current;
            const nestedRect = nestedEl.getBoundingClientRect();
            let top = window.pageYOffset + elRect.top - INNER_PADDING;
            let left = elRect.left + elRect.width;

            if (left + nestedRect.width + PADDING > window.innerWidth) {
                left = getMinHorizontalPosition(elRect.left, nestedRect.width, PADDING);
            }

            if (top + nestedRect.height > window.innerHeight + window.pageYOffset) {
                top = window.pageYOffset + rootRect.top + rootRect.height - nestedRect.height;
            }

            nestedEl.style.top = `${top}px`;
            nestedEl.style.left = `${left}px`;
        }
    };

    private handleOnItemClick =
        (item: ListItem) =>
        (e: React.MouseEvent): void => {
            this.sendGa('item-click', this.props.doNotGaSendId ? '' : item.id, this.props.gaSuffix);

            if (this.props.isNewUser) {
                sendNewUserGa(item.id, this.props.gaId);
            }

            if (!item.disablePreventDefault) {
                e.preventDefault();
            }
            e.stopPropagation();

            if (!item.list || item.openNestedByArrow) {
                if (this.props.closeOnItemClick) {
                    this.handleClose();
                    this.unbindDocumentEvents();
                }
            } else if (this.state.opened) {
                if (!item.list) {
                    this.setState({ opened: null });
                }
            } else {
                const target = e.currentTarget;
                this.setState({ opened: item.id }, (): void => {
                    this.findNestedListPosition(target);
                });
            }

            if (item.onClick) {
                item.onClick(
                    item.id,
                    (params) => {
                        if (params.source === 'search') {
                            const dwhData = {
                                eventCategory: ECategoryGa.toolbar_search,
                                action: params.action,
                                count_files: params.count_files,
                            };
                            const items = params.items.map((item) => ({
                                file_name: 'nameWithoutExt' in item && item.nameWithoutExt,
                                type: item.kind,
                                pos: 'pos' in item && item.pos,
                                file_id: item.id,
                            }));
                            store.dispatch(dispatchNewSearchRadar({ dwhData, items }));
                        }
                    },
                    e
                );
            }
        };

    private handleOnArrowClick =
        (item: ListItem): ((event) => void) =>
        (event): void => {
            event.stopPropagation();

            if (this.state.opened) {
                this.setState({ opened: null });
            } else {
                const parentEl = event.currentTarget.parentElement;
                this.setState({ opened: item.id }, (): void => {
                    this.findNestedListPosition(parentEl);
                });
            }
        };

    private handleNestedMouseEnter = (): void => {
        if (this.mouseEnterTimerId) {
            clearTimeout(this.mouseEnterTimerId);
        }
    };

    private handleOnMouseLeave = (): void => {
        if (this.mouseEnterTimerId) {
            clearTimeout(this.mouseEnterTimerId);
        }
        this.mouseEnterTimerId = window.setTimeout((): void => {
            this.setState({
                opened: null,
            });
        }, 200);
    };

    private handleOnMouseEnter =
        (item: ListItem): (({ currentTarget }) => void) =>
        ({ currentTarget }): void => {
            if (this.mouseEnterTimerId) {
                window.clearTimeout(this.mouseEnterTimerId);
            }
            this.mouseEnterTimerId = window.setTimeout((): void => {
                this.setState(
                    (): State => ({
                        opened: item.id,
                    }),
                    (): void => {
                        this.findNestedListPosition(currentTarget);
                    }
                );
            }, 200);
        };

    private handleOnMouseEnterArrow =
        (item: ListItem): (({ currentTarget }) => void) =>
        ({ currentTarget }): void => {
            this.handleOnMouseEnter(item)({ currentTarget: currentTarget.parentElement });
        };

    private handleOnMouseLeaveList = (): void => {
        if (this.props.closeOnMouseLeave) {
            this.handleClose();
        }
    };

    private renderItem = (item: ListItem, index: number, list: ListItem[]): ReactElement => {
        const { openNestedOnHover } = this.props;
        const onClick = this.handleOnItemClick(item);

        return (
            <Fragment key={item.id}>
                <div
                    className={classNames({
                        [styles.item]: true,
                        [styles.item_divider]: item.divider && index !== list.length - 1,
                        [styles.item_selected]: item?.isSelected,
                    })}
                    data-name={item.id}
                    onClick={onClick}
                    onMouseEnter={item.list && openNestedOnHover && !item.openNestedByArrow ? this.handleOnMouseEnter(item) : noop}
                    onMouseLeave={item.list && openNestedOnHover && !item.openNestedByArrow ? this.handleOnMouseLeave : noop}
                    key={item.id}
                >
                    {this.props.renderItem(item, index)}
                    {item.list && (
                        <div
                            className={classNames({
                                [styles.dropdownIcon]: true,
                                [styles.borderedArrow]: item.borderedArrow,
                            })}
                            onClick={item.openNestedByArrow ? this.handleOnArrowClick(item) : noop}
                            onMouseEnter={item.openNestedByArrow && openNestedOnHover ? this.handleOnMouseEnterArrow(item) : noop}
                            onMouseLeave={item.openNestedByArrow && openNestedOnHover ? this.handleOnMouseLeave : noop}
                        >
                            <ChevronRightIcon />
                        </div>
                    )}
                </div>
                {item.list &&
                    this.state.opened === item.id &&
                    this.portalNode &&
                    createPortal(this.renderList(item.list, this.nestedRef, true, item.theme), this.portalNode)}
            </Fragment>
        );
    };

    private renderList = (menu: ListItem[], ref: Ref<HTMLDivElement>, isNested?, theme?): ReactElement => {
        const { fixedPosition, title, font, animated, dataQAId, maxDropdownListHeight, className } = this.props;
        const dropdownTheme = theme || this.props.theme;
        const style = {};
        if (Array.isArray(dropdownTheme)) {
            dropdownTheme.forEach((theme) => {
                style[styles[`list_${theme}`]] = !!theme;
            });
        } else {
            style[styles[`list_${dropdownTheme}`]] = !!dropdownTheme;
        }

        return (
            <CSSTransition
                in={this.state.visible}
                timeout={100}
                appear={animated}
                classNames={{
                    exit: styles.animation_exit,
                    exitActive: styles.animation_exit_active,
                    appear: styles.animation_appear,
                    appearActive: styles.animation_appear_active,
                }}
            >
                <div
                    id="dropdownList"
                    data-qa-id={dataQAId}
                    className={classNames(className, styles.list, {
                        [styles.list_nested]: isNested,
                        [styles.list_fixed]: fixedPosition,
                        ...style,
                        [styles[`listFont_${font}`]]: !!font,
                        [styles.list_responsive]: IS_BIZ_USER,
                    })}
                    ref={ref}
                    onMouseEnter={isNested ? this.handleNestedMouseEnter : noop}
                    onMouseLeave={this.handleOnMouseLeaveList}
                    style={{
                        top: 0,
                        left: 0,
                        ...(maxDropdownListHeight && { maxHeight: maxDropdownListHeight }),
                    }}
                >
                    {!isNested && title && <div className={styles.title}>{title}</div>}
                    {menu.map(this.renderItem)}
                </div>
            </CSSTransition>
        );
    };

    public render(): ReactElement | null {
        if (!this.portalNode) {
            return null;
        }

        return createPortal(this.renderList(this.props.list, this.rootRef), this.portalNode);
    }
}

export const DropdownList = connect(mapStateToProps, mapDispatchToProps)(DropdownListComponent);
