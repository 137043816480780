import { PayloadAction } from '@reduxjs/toolkit';
import { FaceListApiCall, PublicFilteredByFaceApiCall } from 'reactApp/api/FaceApiCall';
import {
    loadFacesOnPublicStart,
    requestFacesListFail,
    requestFacesListLoadMoreRequest,
    requestFacesListStart,
    requestFacesListSuccess,
    requestFacesOnPhotoStart,
    requestFacesOnPhotoSuccess,
    requestFilesWithFaceFail,
    requestFilesWithFaceStart,
    requestFilesWithFaceSuccess,
    requestMoreFilesWithFaceStart,
    selectFace,
    setLoadingFacesRequestState,
} from 'reactApp/modules/faces/faces.module';
import {
    getCurrentFaceFolderId,
    getCurrentPublicId,
    getCursorByFaceId,
    getFacesListCursor,
    getSelectedFaceId,
} from 'reactApp/modules/faces/faces.selectors';
import { IRequestFacesListStartData, ISelectFaceData } from 'reactApp/modules/faces/faces.types';
import { filesFilterAll } from 'reactApp/modules/filesFilter/filesFilter.module';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { sendPublicAnalytics } from 'reactApp/modules/public/public.saga';
import { DEFAULT_API_LIMIT } from 'reactApp/modules/storage/storage.helpers';
import { callSagaFromAction } from 'reactApp/utils/callSagaFromAction';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

const FACE_LIST_API_LIMIT = 100;

export const getFacesListApiCall = ({ id, limit = FACE_LIST_API_LIMIT, cursor = '' }: { id: string; limit?: number; cursor?: string }) =>
    new FaceListApiCall().makeRequest(null, { url: `?public_id=${id}&limit=${limit}${cursor ? `&cursor=${cursor}` : ''}` });
export const getFacesOnPhotoApiCall = ({ id, path }: { id: string; path: string }) =>
    new FaceListApiCall().makeRequest(null, { url: `?public_id=${id}&element=${path}` });
export const getFilesWithFaceApiCall = ({
    id,
    faceId,
    limit = DEFAULT_API_LIMIT,
    cursor = '',
}: {
    id: string;
    faceId: string;
    limit?: number;
    cursor?: string;
}) =>
    new PublicFilteredByFaceApiCall().makeRequest(null, {
        url: `?public_id=${id}&face_id=${faceId}&limit=${limit}${cursor ? `&cursor=${cursor}` : ''}`,
    });

export function* handleLoadFacesList(payload: IRequestFacesListStartData) {
    try {
        const { id } = payload;

        yield put(setLoadingFacesRequestState({ id }));

        const { data } = yield call(getFacesListApiCall, { id });

        yield put(requestFacesListSuccess({ id, faces: data?.faces, cursor: data?.cursor }));
    } catch (error) {
        yield loggerSaga({ error, action: requestFacesListFail('exception'), noCancel: true });
    }
}

const handleLoadFacesListRequest = (action: PayloadAction<IRequestFacesListStartData>) =>
    callSagaFromAction<IRequestFacesListStartData>(handleLoadFacesList, action);

function* handleLoadFacesMoreList() {
    try {
        const id = yield select(getCurrentFaceFolderId);
        const cursor = yield select(getFacesListCursor);
        const { data } = yield call(getFacesListApiCall, { id, cursor });

        yield put(requestFacesListSuccess({ id, faces: data?.faces, cursor: data?.cursor }));
    } catch (error) {
        yield loggerSaga({ error, action: requestFacesListFail('exception') });
    }
}

function* handleLoadFilesWithFace(action) {
    const id = action.payload?.id || (yield select(getCurrentPublicId));
    const faceId = action.payload?.faceId || (yield select(getSelectedFaceId));
    try {
        if (!id || !faceId) {
            yield put(requestFilesWithFaceSuccess({ id, faceId, files: [] }));
            return;
        }

        const { data } = yield call(getFilesWithFaceApiCall, { id, faceId });

        yield put(requestFilesWithFaceSuccess({ id, faceId, files: data?.list, cursor: data?.cursor }));
    } catch (error) {
        yield loggerSaga({ error, action: requestFilesWithFaceFail({ error: 'exception', faceId }) });
    }
}

function* handleLoadMoreFilesWithFace(action) {
    const id = action.payload?.id || (yield select(getCurrentPublicId));
    const faceId = action.payload?.faceId || (yield select(getSelectedFaceId));
    try {
        if (!id || !faceId) {
            yield put(requestFilesWithFaceSuccess({ id, faceId, files: [] }));
            return;
        }

        const cursor = yield select(getCursorByFaceId, faceId);

        const { data } = yield call(getFilesWithFaceApiCall, { id, faceId, cursor });

        yield put(requestFilesWithFaceSuccess({ id, faceId, files: data?.list, cursor: data?.cursor }));
    } catch (error) {
        yield loggerSaga({ error, action: requestFilesWithFaceFail({ error: 'exception', faceId }) });
    }
}

function* handleLoadFacesOnPhoto(action) {
    try {
        const { id, path } = action.payload;
        const { data } = yield call(getFacesOnPhotoApiCall, { id, path });

        yield put(requestFacesOnPhotoSuccess({ id, path, faces: data?.faces, cursor: data?.cursor }));
    } catch (error) {
        yield loggerSaga({ error, action: requestFacesListFail('exception') });
    }
}

function* handleLoadOnPublicStart(action: PayloadAction<IRequestFacesListStartData>) {
    const { id } = action.payload;

    yield handleLoadFacesList({ id });
    yield sendPublicAnalytics(id);
}

function* handleSelectFaceId(action: PayloadAction<ISelectFaceData>) {
    const { id } = action.payload;

    if (id) {
        yield put(requestFilesWithFaceStart({ faceId: id }));
        yield put(filesFilterAll());
    }
}

export function* watchFaces() {
    yield takeLatest(requestFacesListStart.toString(), handleLoadFacesListRequest);
    yield takeLatest(requestFacesListLoadMoreRequest.toString(), handleLoadFacesMoreList);
    yield takeLatest(requestFilesWithFaceStart.toString(), handleLoadFilesWithFace);
    yield takeLatest(requestMoreFilesWithFaceStart.toString(), handleLoadMoreFilesWithFace);
    yield takeLatest(requestFacesOnPhotoStart.toString(), handleLoadFacesOnPhoto);
    yield takeEvery(loadFacesOnPublicStart.toString(), handleLoadOnPublicStart);
    yield takeEvery(selectFace.toString(), handleSelectFaceId);
}
