import { Icon20Cancel, Icon20ComputerOutline, Icon20SmartphoneOutline } from '@vkontakte/icons';
import { Headline } from '@vkontakte/vkui';
import React, { memo, useCallback, useState } from 'react';
import { MobileAppPromoType } from 'reactApp/modules/promo/promoMobileApp/promoMobileApp.types';
import { showMobileAppRQ } from 'reactApp/ui/AsidePromoModal/ShowMobileAppRQ';
import { Button } from 'reactApp/ui/Button/Button';

import styles from './TreePromoMobileApp.css';

const desktopAppLandingUrl = 'https://cloud.mail.ru/promo/desktop';

interface IProps {
    onClose?: () => void;

    onClick(type: 'mobile' | 'desktop'): void;
}

export const TreePromoMobileApp = memo<IProps>(({ onClick, onClose }) => {
    const [show, setShow] = useState(true);

    const handleDeskAppClick = useCallback(() => {
        onClick('desktop');

        open(desktopAppLandingUrl, '_blank');
    }, [onClick]);

    const handleMobileAppClick = useCallback(() => {
        onClick('mobile');

        showMobileAppRQ({
            type: MobileAppPromoType.public,
            url: 'https://trk.mail.ru/c/v0erc0',
            from: 'install',
            popupTitle: 'Наведите камеру на\u00A0QR-код',
            popupText: 'Чтобы скачать Облако на смартфон',
        });
    }, [onClick]);

    const handleClose = useCallback(() => {
        onClose?.();
        setShow(false);
    }, [onClose, setShow]);

    if (!show) {
        return null;
    }

    return (
        <div className={styles.root}>
            <div className={styles.close} onClick={handleClose}>
                <Icon20Cancel />
            </div>
            <Headline level="2" weight="2" className={styles.title}>
                Подключить устройства
            </Headline>
            <div className={styles.buttons}>
                <Button className={styles.button} theme="octavius" onClick={handleMobileAppClick} fluid>
                    <Icon20SmartphoneOutline />{' '}
                    <Headline level="2" weight="2" className={styles.title}>
                        Смартфон
                    </Headline>
                </Button>
                <Button className={styles.button} theme="octavius" onClick={handleDeskAppClick} fluid>
                    <Icon20ComputerOutline />{' '}
                    <Headline level="2" weight="2" className={styles.title}>
                        Компьютер
                    </Headline>
                </Button>
            </div>
        </div>
    );
});

TreePromoMobileApp.displayName = 'TreePromoMobileApp';
