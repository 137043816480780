import React, { memo, ReactElement, useEffect } from 'react';
import { sendPromocodesAnalytics } from 'reactApp/ui/PresentLanding/PresentLanding.analytics';
import { activatePromocode } from 'reactApp/ui/PresentLanding/PresentLanding.helpers';

// костыль только для аб
/** tempexp_150202-start */
export const PresentLandingReloader = ({ location }) => {
    useEffect(() => {
        window.location.assign(`/promo/present${location.search}`);
    }, []);

    return <></>;
};
/** tempexp_150202-end */

export const PresentLanding = memo(function PresentLanding({ type }: { type: 'mail' | 'cloud' }): ReactElement {
    useEffect(() => {
        sendPromocodesAnalytics({ action: 'open', version: 'new', product: type });

        (window as any).activatePromocode = activatePromocode;
        (window as any).sendLandingAnalytics = sendPromocodesAnalytics;
    }, [type]);

    return <div id="react-modals" />;
});
