import classNames from 'clsx';
import React, { memo, ReactElement, useMemo } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window';
import { AdvBanner } from 'reactApp/ui/Mobile/Datalist/AdvBanner/AdvBanner';

import { DATA_LIST_BREADCRUMBS_ID, DATA_LIST_EXTRABLOCKS_ID, DATA_LIST_STORIES_WIDGET_ID } from '../Datalist/DataList';
import { COMPENSATE_SCROLL_PADDING } from './VirtualList.constants';
import { VIRTUAL_LIST_LOADER_ID } from './VirtualList.new';
import styles from './VirtualList.new.css';

export const VirtualListGridRow = memo(function VirtualListGridRow(props: ListChildComponentProps): ReactElement | null {
    const { index, style, data } = props;
    const { list, width, margin, height, itemActiveIndex, indexes, dividerSize, columnCount } = data;

    const startIndex = indexes[index].start;
    const lastIndex = Math.min(indexes[index].end, list.length);
    const handleRef = index === itemActiveIndex ? data.handleRef : null;
    const isDivider = list[startIndex].divider;

    const singleRowItem =
        startIndex === lastIndex &&
        [DATA_LIST_EXTRABLOCKS_ID, VIRTUAL_LIST_LOADER_ID, DATA_LIST_BREADCRUMBS_ID, DATA_LIST_STORIES_WIDGET_ID].includes(
            list[startIndex]
        );

    const accumulatedMargin = (columnCount - 1) * margin;
    const resultMargin = (accumulatedMargin - COMPENSATE_SCROLL_PADDING * 2) / (columnCount - 1);

    const content = useMemo(() => {
        const output: ReactElement[] = [];

        for (let i = startIndex; i <= lastIndex; i++) {
            const skipRenderItem = list[i]?.divider;

            if (skipRenderItem) {
                continue;
            }

            const key = String(typeof list[i] === 'string' ? list[i] : list[i].id);
            const itemStyle = {
                width: `${width}px`,
                marginRight: i === lastIndex ? 0 : `${resultMargin}px`,
                height: `${height}px`,
            };

            output.push(
                <div key={key + i} style={itemStyle}>
                    <div className={styles.colItem}>{data.renderItem(list[i], { index: i })}</div>
                </div>
            );
        }

        return output;
    }, [startIndex, lastIndex, list, width, resultMargin, height, data]);

    if (singleRowItem) {
        return (
            <div key={index} className={styles.singleRow} style={style}>
                <div className={classNames(styles.singleRow, styles.compensateScroll)} style={{ height: '100%' }}>
                    {data.renderItem(list[startIndex], { index: startIndex })}
                </div>
            </div>
        );
    }

    if (isDivider) {
        return (
            <div
                key={index}
                className={classNames({
                    [styles.dividerContainer]: true,
                    [styles.dividerGrid]: true,
                    [styles.dividerAdv]: list[startIndex].showAdv,
                })}
                style={style}
                data-qa-divider={list[startIndex].text}
            >
                <div
                    className={classNames(styles.compensateScroll, {
                        [styles.divider]: true,
                        [styles[`divider_${dividerSize}`]]: !!dividerSize,
                    })}
                >
                    {list[startIndex].text}
                    {list[startIndex].showAdv && <AdvBanner />}
                </div>
            </div>
        );
    }

    return (
        <div style={style} className={styles.gridRow} ref={handleRef}>
            {content}
        </div>
    );
}, areEqual);
