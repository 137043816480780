import escapeForHtml from 'lib/escapeForHtml';

import { ClientError } from './ClientError';

class JavaScriptError extends ClientError {
    /**
     * @constructor
     * @extends {ClientError}
     * @param {Error} stack
     * @param {string} source
     * @param {Error} error
     */
    constructor({ source, error }) {
        super(...arguments);

        let details = JavaScriptError.getErrorDetails(error);

        this.message = this.message.replace('{DETAILS}', details);
        this.error = error;

        if (error.stack) {
            this.stack = error;
        }
    }

    /**
     * @param {Error} error
     * @returns {string}
     */
    static getErrorStack(error) {
        let stack = error.stack;

        if (stack) {
            let origin = location.origin; // TODO: cross-browser?

            stack = stack.split(origin).join('');
            stack = stack.split('    at ').join('');
            // TODO: normalize stack
        }

        return stack;
    }

    /**
     * @param {Error|DOMException} error
     * @returns {string|undefined} – e.g. "NOT_FOUND_ERR" or "MEDIA_ERR_DECODE"
     */
    static getErrorCodeName(error) {
        let name;
        let code = error.code;
        let Constructor = error.constructor;

        if (code && Constructor) {
            name = Object.keys(Constructor).find(function(key) {
                // e.g. DOMException.NOT_FOUND_ERR or MediaError.MEDIA_ERR_DECODE
                let isCodeName = key.toUpperCase() === key;

                return isCodeName && Constructor[key] === code;
            });
        }

        if (!name && error.name !== 'Error') {
            // e.g. DOMException.name → "Error"
            name = error.name; // e.g. "NotFoundError" or "SecurityError"
        }

        return name;
    }

    /**
     * @param {Error|DOMException} error
     * @returns {string} – e.g. "TypeError", "DOMException" or "MediaError"
     */
    static getErrorType(error) {
        let type;
        let name;
        let Constructor = error.constructor;

        if (Constructor) {
            // Object.create(null).constructor → undefined
            let proto = Constructor.prototype;

            if (proto && window.Symbol && Symbol.toStringTag) {
                name = proto[Symbol.toStringTag]; // e.g. "TypeError", "DOMException" or "MediaError"
            }

            if (!name) {
                name = Constructor.name; // e.g. "TypeError", "DOMException" or "MediaError"
            }

            if (name !== 'Object') {
                type = name;
            }
        }

        if (!type) {
            type = Object.prototype.toString.call(error); // e.g. "[object MediaError]"
            type = type.slice(8, -1); // "[object MediaError]" → "MediaError"
        }

        return type;
    }

    /**
     * @param {Error|DOMException} error
     * @returns {string}
     */
    static getErrorDetails(error) {
        let type = JavaScriptError.getErrorType(error);
        let stack = JavaScriptError.getErrorStack(error);
        let codeName = JavaScriptError.getErrorCodeName(error);
        let codeNumber = error.code;
        let message = error.message;
        let details;

        if (stack) {
            details = '{STACK}';
        } else if (message && codeName) {
            details = '{TYPE}: {CODE_NAME} – {MESSAGE}';
        } else if (message && codeNumber) {
            details = '{TYPE}: code {CODE_NUMBER} – {MESSAGE}';
        } else if (codeName) {
            details = '{TYPE}: {CODE_NAME}';
        } else if (codeNumber) {
            details = '{TYPE}: code {CODE_NUMBER}';
        } else if (message) {
            details = '{TYPE}: {MESSAGE}';
        } else {
            details = '{TYPE}';
        }

        details = details.replace('{TYPE}', type);

        if (stack) {
            details = details.replace('{STACK}', escapeForHtml(stack));
        }

        if (message) {
            details = details.replace('{MESSAGE}', escapeForHtml(message));
        }

        if (codeName) {
            details = details.replace('{CODE_NAME}', codeName);
        }

        if (codeNumber) {
            details = details.replace('{CODE_NUMBER}', codeNumber);
        }

        return details;
    }

    static radarName = 'failure_{SOURCE}_error-js';

    className = 'JavaScriptError';
    message = 'Произошла JavaScript ошибка: <pre>{DETAILS}</pre>';
    error = null;

    toLogStringPattern = '[{TIME}] {USER} x-page-id={PAGE_ID} id={ID} {SOURCE} {CLASS_NAME} { {META} }: {ERROR}';

    toLogString() {
        let result = super.toLogString(...arguments);

        result = result.replace('{ERROR}', String(this.error));

        return result;
    }

    getDetails() {
        let details = {
            ...super.getDetails(...arguments),
            stack: JavaScriptError.getErrorDetails(this.error)
        };

       // details.error = String(this.error);

        return details;
    }
}

export { JavaScriptError };
