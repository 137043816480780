import { xray } from 'lib/xray';
import { IOS_BUILD_VERSION, IS_IOS_MOBILE, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';

export const sendWebviewAnalytics = (t: string, isMobilePayAvailable: boolean): void => {
    // шлем радары только из webview ios
    if (!IS_WEBVIEW || !IS_IOS_MOBILE) {
        return;
    }

    const appbuild = IOS_BUILD_VERSION;

    const safeParameter = !isMobilePayAvailable ? '_safe' : '_unsafe';
    const deviceParameter = '_ios';
    const appbuildParameter = appbuild ? `_${appbuild}` : '';

    xray.send(`quota_tariffs_${t}${deviceParameter}${safeParameter}${appbuildParameter}`, {
        dwh: {
            appbuild,
        },
    });
};
