import { captureException } from '@sentry/browser';
import { Rubles } from 'Cloud/Application/Rubles';
import classNames from 'clsx';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import {
    DOM_ID_WORM,
    HIDE_ADS,
    IS_B2B_BIZ_USER,
    IS_CHOSEN_PUBLIC_FILE,
    IS_MOBILE_OR_TABLET_BROWSER,
    IS_PHONE_BROWSER,
    IS_PUBLIC,
    PUBLIC_SHOVE,
} from 'reactApp/appHelpers/configHelpers';
import { isDarkTheme, isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { ADS_TIMER_MS } from 'reactApp/constants';
import { useShowAdsBannerTimer } from 'reactApp/hooks/useShowAdsBannerTimer';
import { getFeatureNewAdFormat } from 'reactApp/modules/features/features.selectors';
import { isDefaultRootContent } from 'reactApp/modules/home/home.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { DisableButton } from 'reactApp/ui/AdvBanners/DisableButton/DisableButton';

import styles from './Worm.css';

export const Worm = memo(() => {
    const isViewerActive = useSelector(ViewerSelectors.isViewerActive);
    const isNewAdFormat = useSelector(getFeatureNewAdFormat);
    const isDefaultContent = useSelector(isDefaultRootContent);
    const storage = useSelector(getCurrentStorage);

    const isWelcomePromo = storage === EStorageType.home && isDefaultContent;

    const chosenPublicFile = IS_CHOSEN_PUBLIC_FILE && !IS_PHONE_BROWSER;
    const showAD =
        DOM_ID_WORM &&
        !HIDE_ADS &&
        !PUBLIC_SHOVE &&
        !IS_MOBILE_OR_TABLET_BROWSER &&
        !IS_B2B_BIZ_USER &&
        !chosenPublicFile &&
        !isViewerActive &&
        !isWelcomePromo;

    const handleUpdateWorm = () => {
        if (!showAD) {
            return;
        }

        try {
            Rubles?.updateWorm(isDarkTheme);
        } catch (error) {
            captureException(error);
        }
    };

    return useShowAdsBannerTimer({
        adsUpdateTimerMs: ADS_TIMER_MS,
        updateAds: () => handleUpdateWorm(),
        suspendTimer: !showAD,
        renderItem: () =>
            showAD ? (
                <div className={classNames(styles.root, { [styles.root_new_portal_header]: isNewPortalHeader.client })}>
                    <style>{`
               #${DOM_ID_WORM} {
                    overflow: hidden;
                    position: relative;
                    ${isNewAdFormat ? 'height: 48px;' : 'height: 23px;'}
                    ${isNewAdFormat ? 'width: 100%;' : ''}
                    ${isNewAdFormat ? 'padding: 0;' : 'padding: 6px 0 7px 7px;'}
                    line-height: 18px;
                    white-space: nowrap;
                }
                #${DOM_ID_WORM} .title__link {
                    color: #656972 !important;
                    text-decoration: underline !important;
                }

                #${DOM_ID_WORM} .content {
                    color: #656972 !important;
                    margin-left: .6em !important;
                    background: none !important;
                    box-shadow: none !important;
                    font-family: inherit !important;
                    border-radius: 0 !important;
                    font-size: inherit !important;
                }
            `}</style>
                    {!IS_PUBLIC && (
                        <style>{`
                #${DOM_ID_WORM} {
                    background-color: var(--vkui--octavius_color_list_letter_adv_background);
                    ${isNewAdFormat ? 'padding-top: 0;' : 'padding-top: 10px;'}
                    ${isNewAdFormat ? 'margin-top: 0;' : 'margin-top: 2px;'}
                    border-radius: 12px;
                }
            `}</style>
                    )}
                    <div id={DOM_ID_WORM} />
                    <DisableButton className={classNames(styles.btn, { [styles.btn_new]: isNewAdFormat })} id="top-ad" />
                </div>
            ) : null,
    });
});

Worm.displayName = 'Worm';
