import { ISmsSenderState } from 'reactApp/modules/smsSender/smsSender.types';
import { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getSmsSenderState = (state: RootState): ISmsSenderState => state.smsSender as ISmsSenderState;

export const getMessage = createSelector(getSmsSenderState, (state) => state.message);
export const hasError = createSelector(getSmsSenderState, (state) => state.hasError);

export const SmsSenderSelectors = {
    getLifeCycleState: (state) => {
        const { isLoaded, isLoading, hasError } = getSmsSenderState(state);

        return {
            isLoaded,
            isLoading,
            hasError,
        };
    },
    getMessage,
    hasError,
};
