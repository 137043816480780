import classNames from 'clsx';
import { ReactComponent as ErrorIcon } from 'mrg-icons/src/mailru/actions/close_big.svg';
import { ReactComponent as SuccessIcon } from 'mrg-icons/src/mailru/status/done_big.svg';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { sendLinkBySmsRequest } from 'reactApp/modules/smsSender/smsSender.module';
import { SmsSenderSelectors } from 'reactApp/modules/smsSender/smsSender.selectors';
import { Button } from 'reactApp/ui/Button/Button';
import { sendGa } from 'reactApp/utils/ga';
import { formatPhone, processPhone } from 'reactApp/utils/phoneHelpers';

import styles from './GetLinkBySms.css';

const mapStateToProps = (state) => {
    const { isLoaded, isLoading, hasError } = SmsSenderSelectors.getLifeCycleState(state);

    return {
        isLoaded,
        isLoading,
        hasError,
        message: SmsSenderSelectors.getMessage(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    sendLinkBySms: (phone, gaCategory) => dispatch(sendLinkBySmsRequest({ phone, gaCategory })),
});

type TSize = 'small' | 'middle' | '';
type TMod = 'welcome' | 'round' | 'row' | '';

interface IProps {
    sendLinkBySms(phone?, gaCategory?): void;
    hasError?: boolean;
    isLoading?: boolean;
    isLoaded: boolean | null;
    withMobile: boolean;
    message?: string | ReactNode;
    title?: string;
    placeholder?: string;
    buttonText?: string;
    gaCategory?: string;
    inverseStyle?: boolean;
    size?: TSize;
    disableButton: boolean;
    hideForm: boolean;
    hideIcon: boolean;
    mod?: TMod;
}

export class GetLinkBySms extends Component<IProps> {
    static defaultProps = {
        sendLinkBySms: () => null,
        title: 'Скачайте приложение в App Store или Google Play:',
        placeholder: 'Введите номер телефона',
        withMobile: true,
        gaCategory: 'get-link',
        inverseStyle: false,
        disableButton: false,
        hideForm: false,
        hideIcon: false,
        message: 'Сообщение отправлено на указанный вами номер',
        buttonText: 'Получить ссылку',
        isLoaded: null,
        size: '' as TSize,
        mod: '' as TMod,
    };

    state = {
        phone: '',
        showError: this.props.hasError,
    };

    componentDidMount() {
        if (!this.props.placeholder) {
            this.handlePhoneFocus();
        }
    }

    handlePhoneChange = (e) => {
        this.setState({ phone: formatPhone(e.target.value) });
    };

    handlePhoneFocus = () => {
        const { phone } = this.state;

        if (!phone) {
            this.setState({ phone: '+7' });
        }

        this.setState({ showError: false });
    };

    handleGetLink = (event) => {
        const { phone } = this.state;
        const { sendLinkBySms, gaCategory, disableButton } = this.props;
        event.preventDefault();

        if (disableButton && !phone) {
            return;
        }

        sendGa(gaCategory, 'send-sms', 'click');

        sendLinkBySms(processPhone(phone), gaCategory);

        this.setState({ showError: true });
    };

    render() {
        const {
            hasError,
            isLoading,
            message,
            title,
            placeholder,
            withMobile,
            inverseStyle,
            size,
            disableButton,
            hideForm,
            hideIcon,
            mod,
            buttonText,
            isLoaded,
        } = this.props;
        const { phone, showError } = this.state;

        const hideInput = hideForm && message && !hasError;
        const hideButton = hideForm && message && showError;

        const reqMessage = !showError && hasError ? '' : message;

        const icon =
            hideForm && hasError ? (
                <ErrorIcon className={styles.icon} width={10} height={10} />
            ) : (
                <SuccessIcon className={styles.icon} width={20} height={20} />
            );

        return (
            <form onSubmit={this.handleGetLink}>
                <div
                    className={classNames({
                        [styles.wrapper]: true,
                        [styles.mobile]: withMobile,
                        [styles.inverse]: inverseStyle,
                        [styles[size!]]: !!size,
                        [styles[mod!]]: !!mod,
                    })}
                >
                    {!!title && <div className={styles.label}>{title}</div>}
                    {!hideInput && (
                        <div className={styles.inputBlock}>
                            <input
                                className={classNames({ [styles.input]: true, [styles.inputError]: hasError })}
                                type="tel"
                                placeholder={placeholder}
                                value={phone}
                                onChange={this.handlePhoneChange}
                                onFocus={this.handlePhoneFocus}
                                id="phone"
                                name="phone"
                                disabled={isLoading}
                                autoComplete="off"
                            />
                            {!hideButton && (
                                <Button
                                    className={classNames({
                                        [styles.button]: true,
                                        [styles.button_disable]: disableButton && !phone,
                                    })}
                                    primary
                                    onClick={this.handleGetLink}
                                    loading={isLoading}
                                    type="submit"
                                >
                                    {buttonText}
                                </Button>
                            )}
                        </div>
                    )}
                    {!!reqMessage && (
                        <div
                            className={classNames({
                                [styles.message]: true,
                                [styles.message__error]: hasError,
                                [styles.message__success]: isLoaded === null ? !hasError : isLoaded,
                            })}
                        >
                            {!hideIcon && icon}
                            <div className={styles.messageText}>
                                {hideForm && !hasError ? (
                                    <>
                                        Ссылка отправлена на номер <span className={styles.messagePhone}>{phone}</span>
                                    </>
                                ) : (
                                    reqMessage
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetLinkBySms);
