import './plugin.css';

import videojs from 'video.js';
import { QualityLevelList } from 'videojs-contrib-quality-levels';

import { ConcreteButton } from './ConcreteButton';
import { ConcreteMenuItem } from './ConcreteMenuItem';

interface IOptions {
    displayCurrentQuality?: boolean;
    placementIndex?: number;
    vjsIconClass?: string;
}

const defaults: IOptions = {};

export class HlsQualitySelectorPlugin {
    player: videojs.Player;
    config: IOptions;
    _currentQuality: string | number = 'auto';
    _qualityButton: ConcreteButton;

    constructor(player: videojs.Player, options) {
        this.player = player;
        this.config = options;
        this._qualityButton = new ConcreteButton(player, { name: 'QualityButton' });

        // Create the quality button.
        this.createQualityButton();
        this.bindPlayerEvents();
    }

    getHls() {
        // @ts-ignore
        return this.player.tech({ IWillNotUseThisInPlugins: true }).vhs;
    }

    bindPlayerEvents() {
        this.player.qualityLevels().on('addqualitylevel', this.onAddQualityLevel.bind(this));
    }

    createQualityButton() {
        const player = this.player;

        const placementIndex = player.controlBar.children().length - 2;
        const concreteButtonInstance = player.controlBar.addChild(
            this._qualityButton,
            { componentClass: 'qualitySelector' },
            this.config.placementIndex || placementIndex
        );

        concreteButtonInstance.addClass('vjs-quality-selector');
        if (!this.config.displayCurrentQuality) {
            const icon = ` ${this.config.vjsIconClass || 'vjs-icon-hd'}`;

            concreteButtonInstance.menuButton_.$('.vjs-icon-placeholder').className += icon;
        } else {
            this.setButtonInnerText(this.player.localize('auto'));
        }
        concreteButtonInstance.removeClass('vjs-hidden');
    }

    setButtonInnerText(text) {
        // @ts-ignore
        this._qualityButton?.menuButton_.$('.vjs-icon-placeholder').innerHTML = text;
    }

    getQualityMenuItem(item: { label: string; value: number | string; selected?: boolean }): ConcreteMenuItem {
        const player = this.player;

        return new ConcreteMenuItem(player, item, this._qualityButton, this);
    }

    onAddQualityLevel() {
        const player = this.player;
        const qualityList = player.qualityLevels();
        // @ts-ignore
        const levels = qualityList.levels_ || [];
        const levelItems: ConcreteMenuItem[] = [];

        for (let i = 0; i < levels.length; ++i) {
            const { width, height } = levels[i];
            const pixels = width > height ? height : width;

            if (!pixels) {
                continue;
            }

            if (
                !levelItems.filter((_existingItem) => {
                    return _existingItem.item && _existingItem.item.value === pixels;
                }).length
            ) {
                const levelItem = this.getQualityMenuItem({
                    label: `${pixels}p`,
                    value: pixels,
                });

                levelItems.push(levelItem);
            }
        }

        levelItems.sort((current, next) => {
            if (typeof current !== 'object' || typeof next !== 'object') {
                return -1;
            }
            if (current.item.value < next.item.value) {
                return -1;
            }
            if (current.item.value > next.item.value) {
                return 1;
            }
            return 0;
        });

        levelItems.push(
            this.getQualityMenuItem({
                label: player.localize('Auto'),
                value: 'auto',
                selected: true,
            })
        );

        if (this._qualityButton) {
            this._qualityButton.setItems(levelItems);
            this._qualityButton.update();
        }
    }

    setQuality(quality: string | number) {
        const qualityList = this.player.qualityLevels() as QualityLevelList;

        this.player.trigger('changequality');

        // Set quality on plugin
        this._currentQuality = quality;

        if (this.config.displayCurrentQuality) {
            this.setButtonInnerText(quality === 'auto' ? this.player.localize('auto') : `${quality}p`);
        }

        for (let i = 0; i < qualityList.length; ++i) {
            const { width = 0, height = 0 } = qualityList[i];
            const pixels = width > height ? height : width;

            qualityList[i].enabled = pixels === quality || quality === 'auto';
        }
        this._qualityButton?.unpressButton();
    }

    getCurrentQuality(): string | number {
        return this._currentQuality || 'auto';
    }
}

const onPlayerReady = (player, options) => {
    player.addClass('vjs-hls-quality-selector');
    player._hlsQualitySelector = new HlsQualitySelectorPlugin(player, options);
};

const hlsQualitySelector = function (options: IOptions) {
    // @ts-ignore
    const player = this as videojs.Player;
    player.ready(() => {
        // @ts-ignore
        if (!player.qualityLevels || !player.tech({ IWillNotUseThisInPlugins: true }).vhs) {
            return;
        }
        onPlayerReady(player, videojs.mergeOptions(defaults, options));
    });
};

declare module 'video.js' {
    interface VideoJsPlayer {
        _hlsQualitySelector: HlsQualitySelectorPlugin;
        hlsQualitySelector: (options?: IOptions) => void;
    }
}

export default hlsQualitySelector;
