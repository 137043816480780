import classNames from 'clsx';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getFeatureCreatePublicAlbum } from 'reactApp/modules/features/features.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { DataListCheckbox } from 'reactApp/ui/DataListCheckbox/DataListCheckbox';
import { Link } from 'reactApp/ui/Link/Link';
import { GalleryIcon, LinkIcon } from 'reactApp/ui/VKUIIcons';

import { Label } from '../Label/Label';
import styles from './Album.css';
import { IProps } from './Album.types';
import { useLoading } from './Albums.hooks';
import { Options } from './Options';

export const Album = memo(function Album(props: IProps) {
    const { thumbnails, name, id, weblink, onClick, isLink = true, isSelectable = false, isSelected = false } = props;
    const [isLoaded, onLoad] = useLoading();
    const isPublicAlbumEnabled = useSelector(getFeatureCreatePublicAlbum);
    const showWeblink = weblink && isPublicAlbumEnabled;

    return (
        <Link handleClick={onClick} noLink={!isLink} handleGoTo={onClick} storage={EStorageType.albums} id={id} className={styles.link}>
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.root_selected]: isSelected,
                })}
                data-qa-id={id}
                data-qa-album={name}
            >
                {!thumbnails ? (
                    <GalleryIcon className={styles.icon} data-qa-id="album-icon" />
                ) : (
                    <img
                        src={thumbnails['336x304']}
                        srcSet={thumbnails['1000x1000']}
                        loading="lazy"
                        className={classNames(styles.image, { [styles.image_loaded]: isLoaded })}
                        onLoad={onLoad}
                        data-qa-id="album-thumbnail"
                    />
                )}
                <Label className={styles.title} qaLabel="title">
                    <>
                        {showWeblink && <LinkIcon className={styles.weblink} width={16} height={16} />}
                        {name}
                    </>
                </Label>

                {!isSelectable && <Options id={id} />}
                {isSelectable && (
                    <div className={styles.checkbox}>
                        <DataListCheckbox checked={isSelected} />
                    </div>
                )}
            </div>
        </Link>
    );
});
