/* eslint-disable sonarjs/no-duplicate-string */
import { EditorID, WopiParams } from 'Cloud/Application/Editor/types';
import { addParams } from 'lib/urlUtils';
import { ENVIRONMENT } from 'server/constants/environment';
import { EditorConfig, EditorMode } from 'server/helpers/editors/types';
import type { IUser } from 'server/helpers/getUser';
import { EEnvironment } from 'server/types/EEnvironment';

export const addProtocol = (url: string) => url.replace(/^\/\//, `${window.location.protocol}//`); // CLOUDWEB-3901

const get_view_url = <
    Item extends {
        stockId?: string;
        id?: string;
        home?: string;
    }
>(
    item: Item,
    storage: string,
    wopiParams: WopiParams
): Promise<string> => {
    const viewUrl = wopiParams?.url
        ? addParams(wopiParams.url, {
              title: 'title',
              lang: 'ru-ru',
              closebutton: 1,
              revisionhistory: 1,
          })
        : `/${(storage === 'stock' ? item.stockId : item.id) || item.home}`;

    return Promise.resolve(viewUrl);
};

const editData = (isEdit: boolean, id: string) => {
    return {
        Status: '200',
        edit_url: `/${id}`,
        action: isEdit ? 'edit' : 'view',
    } as const;
};

// eslint-disable-next-line max-lines-per-function
export const getEditorsConfig = (editorId: EditorID, imgUrl?: string, user?: IUser): EditorConfig | undefined => {
    if (editorId === EditorID.MAILPAD) {
        const documentsRoot = ENVIRONMENT === EEnvironment.DEV ? '//docs.win54.dev.mail.ru/' : '//docs.mail.ru/';

        return {
            id: EditorID.MAILPAD,
            title: 'Редактор Mail.ru',
            description: 'Новое приложение Mail.ru для редактирования документов Word. Доступна альфа-версия с базовым функционалом',
            icon: {
                '16x16': `${imgUrl}editor/mailpad_16x16.png`,
                '32x32': `${imgUrl}editor/mailpad_32x32.png`,
            },
            'x-docs-root': documentsRoot,
            documentsRoot,
            get_view_url(item, storage) {
                const url = (storage === 'stock' ? item.stockId : item.id) || item.home;
                const viewUrl = url ? addProtocol(`${documentsRoot}view/?src=${encodeURIComponent(url)}&r=${Math.random()}`) : '';
                return Promise.resolve(viewUrl);
            },
        };
    }

    if (editorId === EditorID.R7) {
        return {
            id: EditorID.R7,
            title: 'Редактор R7',
            description: 'Редактор R7',
            view_url: '/r7/view/',
            edit_uri: '/r-seven/edit/',
            create_uri: '/r-seven/create/',
            edit_storage: 'r-seven/edit/home',
            edit_data(isEdit, id) {
                return {
                    access_token_ttl: 0,
                    Status: '200',
                    edit_url: `/${id}`,
                    action: isEdit ? EditorMode.EDIT : EditorMode.VIEW,
                    access_token: 'fakeForR7',
                };
            },
            icon: {
                '16x16': `${imgUrl}editor/hancom_16x16.png`,
                '32x32': `${imgUrl}editor/hancom_32x32.png`,
            },
            documentsRoot: '/r7/view/',
            get_view_url({ stockId, id, home }, storage) {
                const viewUrl = (storage === 'stock' ? stockId : id) || home;

                return Promise.resolve(`/${viewUrl}`);
            },
        };
    }

    if (editorId === EditorID.MYOFFICE) {
        return {
            id: EditorID.MYOFFICE,
            title: 'Редактор Мой офис',
            description: 'Редактор Мой офис',
            wopi: true,
            view_url: '/myoffice/view/',
            edit_uri: '/myoffice/edit/',
            create_uri: '/myoffice/',
            edit_storage: 'myoffice/edit/home' || 'myoffice/edit/attaches',
            edit_data(isEdit, id) {
                return {
                    action: isEdit ? EditorMode.EDIT : EditorMode.VIEW,
                    edit_url: `/${id}`,
                };
            },
            icon: {
                '16x16': `${imgUrl}editor/hancom_16x16.png`,
                '32x32': `${imgUrl}editor/hancom_32x32.png`,
            },
            documentsRoot: '/myoffice/view/',
            get_view_url,
        };
    }

    if (editorId === EditorID.OFFICE) {
        const documentsRoot = '/office/view';
        return {
            id: EditorID.OFFICE,
            title: 'Microsoft Office Online',
            description: 'Онлайн-версия Microsoft Office, позволяющая редактировать документы Word, таблицы Excel и презентации PowerPoint',
            icon: {
                '16x16': `${imgUrl}editor/owa_16x16.png`,
                '32x32': `${imgUrl}editor/owa_32x32.png`,
            },
            edit_uri:
                user?.isMailUser || user?.isBizUser || user?.externalUser || ENVIRONMENT === EEnvironment.DEV ? '/office/edit/' : undefined,
            edit_storage: 'office/edit/home',
            edit_data: editData,
            documentsRoot,
            get_view_url({ stockId, id, weblink, home, isStock }, storage, wopiParams) {
                let viewUrl = documentsRoot.replace(/\/$/, '');

                if (isStock || storage === 'stock') {
                    viewUrl += `/stock/${encodeURIComponent(stockId || id || '')}`;
                } else if (storage === 'public') {
                    viewUrl += `/public/${encodeURIComponent(weblink || '')}`;
                } else {
                    viewUrl += `/home${encodeURIComponent(home || id || '')}`;
                }

                const resultUrl = addParams(addProtocol(viewUrl), {
                    from: 'cloud',
                    access_token: wopiParams?.access_token,
                    access_token_ttl: wopiParams?.access_token_ttl,
                    url: wopiParams?.url ? encodeURIComponent(wopiParams?.url) : '',
                    status: wopiParams?.status,
                });

                return Promise.resolve(resultUrl);
            },
        };
    }

    if (editorId === EditorID.DOCS_MAIL) {
        return {
            id: EditorID.DOCS_MAIL,
            title: 'docs.mail.ru',
            description: 'docs.mail.ru',
            icon: {},
            documentsRoot: '//docs.mail.ru/',
        };
    }

    // TODO CLOUDWEB-15451 убрать проверку "user && isAnonymUser" когда Ovidius будет работать на анонимных пользователях (ВК)
    if (editorId === EditorID.OVIDIUS_V2 && user && !user.isAnonymUser) {
        return {
            id: EditorID.OVIDIUS_V2,
            title: 'Ovidius',
            description: 'Ovidius',
            icon: {},
            documentsRoot: '/ovidiusV2/view',
        };
    }

    if (editorId === EditorID.R7_WOPI) {
        return {
            id: EditorID.R7_WOPI,
            title: 'Редактор R7 WOPI',
            description: 'r7 wopi',
            wopi: true,
            icon: {},
            documentsRoot: '',
            edit_uri: '/r7w/edit/',
            edit_storage: 'r7w/edit/home',
            edit_data: editData,
            get_view_url: ({ stockId, id, home }, storage, wopiParams) => {
                const viewUrl = wopiParams?.url ? wopiParams.url : `/${(storage === 'stock' ? stockId : id) || home}`;

                return Promise.resolve(viewUrl);
            },
        };
    }
};
