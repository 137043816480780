import { xray } from 'lib/xray';
import { useCallback } from 'react';
import { Product } from 'reactApp/types/Billing';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

// https://docs.google.com/spreadsheets/d/1oxESW-0Ne7WtP7gKejV6CJYSv-Q9ssFDC8MY1_chDT0

export enum E2GBPopupAction {
    SHOW = 'show',
    CLICK = 'click',
    PAYMENT_SUCCESS = 'payment-success',
    PAYMENT_FAILURE = 'payment-failure',
}

interface I2GBPopupAnalytics {
    action: E2GBPopupAction;
    is_trial: boolean;
    period: string;
    quota: number;
}

export const send2GBPopupAnalytics = ({ action, ...rest }: I2GBPopupAnalytics): void => {
    sendDwh({
        eventCategory: ECategoryGa['2gb'],
        action,
        dwhData: rest,
    });
};

export const useSend2GBPopupAnalytics = (tariff: Product | undefined) => {
    return useCallback(
        (action: E2GBPopupAction) => {
            if (!tariff) {
                return;
            }

            send2GBPopupAnalytics({
                action,
                is_trial: tariff.id.includes('_trial_') || tariff.hasTrial,
                period: tariff.period,
                quota: tariff.space.space,
            });
        },
        [tariff]
    );
};

export enum CloudStockAnalytics {
    SHOW = 'show',
    CHECKOUT_OPEN = 'checkout-open',
    CLOSE = 'close',
    CLEANER_OPEN = 'cleaner-open',
    HOME_OPEN = 'home-open',
}

export const sendCloudStockAnalytics = (type: CloudStockAnalytics) => xray.send('cloud_attach', { i: type });

export const useSend2GBPopupXray = (tariff: Product | undefined) => {
    return useCallback(
        (action: E2GBPopupAction) => {
            if (!tariff) {
                return;
            }

            let productType = 'tariff';

            if (tariff.id.includes('_trial_') || tariff.hasTrial) {
                productType = 'trial';
            } else if (tariff.hasDiscount) {
                productType = 'discount';
            }

            xray.send(['mail', 'popup', productType, action]);
        },
        [tariff]
    );
};
