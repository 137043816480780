import { IS_AUTO_TEST_HIDE, IS_PUBLIC_ALBUM } from 'reactApp/appHelpers/configHelpers';
import { requestFacesListSuccess } from 'reactApp/modules/faces/faces.module';
import { hasFolderFileWithFace } from 'reactApp/modules/faces/faces.selectors';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getPublicRootWeblink } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { isReactMobilePublic } from 'reactApp/sections/MobilePublicPage/isReactMobilePublic';
import { store } from 'reactApp/store';
import { renderMobileSplashScreen } from 'reactApp/ui/Mobile/SplashScreen/helpers/SplashScreen.helpers';
import { useFacesFilterScreen } from 'reactApp/ui/Mobile/SplashScreen/hooks/useFacesFilterScreen';
import { call, delay, put, race, take } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const FACE_PROMO_ID = 'faceScreenMobile';

export function* initMobileFaceFilterPromo() {
    if (!isReactMobilePublic) {
        return;
    }

    const { weblink } = yield* select(getPublicRootWeblink);
    const storage = yield* select(getCurrentStorage);
    const { isStock } = getStorage(storage);

    if (IS_AUTO_TEST_HIDE || isStock || !weblink || IS_PUBLIC_ALBUM) {
        return;
    }

    yield race({
        done: take(requestFacesListSuccess.type),
        timeout: delay(2000),
    });

    const isShown = storeHelper.getValue(FACE_PROMO_ID);
    const hasFaces = yield select(hasFolderFileWithFace, weblink);

    if (isShown || !hasFaces) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.mobileFaceFilter,
            promoId: FACE_PROMO_ID,
            onShow: () => {
                store.dispatch(promoShown(EPromoType.mobileFaceFilter));
                storeHelper.markAsShown(FACE_PROMO_ID);
            },
            onClose: () => {
                store.dispatch(removePromo(EPromoType.mobileFaceFilter));
            },
        })
    );
}

export function* showMobileFaceFilterPromo() {
    const mobileFaceFilterPromo = yield* select(PromoSelectors.getPromo(EPromoType.mobileFaceFilter));

    if (!mobileFaceFilterPromo) {
        return;
    }

    mobileFaceFilterPromo.onShow();
    yield call(renderMobileSplashScreen, { contentHook: useFacesFilterScreen }, mobileFaceFilterPromo.onClose());
}
