import classNames from 'clsx';
import React, { useEffect, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { loadSharedLinksStartRequest } from 'reactApp/modules/shared/shared.module';
import { getSharedLinksStatistics, getSharedLoadingState } from 'reactApp/modules/shared/shared.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import BizPageTabMenu from 'reactApp/ui/DataListItemRow/components/BizPageTabMenu/BizPageTabMenu';
import { SharedTabs } from 'reactApp/ui/SharedTabs/SharedTabs';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './SharedPage.css';

type Props = {
    initialItemId?: string;
};

export const SharedPage: VFC<Props> = ({ initialItemId = '' }) => {
    const dispatch = useDispatch();
    const { isLoaded } = useSelector(getSharedLoadingState);
    const statistic = useSelector(getSharedLinksStatistics);
    const showBizPageTabMenu = statistic.all > 0;
    const showSharedTabs = !IS_BIZ_USER;

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'common' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'common' });

        dispatch(loadSharedLinksStartRequest());
    }, []);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_new_portal_header]: isNewPortalHeader.client,
            })}
        >
            <div className={classNames({ [styles.headerContainer_new_portal_header]: isNewPortalHeader.client })}>
                {isLoaded && <BreadcrumbsContainer />}
                {showSharedTabs && <SharedTabs />}
                {showBizPageTabMenu && <BizPageTabMenu noMarginBottom />}
            </div>
            <DataList
                goTo={noop}
                initialItemId={initialItemId}
                storage={EStorageType.sharedLinks}
                selectableClassName={styles.datalistSelectable}
            />
        </div>
    );
};
