import React, { VFC } from 'react';

import { DiscountCard } from '../DiscountCard/DiscountCard';
import { buttonTextMap } from '../DiscountCard/DiscountCard.constant';
import type { Props as DiscountCardProps } from '../DiscountCard/DiscountCard.types';

type Props = {
    data: Array<Omit<DiscountCardProps, 'onClick' | 'buttonText'>>;
};

export const DiscountList: VFC<Props> = ({ data }) => {
    return (
        <>
            {data.map(({ type, id, title, text, link }) => (
                <DiscountCard
                    id={id}
                    key={`${type}-${id}`}
                    type={type}
                    text={text}
                    title={title}
                    buttonText={buttonTextMap[type]}
                    link={link}
                />
            ))}
        </>
    );
};
