import classNames from 'clsx';
import throttle from 'lodash.throttle';
import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { getDomainFoldersFilterActive, isReadOnly } from 'reactApp/modules/home/home.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getIds, getStorageCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { setProvocationShowAction, setUploadFromAction } from 'reactApp/modules/upload/upload.module';
import { getAllowedMimeTypes } from 'reactApp/modules/upload/upload.selectors';
import { useUploadInputHandlers } from 'reactApp/modules/uploading/hooks/useUploadInputHandlers';
import { RootState } from 'reactApp/store';

import styles from './UploadProvocation.css';
import { sendProvocationGa } from './UploadProvocation.helpers';
import { IProvocation } from './UploadProvocation.types';

const MIN_HEIGHT = 120;
const MAX_HEIGHT = 400;
const FOOTER_HEIGHT = 40;

interface UploadProvocationProps {
    maxHeight?: number;
}

const UploadProvocationBase = forwardRef<IProvocation, UploadProvocationProps>(function UploadProvocation({ maxHeight = MAX_HEIGHT }, ref) {
    const storage = useSelector(getCurrentStorage);
    const [canShow, setShow] = useState<boolean>(false);
    const [height, setHeight] = useState<number>(0);
    const folder = useSelector((state: RootState) => getStorageCurrentFolder(state, storage));
    const isReadOnlyFolder = useSelector((state: RootState) => isReadOnly(state, folder?.id));
    const items = useSelector((state: RootState) => getIds(state, storage));
    const viewMode = useSelector((state) => SettingsSelectors.getViewByStorage(state, storage));
    const itemsCount = items.length;
    const provocationRef = useRef<HTMLDivElement>(null);
    const allowedMimeTypes = useSelector(getAllowedMimeTypes)?.join(',');
    const domainFoldersFilterActive = useSelector(getDomainFoldersFilterActive);

    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        canShow: () => canShow,
    }));

    const { subscribeToInput, unsubscribeOfInput } = useUploadInputHandlers();

    useEffect(() => {
        subscribeToInput(inputRef.current);

        return () => {
            unsubscribeOfInput(inputRef.current);
        };
    }, [canShow]);

    const onClick = useCallback(() => {
        dispatch(setUploadFromAction('provocation'));
        sendProvocationGa('click');
    }, []);

    const setProvocationHeight = useCallback(() => {
        if (isReadOnlyFolder) {
            return;
        }

        const topBlockPosition = (provocationRef.current?.getBoundingClientRect()?.top || 0) + window.scrollY;

        if (typeof topBlockPosition !== 'number') {
            return;
        }

        const blockHeight = window.innerHeight - topBlockPosition - FOOTER_HEIGHT;
        const isFit = blockHeight >= MIN_HEIGHT;
        const provocationHeight = Math.min(blockHeight, maxHeight);

        setShow(isFit);
        dispatch(setProvocationShowAction(isFit));

        if (!isFit) {
            return;
        }

        setHeight(provocationHeight);
    }, [dispatch, isReadOnlyFolder, maxHeight]);

    const handleOnResize = useCallback(
        // Уменьшать таймаут нельзя, так как на ресайз обрабатывается даталистом тоже не сразу.
        // В итоге, сюда ресайз придет, а даталист еще не переместил элементы и мы получим не то значение topBlockPosition
        throttle(setProvocationHeight, 300),
        [setProvocationHeight]
    );

    useLayoutEffect(() => {
        setTimeout(setProvocationHeight, 300);

        // viewMode, itemsCount в зависимостях для того,
        // чтобы размеры пересчитывались при изменении viewMode и количества элементов
    }, [viewMode, itemsCount, setProvocationHeight]);

    useEffect(() => {
        if (!canShow) {
            return;
        }

        sendProvocationGa('show');
    }, [canShow]);

    useEffect(() => {
        window.addEventListener?.('resize', handleOnResize);
        return () => {
            window.removeEventListener?.('resize', handleOnResize);
        };
    }, [handleOnResize]);

    if (isReadOnlyFolder || domainFoldersFilterActive) {
        return null;
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_hidden]: !canShow,
                [styles.root_new_portal_header]: isNewPortalHeader.client,
            })}
            ref={provocationRef}
        >
            {canShow && (
                <>
                    <div className={styles.inner} />
                    <div className={styles.wrapper} onClick={onClick} style={{ height: `${height}px` }}>
                        <input type="file" multiple className={styles.input} ref={inputRef} accept={allowedMimeTypes} />
                        <div className={styles.description}>
                            <span className={styles.text}>Нажмите</span> или перенесите
                            <br />
                            файлы для загрузки
                        </div>
                    </div>
                </>
            )}
        </div>
    );
});

export const UploadProvocation = memo(UploadProvocationBase);
