import React, { ReactElement } from 'react';

import styles from './FilesFolderLogViewerStub.css';
import { useStubImage } from './useStubImage';
export const FilesFolderLogViewerStub = (): ReactElement => {
    const { stubImage } = useStubImage();
    return (
        <div className={styles.wrapper}>
            <img src={stubImage} />
            <div className={styles.regular}>Выберите один файл или папку, чтобы увидеть историю</div>
        </div>
    );
};
