import { Button, ButtonGroup } from '@vkontakte/vkui';
import { xray } from 'lib/xray';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancel as cancelPublishing, publishSelected } from 'reactApp/modules/integration/integration.actions';
import { getSelectedItems } from 'reactApp/modules/storage/storage.selectors';

import styles from './IntegrationFooter.css';

export const IntegrationFooter = React.memo(() => {
    const dispatch = useDispatch();
    const selectedItems = useSelector(getSelectedItems);

    const onPublishClick = () => {
        xray.send('attach_cloud_add');
        dispatch(publishSelected());
    };

    const onCancelClick = () => {
        xray.send('attach_cloud_closed');
        dispatch(cancelPublishing());
    };

    return (
        <div className={styles.root} data-qa-id="integration-footer">
            <ButtonGroup gap="s">
                <Button
                    appearance="accent"
                    mode="primary"
                    size="m"
                    className={styles.button}
                    disabled={!selectedItems?.length}
                    onClick={onPublishClick}
                    data-qa-id="attach-button"
                >
                    Прикрепить
                </Button>
                <Button
                    appearance="neutral"
                    mode="secondary"
                    size="m"
                    className={styles.button}
                    onClick={onCancelClick}
                    data-qa-id="cancel-button"
                >
                    Отменить
                </Button>
            </ButtonGroup>
        </div>
    );
});

IntegrationFooter.displayName = 'IntegrationFooter';
