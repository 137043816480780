import { Icon16ArrowLeftOutline as BackIcon } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    ACTION_PROMO,
    ENABLE_FULL_RESPONSIVE,
    IS_BLOCKED,
    IS_CHOSEN_PUBLIC_FILE,
    IS_FROZEN,
    IS_MY_TEAM,
    IS_PUBLIC_FOLDER,
    PUBLIC_NOT_AVAILABLE,
    PUBLIC_NOT_EXISTS,
} from 'reactApp/appHelpers/configHelpers';
import { isDarkTheme, isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { isViewContentOnly } from 'reactApp/appHelpers/settingsHelpers';
import { BREAKPOINT_MD } from 'reactApp/constants/breakpoints';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { useWindowSize } from 'reactApp/hooks/useWindowSize';
import { ActionPanelSelectors } from 'reactApp/modules/actionpanel/actionpanel.selectors';
import { getAllDocumentsCurrentType } from 'reactApp/modules/allDocuments/allDocuments.selectors';
import { EbookTheme } from 'reactApp/modules/ebook/ebook.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getCurrentStorage, isSubscriptionPage } from 'reactApp/modules/router/router.selectors';
import { getSearchEmptyFilters } from 'reactApp/modules/search/search.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EHeaderColor, HeaderWrapper } from 'reactApp/ui/Header/HeaderWrapper';
import { LeftSide } from 'reactApp/ui/Header/LeftSide';
import { Logo } from 'reactApp/ui/Header/Logo/Logo';
import { RightSide } from 'reactApp/ui/Header/RightSide';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';
import { ViewerShareButton } from 'reactApp/ui/ViewerShareButton/ViewerShareButton';

import styles from './Header.css';

interface Props {
    openedFileId?: string;

    onBackClick?(): void;

    onBookmark?(isActiveBookmark: boolean): void;

    isEbookReader?: boolean;
    theme?: EbookTheme;
}

// eslint-disable-next-line complexity
export const Header = memo(({ openedFileId, onBackClick, isEbookReader, theme, onBookmark }: Props) => {
    const storage = useSelector(getCurrentStorage);
    const {
        isHome,
        isSearch,
        isAttaches,
        isGallery,
        isTrashBin,
        isAllDocuments,
        isPdfEdit,
        isIntegration,
        isQuotaCleaner,
        isSubscription,
        isFamilyPage,
    } = getStorage(storage);
    const hideRightSide = !useSelector(getAllDocumentsCurrentType) && isAllDocuments;
    const { windowWidth } = useWindowSize();
    const isPanelOpened = useSelector(ActionPanelSelectors.isActionPanelOpened);
    const actionPanelWidth = isPanelOpened ? 380 : 0;
    const isResponsiveEnable = ENABLE_FULL_RESPONSIVE && windowWidth - actionPanelWidth <= BREAKPOINT_MD;

    const hideLogo = ENABLE_FULL_RESPONSIVE;

    const emptyFilters = useSelector(getSearchEmptyFilters);
    const queryParams = useSelector(SettingsSelectors.getQueryParams);
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const hideHeader =
        (isPhone &&
            (isSubscriptionPage() ||
                isHome ||
                IS_PUBLIC_FOLDER ||
                isAttaches ||
                isGallery ||
                isTrashBin ||
                isSearch ||
                isViewContentOnly)) ||
        isQuotaCleaner ||
        (isNewPortalHeader.client && !isPdfEdit && !isEbookReader && !(IS_CHOSEN_PUBLIC_FILE && isPhone)) ||
        isFamilyPage;

    const hasSearchQuery = Boolean(queryParams?.query);
    const searchOnlyExpanded = isSearch || (isHome && emptyFilters) || (isHome && hasSearchQuery) || (isAttaches && hasSearchQuery);
    const [isSearchExpanded, setSearchExpanded] = useState<boolean>(searchOnlyExpanded);

    let color = isPhone ? EHeaderColor.BLUE : EHeaderColor.WHITE;

    if (isEbookReader) {
        if (theme === EbookTheme.CEPIA) {
            color = EHeaderColor.CEPIA;
        } else if (theme === EbookTheme.DARK) {
            color = EHeaderColor.DARK;
        }
    }

    if ((Boolean(ACTION_PROMO) && isSubscription) || isDarkTheme) {
        color = EHeaderColor.DARK;
    }

    const toggleSearch = useCallback((toggleState: boolean) => {
        setSearchExpanded(toggleState);
    }, []);

    useEffect(() => {
        if (!searchOnlyExpanded) {
            setSearchExpanded(false);
        }
    }, [searchOnlyExpanded, storage]);

    const content = useMemo(() => {
        if (IS_FROZEN || IS_BLOCKED || PUBLIC_NOT_EXISTS || PUBLIC_NOT_AVAILABLE || isIntegration) {
            return <Logo />;
        }

        if (isPhone && IS_CHOSEN_PUBLIC_FILE) {
            return (
                <>
                    <Logo />
                    {IS_MY_TEAM && (
                        <div
                            className={styles.mobile_button_back}
                            onClick={() => {
                                location.href = ROOT_FOLDER_ID;
                            }}
                        >
                            <BackIcon width={16} height={20} fill="currentColor" />
                        </div>
                    )}
                    <ViewerShareButton basic placement={tooltipPlacements.BOTTOM_RIGHT} />
                </>
            );
        }

        return (
            <>
                <LeftSide
                    storage={storage}
                    toggleSearch={toggleSearch}
                    isSearchExpanded={isSearchExpanded}
                    isResponsiveEnable={isResponsiveEnable}
                    hideLogo={hideLogo}
                    openedFileId={openedFileId}
                    onBackClick={onBackClick}
                    color={color}
                />
                {!hideRightSide && (
                    <RightSide
                        storage={storage}
                        toggleSearch={toggleSearch}
                        isSearchExpanded={isSearchExpanded}
                        searchOnlyExpanded={searchOnlyExpanded}
                        isResponsiveEnable={isResponsiveEnable}
                        isEbookReader={isEbookReader}
                        onBookmark={onBookmark}
                        color={color}
                    />
                )}
            </>
        );
    }, [
        isIntegration,
        isPhone,
        storage,
        toggleSearch,
        isSearchExpanded,
        isResponsiveEnable,
        hideLogo,
        openedFileId,
        onBackClick,
        color,
        hideRightSide,
        searchOnlyExpanded,
        isEbookReader,
        onBookmark,
    ]);

    if (hideHeader) {
        return null;
    }

    return (
        <HeaderWrapper className={classNames({ [styles.withShadow]: !isPdfEdit && !isIntegration })} color={color} isPhone={isPhone}>
            {content}
        </HeaderWrapper>
    );
});

Header.displayName = 'Header';
