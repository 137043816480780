import classNames from 'clsx';
import React, { ReactElement, useCallback, useState } from 'react';
import { ENABLE_FULL_RESPONSIVE, IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { getNameById } from 'reactApp/modules/file/utils';

import styles from './SharingNewUnMount.css';
import { EAction, IProps } from './SharingNewUnMount.types';

const buttons = IS_BIZ_USER ? [EButtonType.accept, EButtonType.cancel] : [EButtonType.accept, EButtonType.action, EButtonType.cancel];

export const SharingNewUnMount = React.memo(function SharingNewUnMount(props: IProps): ReactElement {
    const { info, onClose, onUnmount } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const folderName = info.name || getNameById(info.home || '');

    const onConfirm = useCallback(() => {
        setLoading(true);
        onUnmount(IS_BIZ_USER ? EAction.REMOVE : EAction.UNMOUNT);
    }, [onUnmount]);

    const onRemoveItem = useCallback(() => {
        setLoading(true);
        onUnmount(EAction.REMOVE);
    }, [onUnmount]);

    const renderHeader = useCallback(() => <div>Отказ от доступа</div>, []);

    const renderContent = useCallback(() => {
        const baseTextEl = (
            <>
                Вы действительно хотите отказаться от доступа {info.access === 'read_only' ? '(только просмотр)' : '(редактирование)'} к
                папке «{folderName}» пользователя «{info.owner.email}»?
            </>
        );
        return (
            <div>{IS_BIZ_USER ? <>{baseTextEl} В случае отказа от доступа, папка будет недоступна в вашем хранилище.</> : baseTextEl}</div>
        );
    }, [info.access, folderName, info.owner.email]);

    return (
        <BaseConfirmDialog
            renderHeader={renderHeader}
            actionButtonText={IS_BIZ_USER ? undefined : 'Отказаться и удалить папку'}
            successButtonText="Отказаться"
            cancelButtonText="Отменить"
            buttons={buttons}
            onClose={onClose}
            onSuccess={onConfirm}
            onAction={IS_BIZ_USER ? undefined : onRemoveItem}
            renderContent={renderContent}
            className={classNames(styles.root, {
                [styles.root_responsive]: ENABLE_FULL_RESPONSIVE,
            })}
            loading={loading}
        />
    );
});
