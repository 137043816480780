/* eslint-disable max-lines-per-function */
import classNames from 'clsx';
import { ReactComponent as ProIcon } from 'img/pro-label.svg';
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { useDispatch, useSelector } from 'react-redux';
import { isAbNewSharingFolder } from 'reactApp/appHelpers/featuresHelpers';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { SharingDropdown } from 'reactApp/components/SharingWindow/Dropdown/SharingDropdown';
import { EDropdownColors } from 'reactApp/components/SharingWindow/Dropdown/SharingDropdown.types';
import { AUTODELETE_OPTIONS, QA_VALUE } from 'reactApp/components/SharingWindow/Sharing.constants';
import { AutoDeletePeriod } from 'reactApp/components/SharingWindow/Sharing.types';
import { useConfirmDialogContent } from 'reactApp/components/SharingWindow/SharingNew/Weblink/AutoDelete/useConfirmDialogContent';
import styles from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.css';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureAbAutoDelete } from 'reactApp/modules/features/features.selectors';
import { updateWeblinkAutoDeleteRequest } from 'reactApp/modules/modifying/modifying.actions';
import { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { Dropdown } from 'reactApp/ui/Dropdown/Dropdown';
import { DropdownMenu } from 'reactApp/ui/Dropdown/DropdownMenu';
import { PromoProTooltip } from 'reactApp/ui/PromoProTooltip/PromoProTooltip';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';
import { formatAutoDeleteExpires } from 'reactApp/utils/formatDate';
import { sendGa } from 'reactApp/utils/ga';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

const defaultOption = AUTODELETE_OPTIONS[AutoDeletePeriod.None];
const proTooltipId = 'autodelete-pro-tooltip';

const CONFIRM_POPUP_LABEL = 'confirm-popup';

const BUTTONS = [EButtonType.accept, EButtonType.cancel];

interface IProps {
    item: PublishItem;
    disabled?: boolean;
    gaSuffix?: string;
}

export const AutoDelete = memo(({ item, disabled, gaSuffix }: IProps): ReactElement | null => {
    const dispatch = useDispatch();
    const expires = 'weblinkExpires' in item && item.weblinkExpires;
    const isAutoDelete = 'weblinkAutoDelete' in item && item.weblinkAutoDelete;
    const folderName = item.name;
    const tariffsType = useSelector(getFeatureAbAutoDelete)?.data;
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const proRef = useRef<HTMLDivElement>(null);

    /* tempexp_15752-next-line */
    const abSharing = isAbNewSharingFolder && !isPhone;

    const isProTooltipShown = Boolean(dispatch(UserStorageActions.get(proTooltipId)));

    const [showProTooltip, setShowProTooltip] = useState<boolean>(false);
    const [showConfirmAutoDelete, setShowConfirmAutoDelete] = useState<boolean>(false);
    const [autoDeleteExpires, setAutoDeleteExpires] = useState<number>(0);

    const { renderDialogContent, renderDialogHeader } = useConfirmDialogContent({
        name: folderName,
        expires: autoDeleteExpires,
    });

    const sendAutoDeleteGa = useCallback(
        (action, label) => {
            sendGa('autodelete', action, label);

            if (gaSuffix) {
                sendGa(`autodelete-${gaSuffix}`, action, label);
            }
        },
        [gaSuffix]
    );

    const openProTooltip = useCallback(() => {
        if (!disabled || isPhone) {
            return;
        }

        setShowProTooltip(true);
        if (!isProTooltipShown) {
            dispatch(UserStorageActions.set(proTooltipId, new Date().valueOf()));
        }
    }, [disabled, isPhone, isProTooltipShown, dispatch]);

    const closeProTooltip = useCallback(() => {
        setShowProTooltip(false);
    }, []);

    useEffect(() => {
        sendAutoDeleteGa('show', disabled ? 'disabled' : '');
    }, []);

    useEffect(() => {
        if (!isProTooltipShown && disabled) {
            // setTimeout чтобы тултип располагался правильно после анимации
            setTimeout(() => openProTooltip(), 300);
        }
    }, [isProTooltipShown, openProTooltip, disabled]);

    const renderAutoDeleteDropdown = useCallback(
        (onClick): ReactElement => {
            const list = Object.values(AUTODELETE_OPTIONS).map((item) => ({
                id: item.id,
                text: item.text,
                check: !isAutoDelete && item.id === AutoDeletePeriod.None,
                qaValue: item.id,
            }));

            if (expires && isAutoDelete) {
                list.unshift({
                    text: `Удалить ${formatAutoDeleteExpires(expires)}`,
                    id: AutoDeletePeriod.Autodeleting,
                    check: true,
                    qaValue: AutoDeletePeriod.Autodeleting,
                });
            }

            return <DropdownMenu list={list} theme="octavius" onClick={onClick} className={styles.dropdownMenu} />;
        },
        [expires, isAutoDelete]
    );

    const handleUpdateAutoDelete = useCallback(
        (autoDeletePeriod: AutoDeletePeriod): void => {
            sendAutoDeleteGa('dropdown-click', autoDeletePeriod);

            if (autoDeletePeriod === AutoDeletePeriod.Autodeleting) {
                return;
            }

            sendPaymentGa({
                eventCategory: ECategoryGa.public,
                action: 'edit-autodelete',
                time_autodelete: autoDeletePeriod,
                public_id: item?.weblink,
            });

            if (autoDeletePeriod === AutoDeletePeriod.None) {
                dispatch(updateWeblinkAutoDeleteRequest({ item, expires: 0 }));
                return;
            }

            const expires = AUTODELETE_OPTIONS[autoDeletePeriod]?.getValue();
            setAutoDeleteExpires(expires);
            setShowConfirmAutoDelete(true);
            sendAutoDeleteGa('show', CONFIRM_POPUP_LABEL);
        },
        [dispatch, item]
    );

    const handleCloseConfirmAutoDelete = useCallback(() => {
        sendAutoDeleteGa('close', CONFIRM_POPUP_LABEL);
        setShowConfirmAutoDelete(false);
        setAutoDeleteExpires(0);
    }, []);

    const updateAutoDeleteExpires = useCallback(() => {
        sendAutoDeleteGa('success', CONFIRM_POPUP_LABEL);
        dispatch(updateWeblinkAutoDeleteRequest({ item, expires: autoDeleteExpires }));
        handleCloseConfirmAutoDelete();
    }, [autoDeleteExpires, dispatch, handleCloseConfirmAutoDelete, item]);

    const dropdownValue = useMemo(() => {
        return expires && isAutoDelete ? `будут удалены ${formatAutoDeleteExpires(expires)}` : defaultOption?.text;
    }, [expires, isAutoDelete]);

    return (
        <div
            className={classNames(styles.rightItem, {
                [styles.rightItem_disabled]: disabled,
            })}
            data-qa-item={QA_VALUE.autoDeleteItem}
            onClick={openProTooltip}
        >
            <span className={styles.rightTitle}>Файлы из папки</span>
            {/* tempexp_15752-next-line */}
            <div className={styles.dropdown} ref={abSharing ? proRef : undefined}>
                <Dropdown
                    withinDialog
                    theme="octavius"
                    qaValue={defaultOption.id}
                    as={SharingDropdown}
                    color={isAutoDelete ? EDropdownColors.RED : EDropdownColors.BLUE}
                    value={isPhone ? dropdownValue : dropdownValue.toLowerCase()}
                    content={renderAutoDeleteDropdown}
                    onClickItem={handleUpdateAutoDelete}
                    placement={tooltipPlacements.BOTTOM_LEFT}
                    qaValueContent={QA_VALUE.autoDeleteDropdownContent}
                    disabled={disabled}
                    animatedTooltip
                    tooltipClassName={isPhone ? styles.tooltip : undefined}
                    isPhone={isPhone}
                />
            </div>
            {
                /* tempexp_15752-start */
                !abSharing && (
                    <div className={styles.rightIcon} ref={proRef} onMouseEnter={openProTooltip}>
                        {tariffsType === 'paid' ? <div className={styles.paidLabel}>По подписке</div> : <ProIcon height={21} width={39} />}
                    </div>
                )
                /* tempexp_15752-end */
            }
            {showProTooltip && (
                <Portal>
                    <PromoProTooltip target={proRef} onClose={closeProTooltip} gaLabel="autodelete-tooltip" tariffsType={tariffsType} />
                </Portal>
            )}
            {showConfirmAutoDelete && (
                <BaseConfirmDialog
                    successButtonText="Включить автоудаление"
                    cancelButtonText="Не включать"
                    buttons={BUTTONS}
                    onClose={handleCloseConfirmAutoDelete}
                    onSuccess={updateAutoDeleteExpires}
                    renderContent={renderDialogContent}
                    renderHeader={renderDialogHeader}
                />
            )}
        </div>
    );
});

AutoDelete.displayName = 'AutoDelete';
