import { ReactComponent as UploadFileIcon } from 'img/icons/uploader/upload_file.svg';
import { ReactComponent as UploadFolderIcon } from 'img/icons/uploader/upload_folder.svg';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { CloudIcon } from 'reactApp/ui/VKUIIcons';

export const useUploadButtons = ({ onFolderClick, onFileClick, onSelectFromCloud }) => {
    const storage = useSelector(getCurrentStorage);

    const UPLOAD_OPTIONS = {
        selectFromCloud: {
            id: 'selectFromCloud',
            text: 'Выбрать из Облака',
            icon: <CloudIcon width="20" height="20" />,
            onClick: onSelectFromCloud,
        },
        uploadFolder: {
            id: 'uploadFolder',
            text: 'Загрузить папку',
            icon: <UploadFolderIcon width="16" height="16" />,
            onClick: onFolderClick,
        },
        uploadFiles: {
            id: 'uploadFiles',
            text: 'Загрузить файлы',
            icon: <UploadFileIcon width="16" height="16" />,
            onClick: onFileClick,
        },
        uploadFilesToAlbum: {
            id: 'uploadFiles',
            text: 'Загрузить с компьютера',
            icon: <UploadFileIcon width="16" height="16" />,
            onClick: onFileClick,
        },
    };

    if (storage === EStorageType.albums) {
        return [UPLOAD_OPTIONS.uploadFilesToAlbum, UPLOAD_OPTIONS.selectFromCloud];
    }

    if (storage === EStorageType.alldocuments) {
        return [UPLOAD_OPTIONS.uploadFiles];
    }
    return [UPLOAD_OPTIONS.uploadFolder, UPLOAD_OPTIONS.uploadFiles];
};
