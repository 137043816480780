import app from 'Cloud/Application/app';
import { IS_PUBLIC_FOLDER } from 'reactApp/appHelpers/configHelpers';
import { promoMobileAppPublic } from 'reactApp/appHelpers/featuresHelpers';
import { setDownloadEbookClick } from 'reactApp/modules/ebook/ebook.module';
import { isEBook } from 'reactApp/modules/file/utils';
import { showMobileAppPromo } from 'reactApp/modules/promo/promo.module';
import { reDownloadController, ReDownloadEntry } from 'reactApp/modules/requiredAuthorization/reDownloadController';
import { selectOne } from 'reactApp/modules/selections/selections.actions';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { isDownloadByUrl } from 'reactApp/modules/userQuotaCleaner/helpers/isDownloadByUrl';
import { QuotaCleanerItem } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';
import { store as reduxStore } from 'reactApp/store';
import { sendXray } from 'reactApp/utils/ga';

export const downloadItem = ({ itemOrId, useDownloadUrl = false, storage = null, forceDownload = null, fromViewer = false }: any) => {
    const isQuotaCleaner = storage === EStorageType.quotaCleaner;
    const isDownloadUrl = useDownloadUrl || (isQuotaCleaner && isDownloadByUrl(itemOrId as QuotaCleanerItem));

    const isEbookItem = isEBook(itemOrId);

    if (isEbookItem) {
        sendXray(['ebook', 'download', fromViewer ? 'viewer' : 'list', itemOrId?.ext]);

        setTimeout(() => {
            reduxStore.dispatch(setDownloadEbookClick(true));
            reduxStore.dispatch(selectOne({ selectedIdx: itemOrId?.id, storage }));
        }, 1000);
    }

    if (storage === EStorageType.public && promoMobileAppPublic && !IS_PUBLIC_FOLDER) {
        reduxStore.dispatch(showMobileAppPromo({ isDark: true }));
    }

    /* tempexp_15344-next-line */
    reDownloadController.setFutureParams(ReDownloadEntry.AppHelpers, {
        itemOrId,
        useDownloadUrl,
        storage,
        forceDownload,
        fromViewer,
    });

    if (itemOrId.isFolder) {
        app.downloadItems(itemOrId.name, itemOrId, null);
    } else {
        app.download(itemOrId, forceDownload, isDownloadUrl, storage, fromViewer);
    }
};
export const downloadItemsZip = ({ items, name = 'archive', forceDownload = null }) => app.downloadItems(name, items, forceDownload);
export const downloadByUrl = (url, name, storage) => app.downloadItem(url, name, storage);
