import { useEffect } from 'react';
import { VK_UI_DARK_THEME } from 'reactApp/constants';
import { setPortalMenuTheme } from 'reactApp/modules/ph/ph.helpers';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';
import loaderStyles from 'server/components/ContentLoader/ContentLoader.css';
import footerStyles from 'server/components/Footer/Footer.css';
import styles from 'server/components/Page/Page@desktop.css';
import stylesMobile from 'server/components/Page/Page@touch.css';

const setDarkTheme = (keepDarkTheme = false, skipPageDesktopFix = true, skipPageTouchFix = true) => {
    document.body.classList.add(VK_UI_DARK_THEME);

    const page = document.querySelector(`.${styles.root}`);
    const pageMobile = document.querySelector(`.${stylesMobile.root}`);
    const loader = document.querySelector(`.${loaderStyles.root}`);
    const footer = document.querySelector(`.${footerStyles.root}`);

    if (!skipPageDesktopFix) {
        if (page && !page.classList.contains(styles.root_theme_dark)) {
            page.classList.add(styles.root_theme_dark);
        }

        if (loader && !loader.classList.contains(loaderStyles.root_theme_dark)) {
            loader.classList.add(loaderStyles.root_theme_dark);
        }

        if (footer && !footer.classList.contains(footerStyles.root_theme_dark)) {
            footer.classList.add(footerStyles.root_theme_dark);
        }
    }

    if (!skipPageTouchFix && pageMobile && !pageMobile.classList.contains(stylesMobile.root_theme_dark)) {
        pageMobile.classList.add(stylesMobile.root_theme_dark);
    }

    setPortalMenuTheme('dark');

    return () => {
        if (keepDarkTheme) {
            return;
        }
        document.body.classList.remove(VK_UI_DARK_THEME);
        page?.classList.remove(styles.root_theme_dark);
        pageMobile?.classList.remove(stylesMobile.root_theme_dark);
        loader?.classList.remove(loaderStyles.root_theme_dark);
        footer?.classList.remove(footerStyles.root_theme_dark);
        setPortalMenuTheme('default');
    };
};

// Этот хух нужен при навигации между страницами с разными темами, чтобы переключать ее в корне.
export const useDarkTheme = (useDark: boolean) => {
    useEffect(() => {
        const containsDarkTheme = isDarkThemeModeEnabled();

        if (!useDark) {
            if (containsDarkTheme) {
                const page = document.querySelector(`.${styles.root_theme_dark}`);
                const pageMobile = document.querySelector(`.${stylesMobile.root_theme_dark}`);
                const loader = document.querySelector(`.${loaderStyles.root}`);
                const footer = document.querySelector(`.${footerStyles.root}`);

                document.body.classList.remove(VK_UI_DARK_THEME);
                page?.classList.remove(styles.root_theme_dark);
                pageMobile?.classList.remove(stylesMobile.root_theme_dark);
                loader?.classList.remove(loaderStyles.root_theme_dark);
                footer?.classList.remove(footerStyles.root_theme_dark);

                return () => {
                    // restore previous state
                    document.body.classList.add(VK_UI_DARK_THEME);
                    page?.classList.add(styles.root_theme_dark);
                    pageMobile?.classList.add(stylesMobile.root_theme_dark);
                    loader?.classList.add(loaderStyles.root_theme_dark);
                    footer?.classList.add(footerStyles.root_theme_dark);
                };
            }
            return;
        }

        return setDarkTheme(containsDarkTheme, false, false);
    }, [useDark]);
};

// Этот ставит всегда темную тему, если параметр тру
export const useGlobalDarkTheme = (useDark: boolean) => {
    useEffect(() => {
        if (!useDark) {
            return;
        }

        return setDarkTheme();
    }, [useDark]);
};
