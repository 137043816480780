import { debounce } from '@vkontakte/vkjs';
import { MYOFFICE_VARIANTS } from 'Cloud/Application/Editor/MyOffice/myOffice.types';
import { xray } from 'lib/xray';
import React, { createRef, PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { downloadItem } from 'reactApp/appHelpers/appDownload';
import { getCurrentState, goTo } from 'reactApp/appHelpers/appHelpers';
import { ENABLE_FULL_RESPONSIVE, IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { isAllDocumentsReturn } from 'reactApp/appHelpers/featuresHelpers';
import { RenamedFileMeta, setRenamedFileIdInsideViewer } from 'reactApp/appHelpers/updateRenamedFileInsideViewer';
import { BREAKPOINT_MD } from 'reactApp/constants/breakpoints';
import { getBackUrl, getEditorItem, isEnableRenamingInsideEditor } from 'reactApp/modules/editor/editor.selectors';
import { EditorItem } from 'reactApp/modules/editor/editor.types';
import { getFeatureMyOfficeEditorPromo, getFeatureWopi } from 'reactApp/modules/features/features.selectors';
import { addToFavorites, removeFromFavorites } from 'reactApp/modules/modifying/modifying.actions';
import { renameRequest } from 'reactApp/modules/modifying/sagas/rename.saga';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUser } from 'reactApp/modules/user/user.thunkActions';
import { EditorHeaderMapState, EditorHeaderProps } from 'reactApp/ui/EditorHeader/EditorHeaderContainer';
import { HeaderLeftGroup } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/components/HeaderLeftGroup/HeaderLeftGroup';
import { HeaderRightGroup } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/components/HeaderRightGroup/HeaderRightGroup';
import { renderSmallWindowWarningDialog } from 'reactApp/ui/SmallWindowWarningDialog/smallWindowWarningDialog.helpers';
import { createGaSender, sendGa } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { isEditingAttach } from 'reactApp/utils/isEditingFile';
import { sendEditRadar } from 'reactApp/utils/sendEditRadar';

import styles from './EditorHeader.css';

const updateWeblinkInUrlAction = (id, weblink): void => {
    const state = getCurrentState();

    if (!state) {
        return;
    }

    const { ...params } = state.params;

    if (weblink) {
        params.weblink = weblink;
    } else {
        delete params.weblink;
    }

    goTo({ id, params });
};

interface State {
    windowWidth?: number;
}

const mapStateToProps = (state, props): EditorHeaderMapState => {
    const { isPublic, itemSelector = getEditorItem } = props;
    const currentStorage = getCurrentStorage(state);

    const isAnonymous = UserSelectors.isAnonymous(state);
    const myOfficePromoEditorEnabled = !!getFeatureMyOfficeEditorPromo(state);
    const file = itemSelector(state);
    const backLink = getBackUrl(state, file, isPublic);

    const isWopiFeatureEnabled = getFeatureWopi(state);

    return {
        backLink,
        updateWeblinkInUrl: updateWeblinkInUrlAction,
        renameItem: renameRequest,
        isAnonymous,
        enableRenaming: isEnableRenamingInsideEditor(state),
        file,
        storage: currentStorage,
        id: file?.id,
        isWopiFeatureEnabled,
        myOfficePromoEditorEnabled,
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadUser: (): void => dispatch(loadUser()),
    showNotify: (options): void => dispatch(showSnackbarAction(options)),
    toggleFavorite: (item: EditorItem): void =>
        dispatch(item.isInFavorites ? removeFromFavorites({ items: [item] }) : addToFavorites({ items: [item], from: 'editor' })),
    setRenamedFileIdInsideViewer: (init: RenamedFileMeta) => dispatch(setRenamedFileIdInsideViewer(init)),
});

export class EditorHeaderComponent extends PureComponent<EditorHeaderProps, State> {
    static defaultProps = {
        onBubbleShow: noop,
        toggleFavorite: noop,
        updateWeblinkInUrl: noop,
        renameItem: noop,
        loadUser: noop,
        showNotify: noop,
        isPublic: false,
        isStock: false,
        enableFavorites: false,
        isAnonymous: false,
        backLink: '',
        file: null,
        id: '',
        onBackToCloud: null,
        gaCategory: '',
        returnButtonTitle: isAllDocumentsReturn ? 'Документы' : 'Вернуться в Облако',
        editorId: undefined,
    };

    public readonly state: State = {
        windowWidth: undefined,
    };

    buttonTextRef = createRef<HTMLDivElement>();

    gaSender = createGaSender(`${this.props.gaCategory}-${this.props.isPublic ? 'public' : 'home'}`);

    componentDidMount(): void {
        const { file, onBubbleShow, showBubble, loadUser, isPublic, updateWeblinkInUrl } = this.props;
        this.gaSender('show');

        xray.send('editor-header-rendered', { i: 'white' });

        if (isPublic) {
            sendGa('public-edit', 'file-type', 'document');
            const ext = file?.ext;
            if (ext) {
                sendGa('public-edit', 'file-extention', ext);
            }
        } else {
            updateWeblinkInUrl(file?.id, file?.weblink);
        }

        if (this.buttonTextRef.current && showBubble) {
            onBubbleShow();
        }

        loadUser();

        if (IS_BIZ_USER && ENABLE_FULL_RESPONSIVE) {
            this.updateDimensions();
            window.addEventListener('resize', debounce(this.updateDimensions, 1500));
            this.renderWarningDialog();
        }
    }

    componentDidUpdate(prevProps: EditorHeaderProps): void {
        const { editorId } = this.props;
        const { windowWidth } = this.state;
        const baseWarningDialogManageCondition =
            IS_BIZ_USER && ENABLE_FULL_RESPONSIVE && editorId && editorId === 'myoffice' && windowWidth;

        if (prevProps.file?.id !== this.props.file?.id || prevProps.file?.weblink !== this.props.file?.weblink) {
            this.props.updateWeblinkInUrl(this.props.file?.id, this.props.file?.weblink);
        }

        /*
            на экране < 1024px интерфейс моего офиса не адаптируется корректно,
            поэтому в респонсив версии показываем попап с предупреждением
        */
        if (baseWarningDialogManageCondition && windowWidth < BREAKPOINT_MD) {
            this.renderWarningDialog();
        }

        //  на экранах > 1024 скрываем попап с предупреждением
        if (baseWarningDialogManageCondition && windowWidth >= BREAKPOINT_MD) {
            this.closeWarningDialog();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    renderWarningDialog = () => {
        renderSmallWindowWarningDialog();
    };

    closeWarningDialog = () => {
        closePopupHelper(popupNames.SMALL_WINDOW_WARNING_DIALOG);
    };

    updateDimensions = () => {
        this.setState({
            windowWidth: window.innerWidth,
        });
    };

    // Отличается от gaSender, т.к. отправляют другие продуктовые метрики
    sendEditorRadar = (i: string) => {
        const { storage, file, isWopiFeatureEnabled, backLink } = this.props;

        if (file && storage) {
            sendEditRadar({
                i,
                storage,
                item: file,
                version: isWopiFeatureEnabled ? MYOFFICE_VARIANTS.wopi : MYOFFICE_VARIANTS.amr,
                isAttach: isEditingAttach(storage),
                back: i === 'back' ? backLink : undefined,
            });
        }
    };

    handleFileNameEdit = ({ value }: { value: string }): Promise<string | void> => {
        const { showNotify, file, renameItem, setRenamedFileIdInsideViewer } = this.props;

        const trimedValue = value.trim();

        if (trimedValue === file?.nameWithoutExt) {
            return Promise.resolve();
        }

        if (!trimedValue) {
            showNotify({
                type: SnackbarTypes.failure,
                text: 'Название не может быть пустым',
                id: 'rename',
                closable: true,
            });
            return Promise.reject();
        }

        const newName = `${trimedValue}.${file?.ext}`;

        return renameItem(file, newName)
            .then(() => {
                this.gaSender('rename', 'success');

                this.sendEditorRadar('rename');

                /**
                 * При переименовании файла из редактора МойОфис необходимо обновить вкладку просмотрщика.
                 * Отслеживание изменений происходит в localstorage.
                 */
                if (file?.home && file?.name) {
                    setRenamedFileIdInsideViewer({ newId: file.home.split(file.name).join(newName), outdateId: file.home });
                }

                goTo({ id: this.props.file?.id });
                showNotify({
                    id: 'document-rename-success',
                    type: SnackbarTypes.success,
                    text: 'Файл успешно переименован',
                    closable: true,
                });
            })
            .catch((error) => {
                this.gaSender('rename', 'error');
                showNotify({
                    type: SnackbarTypes.failure,
                    text: `Не удалось переименовать файл: ${error}`,
                    id: 'document-rename-error',
                    closable: true,
                    bigSnackbar: true,
                });
                return Promise.reject();
            });
    };

    handleDownload = (): void => {
        const { file } = this.props;

        this.gaSender('download', 'click');

        downloadItem({ itemOrId: file });
    };

    render(): ReactElement {
        const { licenseExpired = false } = this.props;

        return (
            <>
                <div className={styles.root}>
                    <HeaderLeftGroup
                        gaSender={this.gaSender}
                        handleFileNameEdit={this.handleFileNameEdit}
                        sendEditorRadar={this.sendEditorRadar}
                        {...this.props}
                    />
                    <HeaderRightGroup gaSender={this.gaSender} sendEditorRadar={this.sendEditorRadar} {...this.props} />
                </div>
                {licenseExpired && (
                    <div className={styles.license}>
                        <div>Количество лицензий закончилось</div>
                    </div>
                )}
            </>
        );
    }
}

export const EditorHeader = connect(mapStateToProps, mapDispatchToProps)(EditorHeaderComponent);
