import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { path, pathOr, reduce } from 'ramda';
import { ReceivedGiftsFetch, ReceivedGiftsUpdate } from 'reactApp/modules/giftReceived/giftReceived.constants';

const INITIAL_STATE = {
    list: {},
    rawList: [],
};

export const giftReceivedReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReceivedGiftsFetch.SUCCESS:
        case ReceivedGiftsUpdate.SUCCESS: {
            const today = Date.now();

            return {
                ...state,
                rawList: action.list,
                list: reduce(
                    (acc, gift) => {
                        const discount = pathOr(path(['giftible', 'services', 'unlocked'], gift), ['services', 'unlocked'], gift);
                        const productId = path(['web'], discount);

                        const start = gift.start_at * 1000;

                        if (start && today < start) {
                            return acc;
                        }

                        return {
                            ...acc,
                            [gift.code]: {
                                expires: gift.expire_at * 1000,
                                id: gift.gift_id,
                                code: gift.code,
                                start,
                                created: gift.created * 1000,
                                productId,
                                discount,
                                space: bytesToNDigits(path(['services', 'quota'], gift), 3),
                            },
                        };
                    },
                    {},
                    action.list
                ),
            };
        }

        default:
            return state;
    }
};
