import classNames from 'clsx';
import throttle from 'lodash.throttle';
import React, { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';
import { IS_BIZ_USER, IS_MY_TEAM, IS_PHONE_BROWSER, USER_EMAIL } from 'reactApp/appHelpers/configHelpers';
import { ActionPanelSelectors } from 'reactApp/modules/actionpanel/actionpanel.selectors';
import { addItemsFromCloudToAlbumRequest } from 'reactApp/modules/albums/albums.actions';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { chooseVariant } from 'reactApp/modules/features/features.helpers';
import { getFeatureHideCreateUploadInRoFolders, getFeatureNewEmptyStatesTouch } from 'reactApp/modules/features/features.selectors';
import { getDomainFoldersFilterActive, isReadOnly } from 'reactApp/modules/home/home.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorageCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { TUploadTypeClick } from 'reactApp/modules/upload/upload.model';
import { setButtonTypeClickAction, setShowInputAction, setUploadFromAction } from 'reactApp/modules/upload/upload.module';
import { getAllowedMimeTypes, getShowInput, isUploaderVisible } from 'reactApp/modules/upload/upload.selectors';
import { showUploadButton } from 'reactApp/modules/uploadList/uploadList.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { B2BUploadButton } from 'reactApp/ui/UploadDropArea/B2BUploadButton/B2BUploadButton';
import { UploadButton } from 'reactApp/ui/UploadDropArea/Upload/Upload';
import { UploadInput } from 'reactApp/ui/UploadInput/UploadInput';
import { AddIcon } from 'reactApp/ui/VKUIIcons';
import { sendDatalistGa } from 'reactApp/utils/datalistGa';
/* tempexp_14812-next-line */
import { sendEmptyStatesAnalitics } from 'reactApp/utils/emptyStatesGa';
import { createGaSender } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { sendNewUserGa } from 'reactApp/utils/newUserGa';

import { ReactComponent as UploadIcon } from '~img/cloud_upload.svg';

import styles from './UploadDropArea.css';

let isOpen = false;

const Circle = () => (
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="50" fill="currentColor" />
    </svg>
);

export const getOpenState = () => isOpen;

const mapStateToProps = (state) => {
    const isMobile = EnvironmentSelectors.isMobile();
    const showUpload = showUploadButton(state);
    const allowedMimeTypes = getAllowedMimeTypes(state)?.join(',');
    const storage = getCurrentStorage(state);
    const domainFoldersFilterActive = getDomainFoldersFilterActive(state);
    const folder = getStorageCurrentFolder(state, storage as EStorageType);
    const isReadOnlyFolder = isReadOnly(state, folder?.id);
    const hideCreateUploadInRoFolders = getFeatureHideCreateUploadInRoFolders(state);
    const isActionPanelOpened = ActionPanelSelectors.isActionPanelOpened(state);

    return {
        isVisible: isUploaderVisible(state),
        showInput: getShowInput(state),
        isMobile,
        isNewUser: UserSelectors.isNewUser(state),
        showUpload,
        allowedMimeTypes,
        storage,
        domainFoldersFilterActive,
        isReadOnlyFolder,
        hideCreateUploadInRoFolders,
        isActionPanelOpened,
    };
};

const mapDispatchToProps = {
    setShowInput: setShowInputAction,
    setButtonTypeClick: setButtonTypeClickAction,
    setUploadFrom: setUploadFromAction,
    addItemsFromCloudToAlbum: addItemsFromCloudToAlbumRequest,
};

export interface IUploadDropAreaProps {
    forceOpen?: boolean;
    inline?: boolean;
    email?: string;
    gaCategory: string;
    handleRef?: (data) => void;
    simple?: boolean;
    isPublic?: boolean;
    isAuthorized?: boolean;
    showDropArea?: boolean;
    onAuthorize?: () => void;

    subscribeToInput?(input);

    unsubscribeOfInput?(input);

    processDrop?(dataTransfer: DataTransfer);

    containerElement?: HTMLDivElement;
    isOwnerInViewMode?: boolean;
}

type IProps = IUploadDropAreaProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

interface State {
    isOpen: boolean;
    isDrag: boolean;
    uploadFolder: boolean;
}

export class UploadDropAreaComponent extends PureComponent<IProps, State> {
    public static defaultProps: Partial<IProps> = {
        isVisible: true,
        handleRef: noop,
        forceOpen: false,
        isMobile: false,
        inline: false,
        email: USER_EMAIL,
        gaCategory: 'upload-accent',
        simple: false,
        isAuthorized: true,
        onAuthorize: noop,
        isNewUser: false,
        showUpload: false,
        showInput: false,
        storage: EStorageType.home,
        showDropArea: true,
    };

    state = {
        isOpen: false,
        isDrag: false,
        uploadFolder: false,
    };

    input: any = null;

    uploaderRef = createRef<HTMLDivElement>();

    sendGa = createGaSender(this.props.gaCategory);

    componentDidMount() {
        const { handleRef, subscribeToInput } = this.props;

        handleRef?.(this.uploaderRef);

        this.sendGa('show', 'default');

        document.addEventListener('dragover', this.onDrag, true);
        document.addEventListener('dragenter', this.onDrag);
        document.addEventListener('dragleave', this.onDrag);
        document.addEventListener('drop', this.onDrop);

        if (this.input) {
            subscribeToInput?.(this.input);
        }
    }

    componentDidUpdate(prevProps: IProps) {
        const { isVisible, showInput, setShowInput, subscribeToInput, unsubscribeOfInput } = this.props;

        if (!prevProps.isVisible && prevProps.isVisible !== isVisible && this.input) {
            subscribeToInput?.(this.input);
        }

        if (prevProps.showInput !== showInput) {
            if (showInput) {
                if (this.input) {
                    subscribeToInput?.(this.input);
                }

                this.setState({ uploadFolder: false }, () => {
                    this.handleOnClick();
                    setShowInput(false);
                });
            } else {
                unsubscribeOfInput?.(this.input);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('dragover', this.onDrag, true);
        document.removeEventListener('dragenter', this.onDrag);
        document.removeEventListener('dragleave', this.onDrag);
        document.removeEventListener('drop', this.onDrop);

        if (this.input) {
            this.props.unsubscribeOfInput?.(this.input);
        }
    }

    onDrop = (e) => {
        if (!e.dataTransfer || !e.dataTransfer.types || !e.dataTransfer.types.includes('Files')) {
            return;
        }

        e.preventDefault();

        this.throttledOpenState.cancel();

        this.props.processDrop?.(e.dataTransfer);

        this.setState({
            isDrag: false,
        });
        this.close();
        this.sendGa('drop', !isOpen ? 'opened' : 'default');
    };

    // eslint-disable-next-line unicorn/consistent-function-scoping
    onDrag = (e) => {
        if (!e.dataTransfer || !e.dataTransfer.types || !e.dataTransfer.types.includes('Files')) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();

        if (!this.props.isVisible) {
            return;
        }

        if (e.type === 'dragleave') {
            this.throttledOpenState(false);
        } else {
            e.dataTransfer.dropEffect = 'copy';

            this.throttledOpenState(true);
        }
    };

    // eslint-disable-next-line unicorn/consistent-function-scoping
    throttledOpenState = throttle((isDrag) => {
        if (isDrag) {
            this.open();
        } else {
            this.close();
        }
        this.setState({
            isDrag,
        });
    }, 100);

    handleOnClick = () => {
        const { isAuthorized, onAuthorize, setUploadFrom } = this.props;
        sendDatalistGa('upload');

        /* tempexp_14812-next-line */
        sendEmptyStatesAnalitics({ action: 'download' });

        if (this.props.isNewUser) {
            sendNewUserGa('upload', 'drop-area');
        }

        this.sendGa('click', !isOpen ? 'opened' : 'default');

        setUploadFrom('upload-area');

        if (!isAuthorized) {
            return onAuthorize && onAuthorize();
        }

        if (this.input) {
            this.input.click();
        }
    };

    setButtonType = (type: TUploadTypeClick) => {
        const { setButtonTypeClick } = this.props;

        setButtonTypeClick(type);
    };

    onFolderClick = (e) => {
        e.stopPropagation();

        this.setButtonType('folder');

        this.setState({ uploadFolder: true }, () => {
            this.handleOnClick();
        });
    };

    onFileClick = (e) => {
        e.stopPropagation();

        this.setButtonType('file');

        this.setState({ uploadFolder: false }, () => {
            this.handleOnClick();
        });
    };

    onSelectFromCloud = (e) => {
        e.stopPropagation();

        this.props.addItemsFromCloudToAlbum();
    };

    onAreaClick = (e) => {
        e.stopPropagation();

        this.setButtonType('area');

        this.setState({ uploadFolder: false }, () => {
            this.handleOnClick();
        });
    };

    open = () => {
        isOpen = true;
        this.setState({ isOpen: true });
    };

    close = () => {
        isOpen = false;
        this.setState({ isOpen: false });
    };

    getSimpleUpload = () => {
        const { isVisible, allowedMimeTypes, showInput } = this.props;

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.root_hide]: !isVisible,
                    [styles.root_simple]: true,
                })}
                ref={this.uploaderRef}
            >
                <div className={styles.clickArea} onClick={this.onFileClick} />
                {(isVisible || showInput) && (
                    <input
                        type="file"
                        accept={allowedMimeTypes}
                        multiple
                        className={styles.input}
                        ref={(node) => {
                            this.input = node;
                        }}
                    />
                )}
                <UploadIcon width={21} height={21} className={styles.upload} />
            </div>
        );
    };

    getB2BUpload = () => {
        const { storage, showUpload, isActionPanelOpened, isVisible, showInput, isOwnerInViewMode } = this.props;

        return (
            <>
                {(isVisible || showInput) && <input {...this.getInputProps()} />}
                {isOwnerInViewMode && <UploadInput />}
                {showUpload && <B2BUploadButton {...{ storage, isActionPanelOpened }} />}
            </>
        );
    };

    getIcon = () => {
        const { forceOpen, showUpload, storage } = this.props;
        const { isOpen: shouldOpen } = this.state;

        const isOpen = (shouldOpen && !IS_PHONE_BROWSER) || forceOpen;
        let size = 21;

        if (isOpen && showUpload) {
            size = 44;
        }

        if (storage === EStorageType.albums && !isOpen) {
            return <AddIcon width={size} height={size} className={styles.upload} />;
        }

        return <UploadIcon width={size} height={size} className={styles.upload} />;
    };

    getText = () => {
        const { isMobile, storage } = this.props;

        if (storage === EStorageType.albums) {
            return 'Нажмите на\u00A0кнопки или перенесите фото для быстрой загрузки в\u00A0альбом';
        }

        if (isMobile) {
            return 'Нажмите на\u00A0эту кнопку для быстрой загрузки файлов в\u00A0Облако';
        }

        return 'Нажмите на кнопки или перенесите файлы на экран Облака для быстрой загрузки';
    };

    getTitle = () => {
        const { storage } = this.props;

        if (storage === EStorageType.albums) {
            return 'Добавить в альбом';
        }

        return 'Загрузить';
    };

    getCircle = (style) => (
        <div className={style}>
            <Circle />
        </div>
    );

    getInputProps = () => {
        const { allowedMimeTypes } = this.props;
        const { uploadFolder } = this.state;
        const inputProps = {
            type: 'file',
            multiple: true,
            className: styles.input,
            accept: allowedMimeTypes,
            ref: (node) => {
                this.input = node;
            },
        };

        if (uploadFolder) {
            // @ts-ignore
            inputProps.webkitdirectory = `${uploadFolder}`;
        }

        return inputProps;
    };

    render() {
        const {
            isVisible,
            forceOpen,
            inline,
            simple,
            showUpload,
            showInput,
            isPublic = false,
            storage,
            domainFoldersFilterActive,
            showDropArea = true,
            isReadOnlyFolder,
            hideCreateUploadInRoFolders,
            isActionPanelOpened,
        } = this.props;
        const { isOpen: shouldOpen, isDrag } = this.state;

        const isOpen = Boolean((shouldOpen && !IS_PHONE_BROWSER && storage !== EStorageType.alldocuments) || forceOpen);
        const icon = this.getIcon();
        const text = this.getText();
        const title = this.getTitle();

        if ((IS_BIZ_USER && isReadOnlyFolder && hideCreateUploadInRoFolders) || domainFoldersFilterActive) {
            return null;
        }

        if (IS_MY_TEAM && !IS_PHONE_BROWSER) {
            return this.getB2BUpload();
        }

        if (simple) {
            return this.getSimpleUpload();
        }

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.root_isDrag]: isDrag,
                    [styles.root_hide]: (!isVisible && !forceOpen) || !showDropArea,
                    [styles.root_inline]: inline,
                    [styles.root_phone]: IS_PHONE_BROWSER,
                    [styles.root_upload]: showUpload,
                    [styles.root_public]: isPublic,
                    [styles.root_album]: storage === EStorageType.albums,
                    [styles.shifted_by_panel]: isActionPanelOpened,
                    /* tempexp_14812-next-line */
                    [styles.new_empty_states]: chooseVariant((state) => getFeatureNewEmptyStatesTouch(state) && IS_PHONE_BROWSER, {
                        control: false,
                        variant1: true,
                    })(),
                })}
            >
                {(isVisible || showInput) && <input {...this.getInputProps()} />}

                {showUpload && (
                    <UploadButton
                        icon={icon}
                        isOpen={isOpen}
                        text={text}
                        title={title}
                        onMouseEnter={this.open}
                        onMouseLeave={this.close}
                        onClick={this.onAreaClick}
                        onFileClick={this.onFileClick}
                        onFolderClick={this.onFolderClick}
                        onSelectFromCloud={this.onSelectFromCloud}
                        isPhone={IS_PHONE_BROWSER}
                    />
                )}
            </div>
        );
    }
}

export const UploadDropArea = connect(mapStateToProps, mapDispatchToProps)(UploadDropAreaComponent);
