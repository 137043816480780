import { BReactComponent } from 'blocks-cloud/b-react-component';
import app from 'Cloud/Application/app';
import { IS_PUBLIC, IS_STOCK } from 'reactApp/appHelpers/configHelpers';
import { EditorHeaderContainer as EditorHeader } from 'reactApp/ui/EditorHeader/EditorHeaderContainer';

export const updateEditorHeaderLicenseExpired = () => {
    const header = document.querySelector('#react-header');

    if (!header) {
        return;
    }

    const file = app.current();

    const reactHeader = new BReactComponent({
        ReactComponent: EditorHeader,
        renderMode: 'replace',
    });

    reactHeader.renderTo(header, {
        props: {
            isPublic: !!IS_PUBLIC,
            isStock: Boolean(IS_STOCK),
            enableFavorites: !IS_PUBLIC && !app.isMountedOrSharedFolder(file).isMounted,
            licenseExpired: true,
        },
    });
};
