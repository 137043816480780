import { SubscriptionBuyAPICall } from 'reactApp/api/billing/subscription/SubscriptionBuyAPICall';
import { SubscriptionBuyTrialAPICall } from 'reactApp/api/billing/subscription/SubscriptionBuyTrialAPICall';
import { SubscriptionQuickAPICall } from 'reactApp/api/billing/subscription/SubscriptionQuickAPICall';
import { isHidePayMethodsMenuTouch } from 'reactApp/appHelpers/featuresHelpers';
import { getBuyApiParams, getMidasPaymentLink, getPaymentDescription, getRequestId } from 'reactApp/modules/billing/billing.helpers';
import { BillingActions } from 'reactApp/modules/billing/billing.module';
import { BuyApiParams } from 'reactApp/modules/billing/billing.types';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { sendXray } from 'reactApp/utils/ga';
import { put } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';

export const subscriptionBuyApiCall = (params: BuyApiParams) => new SubscriptionBuyAPICall().makeRequest(params);
export const subscriptionBuyTrialApiCall = (params: BuyApiParams) => new SubscriptionBuyTrialAPICall().makeRequest(params);
export const subscriptionQuickApiCall = (params: BuyApiParams) => new SubscriptionQuickAPICall().makeRequest(params);

export function* handleBuySubscription(action: ReturnType<typeof BillingActions.buySubscription>) {
    const requestId = getRequestId();

    yield put(BillingActions.startLoading({ requestId }));

    const { id, isMobile, isQuick, xraySource = '', isMidas, description } = action.payload;

    const isMobileStr = isMobile ? 'touch' : '';

    const product = yield* select(ProductsSelectors.getProductById, id);

    const params = {
        ...getBuyApiParams(action.payload),
        product_id: id,
        description: isMidas ? getPaymentDescription(product) : description,
    };

    if (!product) {
        sendXray(['blng', 'buy', 'link', 'no-product', xraySource, isMobileStr]);
        yield put(BillingActions.hasError({ requestId }));
        yield loggerSaga({ error: 'buy_no_product' });
    }

    let link: string;

    try {
        if (product.hasTrial) {
            const { data } = yield* call(subscriptionBuyTrialApiCall, params);
            link = data.url;
        } else if ((isMobile && isHidePayMethodsMenuTouch) || isQuick) {
            const { data } = yield* call(subscriptionQuickApiCall, params);
            link = data.pw;
        } else {
            const { data } = yield* call(subscriptionBuyApiCall, params);
            link = isMidas ? getMidasPaymentLink(data.pw) : data.pw;
        }

        yield put(BillingActions.setLink({ link, requestId }));
        sendXray(['blng', 'buy', 'link', 'success', xraySource, isMobileStr]);
    } catch (error) {
        sendXray(['blng', 'buy', 'link', 'error', xraySource, isMobileStr]);
        yield put(BillingActions.hasError({ requestId }));
        yield loggerSaga({ error });
    }
}
