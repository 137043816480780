import ReactDOM from 'react-dom';
import { PropsStore } from 'reactApp/react-into-toolkit-block-wrapper/PropsStore';
import { dispatch,renderComponentInExternalDom } from 'reactApp/react-into-toolkit-block-wrapper/renderComponentInExternalDom';
import toolkit from 'toolkit/b-toolkit/b-toolkit';

const BToolkit = toolkit['b-toolkit'];

export const BReactComponent = toolkit.create('b-react-component', {
    renderMode: 'replace',
    service: false,
    inner: false,
    deleteRootElement: true,

    ReactComponent: null,
    _propsStore: null,

    _isRendered: false,

    _renderReactComponent(data = {}) {
        if (!document.body.contains(this.el)) {
            throw new Error(`${this.bemName}: this.el must be mounted to the DOM tree!`);
        }

        const props = { ...this.data.props, ...data.props };

        if (!this._isRendered) {
            this._propsStore = new PropsStore(props);

            ReactDOM.render(renderComponentInExternalDom(this.ReactComponent, this._propsStore), this.el);

            this._isRendered = true;
        } else {
            this._propsStore.setProps(props);
        }
    },

    dispatch,

    result: null,

    render(data) {
        let temporaryContainer;
        if (this._isRendered) {
            temporaryContainer = document.createElement('div');
            this._moveChildNodes(this.el, temporaryContainer);
        } else if (typeof fest.app === 'function') {
            // для реакт страниц пока просто пропускаем, шаблоны не подгружены и фест нормально не инициализирован
            this.result = BToolkit.fn.render.apply(this, arguments);
        }

        if (this._isRendered) {
            this._moveChildNodes(temporaryContainer, this.el);
        }

        this._renderReactComponent(data);

        return this.result;
    },

    _moveChildNodes(from, to) {
        Array.prototype.forEach.call(from.children, (element) => {
            to.appendChild(element);
        });
    },

    init() {
        const result = BToolkit.fn.init.apply(this, arguments);

        if (!this.ReactComponent) {
            throw new TypeError(`${this.bemName}: the "ReactComponent" property must be defined!`);
        }

        if (this.el) {
            this._renderReactComponent(this.data);
        }

        return result;
    },

    destroy() {
        if (this.el) {
            ReactDOM.unmountComponentAtNode(this.el);
        }

        this._propsStore = null;
        this._isRendered = false;
        this.result = null;

        this.emit('unmount');

        const el = this.el;
        const res = BToolkit.fn.destroy.apply(this, arguments);
        if (this.deleteRootElement && el && el.parentNode) {
            el.parentNode.removeChild(el);
        }

        return res;
    },
});
