import { AllDocumentsDomainMeta, AllDocumentsMeta } from 'reactApp/modules/allDocuments/allDocuments.constants';
import {
    AllDocumentsItem,
    EAllDocumentsType,
    IAllDocumentsCategory,
    IAllDocumentsState,
} from 'reactApp/modules/allDocuments/allDocuments.types';
import { getFeatureAllDocuments } from 'reactApp/modules/features/features.selectors';
import { getHomeList } from 'reactApp/modules/home/home.selectors';
import { HomeFile } from 'reactApp/modules/home/home.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { RootState } from 'reactApp/store';
import { Url } from 'reactApp/types/Tree';
import { createSelector } from 'reselect';

const getAllDocumentsStore = (state: RootState) => state.allDocuments as IAllDocumentsState;

export const getAllDocumentsCursor = (state: RootState) => getAllDocumentsStore(state).cursor;
export const getAllDocumentsCurrentType = (state: RootState) => getAllDocumentsStore(state).currentDocType;
export const getDocumentsState = createSelector(getAllDocumentsStore, (state) => {
    return {
        isLoaded: state.isLoaded,
        type: state.currentDocType,
        count: state.idxs.length,
        docsSize: state.docsSize,
        currentDocType: state.currentDocType,
    };
});

export const getCurrentAllDocumentsFolder = createSelector(getAllDocumentsStore, (state): IAllDocumentsCategory | undefined => {
    return {
        id: state.currentDocType,
        value: state.currentDocType,
        name: state.currentDocType,
        isLoaded: state.isLoaded,
        isLoading: state.isLoading,
        cursor: state.cursor,
        hasMoreToLoad: state.hasMoreToLoad,
        error: state.error,
        childs: state.idxs,
    } as IAllDocumentsCategory;
});

export const getAllDocumentsItemById = createSelector(
    getHomeList,
    (state, id) => id,
    (list, id): AllDocumentsItem | undefined => {
        const homeItem = list[id] as HomeFile;

        if (!homeItem) {
            return;
        }

        return {
            ...homeItem,
            isReadOnly: homeItem.isReadOnly ?? false,
            url: homeItem.url ?? ({} as Url),
            storage: EStorageType.alldocuments,
        };
    }
);

export const getExtensionsForType = createSelector(
    getFeatureAllDocuments,
    (state, type: EAllDocumentsType | null | undefined) => type,
    (feature, type): string[] => {
        /**
         *  CLOUDWEB-14993
         *  Для корзины список расширений должен быть пустой
         */
        if (window.location.pathname === '/alldocuments/trashbin') {
            return [];
        }
        return feature?.[type || EAllDocumentsType.document] ?? [];
    }
);

export const getAllDocumentsBreadcrumbs = createSelector(getAllDocumentsCurrentType, (documentType) => {
    return [
        {
            name: AllDocumentsMeta[documentType || EAllDocumentsType.document].name,
            id: AllDocumentsMeta[documentType || EAllDocumentsType.document].id,
            kind: 'storage' as const,
            storage: EStorageType.alldocuments,
        },
    ];
});

export const getAllDocumentsDomainBreadcrumbs = createSelector(getAllDocumentsCurrentType, (documentType) => {
    return [
        {
            name: AllDocumentsDomainMeta[documentType || EAllDocumentsType.document].name,
            id: AllDocumentsDomainMeta[documentType || EAllDocumentsType.document].id,
            kind: 'storage' as const,
            storage: EStorageType.alldocuments,
        },
    ];
});
