export type R7Mode = 'edit' | 'view';

export type R7ErrorHandler = ({ data: any }) => void;

export type R7Options = {
    mode?: R7Mode;
    onDocumentReady: () => void;
    onError: R7ErrorHandler;
};

export const enum R7Theme {
    light = '1',
    dark = '2',
}
