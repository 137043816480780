export const SPACE_LIST = {
    mb_100: 104857600,
    gb_1: 1073741824,
    gb_2: 2147483648,
    gb_4: 4294967296,
    gb_8: 8589934592,
    gb_16: 17179869184,
    gb_25: 26843545600,
    gb_32: 34359738368,
    gb_64: 68719476736,
    gb_128: 137438953472,
    gb_256: 274877906944,
    gb_512: 549755813888,
    tb_1: 1099511627776,
    tb_2: 2199023255552,
    tb_4: 4398046511104,
};
