import { addDays, addHours, addMonths, addWeeks, addYears } from 'date-fns';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';

import {
    AccessStatus,
    AutoDeletePeriod,
    DomainAccess,
    DomainAccessOptions,
    DownloadAccess,
    ExpiryPeriod,
    IAutoDeleteOptions,
    IExpiresOptions,
    IGetTitleProps,
} from './SharingNew.types';

export const DOWNLOAD_FILE_OPTIONS = {
    [DownloadAccess.Limited]: {
        id: DownloadAccess.Limited,
        text: 'С ограничением',
    },
    [DownloadAccess.Unlimited]: {
        id: DownloadAccess.Unlimited,
        text: 'Без ограничений',
    },
};

export const ACCESS_FILE_RIGHTS_OPTIONS = {
    [AccessStatus.ReadOnly]: {
        id: AccessStatus.ReadOnly,
        text: 'Просмотр',
    },
    [AccessStatus.Edit]: {
        id: AccessStatus.Edit,
        text: 'Редактирование',
    },
    [AccessStatus.Download]: {
        id: AccessStatus.Download,
        text: 'Скачивание',
    },
};

export const ACCESS_FOLDER_RIGHTS_OPTIONS = {
    [AccessStatus.ReadOnly]: {
        id: AccessStatus.ReadOnly,
        text: 'Просмотр',
    },
    [AccessStatus.Upload]: {
        id: AccessStatus.Upload,
        text: 'Редактирование',
    },
    [AccessStatus.Download]: {
        id: AccessStatus.Download,
        text: 'Скачивание',
    },
};

export const EXPIRES_OPTIONS: Record<string, IExpiresOptions> = {
    [ExpiryPeriod.Unlimited]: {
        id: ExpiryPeriod.Unlimited,
        text: 'Всегда',
        getValue: () => 0,
        timeSize: 0,
    },
    [ExpiryPeriod.Hour]: {
        id: ExpiryPeriod.Hour,
        text: 'Час',
        getValue: (): number => addHours(new Date(), 1).valueOf(),
        timeSize: addHours(new Date(), 1).valueOf() - Date.now(),
    },
    [ExpiryPeriod.Day]: {
        id: ExpiryPeriod.Day,
        text: 'День',
        getValue: (): number => addDays(new Date(), 1).valueOf(),
        timeSize: addDays(new Date(), 1).valueOf() - Date.now(),
    },
    [ExpiryPeriod.Week]: {
        id: ExpiryPeriod.Week,
        text: 'Неделя',
        getValue: (): number => addWeeks(new Date(), 1).valueOf(),
        timeSize: addWeeks(new Date(), 1).valueOf() - Date.now(),
    },
    [ExpiryPeriod.Month]: {
        id: ExpiryPeriod.Month,
        text: 'Месяц',
        getValue: (): number => addMonths(new Date(), 1).valueOf(),
        timeSize: addMonths(new Date(), 1).valueOf() - Date.now(),
    },
    [ExpiryPeriod.Months3]: {
        id: ExpiryPeriod.Months3,
        text: '3 месяца',
        getValue: (): number => addMonths(new Date(), 3).valueOf(),
        timeSize: addMonths(new Date(), 3).valueOf() - Date.now(),
    },
    [ExpiryPeriod.HalfYear]: {
        id: ExpiryPeriod.HalfYear,
        text: 'Полгода',
        getValue: (): number => addMonths(new Date(), 6).valueOf(),
        timeSize: addMonths(new Date(), 6).valueOf() - Date.now(),
    },
    [ExpiryPeriod.Year]: {
        id: ExpiryPeriod.Year,
        text: 'Год',
        getValue: (): number => addYears(new Date(), 1).valueOf(),
        timeSize: addYears(new Date(), 1).valueOf() - Date.now(),
    },
};

export const AUTODELETE_OPTIONS: Record<string, IAutoDeleteOptions> = {
    [AutoDeletePeriod.None]: {
        id: AutoDeletePeriod.None,
        text: 'Не удалять',
        getValue: () => 0,
    },
    [AutoDeletePeriod.Day]: {
        id: AutoDeletePeriod.Day,
        text: 'Удалить через день',
        getValue: (): number => addDays(new Date(), 1).valueOf(),
    },
    [AutoDeletePeriod.Week]: {
        id: AutoDeletePeriod.Week,
        text: 'Удалить через неделю',
        getValue: (): number => addWeeks(new Date(), 1).valueOf(),
    },
    [AutoDeletePeriod.Month]: {
        id: AutoDeletePeriod.Month,
        text: 'Удалить через месяц',
        getValue: (): number => addMonths(new Date(), 1).valueOf(),
    },
    [AutoDeletePeriod.Months3]: {
        id: AutoDeletePeriod.Months3,
        text: 'Удалить через 3 месяца',
        getValue: (): number => addMonths(new Date(), 3).valueOf(),
    },
    [AutoDeletePeriod.HalfYear]: {
        id: AutoDeletePeriod.HalfYear,
        text: 'Удалить через полгода',
        getValue: (): number => addMonths(new Date(), 6).valueOf(),
    },
    [AutoDeletePeriod.Year]: {
        id: AutoDeletePeriod.Year,
        text: 'Удалить через год',
        getValue: (): number => addYears(new Date(), 1).valueOf(),
    },
};

export const getDomainAccessOptions = (ownerDomain: string | undefined): Record<DomainAccess, DomainAccessOptions> => {
    return {
        [DomainAccess.EXTERNAL]: {
            id: DomainAccess.EXTERNAL,
            text: 'Для всех пользователей',
        },
        [DomainAccess.DOMESTIC]: {
            id: DomainAccess.DOMESTIC,
            text: `Для пользователей домена ${ownerDomain}`,
        },
        [DomainAccess.OFF]: {
            id: DomainAccess.OFF,
            text: 'Никому',
        },
    };
};

export enum QA_VALUE {
    accessItem = 'item_access',
    accessDropdown = 'dropdown_access',
    accessDropdownContent = 'dropdownContent_access',
    expiresItem = 'item_expires',
    expiresDropdown = 'dropdown_expires',
    expiresDropdownContent = 'dropdownContent_expires',
    domesticItem = 'item_domestic',
    domesticDropdown = 'dropdown_domestic',
    domesticDropdownContent = 'dropdownContent_domestic',
    autoDeleteItem = 'item_autoDelete',
    autoDeleteDropdown = 'dropdown_autoDelete',
    autoDeleteDropdownContent = 'dropdownContent_autoDelete',
    downloadItem = 'item_download',
}

export const AB_FEATURE_NAME = 'new-sharing';

export const TOOLTIP_OFFSET = { y: 4 };

export const getTitle = (props: IGetTitleProps): string => {
    const { isPublic, isMounted, name } = props;

    if (IS_BIZ_USER) {
        return `Доступ к «${name}»`;
    }

    if (isPublic) {
        return 'Совместный доступ';
    }

    if (isMounted) {
        return `Доступ к папке «${name}»`;
    }

    return 'Включить совместный доступ';
};
