import { ReactComponent as SuccessIcon } from 'img/icons/successCheckmark.svg';
import React, { memo, ReactNode, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendLinkBySmsRequest } from 'reactApp/modules/smsSender/smsSender.module';
import { SmsSenderSelectors } from 'reactApp/modules/smsSender/smsSender.selectors';
import { GetLinkBySms } from 'reactApp/ui/GetLinkBySms/GetLinkBySms';

import { sendDocumentsGa } from '../PersonalDocuments.helpers';
import styles from './SmsBlock.css';

export const SmsBlock = memo(function SmsBlock() {
    const dispatch = useDispatch();
    const { isLoading, hasError } = useSelector(SmsSenderSelectors.getLifeCycleState);
    const message: string = useSelector(SmsSenderSelectors.getMessage);
    const sendLinkBySmsHandler = useCallback(
        (phone: string, gaCategory: string | undefined) => dispatch(sendLinkBySmsRequest({ phone, gaCategory })),
        [dispatch]
    );

    useEffect(() => {
        sendDocumentsGa('sms-show');
    }, []);

    useEffect(() => {
        if (hasError) {
            sendDocumentsGa('sms-error');
        }
    }, [hasError]);

    let stateMessage: ReactNode | string = '';

    if (hasError) {
        stateMessage = message;
    } else if (message) {
        stateMessage = (
            <div className={styles.messageWrapper}>
                <div className={styles.icon}>
                    <SuccessIcon width={16} height={16} />
                </div>
                <div>
                    <b>Готово!</b>
                    <br />
                    Ссылка на приложение придет в SMS
                </div>
            </div>
        );
    }

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                Установите приложение и снимки
                <br />
                документов автоматически попадут
                <br />в Облако
            </div>

            <GetLinkBySms
                isLoading={isLoading}
                isLoaded={!hasError && !!message}
                hasError={hasError}
                sendLinkBySms={sendLinkBySmsHandler}
                message={stateMessage}
                hideIcon={true}
                title=""
                size="small"
                placeholder="Номер телефона"
                mod="row"
                buttonText="Отправить ссылку"
                gaCategory={'documents'}
                hideForm
            />
        </div>
    );
});
