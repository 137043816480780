import classNames from 'clsx';
import { ReactComponent as WarningIcon } from 'mrg-icons/src/mailru/status/warning_triangle.svg';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { BREAKPOINT_SM } from 'reactApp/constants/breakpoints';
import { useGoToSubscriptions } from 'reactApp/hooks/useGoToSubscriptions';
import { useWindowSize } from 'reactApp/hooks/useWindowSize';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { toggleBin, trashbinLoad } from 'reactApp/modules/trashbin/trashbin.module';
import { getBins, getOpenedBinId } from 'reactApp/modules/trashbin/trashbin.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { FilesCounter } from 'reactApp/ui/FilesCounter/FilesCounter';
import { Informer } from 'reactApp/ui/Informer/Informer';
import { EInformerColor } from 'reactApp/ui/Informer/Informer.types';
import { InlineInformer } from 'reactApp/ui/Informer/InlineInformer/InlineInformer';
import { PageTabMenu } from 'reactApp/ui/PageTabMenu/PageTabMenu';
import { sendGa } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './TrashbinPage.css';

export const TrashbinPage = () => {
    const bins = useSelector(getBins);
    const dispatch = useDispatch();
    const openedBin = useSelector(getOpenedBinId);
    const showOverquotaInfo = useSelector(UserSelectors.isOverQuotaUser);
    const tabs = useMemo(() => bins.map((bin) => ({ value: bin.id, name: bin.name })), [bins]);
    const showTabs = bins.length > 1;

    const currentTabName = tabs.find((tab) => tab.value === openedBin)?.name || '';
    const { goToSubscriptions } = useGoToSubscriptions();
    const { windowWidth } = useWindowSize();

    const handleSetCurrentBin = useCallback(
        (id) => {
            dispatch(toggleBin({ id: openedBin === id ? '' : id }));
        },
        [dispatch, openedBin]
    );

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'basket' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'basket' });

        dispatch(trashbinLoad());
    }, []);

    const handleInformerClick = useCallback(() => {
        sendGa('overquota-informer', 'click');
        goToSubscriptions({ inSameTab: false });
    }, []);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_new_portal_header]: isNewPortalHeader.client,
            })}
        >
            {!ENABLE_FULL_RESPONSIVE || (ENABLE_FULL_RESPONSIVE && windowWidth > BREAKPOINT_SM) ? (
                <div
                    className={classNames(styles.title, {
                        [styles.title_new_portal_header]: isNewPortalHeader.client,
                    })}
                >
                    <BreadcrumbsContainer storage={EStorageType.trashbin} />
                    {!showOverquotaInfo && (
                        <div className={styles.informer}>
                            <InlineInformer color={EInformerColor.red}>Объекты в корзине удаляются через 14 дней</InlineInformer>
                        </div>
                    )}
                    <div className={styles.counter}>{Boolean(openedBin) && <FilesCounter />}</div>
                </div>
            ) : (
                <div className={styles.titleResponsive}>
                    <div className={styles.titleResponsiveHeader}>
                        <BreadcrumbsContainer storage={EStorageType.trashbin} />
                        <div className={classNames(styles.counter, styles.counterResponsive)}>{Boolean(openedBin) && <FilesCounter />}</div>
                    </div>
                    {!showOverquotaInfo && (
                        <div className={classNames(styles.informer, styles.informerResponsive)}>
                            <InlineInformer className={styles.inlineInformerResponsive} color={EInformerColor.red}>
                                Объекты в корзине удаляются через 14 дней
                            </InlineInformer>
                        </div>
                    )}
                </div>
            )}

            {showOverquotaInfo && (
                <div className={styles.informerOverquota}>
                    <Informer
                        color={EInformerColor.red}
                        icon={<WarningIcon height={16} width={18} fill="var(--vkui--color_text_negative--hover)" />}
                    >
                        <div className={styles.informerContent}>
                            Корзина очищается автоматически по прошествии 2 недель.
                            <br />В корзине видны те файлы, для восстановления которых в Облаке хватает места.{' '}
                            <span onClick={handleInformerClick}>Увеличьте Облако</span>, чтобы увидеть и восстановить все файлы
                        </div>
                    </Informer>
                </div>
            )}
            {showTabs && (
                <div className={styles.menu}>
                    <PageTabMenu current={openedBin} tabs={tabs} onClick={handleSetCurrentBin} noVerticalScroll />
                </div>
            )}
            <div
                className={classNames(styles.currentBin, { [styles.currentBin_new_portal_header]: isNewPortalHeader.client })}
                data-qa-name={currentTabName}
            >
                <DataList goTo={noop} storage={EStorageType.trashbin} />
            </div>
        </div>
    );
};
