export enum ESiteZone {
    shoveFile = 1,
    shoveFolder = 2,
    allPrivate = 6,
    folderPublic = 7,
    indiPublic = 8,
    viewerPublic = 9,
}

export enum ESiteZoneErrorPage {
    INTERNAL_SERVER_ERROR = 20,
    NOT_FOUND = 21,
    TOO_MANY_REQUESTS = 22,
    NOT_AVAILABLE = 23,
    GATEWAY_TIMEOUT = 24,
    BAD_BROWSER = 25,
    BAD_BROWSER_ATOM = 25,
}
