import { xray } from 'lib/xray';
import { useMemo } from 'react';
import { IS_IOS_MOBILE } from 'reactApp/appHelpers/configHelpers';
import { iosFavorableTariffs } from 'reactApp/appHelpers/featuresHelpers';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';

import { getSizeRange } from './sizeHelper';

const getElapsedTime = (mountTime: number): number => {
    return Math.ceil(performance.now() - mountTime);
};

export const analyticsTimer = () => {
    const mountTime = performance.now();

    return { getElapsedTime: () => getElapsedTime(mountTime) };
};

interface File {
    attachType?: EAttachTypes;
    storage?: EStorageType;
    ext?: string;
    size?: number;
}

interface Props {
    prefix: string;
    event: string;
    file?: File;
    storage?: EStorageType;
    isErrorEvent?: boolean;
    error?: any;
    time?: number;
}

export const MARKER = 'xx';

/**
 * @description отправляет радар на основе файла (добавяет к радару поля с размером, расширением, типом файла и storage)
 * @example
 * //  xray.send('cloud_test_ok_-_-_-');
 * sendStandardXray({prefix: 'cloud', event: 'test'});
 *  * @example
 * //  xray.send('cloud_no-file_err_-_-_-');
 * sendStandardXray({prefix: 'cloud', isErrorEvent: true, event: 'no-file'});
 *  * @example
 * //  xray.send('cloud_load-file_err_home_doc_mb');
 * sendStandardXray({prefix: 'cloud', event: 'load-file', file: {ext: 'doc', size: 1100000, storage: 'home'}});
 *  * @example
 * //  xray.send('cloud_success_err_cloud-stock_xls_mb');
 * sendStandardXray({prefix: 'cloud', event: 'success', file: {ext: 'xls', size: 1100000, storage: 'attach', attachType: 'cloud_stock'}});
 *  * @example
 * //  xray.send('cloud_time-past_ok_-_-_-_t');
 * sendStandardXray({prefix: 'cloud', event: 'time-past', time: 20});
 */
export const getXrayStr = ({ prefix, event, isErrorEvent, file, storage }: Props) => {
    const attachType = file?.attachType;
    const actualStorage = storage || file?.storage;

    const attachTypeStr = attachType ? `_${attachType.replace('_', '-')}` : '';
    const storageStr = actualStorage ? `_${actualStorage.replace('/', '-')}` : '';
    const fileExtStr = file?.ext ? `_${file?.ext}` : '_-';
    const fileSizeStr = file?.size ? `_${getSizeRange(file.size)}` : '_-';
    const isErrorStr = isErrorEvent ? '_err' : '_ok';

    return `${MARKER}_${prefix.replace('_', '-')}_${event.replace('_', '-')}${isErrorStr}${
        attachTypeStr ? attachTypeStr : storageStr || '_-'
    }${fileExtStr}${fileSizeStr}`;
};

export const sendStandardXray = (props: Props) => {
    const { prefix, event, time, error } = props;

    xray.send(getXrayStr(props), {
        i: time ? { t: time } : undefined,
        rlog: error ? `${prefix}_err` : undefined,
        rlog_message: error
            ? {
                  message: `${prefix}_error_${event}`,
                  error: error instanceof Error ? error : error ? { error: JSON.stringify(error) } : undefined,
              }
            : undefined,
    });
};

export const initStandardAnalytics = (prefix: string, file?: CloudFile, storage?: EStorageType) => {
    let getTime = analyticsTimer().getElapsedTime;
    return {
        resetTimer: () => {
            getTime = analyticsTimer().getElapsedTime;
        },
        sendRadar: (event: string, withTimer = false, isErrorEvent = false, error?: any) => {
            sendStandardXray({
                prefix,
                event,
                file,
                storage,
                time: withTimer ? getTime() : 0,
                isErrorEvent,
                error,
            });
        },
    };
};

export const useStandardAnalytics = (prefix: string, file?: CloudFile, storage?: EStorageType) => {
    return useMemo(() => initStandardAnalytics(prefix, file, storage), [prefix, file, storage]);
};

// tempexp_16799-start
export const sendIosFavorableTariffsAnalytics = (t: string[]) => {
    if (IS_IOS_MOBILE && Boolean(iosFavorableTariffs?.tariffs?.length)) {
        xray.send(['ios-favorable-tariffs', ...t, iosFavorableTariffs.variant || 'control']);
    }
};
// tempexp_16799-end
