import { IS_REACT_PROMO_QUOTA_PARTNER_PAGE, IS_REACT_PROMO_QUOTA_TARIFFS_PAGE } from 'reactApp/appHelpers/configHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureAbQuotaLanding } from 'reactApp/modules/features/features.selectors';
import { MediaSelectors } from 'reactApp/modules/media/media.selectors';
import { State } from 'reactApp/modules/quotaLanding/quotaLanding.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getState = (state: RootState): State => state.quotaLanding as State;

const isMobile = (state: RootState): boolean => {
    if (MediaSelectors.getMediaState(state)) {
        return Boolean(MediaSelectors.isMobile(state));
    }

    return EnvironmentSelectors.isMobile();
};

const getAbLandingFeature = createSelector(getFeatureAbQuotaLanding, getCurrentStorage, (feature, storage) => {
    const { isNewQuotaLanding } = getStorage(storage);

    return feature === 'b' || IS_REACT_PROMO_QUOTA_PARTNER_PAGE || IS_REACT_PROMO_QUOTA_TARIFFS_PAGE || isNewQuotaLanding;
});

export const QuotaLandingSelectors = {
    getState,
    isMobile,
    getAbLandingFeature,
};
