import Emitter from 'Emitter';
import { equals } from 'ramda';

export class PropsStore extends Emitter {
    constructor(props = {}) {
        super(arguments);

        this._props = props;
    }

    getProps = () => ({ ...this._props });

    setProps = props => {
        if (equals(this._props, props)) {
            return;
        }

        this._props = props;

        this.emit('change', [this.getProps()]);
    };
}
