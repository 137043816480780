import React, { memo, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_REACT_PAGE } from 'reactApp/appHelpers/configHelpers';
import { useGoToSubscriptions } from 'reactApp/hooks/useGoToSubscriptions';
import { fileHistoryLoadRequest } from 'reactApp/modules/fileHistory/fileHistory.module';
import { FileHistorySelectors } from 'reactApp/modules/fileHistory/fileHistory.selectors';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import { RootState } from 'reactApp/store';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { sendFilesHistoryGa } from 'reactApp/ui/FileHistory/FileHistory.helpers';
import { IFileHistoryProps } from 'reactApp/ui/FileHistory/FileHistory.types';
import { FileHistoryList } from 'reactApp/ui/FileHistory/FileHistoryList/FileHistoryList';
import { useContent } from 'reactApp/ui/FileHistory/useContent';
import { openSubscriptionsPage } from 'reactApp/utils/openHelpers';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './FileHistory.css';
import { PaidFeatureDialog } from './FileHistoryDialogs/PaidFeatureDialog';

export const FileHistory = memo(({ onClose, itemId }: IFileHistoryProps): ReactElement => {
    const dispatch = useDispatch();
        const [showDialog, setShowDialog] = useState(false);

    const item = useSelector((state: RootState) => getItemById(state, itemId));
    const { isLoading, hasError } = useSelector(FileHistorySelectors.getLoadingState);

    const { goToSubscriptions } = useGoToSubscriptions();

        const content = useContent(item);

    const header = useMemo(() => <div className={styles.header}>{content?.header}</div>, [content?.header]);
    const description = useMemo(() => <div className={styles.description}>{content.description}</div>, [content.description]);

    const handleClose = useCallback(() => {
        sendFilesHistoryGa('close');
        sendPaymentGa({
            action: EPaymentGa.filesHistory,
            label: 'close',
        });
        onClose();
    }, [onClose]);

    const handleSwitchPaidInfo = useCallback(
        () => setShowDialog((state) => !state),
        [],
    );

    const handleOpenTariffs = useCallback(
        () => {
            setShowDialog(false);
            onClose();

            if (IS_REACT_PAGE) {
                goToSubscriptions();
            } else {
                openSubscriptionsPage({ inSameTab: true });
            }
        },
        [goToSubscriptions],
    );

    useEffect(() => {
        sendFilesHistoryGa('show');
        sendPaymentGa({
            action: EPaymentGa.filesHistory,
            label: 'view',
        });
        dispatch(fileHistoryLoadRequest({ id: itemId }));
    }, []);

    return (
        <Dialog size="large" open header={header} id="file-history-modal" className={styles.root} onCancel={handleClose}>
            <Content isModal isLoading={isLoading} wrapClass={styles.content} hasError={hasError}>
                {description}
                <FileHistoryList itemId={itemId} onPaidInfo={handleSwitchPaidInfo}/>
                    {showDialog && <PaidFeatureDialog onClose={handleSwitchPaidInfo} onOpenTariffs={handleOpenTariffs} />}
            </Content>
        </Dialog>
    );
});

FileHistory.displayName = 'FileHistory';
