import classNames from 'clsx';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getPublicOwner } from 'reactApp/modules/public/public.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Button } from 'reactApp/ui/Button/Button';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement, ETooltipSize } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { emitUploadStart, UploadInput } from 'reactApp/ui/UploadInput/UploadInput';
import { loginShow } from 'reactApp/utils/auth';
import { createAvatarUrl } from 'reactApp/utils/avatar';

import styles from './PublicUploadHeader.css';

export const PublicUploadHeader: React.FC = () => {
    const publicUploadPromo = useSelector(PromoSelectors.getPromo(EPromoType.publicUpload));
    const publicOwner = useSelector(getPublicOwner);
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const { email = '', firstName, lastName, nick } = publicOwner || {};
    const ref = useRef<HTMLDivElement>(null);
    const avatar = createAvatarUrl(email, email, 40);

    const name = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`.trim() || nick || email;

    useEffect(() => {
        if (publicUploadPromo) {
            publicUploadPromo.onShow();
        }
    }, [publicUploadPromo]);

    const onUploadClick = useCallback(() => {
        if (isAnonymous) {
            loginShow({
                closable: true,
            });
        } else {
            emitUploadStart({ type: 'file' });
        }
    }, [isAnonymous]);

    return (
        <div className={classNames(styles.root, { [styles.root_newHeader]: isNewPortalHeader.client })}>
            <div className={styles.content}>
                <UploadInput />
                <div className={styles.avatar} style={{ backgroundImage: `url(${avatar})` }} />
                {!!name && (
                    <div>
                        <div className={styles.name}>{name}</div>
                        <div className={styles.text}>сделал эту папку общей и разрешил загружать в нее файлы</div>
                    </div>
                )}
                <div className={styles.button}>
                    <div ref={ref}>
                        <Button primary size="big" onClick={onUploadClick} theme="octavius">
                            Загрузить файлы
                        </Button>
                    </div>
                    {!!publicUploadPromo && (
                        <FloatingTooltip
                            target={ref}
                            placement={ETooltipPlacement.bottomStart}
                            size={ETooltipSize.medium}
                            title="Загружайте файлы вместе"
                            text="В папку может грузить файлы любой, у кого есть ссылка"
                            onClose={publicUploadPromo?.onClose}
                            qaId="tooltip-public-upload"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
