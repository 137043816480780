import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yearlyPriceOnMonthlyTariffButtonTouchExpInfo } from 'reactApp/appHelpers/featuresHelpers';
import { openTariffBuy } from 'reactApp/modules/payment/payment.module';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isEmailAbsent } from 'reactApp/modules/socialUser/socialUser.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { sendWebviewAnalytics } from 'reactApp/sections/MobileQuotaTariffsLanding/MobileQuotaTariffsLanding.helpers';
import { EPostMessageBuy } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.types';
import { getQuotaBillingSource, sendQuotaGa, sendWVRadar } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { revealPlatformPopup } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/TariffPlansCard.helpers';
import { renderConfirmEmailMobile } from 'reactApp/ui/ConfirmEmail/ConfirmEmailMobile/ConfirmEmailMobile.helpers';
import { scrollToTop } from 'reactApp/utils/helpers';
import { sendPostMessage } from 'reactApp/utils/windowHelper';

import { IMobilePayProps, TBuyClick } from './TariffPlansCard.types';

export const useBuyClick = ({
    isMobilePayAvailable,
    isMobilePayFromGetParam,
    isIOSPromo = false,
    isAndroidPromo = false,
}: IMobilePayProps): TBuyClick => {
    const dispatch = useDispatch();
    const storage = useSelector(getCurrentStorage);
    const isSocial = useSelector(UserSelectors.isSocialUser);
    const isSocialUserWithoutEmail = useSelector(isEmailAbsent);
    const isMobile = useSelector(QuotaLandingSelectors.isMobile);

    const { isQuotaTariffs } = getStorage(storage);

    const openBuy = useCallback(
        ({ productId, price, paySource, onClick }) => {
            const onSuccess = () => {
                sendWebviewAnalytics('payok', isMobilePayAvailable);
                sendWVRadar('quota_land_wv_payok', { isIOSPromo, isAndroidPromo, isMobilePayAvailable, isMobilePayFromGetParam });

                // tempexp_16948-next-line
                sendQuotaGa({
                    action: 'tariff',
                    label: 'buy',
                    tariff: productId,
                    amount: price,
                    group: yearlyPriceOnMonthlyTariffButtonTouchExpInfo,
                });

                if (isMobile) {
                    sendPostMessage({
                        paymentResult: EPostMessageBuy.SUCCESS,
                        productId,
                        price,
                    });
                }

                scrollToTop();
            };

            const onError = () => {
                sendWebviewAnalytics('payerror', isMobilePayAvailable);
                sendWVRadar('quota_land_wv_payerror', { isIOSPromo, isAndroidPromo, isMobilePayAvailable, isMobilePayFromGetParam });
                sendQuotaGa({ action: 'tariff', label: 'buy-error', tariff: productId, amount: price });

                if (isMobile) {
                    sendPostMessage({
                        paymentResult: EPostMessageBuy.FAILED,
                    });
                }
            };

            dispatch(
                openTariffBuy({
                    productId,
                    source: getQuotaBillingSource(),
                    checkAnonymous: true,
                    onSuccess,
                    isMobile,
                    onError,
                    paySource,
                    onClick,
                })
            );
        },
        [dispatch, isAndroidPromo, isIOSPromo, isMobile, isMobilePayAvailable, isMobilePayFromGetParam]
    );

    return useCallback(
        ({ productId, price, paySource, onClick }) => {
            sendWebviewAnalytics('tclick', isMobilePayAvailable);
            sendWVRadar('quota_land_wv_tclick', { isIOSPromo, isAndroidPromo, isMobilePayAvailable, isMobilePayFromGetParam });

            if ((isAndroidPromo || isIOSPromo) && !isMobilePayAvailable) {
                revealPlatformPopup(productId, isIOSPromo, isAndroidPromo);
                return;
            }

            // Социальным пользователям показываем окно привязки почты
            if (isQuotaTariffs && isMobile && isSocial && isSocialUserWithoutEmail) {
                renderConfirmEmailMobile({ onSuccess: () => openBuy({ productId, price, paySource }) });
                return;
            }

            // tempexp_16948-next-line
            sendQuotaGa({
                action: 'tariff',
                label: 'click',
                tariff: productId,
                amount: price,
                group: yearlyPriceOnMonthlyTariffButtonTouchExpInfo,
            });

            openBuy({ productId, price, paySource, onClick });
        },
        [
            isIOSPromo,
            isAndroidPromo,
            isMobilePayAvailable,
            isMobilePayFromGetParam,
            isQuotaTariffs,
            isMobile,
            isSocialUserWithoutEmail,
            openBuy,
        ]
    );
};
