import React, { useEffect, VFC } from 'react';
import { IS_REACT_PROMO_QUOTA_TARIFFS_PAGE } from 'reactApp/appHelpers/configHelpers';
import { ACTIONS_DEFS } from 'reactApp/ui/TariffsSection/TariffSection.constants';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import { ACTIVATE_PROMOCODE_CARD, DISCOUNTS } from './Components/DiscountCard/DiscountCard.constant';
import type { Props as DiscountCardProps } from './Components/DiscountCard/DiscountCard.types';
import { DiscountList } from './Components/DiscountList/DiscountList';
import { EmptyDiscounts } from './Components/EmptyDiscounts/EmptyDiscounts';
import type { Discount } from './Discounts.types';

type Props = {
    marketingPromo?: (typeof ACTIONS_DEFS)['marketingPromo'];
    discounts: Discount[];
    isCompact?: boolean;
};

const sendAnalytics = (action: string, label: string) => {
    sendDwh({
        eventCategory: IS_REACT_PROMO_QUOTA_TARIFFS_PAGE ? ECategoryGa.quotaTariffs : ECategoryGa.payment,
        action,
        label,
    });
};

export const Discounts: VFC<Props> = ({ marketingPromo, discounts, isCompact }) => {
    const showDiscounts = discounts.length || marketingPromo;

    const list: Array<Omit<DiscountCardProps, 'onClick' | 'buttonText'>> = [...discounts];
    if (marketingPromo) {
        list.push({
            id: DISCOUNTS.promo,
            type: DISCOUNTS.promo,
            title: 'Чёрная пятница',
            text: 'Самые низкие цены на годовые тарифы!',
        });
    }

    list.push(ACTIVATE_PROMOCODE_CARD);

    useEffect(() => {
        sendAnalytics(showDiscounts ? 'discounts-empty' : 'discounts', 'show');
    }, []);

    return showDiscounts ? <DiscountList data={list} /> : <EmptyDiscounts isCompact={isCompact} />;
};
