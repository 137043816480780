import { Button } from '@vkontakte/vkui';
import classNames from 'clsx';
import hotkeys from 'hotkeys-js';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { ActionPanelSelectors } from 'reactApp/modules/actionpanel/actionpanel.selectors';
/* tempexp_14812-next-line */
import { FeatureSelector } from 'reactApp/modules/features/components/FeatureSelector';
/* tempexp_14812-next-line */
import { chooseVariant } from 'reactApp/modules/features/features.helpers';
/* tempexp_14812-next-line */
import { getFeatureNewEmptyStatesTouch } from 'reactApp/modules/features/features.selectors';
import styles from 'reactApp/ui/UploadDropArea/Upload/Upload.css';
import { UploadButtons } from 'reactApp/ui/UploadDropArea/UploadButtons/UploadButtons';
import { sendGa } from 'reactApp/utils/ga';

interface Props {
    isOpen: boolean;
    isPhone?: boolean;
    text: string;
    title?: string;
    icon: ReactElement | null;
    onMouseEnter: (e: any) => void;
    onMouseLeave: (e: any) => void;
    onClick: (e: React.MouseEvent | KeyboardEvent) => void;
    onFolderClick: (e: React.MouseEvent | KeyboardEvent) => void;
    onFileClick: (e: React.MouseEvent | KeyboardEvent) => void;
    onSelectFromCloud: (e: React.MouseEvent | KeyboardEvent) => void;
}

export const UploadButton = ({
    isOpen,
    text,
    icon,
    onMouseEnter,
    onMouseLeave,
    onClick,
    onFolderClick,
    onFileClick,
    onSelectFromCloud,
    isPhone = false,
    title = 'Загрузить',
}: Props): ReactElement | null => {
    const isActionPanelOpened = useSelector(ActionPanelSelectors.isActionPanelOpened);

    const onMouseEnterHandler = useCallback(
        (event) => {
            onMouseEnter(event);
        },
        [onMouseEnter]
    );

    const onMouseLeaveHandler = useCallback(
        (event) => {
            onMouseLeave(event);
        },
        [onMouseLeave]
    );

    useEffect(() => {
        const hotkey = 'shift+u,shift+i';
        hotkeys(hotkey, (event) => {
            sendGa('hotkey', `shift_${event.key}`);
            if (event.key.toLowerCase() === 'i') {
                onClick(event);
            } else {
                onFolderClick(event);
            }
        });

        return () => {
            hotkeys.unbind(hotkey);
        };
    }, [onClick, onFolderClick]);

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles.open]: isOpen,
                [styles.root_phone]: isPhone,
                [styles.shifted_by_panel]: isActionPanelOpened,
                [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                /* tempexp_14812-next-line */
                [styles.new_empty_states]: chooseVariant((state) => getFeatureNewEmptyStatesTouch(state) && isPhone, {
                    control: false,
                    variant1: true,
                })(),
            })}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            onClick={onClick}
        >
            {/* tempexp_14812-next-line */}
            <FeatureSelector
                selector={(state) => getFeatureNewEmptyStatesTouch(state) && isPhone}
                control={
                    <div className={styles.button}>
                        <div className={styles.icon}>{icon}</div>
                        <div className={styles.buttonText}>{title}</div>
                    </div>
                }
                variant1={
                    <Button size="l" className={styles['touchButton']}>
                        Загрузить
                    </Button>
                }
            />
            <div className={styles.circle}>
                <div className={styles.circleContent}>
                    <div className={classNames(styles.icon, styles.big)}>{icon}</div>
                    <div className={styles.circleText} dangerouslySetInnerHTML={{ __html: text }} />
                    <UploadButtons onFolderClick={onFolderClick} onFileClick={onFileClick} onSelectFromCloud={onSelectFromCloud} />
                </div>
            </div>
        </div>
    );
};
