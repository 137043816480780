import { createSelector } from '@reduxjs/toolkit';
import {
    abDecreaseBaseQuota,
    additionalTariffsList,
    favorableTariffs as favorableTariffsIDs,
    trialTariffsList,
} from 'reactApp/appHelpers/featuresHelpers';
import { getBaseUserQuota } from 'reactApp/modules/family/family.selectors';
import { getQuotaLandingTariffsConfig } from 'reactApp/modules/features/features.selectors';
import { PaidInfoSelectors } from 'reactApp/modules/paidInfo/paidInfo.selectors';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { normalizeMobileTariffList } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.helpers';
import { ITariffsMobile } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.types';
import { RootState } from 'reactApp/store';
import { Product, Tariff } from 'reactApp/types/Billing';
import { ITariffsByTab } from 'reactApp/ui/TariffSectionNew/TariffsSection.types';
import { getDiscount } from 'reactApp/utils/getDiscount';
import { getMonthes, getTariffProductsByPeriods } from 'reactApp/utils/Period';

import { baseYearProduct } from './TariffsPlans.helpers';

export const getMaxTariffDiscount = createSelector(
    [
        (state: RootState) => ProductsSelectors.getTariffsForQuotaLanding(state),
        (state, actionName: string) => ProductsSelectors.getPromoTariffs(state, actionName, true),
        (state, actionName) => actionName,
        // tempexp_16373-next-line
        (state) => ProductsSelectors.getFavorableTariffList(state),
    ],
    // tempexp_16373-next-line
    (nonSpecialTariffs, promoTariffs, actionName, favorableTariffs) => {
        let tariffs: Tariff[] = actionName ? promoTariffs : nonSpecialTariffs;

        // tempexp_16521-next-line
        if (favorableTariffs.length && (!abDecreaseBaseQuota || abDecreaseBaseQuota === 'b')) {
            tariffs = favorableTariffs;
        }

        const discounts = tariffs.map((tariffItem) => {
            const { monthProduct, yearProduct } = getTariffProductsByPeriods(tariffItem.products);

            if (!(monthProduct && yearProduct)) {
                return 0;
            }

            const yearPriceByMonth = getMonthes(yearProduct.period) * monthProduct.price;
            const { discount } = getDiscount({
                discountPrice: yearProduct?.discountPrice || yearProduct.price,
                price: yearPriceByMonth,
            });

            return discount;
        });

        return discounts.length && Math.max(...discounts);
    }
);

export const getTariffsList = createSelector(
    [
        (state: RootState) => ProductsSelectors.getTariffsForQuotaLanding(state),
        (state, actionName: string) => ProductsSelectors.getPromoTariffs(state, actionName, true),
        (state: RootState) => QuotaLandingSelectors.getAbLandingFeature(state),
        (state, actionName) => actionName,
        (state, actionName, isMobile) => isMobile,
        (state: RootState) => getQuotaLandingTariffsConfig(state),
        (state: RootState) => getBaseUserQuota(state),
        (state: RootState) => ProductsSelectors.getNormalizedProductsBySpace(state, trialTariffsList),
        (state: RootState) => PaidInfoSelectors.isFreeUserWithoutTrials(state),
        // tempexp_16373-next-line
        (state: RootState) => ProductsSelectors.getFavorableTariffList(state),
        // tempexp_16521-next-line
        (state: RootState) => UserQuotaSelectors.getLoadingState(state),
        (state) => UserSelectors.isAnonymous(state),
        (state: RootState) => ProductsSelectors.getNormalizedProductsBySpace(state, additionalTariffsList),
    ],
    (
        nonSpecialTariffs,
        promoTariffs,
        isNewLanding,
        actionName,
        isMobile,
        config,
        baseQuota,
        trialsList,
        isFreeUserWithoutTrials,
        // tempexp_16373-next-line
        favorableTariffs,
        // tempexp_16521-next-line
        userQuotaLoadingState,
        isAnonymous,
        additionalTariffs
    ): ITariffsByTab[] | ITariffsMobile[] => {
        let tariffs: Tariff[];

        tariffs = actionName ? promoTariffs : nonSpecialTariffs;

        // tempexp_16373-next-line tempexp_16521-next-line
        if (favorableTariffs.length && (!abDecreaseBaseQuota || abDecreaseBaseQuota === 'b')) {
            tariffs = favorableTariffs;
        }

        const list = tariffs.reduce((acc: ITariffsByTab[], tariffItem) => {
            const products = getTariffProductsByPeriods(tariffItem.products);

            const trial = isFreeUserWithoutTrials ? trialsList[tariffItem.space.original] : undefined;
            const additionalTariff = additionalTariffs[tariffItem.space.original];

            if (products.yearProduct) {
                let recommendedId = isNewLanding ? config.recommendedIdNew : config.recommendedId;

                // tempexp_16521-next-line
                if (favorableTariffsIDs.length && (!abDecreaseBaseQuota || abDecreaseBaseQuota === 'b')) {
                    recommendedId = config.recommendedIdFavorable;
                }

                acc.push({
                    space: tariffItem.space,
                    tariffId: tariffItem.id,
                    // tempexp_16373-next-line
                    isRecommended: tariffItem.id.includes(recommendedId),
                    products: {
                        yearProduct: trial?.yearProduct || additionalTariff?.yearProduct || products.yearProduct,
                        monthProduct: trial?.monthProduct || additionalTariff?.monthProduct || products.monthProduct,
                    },
                });
            }

            return acc;
        }, []);

        // tempexp_16373-next-line tempexp_16521-next-line
        const showDefaultTariffCard =
            !favorableTariffsIDs.length ||
            (abDecreaseBaseQuota && abDecreaseBaseQuota === 'c' && (userQuotaLoadingState.isLoaded || isAnonymous));

        if (showDefaultTariffCard) {
            list.unshift({
                tariffId: 'base',
                space: { ...baseQuota },
                isRecommended: false,
                isBase: true,
                products: {
                    yearProduct: {
                        ...baseYearProduct,
                        space: baseQuota,
                    },
                    monthProduct: undefined,
                },
            });
        }

        if (isMobile) {
            const mobileTariffList = list.reduce((acc, item) => {
                // не показываем базовый тариф на таче
                if (item.isBase) {
                    return acc;
                }

                acc[item.space.original] = [item.products.yearProduct, item.products.monthProduct];
                return acc;
            }, <Record<number, [Product, Product | undefined]>>{});

            return normalizeMobileTariffList(mobileTariffList, Boolean(actionName));
        }

        return list;
    }
);
