import classNames from 'clsx';
import React, { VFC } from 'react';
import { Link } from 'react-router-dom';
import { ACTIONS_DEFS, ETabsName } from 'reactApp/ui/TariffsSection/TariffSection.constants';

import { useHandlers } from '../hooks/useHandlers';
import { TABS_MAP } from '../MobileSubscriptionsPage.constants';
import { useTariffBuyDialog } from '../MobileSubscriptionsPage.hooks';
import { TariffCard } from '../TariffCard/TariffCard';
import styles from './Tariff.css';

type Props = {
    marketingPromo?: (typeof ACTIONS_DEFS)['marketingPromo'];
    tariffs: any[];
};

export const Tariffs: VFC<Props> = ({ marketingPromo, tariffs }) => {
    const { handleFAQClick, handleAllTariffsClick } = useHandlers();
    const { openTariffBuy } = useTariffBuyDialog();

    return (
        <>
            <div className={styles.wrapper}>
                {tariffs.map((tariff, index) => (
                    <div className={classNames(styles.tariff, { [styles.tariff_action]: marketingPromo })} key={tariff.products?.[0].id}>
                        <TariffCard
                            {...tariff}
                            onClick={openTariffBuy}
                            // tempexp_13600-next-line
                            openAbCard={index === 0}
                        />
                    </div>
                ))}
            </div>

            {marketingPromo && (
                <div className={styles.actionLinks}>
                    <Link to={TABS_MAP[ETabsName.usual]} className={styles.buttonLink} onClick={handleAllTariffsClick}>
                        Все тарифы
                    </Link>
                    <a className={styles.buttonLink} href={marketingPromo.conditions} target="_blank" rel="noreferrer">
                        Полные условия акции
                    </a>
                </div>
            )}

            {tariffs.length === 0 && <div className={styles.noTariff}>Нет доступных тарифов</div>}

            <div
                className={classNames(styles.commonLinks, {
                    [styles.commonLinks_actionPromo]: !!marketingPromo,
                })}
            >
                <a
                    className={styles.link}
                    href="https://help.mail.ru/cloud_web/size/increase"
                    onClick={handleFAQClick}
                    target="_blank"
                    rel="noreferrer"
                    data-name="cancel-subscription"
                >
                    Как отменить подписку?
                </a>
            </div>
        </>
    );
};
