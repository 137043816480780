import settings from 'Cloud/settings';
import { IS_AUTO_TEST_HIDE } from 'reactApp/appHelpers/configHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { productsController } from 'reactApp/modules/products/products.controller';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { EProductPeriod } from 'reactApp/modules/products/products.types';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage, isReactLandingPage } from 'reactApp/modules/router/router.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUserQuotaSuccess } from 'reactApp/modules/userQuota/userQuota.module';
import { store } from 'reactApp/store';
import { renderMobileSplashScreen } from 'reactApp/ui/Mobile/SplashScreen/helpers/SplashScreen.helpers';
import { useOverquotaSplashScreen } from 'reactApp/ui/Mobile/SplashScreen/hooks/useOverquotaSplashScreen';
import { renderOverquotaBanner } from 'reactApp/ui/OverquotaPopup/OverquotaPopup.helpers';
import { put } from 'redux-saga/effects';
import { call, select, take } from 'typed-redux-saga';

export const QUOTA_TIP_ID = 'overQuotaTip';

export function* initOverQuotaPromo() {
    const storage = yield* select(getCurrentStorage);
    const { isHome } = getStorage(storage);
    const isPhone = yield* select(EnvironmentSelectors.isPhone);
    const isWebview = yield select(EnvironmentSelectors.isWebview);
    const isAnonymous = yield* select(UserSelectors.isAnonymous);
    const isLP = isReactLandingPage();
    const { action = null } = yield* select(SettingsSelectors.getQueryParams);

    if (isAnonymous) {
        return;
    }

    if (action === 'request-payment') {
        return;
    }

    if (isPhone && (isWebview || settings?.request?.action || isLP)) {
        return;
    }

    if (isPhone && (storage === EStorageType.subscriptions || storage === EStorageType.family)) {
        return;
    }

    if (!isPhone && (!isHome || IS_AUTO_TEST_HIDE)) {
        return;
    }

    yield take(loadUserQuotaSuccess.toString());

    const isOverQuota = yield* select(UserSelectors.isOverQuotaUser);
    const { over } = yield* select(UserSelectors.getCloudSpace);

    if (!isOverQuota) {
        return;
    }

    yield productsController.loadProducts();
    const { product, tariff } = yield select(ProductsSelectors.getProductAndTariffByQuota, over.original, EProductPeriod.year, false);

    if (!product) {
        return;
    }

    if (!product || !tariff) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.overQuota,
            promoId: QUOTA_TIP_ID,
            onShow: () => store.dispatch(promoShown(EPromoType.overQuota)),
            onClose: () => store.dispatch(removePromo(EPromoType.overQuota)),
            tariff,
            product,
        })
    );
}

export function* showOverquotaPopup() {
    const overquotaPromo = yield* select(PromoSelectors.getPromo(EPromoType.overQuota));
    const isPhone = yield* select(EnvironmentSelectors.isPhone);

    if (!overquotaPromo) {
        return;
    }

    overquotaPromo.onShow();
    if (isPhone) {
        yield call(
            renderMobileSplashScreen,
            { contentHook: useOverquotaSplashScreen, hookParams: { productId: overquotaPromo.product.id } },
            overquotaPromo.onClose
        );
    } else {
        yield call(renderOverquotaBanner, { tariff: overquotaPromo.tariff }, overquotaPromo.onClose);
    }
}
