import { useSelector } from 'react-redux';
import { ACTION_PROMO } from 'reactApp/appHelpers/configHelpers';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { RootState } from 'reactApp/store';
import { Tariff } from 'reactApp/types/Billing';

export const useTariffs = ({ isPro, isOneTariff }: { isPro: boolean; isOneTariff: boolean }): Tariff[] => {
    const userSpace = useSelector(UserQuotaSelectors.getTotal);

    // tempexp_16790-start
    const defaultTariffs = useSelector((state: RootState) => ProductsSelectors.overquotaPopupDefaultTariffs(state, ACTION_PROMO));
    const proTariff = useSelector((state: RootState) =>
        ProductsSelectors.overquotaPopupProTariff(state, userSpace?.original || 0, ACTION_PROMO, false)
    );
    const discountTariff = useSelector((state: RootState) =>
        ProductsSelectors.overquotaPopupProTariff(state, userSpace?.original || 0, ACTION_PROMO, true)
    );
    // tempexp_16790-end

    if (isPro && proTariff) {
        return [proTariff];
    }

    if (isOneTariff && discountTariff) {
        return [discountTariff];
    }

    return defaultTariffs.slice(0, 3);
};
