import { xray } from 'lib/xray';
import { IAllDocumentsState } from 'reactApp/modules/allDocuments/allDocuments.types';
import { getFeatureAllDocuments } from 'reactApp/modules/features/features.selectors';
import { store } from 'reactApp/store';

const radarName = 'alldocuments';

export const allDocumentsHideSpaceRadar = (isHideSpace: boolean) => xray.send(`${radarName}_space_${isHideSpace ? 'hidden' : 'visible'}`);

/**
 * Технические радары страницы всех документов.
 */
export const allDocumentsRadars = ({ section, action }: { section: IAllDocumentsState['currentDocType']; action: string }) =>
    ({
        onSuccess: () => xray.send(`${radarName}_${section}_${action}_success`),
        onError: () => xray.send(`${radarName}_${section}_${action}_error`),
    } as const);

/**
 * Преобразовывает все разрешенные расширения для раздела Документы в единый массив
 */
export const getAllDocumentsAlllowedExt = <T>(docExtensions: T): string[] => {
    return Object.values(docExtensions).reduce<string[]>((allowedExt, currentType) => allowedExt.concat(currentType), []);
};

/**
 * По расширению файла определяем возможность отображения его в разделе документов.
 */
export const getAvailableSectionInAllDocuments = (extension: string) => {
    const state = store.getState();
    const feature = getFeatureAllDocuments(state);

    for (const section in feature) {
        if (feature[section].includes(extension)) {
            return section;
        }
    }

    return null;
};

export const isAvailableInDocumentSection = (extension: string) => {
    const state = store.getState();
    const feature = getFeatureAllDocuments(state);

    return feature.document.includes(extension);
};
